import SubText from "components/shared/SubText";
import MusicSkeleton from "components/skeleton/MusicSkeleton";
import { RouteName } from "constant/routeNames";
import PlaylistCoursel from "pages/musicPage/playlist/PlaylistCoursel";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PlaylistService from "services/music/PlaylistService";

function WelcomePageDiscoverPlaylists({ welcomePage }: any) {
  const [isLoadingPlaylist, setIsLodingPlaylist] = useState<any>(false);
  const [publicPlaylists, setPublicPlaylists] = useState<any>([]);
  const [isLoading, setIsLoding] = useState<any>(false);

  useEffect(() => {
    setIsLoding(true);
    setIsLodingPlaylist(true);
    PlaylistService.getPublicPlaylists()
      .then((res: any) => {
        setIsLodingPlaylist(false);
        setIsLoding(false);
        // console.log("first", res.data.data);
        setPublicPlaylists(res.data.data);
      })
      .catch((e) => {
        setIsLoding(false);
        setIsLodingPlaylist(false);
        // setIsLoding(false);
        // console.log(e);
      });
  }, []);
  return (
    <div>
      {!isLoading && (
        <>
        {publicPlaylists.length != '0' && (
         <>
          <div className="mb-20">
            <div className="flex flex-row items-center w-[95%] justify-between mt-5">
              <SubText text="Discover Playlists" className="font-bold" />
              <Link to={RouteName.SeeAllDiscoverPlaylistsRoute}>
                <p className="font-[500] pr-4 text-[15px] text-[#F50537] cursor-pointer">
                  See all
                </p>
              </Link>
            </div>
            <PlaylistCoursel
              playlists={publicPlaylists}
              isLoadingPlaylist={isLoadingPlaylist}
              welcomePage={welcomePage}
              isLoading = {isLoading}
            />
          </div>
          </>
        )}
        </>
      )}
      {isLoading && <MusicSkeleton />}
    </div>
  );
}

export default WelcomePageDiscoverPlaylists;
