import BackButton from "components/shared/BackButton";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PayPerViewCard from "./shared/PayPerViewCard";
import Pagination from "pages/podcastPage/shared/Pagination";
import PayperviewPickedout from "components/skeleton/PayperviewPickedout";
import PayperviewService from "services/payperview/PayperviewServie";

function SeeAllPurchasedPage() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [purchased, setPurchased] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  // const [genres, setGenres] = useState<any>();
  // const [selectedGenre, setSelectedGenre] = useState("");

  useEffect(() => {
    setIsLoading(true);
    PayperviewService.getPurchasedEvents(loggedInUser?.id)
      .then((res) => {
        setIsLoading(false);
        setPurchased(res.data);
      })
      .catch((e: any) => {
        console.log("getPurchasedEvents ", e);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <BackButton />
      {!isLoading && (
        <>
          <div>
            <div className="flex items-center justify-between mb-8 mr-5 max-sm:block max-sm:mr-0">
              <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
                All Purchased Events
              </h1>
              {/* <GenreSelector
              selectedGenre={selectedGenre}
              setSelectedGenre={setSelectedGenre}
              genres={genres}
            /> */}
            </div>
            {/* {gAdSlot && (
            <Adsense
              client="ca-pub-5773929890067376"
              slot={gAdSlot}
              style={{ display: 'flex', marginBottom:'20px', marginTop:'20px', height:'90px', justifyContent: 'center' }}
              format="horizontal"
              data-full-width-responsive="true"
              // format="fluid"
            />
          )} */}
          </div>
          {purchased && (
            <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full">
              {purchased?.map((event: any, index: any) => (
                <div key={index} className="mb-5 mr-4">
                  <PayPerViewCard
                    event={event}
                    isPurchased={purchased.some((payperviewEvent: any) =>
                      payperviewEvent?.id === event?.id ? true : false
                    )}
                    user={loggedInUser}
                    // favorite={favorite}
                  />
                </div>
              ))}
            </div>
          )}
          {/* <div className="flex justify-center my-5">
            <Pagination
              totalPages={lastPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            /> */}
          {/* {Array.from({ length: 3 }, (_, i) => (
          <button
            key={i}
            onClick={() => setCurrentPage(i + 1)}
            className={`mx-2 px-4 py-2 rounded ${
              currentPage === i + 1 ? "bg-[#f30543] text-white" : "bg-white"
            }`}
          >
            {i + 1}
          </button>
        ))}  */}
          {/* </div> */}
        </>
      )}
      {isLoading && <PayperviewPickedout />}
    </>
  );
}
export default SeeAllPurchasedPage;
