import React from "react";

const BackAndTextSkeleton = () => {
  return (
    <>
      <div className="w-10 h-10 mb-5 bg-[#1a1919] rounded-full animate-pulse max-sm:ml-[16px]"></div>
      <div className="flex flex-row justify-between">
        <div className="w-[250px] h-[30px] bg-[#1a1919]"></div>
        {/* <div className="w-[100px] h-[30px] mr-[50px] bg-[#1a1919]"></div> */}
      </div>
    </>
  );
};

export default BackAndTextSkeleton;
