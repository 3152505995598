import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PlaylistCard from "./PlaylistCard";
import SubText from "components/shared/SubText";
import NoSearch from "pages/searchPage/shared/NoSearch";

function PlaylistCoursel({ creatorData, isLoading }: any) {
  const playlists = creatorData?.playlists || [];
  // console.log(playlists);
  return (
    <div>
      {/* card section */}
      {playlists.length === 0 && playlists ? (
        <NoSearch text="Playlist" />
      ) : (
        <div>
          <div className="mt-4 mb-5 w-[95%] grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 max-sm:mx-auto max-sm:w-full max-sm:gap-5 ">
            {playlists.length > 0 &&
              playlists.map((playlist: any, i: any) => (
                <div key={i}>
                  <PlaylistCard playlist={playlist} creatorData={creatorData} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PlaylistCoursel;
