import { useEffect, useState } from 'react';
import CategoryTrackRow from './CategoryTrackRow';
import { useSelector } from 'react-redux';
import LikeService from 'services/music/LikeService';

function CategoryTrack({
  tracks,
  seeAll,
  userPlaylists,
  setUserPlaylists,
}: any) {
  // console.log(tracks);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [favorite, setFavorite] = useState<any>();

  const visibleSongs = seeAll ? tracks : tracks?.slice(0, 9);

  useEffect(() => {
    if (loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, loggedInUser?.id)
        .then((res) => {
          const favoritesIds = res?.profile?.loved?.data?.map(
            (item: any) => item.id
          );
          setFavorite(favoritesIds);
        })
        .catch(() => {});
    }
  }, [loggedInUser, loggedInUser?.id]);
  return (
    <>
      {visibleSongs && (
        <div className="max-h-[700px] overflow-y-scroll scrollbar-hide">
          {visibleSongs?.map((track: any, i: number) => (
            <CategoryTrackRow
              track={track}
              key={i}
              userPlaylists={userPlaylists}
              setUserPlaylists={setUserPlaylists}
              favorite={favorite}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default CategoryTrack;
