import UserPageNavButton from "components/shared/UserPageNavButton";
import React, { useEffect, useState } from "react";
import HomePage from "../HomePage";
import VideosPage from "../VideosPage";
import PlayListsPage from "../PlayListsPage";
import AboutPage from "../AboutPage";
import LiveTvPage from "../LiveTvPage";
import RadioPage from "../RadioPage";
import PodcastPage from "../PodcastPage";
import MusicPage from "../MusicPage";
import MoviesPage from "../MoviesPage";
import SearchPageSkeleton from "components/skeleton/SearchPageSkeleton";
import PayperviewPage from "../PayperviewPage";

function VideoCreatorButtonSection({
  creatorData,
  isLoading,
  setIsLoading,
}: any) {
  const [isSelected, setIsSelected] = useState<any>(1);

  const [components, setComponents] = useState<any>({
    id: 1,
    component: HomePage,
    name: "HOME",
    data: creatorData?.recent_videos,
  });

  const filterAndCapitalizeButtons = (creatorData: any) => {
    const filteredButtons = [
      {
        id: 1,
        component: HomePage,
        name: "HOME",
        data: creatorData?.recent_videos,
      },
      {
        id: 2,
        component: LiveTvPage,
        name: "LIVE TV",
        data: creatorData?.tv_channels,
      },
      {
        id: 3,
        component: VideosPage,
        name: "VIDEOS",
        data: creatorData?.videos | creatorData?.movies,
      },
      // {
      //   id: 4,
      //   component: MoviesPage,
      //   name: "MOVIES",
      //   data: creatorData?.movies.data,
      // },
      {
        id: 5,
        component: MusicPage,
        name: "MUSIC",
        data: creatorData?.albums,
      },
      {
        id: 6,
        component: PodcastPage,
        name: "PODCAST",
        data: creatorData?.podcasts,
      },
      {
        id: 7,
        component: RadioPage,
        name: "RADIO",
        data: creatorData?.radios,
      },
      {
        id: 8,
        component: PayperviewPage,
        name: "PPV",
        data: creatorData?.ppvEvents,
      },
      {
        id: 9,
        component: PlayListsPage,
        name: "PLAYLISTS",
        data: creatorData?.playlists,
      },
      {
        id: 10,
        component: AboutPage,
        name: "ABOUT",
      },
    ];

    const visibleButtons = filteredButtons.filter((button) => {
      if (button.data) {
        return button.data.length > 0;
      } else {
        return true; // Always show HOME button
      }
    });

    return visibleButtons.map((button) => ({
      ...button,
      name: button.name.toUpperCase(),
    }));
  };

  // Usage
  const visibleButtons = filterAndCapitalizeButtons(creatorData);

  useEffect(() => {
    if (visibleButtons.length > 0) {
      // setIsSelected(visibleButtons.[0]);
      setIsSelected(visibleButtons[0].id);
      setComponents({
        id: visibleButtons[0].id,
        component: visibleButtons[0].component,
        name: visibleButtons[0].name,
        data: visibleButtons[0].data,
      });
    }
  }, [creatorData]);

  return (
    <>
      {!isLoading && (
        <div>
          {/* <HeroBoosterSection follow="257 Followers" /> */}
          <div className="flex items-center justify-start w-full gap-4 my-5 max-sm:grid max-sm:grid-cols-3 max-xl:grid max-xl:grid-cols-5 max-md:grid max-md:grid-cols-4 max-2xl:grid-cols-6 max-sm:mx-0 max-sm:gap-2">
            {visibleButtons.map((button: any, i: number) => (
              <div key={i} className="">
                <UserPageNavButton
                  pages={button}
                  setIsSelected={setIsSelected}
                  isSelected={isSelected}
                  selected={button.id === isSelected ? true : false}
                  isLoading={isLoading}
                  setComponents={setComponents}
                />
              </div>
            ))}
          </div>

          <div id="section1" className=" text-white mb-[150px]">
            {components && (
              <components.component
                creatorData={creatorData}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      )}
      {isLoading && <SearchPageSkeleton />}
    </>
  );
}

export default VideoCreatorButtonSection;
