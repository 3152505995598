import React, { useEffect, useState } from "react";
import AnimatedText from "components/shared/AnimatedText";
import { RouteName } from "constant/routeNames";
import { useNavigate } from "react-router-dom";

function AudioDetails({
  currentSong,
  fullScreen,
  setFullScreen,
  music,
  radio,
  podcast,
}: any) {
  const navigate = useNavigate();

  const enableFullScreenMode = () => {
    if (window.innerWidth < 640 && !radio) {
      setFullScreen(true);
    }
  };

  const handleArtistPage = () => {
    setFullScreen(false);
    navigate(
      RouteName.MusicArtistPage.replace(":slug", currentSong?.artists[0]?.slug)
    );
  };

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const currentStyle = isLargeScreen ? imageStyles.large : imageStyles.small;

  // const dispatch = useDispatch<any>();
  // const handleClearQueue = () => {
  //   setTimeout(() => {
  //     dispatch(clearDataWhenLogout());
  //   }, 500);
  // };
  return (
    <>
      {currentSong && (
        <div className="flex justify-start items-center gap-2">
          <img
            src={
              music || radio
                ? currentSong.artwork_url
                : podcast
                ? currentSong.podcast.artwork_url
                : ``
            }
            width={`60px`}
            height={`60px`}
            alt=""
            className={`rounded-lg drop-shadow-2xl`}
            onClick={enableFullScreenMode}
          />

          {/* song details */}
          <div className="">
            {/* music */}
            {(music || radio) && (
              <>
                {currentSong?.title?.length > 16 ? (
                  <>
                    {music && (
                      <AnimatedText
                        text={currentSong.title}
                        classname={`text-left text-[16px] text-white scroll-text max-md:hidden`}
                      />
                    )}
                    {radio && (
                      <p
                        className={`text-left text-[16px] text-white max-md:hidden`}
                      >
                        {currentSong?.title}
                      </p>
                    )}
                    <p className={`text-left text-[16px] text-white md:hidden`}>
                      {`${currentSong?.title.slice(0, 16)}...`}
                    </p>
                  </>
                ) : (
                  <>
                    <p className={`text-left text-[16px] text-white`}>
                      {currentSong?.title}
                    </p>
                  </>
                )}
                {music && (
                  <p
                    className={`hover:underline cursor-pointer text-[#7B9DB4] mt-2 text-[12px] max-sm:text-[11px] font-thin`}
                    onClick={handleArtistPage}
                  >
                    {currentSong?.artists && currentSong?.artists[0]?.name}
                  </p>
                )}
              </>
            )}

            {/* podcast */}
            {podcast && (
              <>
                {currentSong?.title?.length > 35 ? (
                  <>
                    <AnimatedText
                      text={currentSong.title}
                      classname={`text-left text-[16px] text-white scroll-text max-md:hidden`}
                    />
                    <p className={`text-left text-[16px] text-white md:hidden`}>
                      {`${currentSong?.title.slice(0, 16)}...`}
                    </p>
                  </>
                ) : (
                  <>
                    <p className={`text-left text-[16px] text-white`}>
                      {currentSong?.title}
                    </p>
                  </>
                )}
                <p
                  className={` text-[#7B9DB4] mt-2 text-[12px] max-sm:text-[11px] font-thin`}
                >
                  {currentSong?.artist && currentSong?.artist?.name}
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default AudioDetails;
