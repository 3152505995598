import { RouteName } from "constant/routeNames";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setVideoStart, setVideoSuccess } from "redux/VideoPlayerRedux";
import { Tooltip as ReactTooltip } from "react-tooltip";

function SingleEpisode({ data, id, season, event }: any) {
  const dispatch = useDispatch<any>();
  const { currentVideo } = useSelector((state: any) => state?.video);
  const navigate = useNavigate();

  const episodeOnClick = () => {
    const value = {
      ...data,
      // tv_series_id: id,
      // season: season,
    };
    dispatch(setVideoStart());
    dispatch(setVideoSuccess(value));
    // window.location.reload();
    navigate("/ppv/replay/" + event?.slug + "/" + data?.stream_key, {
      state: { prevPage: event?.slug },
    });
  };

  return (
    // <div
    //   className={`flex items-center w-[411px]  backdrop:blur-md rounded-md my-4 p-2 h-[55px] cursor-pointer ${
    //     currentVideo.id === data.id ? "bg-white/80" : "bg-black/50"
    //   }`}
    //   onClick={episodeOnClick}
    // >
    <div
      className={`flex items-center w-full   backdrop:blur-md rounded-md my-4 min-h-[60px] cursor-pointer  max-sm:w-full bg-black bg-opacity-50 overflow-hidden hover:border hover:border-red-500  ${
        currentVideo?.id === data?.id ? "border border-red-700" : " "
      }`}
      onClick={episodeOnClick}
    >
      {/* image */}
      <img
        src={event?.thumbnailImage}
        alt="thumbnail"
        className="w-[80px] rounded-md h-[50px] bg-cover"
      />
      {/* details */}
      <div
        className="w-full ml-2"
        data-tooltip-id={
          data?.title?.length > 30 ? `ppv-single-recording-${data?.title}` : ""
        }
      >
        {/* title */}
        <p
          className={`${
            currentVideo?.id === data?.id
              ? "text-gray-800 font-semibold"
              : "text-gray-300"
          }`}
        >
          {data?.title?.length > 30
            ? `${data?.title
                ?.slice(0, 30)
                .replace(/&amp;/g, "&")
                .replace(/&rsquo;/g, "’")
                .replace(/&nbsp;/g, "")}...`
            : data?.title
                ?.replace(/&amp;/g, "&")
                .replace(/&rsquo;/g, "’")
                .replace(/&nbsp;/g, "")}
        </p>
        {/* progress bar */}
        <div className="z-50 text-sm">
          <ReactTooltip
            id={`ppv-single-recording-${data?.title}`}
            place="top"
            content={data?.title}
            style={{ width: "180px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default SingleEpisode;
