import { useState } from "react";
import Question from "components/shared/Question";
import SubText from "components/shared/SubText";

function Accodion() {
  const Allquestions = [
    {
      id: 1,
      title:
        "How do I access the pay-per-view event or rent a movie on AYOZAT?",
      info: "After making a purchase, check your inbox for an email containing event details. You can add it to your calendar immediately to ensure you don't miss your purchased event!",
    },
    {
      id: 2,
      title: "Can I watch the event or rented movie on multiple devices?",
      info: "Enjoy your purchased content on up to three different devices, each connected to a unique IP address.",
    },
    {
      id: 3,
      title: "How can I pay for the pay-per-view event or rented movie?",
      info: "Once you have chosen your preferred PPV content, there is a payment tab that says 'Rent' or 'Reserve.' Clicking on that tab will take you to the payment page where you can choose your preferred payment method: credit/debit card or PayPal. We accept all major credit cards. Currently, crypto payments are not available, but they will be an option soon.",
    },
    {
      id: 4,
      title:
        "Can I access the pay-per-view event or rented movie from any location?",
      info: "While most content is available worldwide, some may be restricted in certain regions due to geolocation features.",
    },
    {
      id: 5,
      title: "How long do I have access to a rented movie?",
      info: "The duration of access varies, ranging from a day to a week or a month. Details are displayed when you click on the PPV movie. Live events are available only during the event itself.",
    },
    {
      id: 6,
      title: "Can I get a refund for my purchase?",
      info:(
        
        <span>To request a refund, email us at <a href="mailto:ppv@ayozat.com" className="red-text">ppv@ayozat.com</a> with the order number and screenshots of the issues within 48 hours of the live stream for PPV events or within 48 hours of the VOD purchase. Refunds are processed within 5-12 days if they meet the eligibility standards, and additional proof may be required. Refunds may cover the entire or partial cost for acceptable technical mistakes. 
      No refunds for inaccurate information, inadequate equipment/network, non-compliance with instructions, events beyond our control, PPV event duration changes, VPN-related unavailability, change of mind, or reasons not listed. 
      Refunds for Live Streamed PPV Events are only for multiple purchase errors, event cancellations, or unavailability due to technical issues on our end. For Video-On-Demand Programming, refunds are only for multiple purchase errors, removal of programming, or technical errors from our end.
      The full refund process is available in the Terms and Conditions.</span>
        )
    },
    {
      id: 7,
      title: "How do I contact support?",
      info: (
        <span>
          We're here to help! If you're facing an issue, get in touch with our
          support team via email:{" "}
          <a href="mailto:ppv@ayozat.com" className="red-text">ppv@ayozat.com</a>
        </span>
      ),
    },
    {
      id: 8,
      title: "How do I check my payment history?",
      info: "To review your payment history, click on your profile picture in the top right corner, then select 'Transactions.' This will lead you to your purchase history, which includes purchased events, order numbers, purchase status, amount, and transaction time.",
    },
  ];
  const [questions, setQuestions] = useState(Allquestions);
  return (
    <main>
      <div className="q-container max-sm:w-[95%]">
        <div className="flex justify-center mb-5">
          <SubText
            text={"Frequently Asked Questions"}
            className="my-4 font-bold "
          />
        </div>
        <section className="info">
          {questions.map((question) => (
            <Question key={question.id} {...question} />
          ))}
        </section>
      </div>
    </main>
  );
}

export default Accodion;
