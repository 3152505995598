import React from "react";
import { FaShare } from "react-icons/fa";
import { triggerSuccessNotification } from "utils/notification";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Notification } from "constant/notifications";

function ShareButton({ shareUrl }: any) {
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(shareUrl);
    triggerSuccessNotification(Notification.CopyToClipboard);
  };
  return (
    <div>
      <p
        className="flex items-center mx-auto text-base font-bold text-white cursor-pointer hover:bg-[#48484895] py-3 px-5 border-[#f30543] border-2 rounded-full duration-200 ease-in-out"
        onClick={copyToClipboard}
      >
        <FaShare className="mr-3" /> Share
      </p>
      <ReactTooltip id="copy-media-link" place="bottom" content="Copy Link" />
    </div>
  );
}

export default ShareButton;
