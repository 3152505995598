import React, { useEffect, useState } from "react";
import Followers from "./shared/Followers";
import FollowHeroSection from "./shared/FollowHeroSection";
import UserProfileService from "services/UserProfileService";
import { useParams } from "react-router-dom";
import NoFollowers from "./shared/NoFollowers";
import { useSelector } from "react-redux";
import FollowPageSkeleton from "components/skeleton/FollowPageSkeleton";
import { Helmet } from "react-helmet-async";

function UserFollowers() {
  const { username } = useParams();
  const [followers, setFollowers] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    UserProfileService.getUserByUserName(username).then((res) => {
      setIsLoading(false);
      console.log(res.user.followers);
      setFollowers(res.user.followers);
    });
  }, [username]);

  return (
    <>
      <Helmet>
        <title>
          {/* {`${name} - Ayozat`} */}
          {username ? `${username} - AYOZAT` : "AYOZAT"}
        </title>
        <meta name="title" content={`${username} - AYOZAT`} />
        {/* <meta name="description" content={user?.username} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${user?.name} - Ayozat`} />
        <meta name="twitter:description" content={user?.username} />
        <meta name="twitter:image" content={user?.artwork_url} />
        <meta name="twitter:site" content="AYOZAT" />
        <meta
          name="twitter:url"
          content={`${FE_URL}/profile/${user?.username}`}
        />*/}

        <meta property="og:title" content={`${username} - AYOZAT`} />
        {/* <meta property="og:image" content={user?.artwork_url} /> */}
        <meta property="og:description" content={username} />
        <meta
          property="og:url"
          // content={`${FE_URL}/profile/${user?.username}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>

      {!isLoading && (
        <div>
          {followers && followers?.length === 0 ? (
            <div>
              <NoFollowers />
            </div>
          ) : (
            <>
              <div>
                <FollowHeroSection text="Followers" />
              </div>
              <div>
                {followers && (
                  <>
                    <div className="grid w-full grid-cols-2 mt-4 mb-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full">
                      {followers?.map((friend: any, i: number) => (
                        <div key={i}>
                          <Followers followFriends={friend} />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {isLoading && <FollowPageSkeleton />}
    </>
  );
}

export default UserFollowers;
