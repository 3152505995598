import React, { useEffect, useState } from "react";
import RadioHomeCard from "./RadioHomeCard";
import RadioService from "services/radio/RadioService";
import MostTunedSkeleton from "../skeleton/MostTunedSkeleton";

function RadioHomeCardSection({ channels, isLoadingMostTuned }: any) {
  // const [channels, setChannels] = useState<any[]>([]);
  // const [isLoadingMostTuned, setIsLoadingMostTuned] = useState<any>(false);

  // useEffect(() => {
  //   setIsLoadingMostTuned(true);
  //   RadioService.getMostTunedRadioStations().then((res) => {
  //     // console.log(res.mostTunedRadioStations);
  //     if (res.mostTunedRadioStations.length < 3) {
  //       setIsLoadingMostTuned(false);
  //       setChannels(res.mostTunedRadioStations);
  //     } else {
  //       setIsLoadingMostTuned(false);
  //       setChannels(res.mostTunedRadioStations.slice(0, 3));
  //     }
  //   });
  // }, []);

  return (
    <>
      {!isLoadingMostTuned && (
        <div>
          <div className="flex justify-between max-sm:hidden">
            {channels &&
              channels?.map((value: any, i: number) => (
                <RadioHomeCard data={value} key={i} />
              ))}
          </div>
          <div className="justify-between block sm:hidden">
            {channels && <RadioHomeCard data={channels && channels[0]} />}
          </div>
        </div>
      )}
      {isLoadingMostTuned && <MostTunedSkeleton />}
    </>
  );
}

export default RadioHomeCardSection;
