import { useEffect, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useDispatch } from "react-redux";
import "styles/audioplayer.css";
import CustomProgressBar from "./Components/CustomProgressBar";
import SongQueue from "./Components/SongQueue";
import ControlButtons from "./NewComponents/ControlButtons";
import AudioDetails from "./NewComponents/AudioDetails";
import OtherButtons from "./NewComponents/OtherButtons";
import PlayerSection from "./NewComponents/FullScreen/PlayerSection";
import backgroundImage from "Assets/Images/Music/PlayerFullScreen.svg";
import MobileQueue from "./NewComponents/FullScreen/MobileQueue";
import QueueSection from "./NewComponents/FullScreen/QueueSection";
import { handleVisibleQueue, toggleFullScreen } from "redux/AudioPlayerRedux";

function AyozatAudioPlayer({
  playlist,
  currentAudio,
  playlistLoading,
  setCurrentAudio,
  handlePlayCount,
  isActive,
  radio = false,
  music = false,
  podcast = false,
}: any) {
  const dispatch = useDispatch<any>();

  const [nextId, setNextId] = useState<number>(0);
  const [isRandom, setIsRandom] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [visibleQueue, setVisibleQueue] = useState<any>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [repeat, setRepeat] = useState<number>(2);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [fullScreen, setFullScreen] = useState<any>(false);

  const [loadingStreamingUrl, setLoadingStreamingUrl] =
    useState<boolean>(false);
  const audioRef = useRef<any>(null);
  const popRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<any>(`${window.innerHeight - 110}px`);
  const [queueHeight, setQueueHeight] = useState<any>(
    `${window.innerHeight - 220}px`
  );
  const [mobileQueueHeight, setMobileQueueHeight] = useState<any>(
    `${window.innerHeight - 240}px`
  );

  // store full screen mode in redux
  useEffect(() => {
    dispatch(toggleFullScreen(fullScreen));
    dispatch(handleVisibleQueue(visibleQueue));
  }, [fullScreen, visibleQueue]);

  useEffect(() => {
    // console.log(window.innerHeight - 110);
    setHeight(`${window.innerHeight - 110}px`);
    setQueueHeight(`${window.innerHeight - 100}px`);
  }, [fullScreen]);

  const [pageLoading, setPageLoading] = useState<boolean>(false);

  useEffect(() => {
    if (pageLoading) {
      setLoadingStreamingUrl(true);
    }
  }, [currentAudio]);

  useEffect(() => {
    setLoadingStreamingUrl(false);
    setPageLoading(true);
  }, []);

  useEffect(() => {
    if (!currentAudio) {
      audioRef.current.audio.current.pause();
    }

    if (playlist) {
      const existingAudioIndex = playlist.findIndex(
        (audio: any) => audio.id === currentAudio?.id
      );
      if (existingAudioIndex >= 0) {
        if (playlist.length > 1) {
          const nxtId = existingAudioIndex + 1;
          if (nxtId > playlist?.length - 1) {
            setNextId(0);
          } else {
            setNextId(nxtId);
          }
        } else {
          setNextId(existingAudioIndex);
        }
      }
    }
  }, [currentAudio, currentAudio?.id, playlist]);

  const skipRandom = () => {
    const id = playlist.findIndex(
      (audio: any) => audio.id === currentAudio?.id
    );
    const idNum = id;
    const randomNum = Math.floor(Math.random() * playlist.length);
    if (randomNum === idNum) {
      const newNum = randomNum + 1;
      if (newNum >= playlist.length) {
        const rNum = newNum - 1;
        if (rNum === idNum) {
          dispatch(setCurrentAudio(playlist && playlist[rNum - 1]));
        } else {
          dispatch(setCurrentAudio(playlist && playlist[rNum]));
        }
      } else {
        dispatch(setCurrentAudio(playlist && playlist[newNum]));
      }
    } else {
      dispatch(setCurrentAudio(playlist && playlist[randomNum]));
    }
  };

  const skipForward = () => {
    const id = playlist.findIndex(
      (audio: any) => audio.id === currentAudio?.id
    );
    if (id === null) {
      alert("Choose a song!");
    } else if (isRandom) {
      skipRandom();
    } else if (id === playlist.length - 1) {
      dispatch(setCurrentAudio(playlist && playlist[0]));
    } else {
      const idNum = id;
      const newId = idNum + 1;
      if (newId > playlist.length - 1) {
        dispatch(setCurrentAudio(playlist && playlist[0]));
      } else {
        dispatch(setCurrentAudio(playlist && playlist[newId]));
      }
    }
  };

  const skipBack = () => {
    const id = playlist.findIndex(
      (audio: any) => audio.id === currentAudio?.id
    );
    if (id === null) {
      alert("Choose a song!");
    } else {
      const idNum = id;
      const newId = idNum - 1;
      if (newId < 0) {
        dispatch(setCurrentAudio(playlist && playlist[playlist.length - 1]));
      } else {
        dispatch(setCurrentAudio(playlist && playlist[newId]));
      }
    }
  };

  const handleSongEnd = () => {
    if (isRandom) {
      skipRandom();
    } else {
      if (repeat === 0) {
        if (playlist?.length <= 1) {
          audioRef.current.audio.current.currentTime = 0; // Reset playback position
          audioRef.current.audio.current.play();
        } else {
          skipForward();
        }
      } else if (repeat === 1) {
        audioRef.current.audio.current.currentTime = 0; // Reset playback position
        audioRef.current.audio.current.play(); // Resume playback
      } else {
      }
    }
  };

  useEffect(() => {
    const updateProgress = () => {
      if (
        audioRef.current &&
        audioRef.current.audio &&
        audioRef.current.audio.current
      ) {
        const currentTime = audioRef.current.audio.current.currentTime;
        const duration = audioRef.current.audio.current.duration;
        const progressPercentage = (currentTime / duration) * 100;
        setProgress(progressPercentage);

        setCurrentTime(currentTime);
        setDuration(duration);
      }
    };

    const audioElement =
      audioRef.current &&
      audioRef.current.audio &&
      audioRef.current.audio.current;
    if (audioElement) {
      audioElement.addEventListener("timeupdate", updateProgress);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener("timeupdate", updateProgress);
      }
    };
  }, []);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      audioRef.current.audio.current.pause();
    } else {
      audioRef.current.audio.current.play();
    }
  };

  const toggleShuffle = () => {
    setIsRandom(!isRandom);
  };

  const showQueue = () => {
    setVisibleQueue((state: any) => !state);
  };

  useEffect(() => {
    const elements = document.getElementsByClassName(
      "._orientationRight_f49a.wrap_ae00"
    );
    console.log(elements);
    Array.from(elements).forEach((element) => {
      const htmlElement = element as HTMLElement; // Cast to HTMLElement
      htmlElement.style.display = visibleQueue ? "block" : "none";
    });
  }, [visibleQueue]);

  useEffect(() => {
    if (playlist && playlist?.length <= 1) {
      setVisibleQueue(false);
    }
  }, [playlist]);

  const toggleMute = () => {
    setIsMuted((prevIsMuted) => !prevIsMuted);
    if (audioRef.current && audioRef.current.audio.current) {
      audioRef.current.audio.current.muted = !isMuted;
    }
  };

  //   full screen functions
  useEffect(() => {
    if (fullScreen) {
      window.scrollTo(0, 0);
    }
    document.body.style.overflow = fullScreen ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [fullScreen]);

  // seek bar
  const handleSeek = (seekPercentage: any) => {
    if (
      audioRef.current &&
      audioRef.current.audio &&
      audioRef.current.audio.current &&
      !loadingStreamingUrl
    ) {
      const duration = audioRef.current.audio.current.duration;
      const seekTime = (seekPercentage / 100) * duration;
      audioRef.current.audio.current.currentTime = seekTime;
      setProgress(seekPercentage);
    }
  };

  // handle loading button
  useEffect(() => {
    if (audioRef.current.audio) {
      const audioElement = audioRef.current.audio.current;

      const handleTimeUpdate = () => {
        if (audioElement.currentTime >= 0.1) {
          setLoadingStreamingUrl(false);
          audioElement.removeEventListener("timeupdate", handleTimeUpdate);
        }
      };

      audioElement.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [currentAudio]);

  useEffect(() => {
    const currentAudioTime = audioRef.current.audio.current.currentTime;

    if (currentAudioTime > 0 && duration > 0) {
      const persentageTime = (currentAudioTime / duration) * 100;
      if (isPlaying && persentageTime >= 10 && persentageTime < 10.1) {
        if (handlePlayCount) {
          handlePlayCount(currentAudio?.id);
        }
      }
    }
  }, [currentAudio?.id, isPlaying, currentTime, duration]);

  useEffect(() => {
    // Access the audio element from the audioRef
    const audioElement = audioRef.current.audio.current;

    // Check if the audioElement exists and currentAudio is defined
    if (audioElement && currentAudio) {
      // Set attributes for the audio element
      audioElement.title = currentAudio?.title ? currentAudio?.title : "";
      audioElement.setAttribute(
        "artist",
        currentAudio?.artists ? currentAudio?.artists[0]?.name : ""
      );
    }
  }, [currentAudio]);

  // minimize option
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popRef.current && !popRef.current.contains(event.target as Node)) {
        setFullScreen(false);
      }
    };
    if (fullScreen) {
      window.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [fullScreen]);

  return (
    <>
      {/* {currentSong && ( */}
      <div
        className={`audio-player-container z-auto ${
          !fullScreen ? `relative` : `absolute top-[75px] w-full text-white`
        } ${fullScreen ? "fullscreen-mode" : ""}`}
        ref={popRef}
      >
        <div
          className={`${
            !fullScreen
              ? `fixed left-0 z-50 w-full duration-500 ${
                  visibleQueue
                    ? "bottom-0"
                    : "bottom-[-100px] max-sm:bottom-[0px] max-md:bottom-[-100px] max-lg:bottom-[-100px] max-2xl:bottom-[-100px]"
                }`
              : `relative ${
                  isActive ? `ml-24 duration-500` : `ml-0 duration-500`
                }`
          } ${radio && `sm:mb-[90px] max-sm:mb-[-10px] !h-[90px]`}`}
        >
          <AudioPlayer
            ref={audioRef}
            src={currentAudio && currentAudio.stream_url}
            // src={process.env.PUBLIC_URL + "/music/song.m4a"}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onEnded={handleSongEnd}
            showSkipControls
            showJumpControls={false}
            onClickNext={skipForward}
            onClickPrevious={skipBack}
            autoPlayAfterSrcChange
            autoPlay
            loop={repeat === 1 ? true : false}
            customProgressBarSection={[
              <>
                {!radio && (
                  <CustomProgressBar
                    progress={progress}
                    onSeek={handleSeek}
                    fullScreen={fullScreen}
                    show={true}
                  />
                )}
              </>,
            ]}
            customControlsSection={[
              <div
                className={`audio-player-container ${
                  !fullScreen
                    ? `audio-player-minimized`
                    : `audio-player-full-screen scrollbar-hide`
                }`}
              >
                <div
                  className={`flex text-white  ${
                    !fullScreen
                      ? `w-full md:justify-between max-md:justify-between`
                      : `justify-around gap-5 m-5`
                  }`}
                >
                  {!fullScreen ? (
                    // minimized view
                    <>
                      <div className="flex items-center justify-start md:w-1/3">
                        <AudioDetails
                          currentSong={!playlistLoading && currentAudio}
                          fullScreen={fullScreen}
                          setFullScreen={setFullScreen}
                          music={music}
                          radio={radio}
                          podcast={podcast}
                        />
                      </div>
                      <div className="flex items-center justify-center gap-3 md:w-1/3">
                        <ControlButtons
                          fullScreen={fullScreen}
                          isPlaying={isPlaying}
                          loadingStreamingUrl={loadingStreamingUrl}
                          togglePlayPause={togglePlayPause}
                          skipForward={!radio && skipForward}
                          skipBack={!radio && skipBack}
                          toggleShuffle={!radio && toggleShuffle}
                          isRandom={!radio && isRandom}
                          currentTime={!radio && currentTime}
                          repeat={!radio && repeat}
                          setRepeat={!radio && setRepeat}
                          duration={!radio && duration}
                          playlist={playlist}
                          music={music}
                          radio={radio}
                          podcast={podcast}
                        />
                      </div>
                      <div className="flex items-center justify-end md:w-1/3 max-md:hidden">
                        <OtherButtons
                          audioRef={audioRef}
                          showQueue={showQueue}
                          visibleQueue={visibleQueue}
                          isMuted={isMuted}
                          setIsMuted={setIsMuted}
                          toggleMute={toggleMute}
                          fullScreen={fullScreen}
                          setFullScreen={setFullScreen}
                          currentAudio={currentAudio}
                          playlist={playlist}
                          music={music}
                          radio={radio}
                          podcast={podcast}
                        />
                      </div>
                    </>
                  ) : (
                    // full screen view
                    <div
                      className={`flex max-md:block justify-evenly items-center text-white w-full`}
                      style={{
                        height: `${height}`,
                      }}
                    >
                      {/* player section */}
                      <div
                        className="w-full h-full px-5 bg-[#35353564]"
                        style={{
                          backgroundImage: `${
                            fullScreen ? `url(${backgroundImage}` : ``
                          })`,
                          backgroundSize: "cover", // Adjust this as needed
                          backgroundPosition: "center", // Adjust this as needed
                        }}
                      >
                        <PlayerSection
                          currentSong={!playlistLoading && currentAudio}
                          fullScreen={fullScreen}
                          setFullScreen={setFullScreen}
                          music={music}
                          radio={radio}
                          podcast={podcast}
                          progress={progress}
                          onSeek={handleSeek}
                          currentTime={!radio && currentTime}
                          duration={!radio && duration}
                          controlSection={
                            <ControlButtons
                              fullScreen={fullScreen}
                              isPlaying={isPlaying}
                              loadingStreamingUrl={loadingStreamingUrl}
                              togglePlayPause={togglePlayPause}
                              skipForward={!radio && skipForward}
                              skipBack={!radio && skipBack}
                              toggleShuffle={!radio && toggleShuffle}
                              isRandom={!radio && isRandom}
                              currentTime={!radio && currentTime}
                              repeat={!radio && repeat}
                              setRepeat={!radio && setRepeat}
                              duration={!radio && duration}
                              music={music}
                              radio={radio}
                              podcast={podcast}
                              playlist={playlist}
                            />
                          }
                        />
                      </div>

                      {/* queue section */}
                      <div
                        className="w-full px-3 max-sm:px-0"
                        style={{
                          height: `${height}`,
                        }}
                      >
                        {/* desktop queue */}
                        <div className="w-full h-full max-sm:hidden">
                          <QueueSection
                            nextSong={playlist && playlist[nextId]}
                            music={music}
                            radio={radio}
                            podcast={podcast}
                            playlist={playlist}
                            currentSong={currentAudio}
                          />
                        </div>

                        {/* mobile queue */}
                        <div
                          className="w-full sm:hidden"
                          style={{
                            height: `${mobileQueueHeight}`,
                          }}
                        >
                          <MobileQueue
                            mobileQueueHeight={mobileQueueHeight}
                            playlist={playlist}
                            currentAudio={currentAudio}
                            music={music}
                            radio={radio}
                            podcast={podcast}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>,
            ]}
          />

          {!radio && (
            <div
              className={`${
                !fullScreen ? `text-white max-sm:hidden` : `hidden`
              }`}
            >
              <SongQueue
                musics={playlist}
                currentSong={currentAudio}
                music={music}
                radio={radio}
                podcast={podcast}
              />
            </div>
          )}
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default AyozatAudioPlayer;
