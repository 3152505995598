import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const MusicSkeleton = () => {
  const arr = [1, 2, 3, 4, 5, 6];
  return (
    <div className="mt-10">
      <div className="">
        <Swiper
          slidesPerView={6}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            650: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
            1100: {
              slidesPerView: 5,
            },
            1200: {
              slidesPerView: 6,
            },
            1500: {
              slidesPerView: 7,
            },
            1600: {
              slidesPerView: 8,
            },
          }}
          className="mt-4 mb-5 mySwiper mx-auto"
        >
          {/* <div className='flex justify-between'>
                        <div className='w-[200px] h-[30px] mb-5 bg-[#1a1919] animate-pulse mt-2'></div>
                        <div className='w-[100px] h-[30px] mb-5 bg-[#1a1919] animate-pulse mt-2'></div>
                    </div> */}
          <div className="flex flex-row lg:gap-[20px] sm:gap-[20px]">
            {arr.map((item: any) => (
              <div
                key={item}
                className="flex flex-col justify-center items-start"
              >
                <div className="lg:w-[200px] h-[250px] bg-[#1a1919] animate-pulse mt-2"></div>
              </div>
            ))}
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default MusicSkeleton;
