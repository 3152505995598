import FilterSection from 'components/shared/FilterSection';
import CardGridSection from 'pages/videoPage/shared/CardGridSection';
import { useEffect } from 'react';

function VideoPageHistory() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* main topic part */}
      <div className="mb-8 flex items-center justify-between w-[98%] max-sm:block">
        {/* right corner */}
        <div>
          <h1 className="text-2xl font-medium text-white lg:text-3xl md:text-3xl max-sm:text-3xl max-sm:ml-5">
            User's History
          </h1>
        </div>
        {/* left corner */}

        <FilterSection />
      </div>

      {/* card section */}
      <CardGridSection />
    </div>
  );
}

export default VideoPageHistory;
