import { RouteName } from "constant/routeNames";
import React from "react";
import { Link } from "react-router-dom";

function RadioGenreCard({ genre }: any) {
  // console.log(genre);
  return (
    <>
      <Link
        to={RouteName.Radio.replace(
          "*",
          RouteName.RadioChannelsByCategory.replace(":slug", genre.alt_name)
        )}
      >
        <div className="h-16 w-[70%] relative cursor-pointer bg-gradient-to-r from-[#272424] to-[#f30543] opacity-50 hover:opacity-100 rounded-lg max-sm:w-[95%]">
          <div className="absolute inset-0 flex items-center justify-center mx-auto text-center">
            <p className="text-white font-[800] text-md">{genre.name.replace(/&amp;/g, '&')}</p>
          </div>
          {/* <img
          className="h-20 w-[97%] object-cover opacity-30 hover:opacity-70"
          src={`${"https://townsquare.media/site/366/files/2022/02/attachment-slash-20202.jpg"}`}
          alt="/"
        /> */}
        </div>
      </Link>
    </>
  );
}

export default RadioGenreCard;
