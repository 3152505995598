import { Notification } from "constant/notifications";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { updateFavorites } from "redux/userRedux";
import LikeService from "services/music/LikeService";
import {
  triggerErrorNotification,
  triggerSuccessNotification,
} from "utils/notification";

function SubCribeButton({ podcast }: any) {
  // console.log(podcastId);
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  const [addFav, setAddFav] = useState<boolean>(false);
  // const [favorite, setFavorite] = useState<any>();
  // console.log(loggedInUser.id);

  // useEffect(() => {
  //   LikeService.getFavoriteById(loggedInUser.id, loggedInUser?.id)
  //     .then((res) => {
  //       const favoritesIds = res.profile.favorite_podcasts.map(
  //         (item: any) => item.id
  //       );
  //       setFavorite(favoritesIds);
  //     })
  //     .catch(() => {});
  // }, [loggedInUser.id]);

  const addFavToRedux = () => {
    const updatedPodcastData = [...favorite?.favorite_podcasts, podcast];
    const updatedFavorite = {
      ...favorite,
      favorite_podcasts: updatedPodcastData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const removeFavFromRedux = () => {
    // const updatedPodcastData = [...favorite?.favorite_podcasts, podcast];
    const updatedPodcastData = favorite?.favorite_podcasts.filter(
      (favpodcast: any) => favpodcast.id !== podcast.id
    );
    const updatedFavorite = {
      ...favorite,
      favorite_podcasts: updatedPodcastData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  useEffect(() => {
    const favoritePodcastIds = favorite?.favorite_podcasts.map(
      (item: any) => item.id
    );
    if (podcast && favoritePodcastIds) {
      if (favoritePodcastIds?.includes(podcast?.id)) {
        setAddFav(true);
      } else {
        setAddFav(false);
      }
    }
  }, [favorite, podcast]);

  // useEffect(() => {
  //   if (favorite?.includes(podcastId)) {
  //     setAddFav(true);
  //     // console.log("true");
  //   } else {
  //     setAddFav(false);
  //     // console.log("false");
  //   }
  // }, [favorite]);

  // const handleFavorite = () => {
  //   const favoritePodcast = {
  //     id: podcastId.toString(),
  //     object_type: "podcast",
  //   };

  //   if (!addFav) {
  //     LikeService.likeByUserId(favoritePodcast).then((res) => {
  //       // console.log("like sucessful");
  //     });
  //     setAddFav(true);
  //   } else {
  //     LikeService.unLikeByUserId(favoritePodcast).then((res) => {
  //       // console.log("unlike sucessful");
  //     });
  //     setAddFav(false);
  //   }
  // };

  const handleFavorite = (e: any) => {
    e.preventDefault();
    // console.log(addFav);
    const favoritepodcast = {
      id: podcast.id.toString(),
      object_type: "podcast",
    };

    if (!addFav) {
      setAddFav(true);
      LikeService.likeByUserId(favoritepodcast).then((res) => {
        if (res.status) {
          setAddFav(true);
          // updateFav();
          addFavToRedux();
          triggerSuccessNotification(Notification.AddToFav);
        } else {
          setAddFav(false);
          triggerErrorNotification(Notification.FavError);
        }
      });
    } else {
      setAddFav(false);
      LikeService.unLikeByUserId(favoritepodcast).then((res) => {
        if (res.status) {
          triggerSuccessNotification(Notification.RemovedFromFav);
          // updateFav();
          removeFavFromRedux();
          setAddFav(false);
        } else {
          setAddFav(true);
          triggerErrorNotification(Notification.FavError);
        }
      });
    }
  };
  return (
    <p
      className="flex items-center mx-auto text-base font-bold text-white cursor-pointer hover:bg-[#48484895] py-3 px-5 border-[#f30543] border-2 rounded-full duration-200 ease-in-out"
      onClick={handleFavorite}
    >
      {addFav ? (
        "Subscribed"
      ) : (
        <>
          <FaPlus className="mr-3" /> Subscribe to podcast
        </>
      )}
    </p>
  );
}

export default SubCribeButton;
