import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import AlbumService from "services/music/AlbumService";
import AlbumCard from "./shared/AlbumCard";
import MusicSeeAllSkeleton from "components/skeleton/MusicSeeAllSkeleton";
import { Helmet } from "react-helmet-async";
import Pagination from "pages/podcastPage/shared/Pagination";
import { Adsense } from "@ctrl/react-adsense";
import GeneralServices from "services/General/GeneralServices";
import { RouteName } from "constant/routeNames";

function SeeAllAlbums() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page") || "1";
  const pageNoInt = parseInt(page, 10);
  const [albums, setAlbums] = useState<any>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [url, setUrl] = useState(`albums?page=${pageNoInt}`);
  const [currentPage, setCurrentPage] = useState<any>(pageNoInt);
  const [lastPage, setLastPage] = useState<any>();
  const [gAdSlot, setgAdSlot] = useState<any>();
  const currentUrl = window.location.pathname;
  const path = currentUrl + `?page=${currentPage}`;

  useEffect(() => {
    const handlePopstate = () => {
      const newPage =
        new URLSearchParams(window.location.search).get("page") || "1";
      setCurrentPage(parseInt(newPage, 10));
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_music_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setUrl(`albums?page=${currentPage}`);
  }, [currentPage, navigate]);

  useEffect(() => {
    setLoading(true);
    setIsLoading(true);
    setTimeout(() => {
      AlbumService.getAllAlbums(url).then((res) => {
        setLastPage(res?.albums.last_page);
        setIsLoading(false);
        setAlbums(res.albums.data);
      });
      setLoading(false);
    }, 1000);
  }, [url]);

  useEffect(() => {
    navigate(RouteName.SeeAllAlbumsRoute + `?page=${currentPage}`);
  }, [currentPage, navigate]);

  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  return (
    <>
      <Helmet>
        <title>All Albums - AYOZAT Music</title>
        {/* Meta tags */}
      </Helmet>

      {!isLoading && (
        <div>
          <div>
            <button
              className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
              onClick={() => {
                navigate(RouteName.Music.replace("/*", ""));
              }}
            >
              <AiOutlineArrowLeft className="ml-3" />
            </button>
          </div>
          <div className="mb-8">
            <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
              Albums
            </h1>
          </div>
          {gAdSlot && (
            <Adsense
              client="ca-pub-5773929890067376"
              slot={gAdSlot}
              style={{
                display: "flex",
                marginBottom: "30px",
                marginTop: "20px",
                height: "90px",
                justifyContent: "center",
              }}
              format="horizontal"
              data-full-width-responsive="true"
              // format="fluid"
            />
          )}
          {!loading ? (
            albums && (
              <div>
                <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full">
                  {albums?.map((album: any, index: number) => (
                    <div key={index} className="mb-5 mr-4">
                      <AlbumCard {...album} path={path} />
                    </div>
                  ))}
                </div>
                <div className="flex justify-center my-5">
                  {albums?.length === 0 && albums ? (
                    <></>
                  ) : (
                    <>
                      <Pagination
                        totalPages={lastPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </>
                  )}
                </div>
              </div>
            )
          ) : (
            <>Loading</>
          )}
        </div>
      )}
      {isLoading && <MusicSeeAllSkeleton />}
    </>
  );
}

export default SeeAllAlbums;
