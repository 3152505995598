/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import PopUpModel from "components/PopUpModel";
import SubText from "components/shared/SubText";
import { Epg, Layout, useEpg } from "planby";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LiveTVService from "services/liveTv/LiveTVService";
import { theme } from "styles/EpgTheme";
import {
  addMissingPrograms,
  break24HourLongProgrma,
  convertFromGetAllChannels,
  convertToLocalDate,
} from "utils/convertLiveTVData";
import LiveTVGenreSelector from "../shared/LiveTVGenreSelector";
import TVProgramPopup from "../shared/TVProgramPopup";
import ChannelItem from "./ChannelItem";
import ProgramItem from "./ProgramItem";
import { setCurrentChannelId } from "redux/VideoPlayerRedux";

//  main function
function TvGuide({ setDataList }: any) {
  const dispatch = useDispatch<any>();
  const [channels, setChannels] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { channelCategory } = useSelector((state: any) => state?.video);

  const [selectProgram, setSelectProgram] = useState({});
  const [programViewPopup, setProgramViewPopup] = useState(false);
  // const [epg, setEpg] = useState(ProgramsData);
  const [epg, setEpg] = useState<any[]>([]);
  const [error, setError] = useState("");
  const [category, setCategory] = useState<any>(channelCategory);

  useEffect(() => {
    setCategory(channelCategory);
  }, [channelCategory]);

  const fetchJsonEpg = async () => {
    await LiveTVService.getJsonEpg().then((res) => {
      let data: any[] = res.data?.data;
      let timeFxed:any[] = convertToLocalDate(data);
      let missingEpgs: any[] = addMissingPrograms(channels, timeFxed);
      let chunks: any[] = break24HourLongProgrma(missingEpgs);
      setEpg(chunks);
    });
  };

  const fetchChannels = async () => {
    await LiveTVService.getAllChannels()
      .then((res) => {
        setChannels([]);
        res.data.map((d: any, i: number) => {
          let c: any = convertFromGetAllChannels(d);
          setChannels((prevChannels) => {
            // Check if the new channel (d) already exists in the channels array
            const exists = prevChannels.some(
              (channel) => channel?.uuid === c?.uuid
            ); // Assuming there's an "id" property to identify the channel
            // If it doesn't exist, add the new channel to the channels array
            if (!exists) {
              return [...prevChannels, c];
            }
            // If it already exists, return the previous channels array as it is
            return prevChannels;
          });
          setError("");
        });
      })
      .catch((e) => {
        // console.log(e);
        setError("No Channels Available");
      });
  };

  const fetchChannelsByCategory = async (c: any) => {
    await LiveTVService.getLiveTvByCategory(c)
      .then((res) => {
        setChannels([]);
        // console.log(res);
        res.data.map((d: any, i: number) => {
          let c: any = convertFromGetAllChannels(d);
          setChannels((prevChannels) => {
            const exists = prevChannels.some(
              (channel) => channel?.uuid === c?.uuid
            );
            if (!exists) {
              return [...prevChannels, c];
            }
            return prevChannels;
          });
        });
        setError("");
      })
      .catch((e) => {
        setError("No Channels Available");
      });
  };

  const epgData = useMemo(() => epg, [epg]);
  const channelsData = useMemo(() => channels, [channels, channelCategory]);

  const handleFetchResourcesByCategory = useCallback(async () => {
    setIsLoading(true);
    // fetchEpg();
    if (channelCategory === "all") {
      await fetchChannels();
    } else {
      await fetchChannelsByCategory(channelCategory);
    }
    // await fetchJsonEpg();
    setIsLoading(false);
  }, [channelCategory]);

  useEffect(() => {
    // Use useEffect to fetchJsonEpg whenever channels data changes
    if (channels.length > 0) {
      setIsLoading(true);
      fetchJsonEpg()
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          // Handle errors here
          // console.error('Error fetching JSON EPG:', error);

          setIsLoading(false);
        });
    }
    setDataList([...channels]);
    let newId: any = 0;
    dispatch(setCurrentChannelId(newId));
  }, [channels]);

  useEffect(() => {
    handleFetchResourcesByCategory();
  }, [channelCategory]);

  const { getEpgProps, getLayoutProps } = useEpg({
    epg: epgData,
    channels: channelsData,
    sidebarWidth: 150,
    isBaseTimeFormat: true,
    isTimeline: true,
    isLine: true,
    theme,
    dayWidth: 6000,
    // startDate: new Date().toISOString(), // or 2022-02-02T00:00:00
    // startDate: "2023-07-01T00:00:00",
  });
  return (
    <>
      <div className="flex items-center justify-between mb-5 max-sm:my-3 ">
        <div className="flex max-sm:w-[100px]">
          <SubText text="TV Guide" className="font-bold" />
        </div>
        <div className="flex max-sm:pr-5">
          <LiveTVGenreSelector category={category} setCategory={setCategory} />
        </div>
      </div>
      {error ||
        (channels.length === 0 && (
          <div className="items-center h-full my-10">
            <p className="flex items-center justify-center text-base text-red-500">
              No Channels Available
            </p>
          </div>
        ))}
      <div style={{ height: "880px", width: "100%" }}>
        <Epg isLoading={isLoading} {...getEpgProps()}>
          <Layout
            {...getLayoutProps()}
            // renderTimeline={(props) => <TimeLine {...props} />}
            renderChannel={({ channel }) => (
              <ChannelItem key={channel.uuid} channel={channel} />
            )}
            renderProgram={({ program, ...rest }) => (
              <ProgramItem
                key={program.data.id}
                program={program}
                setSelectProgram={setSelectProgram}
                setProgramViewPopup={setProgramViewPopup}
                {...rest}
              />
            )}
          />
        </Epg>
      </div>

      {programViewPopup && (
        <PopUpModel
          isOpen={programViewPopup}
          onClose={() => {
            setProgramViewPopup(false);
          }}
          width={"400px"}
        >
          <TVProgramPopup
            setProgramViewPopup={setProgramViewPopup}
            selectProgram={selectProgram}
            setSelectProgram={setSelectProgram}
          />
        </PopUpModel>
      )}
    </>
  );
}

export default TvGuide;
