import { RouteName } from "constant/routeNames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function PodcastGenreSelector({ slug, categories }: any) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(slug);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // navigate(`${RouteName.PodcastDiscoverRoute}/${selected}`);
    navigate(
      RouteName.Podcast.replace(
        "*",
        RouteName.PodcastDiscoverByGenreId.replace(":slug", selected)
      )
    );
    // setGenre(selected);
  }, [selected]);

  const onChange = (e: any) => {
    setSelected(e.target.value);
  };

  const handledropDown = () => {};

  return (
    <div className="mr-5">
      <label
        htmlFor="watched"
        className="text-white max-sm:hidden max-md:hidden"
      >
        Genres{" "}
      </label>
      <select
        name="cars"
        id="cars"
        className="p-1 ml-2 text-white rounded-md outline-none searchbar max-h-[60px] overflow-y-auto"
        onChange={onChange}
        value={selected}
      >
        {categories?.map((c: any, i: number) => (
          <option value={c.alt_name} key={i}>
            {c.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default PodcastGenreSelector;
