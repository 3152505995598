import { Notification } from "constant/notifications";
import { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineHeart,
  AiOutlineStar,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  triggerErrorNotification,
  triggerSuccessNotification,
} from "utils/notification";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { updateFavorites } from "redux/userRedux";
import PodcastService from "services/podcast/PodcastService";

function AddPodcastToFav({
  podcast,
  Icon,
  podcastCard,
}: //   hover,
//   addFav,
//   setAddFav,
any) {
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  // console.log(favorite);
  const [addFav, setAddFav] = useState<boolean>(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const favoritePodcastIds = favorite?.favorite_podcasts.map(
      (item: any) => item.id
    );
    if (podcast && favoritePodcastIds) {
      if (favoritePodcastIds?.includes(podcast?.id)) {
        setAddFav(true);
      } else {
        setAddFav(false);
      }
    }
  }, [favorite, podcast]);

  const addFavToRedux = () => {
    const updatedPodcastData = [...favorite?.favorite_podcasts, podcast];
    const updatedFavorite = {
      ...favorite,
      favorite_podcasts: updatedPodcastData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const removeFavFromRedux = () => {
    // const updatedPodcastData = [...favorite?.favorite_podcasts, podcast];
    const updatedPodcastData = favorite?.favorite_podcasts.filter(
      (favpodcast: any) => favpodcast.id !== podcast.id
    );
    const updatedFavorite = {
      ...favorite,
      favorite_podcasts: updatedPodcastData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const handleFavorite = (e: any) => {
    e.preventDefault();
    // console.log(addFav);
    const favoritepodcast = {
      id: podcast.id.toString(),
      object_type: "podcast",
    };

    if (!addFav) {
      setAddFav(true);
      PodcastService.likePodcast(favoritepodcast)
        .then((res) => {
          if (res.status) {
            setAddFav(true);
            // updateFav();
            addFavToRedux();
            triggerSuccessNotification(Notification.AddToFav);
          } else {
            setAddFav(false);
            triggerErrorNotification(Notification.FavError);
          }
        })
        .catch((e) => {
          setAddFav(true);
          triggerErrorNotification(Notification.FavError);
        });
    } else {
      setAddFav(false);
      PodcastService.unlikePodcast(favoritepodcast)
        .then((res) => {
          if (res.status) {
            triggerSuccessNotification(Notification.RemovedFromFav);
            // updateFav();
            removeFavFromRedux();
            setAddFav(false);
          } else {
            setAddFav(true);
            triggerErrorNotification(Notification.FavError);
          }
        })
        .catch((e) => {
          setAddFav(true);
          triggerErrorNotification(Notification.FavError);
        });
    }
  };
  return (
    <div>
      {loggedInUser ? (
        <div
          onClick={handleFavorite}
          className={`cursor-pointer items-center p-1 rounded-full add-button ${
            !Icon && addFav && `bg-red-600`
          }`}
          data-tooltip-id={`add-podcast-to-favorite-${podcast.id}`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {addFav ? (
            <div className="">
              {Icon ? (
                <AiFillHeart className="text-red-600 cursor-pointer" />
              ) : (
                <div className="">
                  {hover ? (
                    <AiFillStar className="text-red-600 cursor-pointer" />
                  ) : (
                    <AiOutlineStar />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div
              className={
                podcastCard
                  ? `${
                      hover
                        ? `text-red-600 duration-500 ease-in-out`
                        : `text-white duration-500 ease-in-out`
                    }`
                  : "text-white"
              }
            >
              {Icon ? (
                <AiOutlineHeart className="cursor-pointer" />
              ) : (
                <AiOutlineStar />
              )}
            </div>
          )}

          <div className="text-sm">
            <ReactTooltip
              id={`add-podcast-to-favorite-${podcast.id}`}
              place="top"
              content={addFav ? "Remove from favourites" : "Add to favourites"}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AddPodcastToFav;
