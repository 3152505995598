import { Notification } from "constant/notifications";
import { useEffect, useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { updateFavorites } from "redux/userRedux";
import ArtistService from "services/music/ArtistService";
import {
  triggerErrorNotification,
  triggerSuccessNotification,
} from "utils/notification";

function FollowArtist({ artistId, user, Icon, artist }: any) {
  // console.log(artistId);
  const dispatch = useDispatch<any>();
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  // console.log("BBB", artist);

  useEffect(() => {
    const favoriteArtist = favorite?.favorite_artists.map(
      (item: any) => item.id
    );
    // console.log(artistId);
    if (favoriteArtist?.includes(artist?.id)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artist]);

  const addFavToRedux = () => {
    const updatedArtistData = [...favorite?.favorite_artists, artist];
    const updatedFavorite = {
      ...favorite,
      favorite_artists: updatedArtistData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const removeFavFromRedux = () => {
    // const updatedArtistData = [...favorite?.favorite_artists, podcast];
    const updatedArtistData = favorite?.favorite_artists.filter(
      (favArtist: any) => favArtist?.id !== artist?.id
    );
    const updatedFavorite = {
      ...favorite,
      favorite_artists: updatedArtistData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  //Like Artist
  const handleFavorite = () => {
    const favoriteArtist = {
      id: artistId.toString(),
      object_type: "artist",
    };

    if (!isLiked) {
      ArtistService.likeArtist(favoriteArtist).then((res) => {
        if (res.status) {
          setIsLiked(true);
          if (user && user?.id === loggedInUser.id) {
            addFavToRedux();
          }
          triggerSuccessNotification(Notification.AddToFav);
        } else {
          setIsLiked(false);
          triggerErrorNotification(Notification.FavError);
        }
      })
      .catch((e) => {
        setIsLiked(true);
        triggerErrorNotification(Notification.FavError);
      });
    } else {
      ArtistService.UnLikeArtist(favoriteArtist).then((res) => {
        if (res.status) {
          if (user && user?.id === loggedInUser.id) {
            removeFavFromRedux();
          }
          triggerSuccessNotification(Notification.RemovedFromFav);
          setIsLiked(false);
        } else {
          setIsLiked(true);
          triggerErrorNotification(Notification.FavError);
        }
      })
      .catch((e) => {
        setIsLiked(true);
        triggerErrorNotification(Notification.FavError);
      });
    }
  };

  // useEffect(() => {
  //   if (user) {
  //     LikeService.getFavoriteById(user?.id).then((res) => {
  //       const favoritesIds = res.profile.favorite_artists.map(
  //         (item: any) => item.id
  //       );
  //       setFavorite(favoritesIds);
  //     });
  //   }
  // }, [user?.id]);

  return (
    <>
      {Icon ? (
        <div onClick={handleFavorite} className="text-2xl">
          {isLiked ? (
            <div className="text-red-600">
              <AiFillHeart />
            </div>
          ) : (
            <div className="text-white">
              <AiOutlineHeart />
            </div>
          )}
        </div>
      ) : (
        <>
          <p
            className="text-base font-bold text-white cursor-pointer hover:bg-[#48484895] py-3 px-3 border-[#f30543] border-2 rounded-full duration-200 ease-in-out max-sm:w-full text-center"
            onClick={handleFavorite}
          >
            {isLiked ? (
              "Following"
            ) : (
              <>
                <p className="flex items-center mx-6 text-base font-bold text-white cursor-pointer  max-sm:w-full  max-sm:mx-3 max-sm:text-[13px]">
                  <FaPlus className="mr-3" /> Follow Artist
                </p>
              </>
            )}
          </p>
        </>
      )}
    </>
  );
}

export default FollowArtist;
