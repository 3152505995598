//import logo
import Logo from "Assets/Images/a_logo.png";
import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthService from "services/auth/AuthService";
import CoverPoster from "Assets/Images/CoverPoster.jpg";

function ResetPassword() {
  let navigate = useNavigate();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [errors, setErrors] = useState({
    email: [],
    password: [],
  });

  const handleResetPassword = async (e: any) => {
    e.preventDefault();
    const data = { ...formData, token };
    removeErrors();

    await AuthService.resetPasswordRequest(data)
      .then((response) => {
        if (response.status === 200) {
          resetForm();
          removeErrors();
          navigate("/login");
          toast.success(
            "You're all set! Feel free to log in with your new credentials",
            {
              autoClose: 7000,
            }
          );
        } else {
          toast.error(
            "An error occurred while attempting to reset the password."
          );
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          const errorData = error.response.data.errors;
          setErrors(errorData);
        } else if (error.response.status === 404) {
          const emailError = [
            "We couldn't locate an account associated with your details",
          ];
          updateEmailError(emailError);
        } else if (
          error.response.status === 500 &&
          error.response.data.message === "passwords.token"
        ) {
          toast.error(
            "It seems the token has expired. Please start the password reset process again.",
            { autoClose: 9000 }
          );
        } else {
          toast.error(
            "An error occurred while attempting to send the password reset email."
          );
        }
      });
  };

  const resetForm = () => {
    setFormData({
      email: "",
      password: "",
      password_confirmation: "",
    });
  };

  const removeErrors = () => {
    setErrors({
      email: [],
      password: [],
    });
  };

  const updateEmailError = (error: any) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: error,
    }));
  };

  return (
    <div
      className="overflow-hidden max-sm:w-[100%] "
      // main back image
      style={{
        color: "#fff",
        backgroundImage: `url(${CoverPoster})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      {/* red dot top left */}
      <div className="loginpage-back scrollbar-hide">
        <div
          className="absolute h-[800px] w-[800px] -left-[350px] -top-[350px] max-sm:hidden"
          style={{
            color: "#fff",
            backgroundImage: `url('https://i.stack.imgur.com/G7vn7.png')`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="overflow-hidden login-page-form-container scrollbar-hide">
          <div
            className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer mb-4`}
          >
            <HiArrowLeft
              className="text-black"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          {/* ayosat logo part */}
          <div className="relative left-0 bg-white rounded-full w-[100px] h-[100px] flex items-center mb-10">
            <img src={Logo} alt="logo" className="w-16 h-16 mx-auto mb-2" />
          </div>

          {/* main topic section */}

          <div className="relative">
            <form>
              <div className="text-left">
                <h1 className="mb-2 text-4xl font-bold white-text sm:text-4xl">
                  Reset Password
                </h1>
                <p className="mb-4 text-sm tracking-wide gray-text">
                  Enter Your new password and confirm it to reset password.
                </p>
              </div>

              <div className="w-full">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={onChange}
                  autoComplete="email"
                  className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] text-gray-600"
                />
                {errors.email?.length > 0 && (
                  <div className="mt-2 text-left text-red-400">
                    <span className="error">{errors.email[0]}</span>
                  </div>
                )}
              </div>

              <div className="block max-sm:block ">
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={onChange}
                  placeholder="New Password"
                  className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] text-gray-600 mr-1 my-3"
                />
                <input
                  type="password"
                  name="password_confirmation"
                  value={formData.password_confirmation}
                  onChange={onChange}
                  placeholder="Confirm Password"
                  className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] text-gray-600 mr-1 max-sm:mt-2"
                />
                {errors.password?.length > 0 && (
                  <div className="mt-2 mb-5 text-left text-red-400">
                    <span className="error">{errors.password[0]}</span>
                  </div>
                )}
              </div>

              <p className="mt-3 text-xs gray-text text-start">
                <Link to={RouteName.ForgotPassword}>
                  <span className="cursor-pointer ">Forgot password?</span>
                </Link>
              </p>

              <button
                className="w-full py-4 my-4 text-lg font-bold rounded-full white-text button-red"
                onClick={handleResetPassword}
                type="submit"
              >
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
