import { RouteName } from "constant/routeNames";
import FeaturePages from "layout/FeaturePages";
import { Route, Routes } from "react-router-dom";
import PodcastMusicPlayer from "./podcastPage/PodcastMusicPlayer";
import PodcastOpenEpisode from "./podcastPage/PodcastOpenEpisode";
import PodcastPageDiscover from "./podcastPage/PodcastPageDiscover";
import PodcastPageFavorite from "./podcastPage/PodcastPageFavorite";
import PodcastPageHistory from "./podcastPage/PodcastPageHistory";
import PodcastPageHome from "./podcastPage/PodcastPageHome";
import SeeAllPodcastCategory from "./podcastPage/SeeAllPodcastCategory";
import DiscoverPodcastCategory from "./podcastPage/DiscoverPodcastCategory";
import SeeAllNewPodcast from "./podcastPage/SeeAllNewPodcast";

function PodcastPage({
  handleClick,
  isActive,
  setIsActive,
  // setPlayerIsActive,
  anyPlayerActive,
}: any) {
  // useEffect(() => {
  //   setPlayerIsActive(isActive);
  // }, []);
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        <Route
          path=""
          element={
            <FeaturePages
              Content={PodcastPageHome}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Podcast"}
            />
          }
        />
        <Route
          path={RouteName.PodcastDiscover}
          element={
            <FeaturePages
              Content={PodcastPageDiscover}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Podcast"}
            />
          }
        />
        <Route
          path={RouteName.PodcastHistory}
          element={
            <FeaturePages
              Content={PodcastPageHistory}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Podcast"}
            />
          }
        />
        <Route
          path={RouteName.PodcastFavourite}
          element={
            <FeaturePages
              Content={PodcastPageFavorite}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Podcast"}
            />
          }
        />
        <Route
          path={RouteName.PodcastOpenEpisode}
          element={
            <FeaturePages
              Content={PodcastOpenEpisode}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Podcast"}
              anyPlayerActive={anyPlayerActive}
            />
          }
        />
        <Route
          path={RouteName.PodcastMusicPlayer}
          element={
            <FeaturePages
              Content={PodcastMusicPlayer}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Podcast"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllPodcastCategory}
          element={
            <FeaturePages
              Content={SeeAllPodcastCategory}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Podcast"}
            />
          }
        />
        <Route
          path={RouteName.PodcastDiscoverByGenreId}
          element={
            <FeaturePages
              Content={DiscoverPodcastCategory}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Podcast"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllNewPodcast}
          element={
            <FeaturePages
              Content={SeeAllNewPodcast}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Podcast"}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default PodcastPage;
