import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { Tooltip as ReactTooltip } from "react-tooltip";

function VisibleQueueButton({ visibleQueue, executeFunction }: any) {
  return (
    <div
      className="duration-300 ease-in-out cursor-pointer max-sm:hidden hover:text-red-600"
      data-tooltip-id={`audio-player-show-queue`}
      onClick={executeFunction}
    >
      {visibleQueue ? (
        <AiOutlineDown className="cursor-pointer" />
      ) : (
        <AiOutlineUp className="cursor-pointer" />
      )}
      <div className="text-sm">
        <ReactTooltip
          id={`audio-player-show-queue`}
          place="top"
          content={visibleQueue ? "Hide Queue" : "Show Queue"}
        />
      </div>
    </div>
  );
}

export default VisibleQueueButton;
