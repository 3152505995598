import { RouteName } from "constant/routeNames";
import PlaylistCard from "pages/musicPage/playlist/PlaylistCard";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

function PlaylistsByUser({ user, isLoading, userPublicPlaylists }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
        <div>
          <h2 className="text-xl font-medium text-white lg:text-2xl md:text-2xl">
            {/* Videos by booster */}
            {user.name}'s Playlists
          </h2>
        </div>
      </div>

      {/* text */}
      {userPublicPlaylists?.length === 0 && userPublicPlaylists ? (
        <div>
          <div className="items-center h-full mt-20">
            {user?.id === loggedInUser?.id ? (
              <>
                <p className="flex items-center justify-center text-base text-gray-500">
                  You don't have any playlists
                </p>
              </>
            ) : (
              <>
                {userPublicPlaylists?.length <= 0 && (
                  <p className="flex items-center justify-center text-base text-gray-500">
                    No any playlists
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div>
          {/* coursel */}
          <div className="">
            <Swiper
              slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                370: {
                  slidesPerView: 2,
                },
                700: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                },
                1400: {
                  slidesPerView: 6,
                },
                1600: {
                  slidesPerView: 7,
                },
              }}
              modules={[Pagination, Navigation]}
              navigation={true}
              className="mt-4 mb-10 mySwiper"
            >
              {userPublicPlaylists?.length > 0 &&
                userPublicPlaylists.map((playlist: any, i: number) => (
                  <SwiperSlide className="" key={i}>
                    <PlaylistCard
                      playlist={playlist}
                      isLoading={isLoading}
                      userPage={true}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
}

export default PlaylistsByUser;
