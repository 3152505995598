import React from "react";
import CreatorLiveTv from "./shared/CreatorLiveTv";

const LiveTvPage = ({ creatorData }: any) => {
  return (
    <div>
      <CreatorLiveTv creatorData={creatorData} />
    </div>
  );
};

export default LiveTvPage;
