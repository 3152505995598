import React from "react";

const BlogCardCategory = ({ categories }: any) => {
  // const categories = [
  //   { id: 1, category: "News", color: "red" },
  //   { id: 2, category: "Sports", color: "green" },
  //   { id: 3, category: "Entertainment", color: "gray" },
  // ];

  // console.log(categories);
  return (
    <div className="flex gap-2 ml-2">
      {categories?.map(
        (
          category: {
            id: number;
            name: string;
            slug: string;
          },
          index: number
        ) => (
          <div key={index} className={`p-1 text-white bg-gray-600 rounded-sm`}>
            <p className="text-[8px] text-center">{category.name}</p>
          </div>
        )
      )}
    </div>
  );
};

export default BlogCardCategory;
