import MessagePopUp from "pages/PayPerView/shared/MessagePopUp";
import React, { ReactNode, useEffect } from "react";
import { HiArrowLeft } from "react-icons/hi";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: Object;
}

const NotificationModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  message,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return isOpen ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="fixed inset-0 flex items-center justify-center backdrop-blur">
        <div className="absolute w-[50%] overflow-hidden rounded-lg shadow-xl">
          <div className="mt-4 rounded-lg">
            <MessagePopUp message={message} onClose={onClose} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default NotificationModal;
