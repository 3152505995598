import React from 'react'
import MovieAndSeriesCardSkeleton from './MovieAndSeriesCardSkeleton'

const VideoSeeAllSkeleton = () => {

    const arr = [1, 2, 3, 4, 5];

    return (
        <div className='flex flex-col'>
            {/* <div className='w-[50px] h-[50px] rounded-full bg-[#1a1919] animate-pulse'></div>
            <div className='w-[300px] h-[40px] bg-[#1a1919] animate-pulse mt-5'></div> */}
            <div className='grid lg:grid-cols-5 max-md:grid-cols-3 max-sm:grid-cols-2 mt-10'>

                {arr.map((item: any) => (
                    <div key={item}>
                        <MovieAndSeriesCardSkeleton />
                    </div>
                ))
                }

            </div>
        </div>
    )
}

export default VideoSeeAllSkeleton