import axios from "axios";
import authHeader from "utils/auth-header";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;
// const VIUZAT_URL = process.env.REACT_APP_VIUZAT_SERVER;
const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

const getBlogBySlug = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/blogs/show/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getAllBlogCategories = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/blog/categories`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getPodcastByCategory = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/blogs/category/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getAllBlogs = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/blogs`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const BlogService = {
  getBlogBySlug,
  getAllBlogCategories,
  getPodcastByCategory,
  getAllBlogs,
};

export default BlogService;
