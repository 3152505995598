import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PayperviewService from "services/payperview/PayperviewServie";
import { TabNames } from "constant/tabNames";
import PPVRecordingsTab from "./shared/PPVRecordingsTab";
import PPVInfoTab from "./shared/PPVInfoTab";
import PPVEpisodes from "./shared/PPVEpisodes";
import ContentSideBar from "./ContentSideBar";

const PPVVideoPlayerLeftMobile = ({ videoDetails }: any) => {
  const location = useLocation();
  const { slug } = useParams();
  const [event, setEvent] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    if (slug) {
      PayperviewService.getEventByEventSlug(slug)
        .then((res) => {
          // console.log("event", res);
          setEvent(res.data);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }, []);

  useEffect(() => {
    setSelectedTab(
      location.pathname.split("/")[2] === "replay"
        ? TabNames.Recordings
        : TabNames.Info
    );
  }, [event]);
  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 0; // Reset scroll position
    }
  }, [selectedTab]);

  return (
    <div className="items-center lg:bg-gradient-to-r lg:from-black/100 text-gray-100 pt-5 w-full lg:mt-[10px] lg:pl-[80px] px-5 text-left !h-auto  justify-center">
      <>
        <div className="flex items-center mb-5 ">
          {event?.replay_videos?.length > 1 &&
          location.pathname.split("/")[2] === "replay" ? (
            <PPVRecordingsTab
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          ) : (
            <PPVInfoTab
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          )}
        </div>

        <div
          className="w-full mt-5 scrollbar-hide max-md:pb-0 "
          ref={scrollableRef}
        >
          {selectedTab === TabNames.Recordings && event && (
            <PPVEpisodes
              videoDetails={event?.replay_videos && event?.replay_videos}
              event={event}
              // loading={loading}
            />
          )}
          {selectedTab === TabNames.Info && videoDetails && (
            <ContentSideBar
              event={videoDetails && videoDetails}
              // loading={loading}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default PPVVideoPlayerLeftMobile;
