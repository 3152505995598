import { AiFillCheckCircle } from "react-icons/ai";

const PackageCard = ({
  packageName,
  price,
  text1,
  text2,
  text3,
  onChange,
  selectedValue,
}: any) => {
  const isSelected = selectedValue === packageName;

  const handleRadioChange = () => {
    if (isSelected) {
      onChange(null);
    } else {
      onChange(packageName);
    }
  };
  return (
    <div
      className={`w-full text-black bg-white rounded-sm h-[119px] relative p-3 my-3 opacity-75 hover:opacity-100 max-sm:mb-[8%] ${
        isSelected ? "border-2 border-blue-500 !opacity-100" : ""
      }`}
    >
      <div className="flex justify-between w-full ">
        <div className="flex items-center gap-1">
          <input
            type="radio"
            name="package"
            checked={isSelected}
            onChange={handleRadioChange}
          />

          <h1 className="w-5 text-lg font-extrabold uppercase">
            {packageName}
          </h1>
        </div>

        <h1 className="text-lg font-extrabold uppercase ">
          ${price}/<span className="text-sm lowercase">month</span>
        </h1>
      </div>

      <div className="top-[45px] text-xs ml-3 mt-2 left-[50px]">
        <div className="flex items-center gap-2">
          <AiFillCheckCircle className="text-sm text-[#7B9DB4]" />
          <h6>{text1}</h6>
        </div>
        <div className="flex items-center gap-2">
          <AiFillCheckCircle className="text-sm text-[#7B9DB4]" />
          <h6>{text2}</h6>
        </div>
        <div className="flex items-center gap-2">
          <AiFillCheckCircle className="text-sm text-[#7B9DB4]" />
          <h6>{text3}</h6>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
