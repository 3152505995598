import { RouteName } from "constant/routeNames";
import { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { HiArrowLeft } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PayperviewService from "services/payperview/PayperviewServie";
import PPVideoPlayerLeftSidePanel from "./videoPlayer/PPVideoPlayerLeftSidePanel";
import PPVVideoPlayer from "./videoPlayer/PPVVideoPlayer";
import Ripple from "components/Ripple";
import PopUpModel from "components/PopUpModel";
import GeoLocationPopup from "pages/videoPage/videoPlayer/shared/GeoLocationPopup";
import { removeAllPlayer } from "redux/AudioPlayerRedux";
import { setVideoSuccess } from "redux/VideoPlayerRedux";
import LeftSideSkeleton from "components/skeleton/LeftSideSkeleton";
import EventWaitingSection from "./videoPlayer/sections/EventWaitingSection";
import PurchasedSection from "./videoPlayer/sections/PurchasedSection";
import TicketVerificationModal from "components/TicketVerificationModal";
import { TfiLayersAlt } from "react-icons/tfi";
import PPVVideoPlayerLeftMobile from "./videoPlayer/PPVVideoPlayerLeftMobile";

function PPVReplayVideoPlayerPage() {
  const { slug, stream } = useParams();
  const [event, setEvent] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const [videoDetails, setVideoDetails] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [explore, setExplore] = useState(false);

  const [hasPurchased, setHasPurchased] = useState<boolean>(true);
  const [hasPurchasedMovie, setHasPurchasedMovie] = useState<boolean>(true);
  const [remainingDays, setRemainingDays] = useState<any>();
  const [moviePlay, setMoviePlay] = useState<boolean>(false);
  const [streamable, setStreamable] = useState<boolean>(true);
  const [hasTicketVerified, setHasTicketVerified] = useState<boolean>(true);
  const [showTicketVerificationModal, setShowTicketVerificationModal] =
    useState(false);

  const [openGeoLoactionModel, setOpenGeoLoactionModel] = useState(false);
  const { prevPage } = location.state || { prevPage: null };

  //load data according to the id
  useEffect(() => {
    dispatch(removeAllPlayer());
    const loadData = async () => {
      setLoading(true);
      setTimeout(async () => {
        await PayperviewService.getEventByEventSlug(slug).then((res) => {
          //   console.log(res);
          setEvent(res.data);
          if (res?.data?.isReplay) {
            setVideoDetails(res?.data);
            if (res?.data?.allowed_to_watch === false) {
              setOpenGeoLoactionModel(true);
            }
            if (res?.data?.category === "Boxing") {
              if (res?.data?.streaming_status === "streaming") {
                setStreamable(true);
              } else {
                setStreamable(false);
              }
            }
            const replayEvent = res?.data?.replay_videos.find(
              (items: any) => items?.stream_key === stream
            );
            const value = {
              ...replayEvent,
              // url: res?.data?.stream_url,
              poster: res?.data?.bannerImage ? res?.data?.bannerImage : res?.data?.thumbnailImage,
            };
            dispatch(setVideoSuccess(value));
          } else {
            console.log("Error");
            //navigate(RouteName.PayPerView.replace("/*", ""));
          }
        });

        setLoading(false);
      }, 1000);
    };
    loadData();
  }, [slug, stream]);

  useEffect(() => {
    if (videoDetails?.id) {
      hasEventPurchased(videoDetails?.id);
    }
  }, [videoDetails?.id]);

  useEffect(() => {
    if (videoDetails?.id) {
      hasEventPurchasedMovie(videoDetails?.id);
    }
  }, [videoDetails?.id]);

  const navigateBack = () => {
    if (!explore) {
      if (prevPage) {
        // window.history.replaceState(null, "", prevPage);
        // window.location.reload();
        navigate(RouteName.PayperviewDetailsRoute.replace(":slug", prevPage));
      } else {
        navigate(RouteName.PayPerView.replace("/*", ""));
      }
    }
  };

  const hasEventPurchased = async (event_id: any) => {
    const response = await PayperviewService.hasPurchasedEvent(event_id);
    setHasPurchased(response?.hasPurchased);
    setHasTicketVerified(response?.has_ticket_verified);
  };

  const hasEventPurchasedMovie = async (event_id: any) => {
    const response = await PayperviewService.hasPurchasedMovie(event_id);
    setHasPurchasedMovie(response?.isRentalValid);
    setRemainingDays(response?.remaining_days);

    if (hasPurchasedMovie === true && remainingDays !== 0) {
      setMoviePlay(true);
    } else {
      setMoviePlay(false);
    }
  };

  useEffect(() => {
    if (videoDetails?.category === "Boxing") {
      if (hasPurchased && streamable && !hasTicketVerified) {
        setShowTicketVerificationModal(true);
      } else {
        setShowTicketVerificationModal(false);
      }
    } else {
      if (hasPurchasedMovie === false && remainingDays !== 0) {
        setShowTicketVerificationModal(true);
      } else {
        setShowTicketVerificationModal(false);
      }
    }
  }, [
    hasPurchasedMovie,
    remainingDays,
    hasPurchased,
    streamable,
    hasTicketVerified,
    videoDetails,
  ]);

  const closeTicketModal = () => {
    setShowTicketVerificationModal(false);
    navigate(RouteName.PayPerView.replace("*", ""));
  };

  // geo location function
  const closeModal = () => {
    setOpenGeoLoactionModel(false);
    navigate(RouteName.PayPerView.replace("*", ""));
  };

  //   const handleToggleModal = () => {
  //     setOpenSideModal((val) => !val);
  //     setIsNewChat(false);
  //   };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 1000);
  }, []);

  return (
    <>
      {parseFloat(videoDetails?.price) === 0 ? (
        <div>
          <div>
            {" "}
            <div className="max-sm:hidden max-md:hidden">
              <PPVideoPlayerLeftSidePanel
                isActive={explore}
                videoDetails={videoDetails}
              />
            </div>
            <div
              className={`fixed z-50 flex items-center justify-between w-full px-10 mt-0 max-sm:sticky`}
            >
              <div className="!z-10 flex items-center mt-10 ">
                <div
                  className={`!z-20 flex items-center justify-center w-10 h-9 text-2xl rounded-full max-sm:scale-[80%] max-sm:ml-[-20px] max-sm:mt-[-20px] opacity-10 hover:opacity-100 duration-700 max-sm:opacity-100 ${
                    explore
                      ? "bg-gray-700 duration-500 text-gray-900"
                      : "bg-white duration-500 cursor-pointer"
                  }`}
                  onClick={navigateBack}
                >
                  <HiArrowLeft />
                </div>

                <div
                  className={`!z-50 flex items-center justify-center w-9 h-9 text-2xl ml-4  rounded-full cursor-pointer max-sm:hidden max-md:hidden ${
                    explore
                      ? "  bg-red-600 duration-500 text-white"
                      : " bg-white duration-500 cursor-pointer text-black"
                  }`}
                  onClick={() => setExplore((val) => !val)}
                >
                  <AiOutlineInfoCircle className="" />
                </div>
                <div
                  className={`pt-1.5 pl-10 text-base duration-500 font-bold  rounded-full w-44 h-9 -ml-9 bg-white  ${
                    explore
                      ? "w-[130px] block pl-10 duration-200  "
                      : "w-0 hidden duration-200 "
                  }`}
                >
                  Explore {pathname === ""}
                </div>
              </div>
            </div>
            <div className="w-[99.98%] mx-auto overflow-hidden lg:h-screen lg:bg-black ">
              <div className="flex justify-center mx-auto overflow-hidden">
                {!loading && !openGeoLoactionModel ? (
                  <PPVVideoPlayer ppvReplay={true} />
                ) : (
                  <div
                    className={`flex flex-col items-center justify-center  max-md:mt-10 md:h-[100vh] max-sm:h-[250px] max-md:h-[500px]`}
                  >
                    <Ripple />
                  </div>
                )}
              </div>
            </div>
            <div className="w-full z-100 lg:hidden max-md:mt-[0px]">
              <div className="block lg:hidden">
                <div className="max-sm:h-[300px] max-md:h-[300px] max-sm:w-full ">
                  {!loading ? (
                    <>
                      <PPVVideoPlayerLeftMobile videoDetails={videoDetails} />
                    </>
                  ) : (
                    <>
                      <LeftSideSkeleton />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {openGeoLoactionModel && (
            <div className="z-50">
              <PopUpModel
                isOpen={openGeoLoactionModel}
                onClose={closeModal}
                width={"800px"}
                showCloseButton={false}
              >
                <GeoLocationPopup onClose={closeModal} />
              </PopUpModel>
            </div>
          )}
        </div>
      ) : (
        <div>
          {hasPurchased && (
            <>
              {streamable ? (
                <div>
                  {" "}
                  <div className="max-sm:hidden max-md:hidden">
                    <div className="flex ">
                      <PPVideoPlayerLeftSidePanel
                        isActive={explore}
                        videoDetails={videoDetails}
                      />
                    </div>
                  </div>
                  <div
                    className={`fixed z-50 flex items-center justify-between w-full px-10 mt-0 max-sm:sticky max-md:sticky `}
                  >
                    <div className="!z-10 flex items-center mt-10 ">
                      <div
                        className={`!z-20 flex items-center justify-center w-10 h-9 text-2xl rounded-full max-sm:scale-[80%] max-sm:ml-[-10px] max-sm:mt-[-20px] opacity-30 hover:opacity-100 duration-700 max-sm:opacity-100 ${
                          explore
                            ? "bg-black duration-500 text-black"
                            : "bg-white duration-500 cursor-pointer"
                        }`}
                        onClick={navigateBack}
                      >
                        <HiArrowLeft />
                      </div>

                      <div
                        className={`!z-50 flex items-center justify-center w-9 h-9 text-2xl ml-4 cursor-pointer rounded-full max-sm:hidden max-md:hidden max-lg:hidden  ${
                          explore
                            ? "  bg-red-600 duration-500 text-white"
                            : " bg-white duration-500 cursor-pointer text-black"
                        }`}
                        onClick={() => setExplore((val) => !val)}
                      >
                        {event?.replay_videos.length > 1 ? (
                          <TfiLayersAlt />
                        ) : (
                          <AiOutlineInfoCircle />
                        )}
                      </div>
                      <div
                        className={`pt-1.5 pl-10 text-base duration-500 font-bold  rounded-full w-44 h-9 -ml-9 bg-white max-sm:hidden max-md:hidden max-lg:hidden  ${
                          explore
                            ? "w-[130px] block pl-10 duration-200  "
                            : "w-0 hidden duration-200 "
                        }`}
                      >
                        Explore {pathname === ""}
                      </div>
                    </div>
                  </div>
                  <div className="w-[99.98%] mx-auto overflow-hidden lg:h-screen lg:bg-black">
                    {videoDetails?.category === "Movie" ? (
                      <>
                        <div className="flex justify-center mx-auto overflow-hidden">
                          {hasPurchasedMovie && !openGeoLoactionModel ? (
                            <PPVVideoPlayer ppvReplay={true} />
                          ) : (
                            <div
                              className={`flex flex-col items-center justify-center  max-md:mt-10 md:h-[100vh] max-sm:h-[250px] max-md:h-[500px]`}
                            >
                              <Ripple />
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex justify-center mx-auto overflow-hidden">
                          {!loading && hasPurchased && !openGeoLoactionModel ? (
                            <PPVVideoPlayer ppvReplay={true} />
                          ) : (
                            <div
                              className={`flex flex-col items-center justify-center  max-md:mt-10 md:h-[100vh] max-sm:h-[250px] max-md:h-[500px]`}
                            >
                              <Ripple />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {/*  Mobile  */}
                  <div className="w-full z-100 lg:hidden max-md:mt-[0px]">
                    <div className="block lg:hidden">
                      <div className="max-sm:h-[300px] max-md:h-[300px] max-sm:w-full ">
                        {!loading ? (
                          <>
                            <PPVVideoPlayerLeftMobile
                              videoDetails={videoDetails}
                            />
                          </>
                        ) : (
                          <>
                            <LeftSideSkeleton />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <EventWaitingSection ppv_event={videoDetails} />
                </>
              )}
            </>
          )}
          {!hasPurchased && <PurchasedSection ppv_event={videoDetails} />}
          {openGeoLoactionModel && (
            <div className="z-50">
              <PopUpModel
                isOpen={openGeoLoactionModel}
                onClose={closeModal}
                width={"800px"}
                showCloseButton={false}
              >
                <GeoLocationPopup onClose={closeModal} />
              </PopUpModel>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default PPVReplayVideoPlayerPage;
