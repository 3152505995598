import React from "react";

const PayperviewCountdown = () => {
  return (
    <>
      <div className="flex items-center justify-between w-[90%] my-10">
        <div className="text-center ">
          <div className="w-[50px] h-[10px] bg-[#1a1919] animate-pulse"></div>
          <div className="w-[50px] mt-2 h-[40px] bg-[#1a1919] animate-pulse"></div>
        </div>
        <div className="text-center">
          <div className="w-[50px] h-[10px] bg-[#1a1919] animate-pulse"></div>
          <div className="w-[50px] mt-2 h-[40px] bg-[#1a1919] animate-pulse"></div>
        </div>
        <div className="text-center">
          <div className="w-[50px] h-[10px] bg-[#1a1919] animate-pulse"></div>
          <div className="w-[50px] mt-2 h-[40px] bg-[#1a1919] animate-pulse"></div>
        </div>
        <div className="text-center">
          <div className="w-[50px] h-[10px] bg-[#1a1919] animate-pulse"></div>
          <div className="w-[50px] mt-2 h-[40px] bg-[#1a1919] animate-pulse"></div>
        </div>
      </div>
    </>
  );
};

export default PayperviewCountdown;
