import React from "react";
import { IoTimeOutline } from "react-icons/io5";

const ExpiredStatus = ({ days }: any) => {
  return (
    <div className="absolute top-0 w-full right-0 rounded-md h-8  text-[12px] bg-gradient-to-t from-transparent to-black">
      <div className="flex items-center justify-end mt-1 mr-2">
        {days !== 0 ? (
          <>
            <IoTimeOutline className="mr-1" />
            <p className="text-gray-300">Expiring in {days} days </p>
          </>
        ) :days === 0 ? (<>
        <IoTimeOutline className="mr-1" />
            <p className="text-gray-300">Expiring Today </p>
        </>):(
          <>
            <IoTimeOutline className="mr-1" />
            <p className="text-gray-300">Expired! </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ExpiredStatus;
