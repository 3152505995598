import { RouteName } from "constant/routeNames";
import { useEffect, useState } from "react";
import { HiX } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setVideoStart, setVideoSuccess } from "redux/VideoPlayerRedux";

function PlayerShowNext({ videoDetails, show, setShowNext }: any) {
  const { currentVideo } = useSelector((state: any) => state?.video);
  const dispatch = useDispatch<any>();
  const [showDetails, setShowDetails] = useState<any | null>(null);
  const navigate = useNavigate();
  const { slug }: any = useParams();

  const findSeasonAndEpisode = () => {
    //console.log("videoDetails", videoDetails);
    //console.log("CurrentVideo", currentVideo);
    // find the season and episode
    if (videoDetails && currentVideo) {
      const seasonIdx: any = videoDetails?.seasons?.findIndex(
        (s: any) => s.id === currentVideo.season
      );

      if (seasonIdx !== -1 && seasonIdx !== undefined) {
        const currentSeason = videoDetails?.seasons[seasonIdx];
        const episodeIdx: any = videoDetails?.seasons[
          seasonIdx
        ]?.episodes?.findIndex((e: any) => e.id === currentVideo.id);
        //console.log(currentSeason);
        //console.log(episodeIdx);
        if (episodeIdx !== -1) {
          const nextEpisodeIdx: number = episodeIdx + 1;

          //console.log("Next Episode Number", nextEpisodeIdx);

          if (nextEpisodeIdx < currentSeason?.episodes?.length - 1) {
            // There is a next episode in the current season
            const nextEpisode = currentSeason?.episodes[nextEpisodeIdx];
            // console.log(currentSeason);
            const value = {
              ...nextEpisode,
              tv_series_id: videoDetails.id,
              season: currentSeason.id,
              poster_image: nextEpisode.thumbnail,
            };
            console.log("Next episode in the current season:", value);
            setShowDetails(value);
          } else {
            // There are no more episodes in the current season, check for the next season
            const nextSeasonIdx = seasonIdx + 1;

            if (nextSeasonIdx < videoDetails?.seasons?.length) {
              // There is a next season
              const nextSeason = videoDetails.seasons[nextSeasonIdx];
              const nextEpisode = nextSeason.episodes[0];
              const value = {
                ...nextEpisode,
                tv_series_id: videoDetails.id,
                season: nextSeason.id,
                poster_image: nextEpisode.thumbnail,
              };
              setShowDetails(value);
              console.log("Next season and its first episode:", value);
            } else {
              console.log("No more episodes or seasons.");
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    // console.log(showDetails);
  }, [showDetails]);

  useEffect(() => {
    findSeasonAndEpisode();
  }, [currentVideo, videoDetails]);

  const episodeOnClick = (e: any) => {
    e.preventDefault();
    dispatch(setVideoStart());
    dispatch(setVideoSuccess(showDetails));
    navigate(
      RouteName.WatchTVSeries.replace(":slug", slug).replace(
        ":stream",
        showDetails?.stream_key
      )
    );
    setShowDetails(null);
    setShowNext(false);
  };

  return (
    <>
      {showDetails && (
        <div
          className={`${
            show ? "playnext" : ""
          } absolute z-50 bg-[#191919] rounded-lg  hover:bg-[#c53f3f] flex items-center cursor-pointer duration-1000 ease-in-out opacity-100 md:opacity-80 hover:opacity-100 max-sm:left-10`}
          onClick={episodeOnClick}
        >
          {/* <div className="flex items-center w-40 h-10 cursor-pointer  hover:bg-[#f30543] absolute bottom-4 right-4 p-2 bg-white bg-opacity-75 rounded-md"> */}
          <div className="h-20 overflow-hidden w-15 max-sm:w-15 max-sm:h-13 ">
            <img
              src={showDetails.poster_image}
              alt="poster"
              className="h-20 bg-cover rounded-lg w-15 max-sm:w-15 max-sm:h-13"
            />
          </div>
          {/* <p className="ml-4 text-white text-[12px]  ">Play Next</p> */}
          <div className="w-full ml-2 max-sm:ml-5 ">
            <p className="text-gray-300 max-sm:text-[10px] max-sm:w-[80%]">
              Play Next
            </p>
            {/* top */}

            {/* title */}
            <p className="text-gray-300 max-sm:text-[10px] max-sm:w-full">
              {/* {showDetails.title} */}
              {showDetails.title?.length > 25
                ? `${showDetails.title.slice(0, 25)}...`
                : showDetails.title}
            </p>

            <div className="flex justify-between w-full">
              <p className="text-[8px] max-sm:text-[] text-gray-400">
                {showDetails.season_name}
              </p>
              {/* <p className="text-[8px] text-gray-400">34m left</p> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PlayerShowNext;
