import Podcastcard from "../shared/Podcastcard";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import SubText from "components/shared/SubText";
import { useState, useEffect } from "react";
import PodcastService from "services/podcast/PodcastService";
import PodcastGridSkeleton from "components/skeleton/PodcastGridSkeleton";

function PodcastGridSection({ categories, user }: any) {
  // console.log(categories[0]?.alt_name);
  const [podcasts, setPodcasts] = useState<any>([]);
  const [slug, setSlug] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setSlug(categories[0]?.alt_name);
  }, [categories]);

  useEffect(() => {
    // setTimeout(() => {
    setIsLoading(true);
    if (slug) {
      PodcastService.getPodcastByGenreSlug(slug).then((res) => {
        setIsLoading(false);
        setPodcasts(res.podcasts.data);
        // console.log(res.podcasts.data);
      });
    }
    // console.log(slug);
    // }, 1000);
  }, [slug]);
  return (
    <>
      {!isLoading && (
        <div className="w-full max-sm:mt-4 max-md:mt-4 max-lg:mt-4">
          <div className="top-0 flex items-start w-full ">
            <div>
              <SubText text="Trending podcasts in" className="font-bold" />
              <p className="text-[10px] font-[400] text-[#7B9DB4] mt-[5px] mb-1 max-sm:text-[10px] max-sm:w-full">
                All Ears English Podcast
              </p>
            </div>
            {/* dropdown */}
            <div className="px-3 py-2 ml-3 text-sm text-white rounded-full searchbar max-sm:px-2 ">
              {categories && (
                <select
                  name="podcast"
                  id="podcast"
                  className="outline-none searchbar"
                  // size={4}
                  value={slug}
                  onChange={(event) => setSlug(event.target.value)}
                >
                  {categories?.map((data: any, i: number) => (
                    <option key={i} value={data.alt_name}>
                      {data.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>

          <div className="w-full max-sm:mt-5 max-md:mt-5 max-lg:mt-5">
            <Swiper
              slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                360: {
                  slidesPerView: 2,
                },
                700: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 3,
                },
                1500: {
                  slidesPerView: 4,
                },
                // 1400: {
                //   slidesPerView: 6,
                // },
                // 1600: {
                //   slidesPerView: 7,
                // },
              }}
              modules={[Pagination, Navigation]}
              navigation={true}
              className="w-full mt-10 mySwiper"
            >
              {podcasts?.length === 0 && podcasts ? (
                <div>
                  <SubText text="No Podcasts Available" className="" />
                </div>
              ) : (
                <>
                  {podcasts &&
                    podcasts?.map((card: any, i: number) => (
                      <SwiperSlide key={i}>
                        <Link to={RouteName.PodcastOpenEpisode}>
                          <div className="mx-1">
                            <Podcastcard podcast={card} user={user} />
                          </div>
                        </Link>
                      </SwiperSlide>
                    ))}
                </>
              )}
              {/* {podcasts &&
            podcasts.map((card: any, i: number) => (
              <SwiperSlide key={i}>
                <Link to={RouteName.PodcastOpenEpisode}>
                  <Podcastcard podcast={card} />
                </Link>
              </SwiperSlide>
            ))} */}
            </Swiper>
          </div>
        </div>
      )}
      {isLoading && <PodcastGridSkeleton />}
    </>
  );
}

export default PodcastGridSection;
