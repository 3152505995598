import { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PodcastService from "services/podcast/PodcastService";
import SubText from "components/shared/SubText";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import PodcastCategoriesSkeleton from "components/skeleton/PodcastCategoriesSkeleton";
import BlogCategoryCard from "../shared/BlogCategoryCard";
import BlogService from "services/blogs/BlogService";

function BlogCategoryCarousal() {
  const [categories, setCategories] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    BlogService.getAllBlogCategories().then((res) => {
      setIsLoading(false);
      setCategories(res.data);
      // console.log(res.data);
    });
  }, []);
  return (
    <>
      {!isLoading && (
        <div>
          {categories && (
            <>
              <div className="flex justify-between w-full mt-10">
                <SubText text="Blog Categories" className="font-bold" />
                <Link to={RouteName.SeeAllBlogCategory}>
                  <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-4">
                    See all
                  </p>
                </Link>
              </div>
              <div className="">
                <Swiper
                  slidesPerView={5}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    370: {
                      slidesPerView: 2,
                    },
                    700: {
                      slidesPerView: 3,
                    },
                    1100: {
                      slidesPerView: 3,
                    },
                    1200: {
                      slidesPerView: 4,
                    },
                    1500: {
                      slidesPerView: 5,
                    },
                    1600: {
                      slidesPerView: 6,
                    },
                  }}
                  modules={[Pagination, Navigation]}
                  navigation={true}
                  className="mt-5 mb-5 mySwiper"
                >
                  {categories.map((genre: any, i: number) => (
                    <SwiperSlide className="" key={i}>
                      <BlogCategoryCard genre={genre} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </>
          )}
        </div>
      )}
      {isLoading && <PodcastCategoriesSkeleton />}
    </>
  );
}

export default BlogCategoryCarousal;
