import React from "react";
import SectionTitle from "./SectionTitle";

const CenterTextSection = ({ title, description }: any) => {
  return (
    <div>
      <SectionTitle text={title} />
      <p className="text-[20px] w-[70%] mx-auto text-center text-gray-500 max-sm:w-[90%] max-sm:text-[15px]">
        {description}
      </p>
    </div>
  );
};

export default CenterTextSection;
