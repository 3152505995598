import AddToQueue from "components/shared/AddToQueue";
import PlayButton from "components/shared/PlayButton";
import { HiArrowLeft } from "react-icons/hi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AlbumService from "./../../services/music/AlbumService";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSongsToQueue } from "redux/AudioPlayerRedux";
import {
  triggerSuccessNotification,
  triggerWarningNotification,
} from "utils/notification";
import { Notification } from "constant/notifications";
import PlaylistService from "services/music/PlaylistService";
import { Helmet } from "react-helmet-async";
import LikeService from "services/music/LikeService";
import LazyLoadImg from "components/LazyLoadImg";
import MusicTable from "./shared/MusicTable";
import MusicPlaylistSkeleton from "components/skeleton/MusicPlaylistSkeleton";
import SubText from "components/shared/SubText";
import AlbumCarouselSkeleton from "components/skeleton/AlbumCarouselSkeleton";
import SuggestedAlbumCardCoursel from "./shared/SuggestedAlbumCardCarousal";
import { RouteName } from "constant/routeNames";

function MusicAlbumPage({ anyPlayerActive }: any) {
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [album, setAlbum] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);
  const { prevPage } = location.state || { prevPage: null };
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  console.log(prevPage);

  useEffect(() => {
    setIsLoading(true);
    AlbumService.getAlbumById(id).then((res) => {
      setIsLoading(false);
      setAlbum(res);
      //console.log("res", res);
    });
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const addAlbumToExistingQueue = () => {
    if (album?.songs.length > 0) {
      dispatch(addSongsToQueue(album?.songs));
      triggerSuccessNotification(Notification.SongsAddedToQueue);
    } else {
      triggerWarningNotification(Notification.NoSongs);
    }
  };

  // playlist
  const [userPlaylists, setUserPlaylists] = useState([]);

  useEffect(() => {
    if (loggedInUser) {
      PlaylistService.getUserPlaylists().then((res) => {
        setUserPlaylists(res.data.data);
      });
    }
  }, [loggedInUser]);

  // favorits
  const [favorite, setFavorite] = useState<any>();
  useEffect(() => {
    if (loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, loggedInUser?.id)
        .then((res) => {
          const favoritesIds = res.profile.loved.data.map(
            (item: any) => item.id
          );
          setFavorite(favoritesIds);
        })
        .catch(() => {});
    }
  }, [loggedInUser, loggedInUser?.id]);

  // handle back
  const handleBack = () => {
    if (prevPage) {
      navigate(`${prevPage}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {/* {`${name} - Ayozat`} */}
          {album && album?.title
            ? `${album && album?.title} - AYOZAT`
            : "AYOZAT"}
        </title>
        <meta name="title" content={album && album?.title} />
        <meta name="description" content={album?.artists[0].name} />
        <meta property="og:image" content={album && album.artwork_url} />

        <link rel="canonical" href={`${FE_URL}/music/album/${album && album.id}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={album && album?.title} />
        <meta name="twitter:description" content={album?.artists[0].name} />
        <meta name="twitter:image" content={album && album.artwork_url} />
        <meta
          name="twitter:url"
          content={`${FE_URL}/music/album/${album && album.id}`}
        />

        <meta property="og:title" content={album && album?.title} />
        <meta property="og:description" content={album?.artists[0].name} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${FE_URL}/music/album/${album && album.id}`}
        />
      </Helmet>

      {!isLoading && (
        <div className="mb-40">
          {/* album hero section */}
          <div
            className="relative h-auto"
            style={{
              backgroundImage: `url("${album && album.artwork_url}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="p-3 full-backdrop-bottom">
              {/* back arrow icon */}
              <div
                className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer max-sm:ml-[7px]`}
                // onClick={() => {
                //   navigate(-1);
                // }}
                onClick={handleBack}
              >
                <HiArrowLeft />
              </div>
              <div className="flex items-end max-sm:block">
                {/* album image */}
                {/* <img
                src={album && album.artwork_url}
                className="album-page-image"
                alt="album"
              /> */}
                <LazyLoadImg
                  imgSrc={album?.artwork_url}
                  imgAlt={"/"}
                  imgHeight={"230px"}
                  imgWidth={"230px"}
                  classname={"mt-10"}
                />
                {/* album details */}
                <div className="ml-10 max-sm:ml-1 max-sm:mt-4">
                  <p className="text-base font-medium fafafa ">
                    {album && `Album`}
                  </p>
                  <h1 className="text-5xl font-bold font-secular fafafa max-sm:text-[20px]">
                    {album && album?.title}
                  </h1>
                  <p className="text-sm font-semibold Color7B9DB4">
                    {album &&
                      (album?.song_count > 0 ? (
                        <>
                          {album?.song_count === 1
                            ? `${album?.song_count} Song`
                            : `${album?.song_count} songs`}
                        </>
                      ) : (
                        `No Songs`
                      ))}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center my-10 max-sm:w-full max-sm:mt-4">
            <div className="max-sm:w-[80%]">
              {album && <PlayButton songs={album.songs} />}
            </div>
            {album?.songs && album?.songs.length > 0 && (
              <div className="flex " onClick={addAlbumToExistingQueue}>
                <AddToQueue />
              </div>
            )}
          </div>

          {/* <div className="w-full px-2 text-white">
          <table className="w-full px-2">
            <thead className="text-left">
              <tr>
                <th className="text-[12px] text-[#7B9DB4] w-4/12 text-center">
                  Title
                </th>
                <th className="text-[12px] text-[#7B9DB4] w-4/12">Artist</th>
                <th className="text-[12px] text-[#7B9DB4] w-4/12">Duration</th>
              </tr>
            </thead>
            <tr>
              <td colSpan={5}>
                <hr className="my-5 border-[#7B9DB4]" />
              </td>
            </tr>
            <tbody className="my-5">
              {album &&
                album.songs.map((data: any, i: number) => (
                  // <p>anknfkanfa</p>
                  <MusicTableTow
                    id={i + 1}
                    data={data}
                    key={i}
                    userPlaylists={userPlaylists}
                    setUserPlaylists={setUserPlaylists}
                    favorite={favorite}
                  />
                ))}
            </tbody>
          </table>
        </div> */}

          <MusicTable
            songs={album && album?.songs}
            userPlaylists={userPlaylists}
            setUserPlaylists={setUserPlaylists}
            favorite={favorite}
          />

          {
            <div className="my-5 mt-10">
              <SubText
                text={`You may also like these`}
                className="font-light"
              />
              <SuggestedAlbumCardCoursel genre={album?.genre} />
            </div>
          }
          {isLoading && <AlbumCarouselSkeleton />}

          {/* bottom more playlist */}
          {/* <div className="my-10">
        <SubText text={`More by ${album && album.artists[0].name}`} />
        <DescText text="Based on what you played" />
      </div>
      <div className="items-center justify-between  mt-4 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:ml-2">
        {played.map((p: any, i: number) => (
          <MusicCard song={p} />
        ))}
      </div> */}
        </div>
      )}
      {isLoading && <MusicPlaylistSkeleton />}
    </>
  );
}

export default MusicAlbumPage;
