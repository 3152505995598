import React from "react";

const TvChannelSmallCardSkeleton = () => {
  return (
    <div className="w-[85%] p-3  h-auto max-sm:w-full ">
      <div className="flex items-center gap-4 ">
        <div className="w-10 h-10 bg-[#1a1919] animate-pulse rounded-lg "></div>

        <div className="w-full h-10 bg-[#1a1919] animate-pulse"></div>
      </div>
      <div className=" w-[full] h-[150px] mt-5 max-sm:w-full bg-[#1a1919] animate-pulse" />
    </div>
  );
};

export default TvChannelSmallCardSkeleton;
