import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HiArrowLeft } from "react-icons/hi";
import { RouteName } from "constant/routeNames";
import BlogCard from "./shared/BlogCard";
import BlogsGenreSelector from "./shared/BlogsGenreSelector";
import BlogService from "services/blogs/BlogService";

function DiscoverBlogCategory() {
  const { slug } = useParams();
  const [blogs, setBlogs] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<any>("");
  const navigate = useNavigate();

  //load data according to the slug
  useEffect(() => {
    // setLoading(true);
    BlogService.getPodcastByCategory(slug)
      .then((res) => {
        console.log("BBB>>", res);
        setBlogs(res?.data);
        // setLoading(false);
        setCategory(slug);
      })
      .catch((e) => {
        // console.log(e);
        // setLoading(false);
      });
  }, [slug]);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    BlogService.getAllBlogCategories()
      .then((res) => {
        setCategories(res.data);
        // console.log("genters>>", res.data);
      })
      .catch((e) => {
        // console.log(e);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // console.log(blogs);

  return (
    <div>
      {/* main topic section */}
      <div
        className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer max-sm:ml-[7px] mb-3`}
        onClick={() => {
          navigate(RouteName.Blog.replace("/*", ""));
        }}
      >
        <HiArrowLeft className="text-black" />
      </div>
      <div>
        <div className="flex items-center justify-between mb-8 max-sm:block w-[98%]">
          <h1 className="text-xl font-medium text-white lg:text-2xl md:text-3xl max-sm:ml-2 max-sm:mb-5">
            Discover Blogs in <span className="red-text">{slug}</span>
          </h1>
          <BlogsGenreSelector slug={slug} categories={categories} />
        </div>
      </div>

      {/* podcast grid */}
      <div className="grid w-full grid-cols-1 mt-4 mb-28 md:grid-cols-3 max-sm:w-full">
        {blogs?.map((blog: any, index: number) => (
          <div key={index} className="mb-5 mr-4">
            <BlogCard blog={blog} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default DiscoverBlogCategory;
