import PlayFromRecent from "./PlayFromRecent";
import SubCribeButton from "./SubCribeButton";
import { useSelector } from "react-redux";
import LazyLoadImg from "components/LazyLoadImg";

function OpenEpisodeLeftSection({
  artwork_url,
  title,
  description,
  noOfEpisodes,
  episodes,
  podcastId,
  podcast,
}: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  return (
    <div className="mr-[20px] mb-[15px] cursor-default  w-full rounded-md max-sm:w-full max-sm:mx-0 max-md:w-full max-lg:[80%] ">
      {/* image */}
      <div className="flex justify-center w-full">
        {/* <img
          src={artwork_url}
          alt="podcast icon"
          className="w-[210px] rounded-lg h-[210px] bg-img white-text object-cover"
        /> */}

        <LazyLoadImg
          imgSrc={artwork_url}
          imgAlt={"podcast icon"}
          imgHeight={"210px"}
          imgWidth={"210px"}
          className="rounded-md album-page-image max-sm:h-[20%]"
          style={{
            borderRadius: "6px",
            width: "210px",
            height: "210px",
            // backgroundSize: "cover",
            // marginRight: "8px",
          }}
        />
      </div>

      {/* details */}
      <div className="mt-10 top-1">
        <div className="my-[4px] mx-auto max-lg:[80%] max-lg:mx-auto">
          <h1 className="mt-2 text-[35px] w-[80%] mx-auto font-fredoka text-center font-[700] text-white max-sm:w-full max-md:w-full max-md:[80%] max-lg:w-full max-lg:[80%] max-lg:mx-auto">
            {title}
          </h1>
          <div className="justify-center w-full">
            <div>
              {/* <div>
                <OpenEpisodeBoosterButton />
              </div> */}
              <p className="text-[12px] w-[80%] mx-auto text-center font-[400] text-[#7B9DB4] mt-[5px]  max-sm:w-full max-md:w-full max-md:[80%] max-md:mx-auto  max-lg:[80%] max-lg:mx-auto">
                {description.replace(/&amp;/g, "&")}
              </p>
              <p className="text-[14px] w-full text-center font-[700] text-[#7B9DB4] mt-[10px] max-sm:w-full max-md:w-full max-lg:w-full  max-md:[80%] max-md:mx-auto  max-lg:[80%] max-lg:mx-auto">
                {noOfEpisodes} Available episodes
              </p>
              <div className="flex justify-center w-full mx-auto">
                <div className="">
                  <div className="mt-5 mb-8 ">
                    <PlayFromRecent episodes={episodes} />
                  </div>
                  {loggedInUser && (
                    <div>
                      <SubCribeButton podcast={podcast} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenEpisodeLeftSection;
