import { RouteName } from "constant/routeNames";
import { useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserProfileService from "services/UserProfileService";

function FollowButton({ user, setUpdatedUser, follow, setFollow }: any) {
  const navigate = useNavigate();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  // console.log(loggedInUser);
  const [followDetails, setFollowDetails] = useState<any>();

  const handleFollow = () => {
    if (loggedInUser) {
      const followObject = {
        id: user?.id.toString(),
        object_type: "user",
      };

      if (!follow) {
        UserProfileService.userFollow(followObject).then((res) => {
          // console.log("follow sucessful");
        });
        setFollow(true);
      } else {
        UserProfileService.userUnFollow(followObject).then((res) => {
          // console.log("unfollow sucessful");
        });
        setFollow(false);
      }
    } else {
      navigate(RouteName.Login);
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      await UserProfileService.getUserById(user?.id).then((res: any) => {
        setUpdatedUser(res?.profile);
        // console.log(res.profile);
      });
    }, 1000);
  }, [follow]);

  useEffect(() => {
    if (loggedInUser) {
      UserProfileService.getFollowingById(loggedInUser?.id).then((res) => {
        const followingIds = res?.profile?.following?.data.map(
          (item: any) => item.id
        );
        // console.log("ids", followingIds);
        setFollowDetails(followingIds);
      });
    }
  }, [loggedInUser?.id]);

  // console.log("AAAA>>", loggedInUser.id);
  useEffect(() => {
    if (followDetails?.includes(user?.id)) {
      // Corrected the syntax here
      setFollow(true);
    } else {
      setFollow(false);
    }
  }, [followDetails]);

  return (
    <div>
      <div onClick={handleFollow}>
        {follow ? (
          <button
            className={`flex items-center w-[130px] h-[40px] p-3 text-center border-solid border-2 border-white bg-[#f30543] text-white rounded-full max-sm:p-2 max-sm:ml-2`}
          >
            <AiOutlineCheck className="mr-4 text-black" /> Following
          </button>
        ) : (
          <button
            className={`flex items-center w-[120px] h-[40px] p-3 text-center border-solid border-2 border-white text-white rounded-full max-sm:p-2 max-sm:ml-2`}
          >
            <AiOutlinePlus className="mr-4" /> Follow
          </button>
        )}
      </div>
    </div>
  );
}

export default FollowButton;
