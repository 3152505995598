import React from "react";
import { FaAngleDown } from "react-icons/fa";

const ScrollBottomBtn = ({
  showScrollButton,
  scrollButtonRef,
  scrollToBottom,
}: any) => {
  return (
    <>
      {showScrollButton && (
        <div className="sticky z-50 flex justify-end w-full bottom-4 ">
          <button
            ref={scrollButtonRef}
            onClick={scrollToBottom}
            className="w-[30px] h-[30px] text-white bg-gray-500 rounded-full"
          >
            <FaAngleDown size={24} className="mx-auto" />
          </button>
        </div>
      )}
    </>
  );
};

export default ScrollBottomBtn;
