import { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { BsPlayCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import HeroSection from "components/skeleton/videoDetailsSkeleton/HeroSection";
import { useSelector } from "react-redux";
import AddToFaavVideo from "./AddToFaavVideo";
import VideoShareButton from "./VideoShareButton";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Helmet } from "react-helmet-async";
import { Notification } from "constant/notifications";

function VideoDetailHeader({ video, isLoading, slug, playerPage }: any) {
  // console.log(video);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const navigate = useNavigate();
  const [movie, setMovie] = useState<any>();
  const [tvSeries, setTvSeries] = useState<any>();
  const BaseUrl = window.location.origin;
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;
  const currentUrl = BaseUrl + window.location.pathname;

  useEffect(() => {
    if (video?.seasons) {
      setTvSeries(video);
    } else {
      setMovie(video);
    }
  }, [video]);

  console.log(tvSeries);

  const setDataToPlayer = async () => {
    if (tvSeries?.first_episode) {
      navigate(
        RouteName.WatchTVSeries.replace(":slug", tvSeries?.slug).replace(
          ":stream",
          tvSeries?.first_episode.stream_key
        )
      );
    } else {
      navigate(RouteName.WatchMovies.replace(":slug", movie?.slug));
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // const shareVideo = () => {
  //   <CopySharebleLink shareUrl={currentUrl} />;
  //   console.log(currentUrl);
  // };

  const handleBack = () => {
    // navigate(`${RouteName.Video.replace('/*', ' ')}`);
    // navigate(-1);
    window.scrollTo(0, 0);

    if (window.history.length !== 2) {
      // navigate(`${RouteName.Video.replace('/*', ' ')}`);

      window.scrollTo(0, 0);
      if (playerPage) {
        navigate(RouteName.Video.replace("/*", ""));
      } else {
        navigate(-1);
      }
    } else {
      navigate(RouteName.Video.replace("/*", ""));
    }
  };
  // console.log(window.history.length);

  return (
    <>
      <Helmet>
        <title>
          {/* {`${name} - Ayozat`} */}
          {video?.seo_title ? `${video?.seo_title}` : "AYOZAT"}
        </title>
        <meta name="title" content={`${video?.seo_title}`} />
        <meta name="description" content={video?.description} />
        <meta name="keywords" content={video?.focus_keyword} />

        <link rel="canonical" href={`${FE_URL}/video/info/${video?.slug}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${video?.seo_title}`} />
        <meta name="twitter:description" content={video?.description} />
        <meta name="twitter:image" content={video?.thumbnail_url} />
        <meta name="twitter:site" content="AYOZAT" />
        <meta
          name="twitter:url"
          content={`${FE_URL}/profile/${video?.description}`}
        />

        <meta property="og:title" content={`${video?.seo_title}`} />
        <meta property="og:image" content={video?.thumbnail_url} />
        <meta property="og:description" content={video?.description} />
        <meta
          property="og:url"
          content={`${FE_URL}/video/info/${video?.slug}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div>
        {!isLoading && (
          <div className="relative flex h-[700px] w-full max-sm:h-[600px] max-sm:w-full overflow-hidden">
            <div
              className="absolute inset-0 bg-center bg-no-repeat bg-cover"
              style={{
                backgroundImage: `url(${
                  video?.thumbnail_url || video?.thumbnail
                })`,
                filter: "blur(8px)",
                zIndex: -1,
              }}
            ></div>

            <div className=" h-[400px] w-full justify-between items-center videohero-back max-sm:w-full max-lg:w-ful">
              <div className="mt-10 ml-8 max-sm:mt-5 max-sm:ml-4 ">
                <button
                  className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px] max-lg:mt-[5px]"
                  onClick={handleBack}
                >
                  <AiOutlineArrowLeft className="ml-3 " />
                </button>
              </div>

              <div className="flex mt-6 max-sm:mt-10 max-lg:mt-10">
                <div className="flex-grow w-[50%] ">
                  <div className="mt-10 ml-8">
                    <div>
                      <h1 className="text-6xl font-oswald text-white leading-tight font-black w-[70%] max-sm:text-[30px] max-sm:w-full max-lg:w-full max-lg:text-[40px] mt-[-60px] max-xl:w-full">
                        {video?.title}
                      </h1>
                    </div>
                    <div>
                      <div className="flex items-center mt-10 cursor-default text-md text-gray font-rubik max-sm:mt-3 max-sm:block">
                        <div className="flex items-center max-sm:flex max-sm:items-center">
                          <p className="font-bold text-white">
                            {video?.seasons ? "TV Series" : "Movie"}
                          </p>
                          {video?.imdb_rating &&
                            video?.imdb_rating !== "N/A" &&
                            video?.imdb_rating !== null && (
                              <>
                                <FaCircle className="ml-2 text-[6px] text-white" />
                                <p className="ml-2 font-bold text-white">
                                  IMDB {video?.imdb_rating}
                                </p>
                              </>
                            )}
                        </div>
                        <div className="flex items-center max-sm:flex max-sm:items-center">
                          {video?.runtime ? (
                            <>
                              <FaCircle className="ml-2 text-[6px] text-white max-sm:hidden" />
                              <p className="ml-2 font-bold text-white max-sm:ml-0">
                                <span>
                                  {Math.floor(video?.runtime / 60) > 0 &&
                                    `${Math.floor(video?.runtime / 60)}h`}
                                </span>
                                <span className="ml-1">
                                  {Math.floor(video?.runtime % 60) > 0 &&
                                    `${Math.floor(video?.runtime % 60)}min`}
                                </span>
                              </p>
                              <div></div>
                            </>
                          ) : (
                            <></>
                          )}

                          {video?.released_at !== null ? (
                            video?.released_at !== "-1" ? (
                              <>
                                <FaCircle className="ml-2 text-[6px] text-white" />
                                <p className="ml-2 font-bold text-white">
                                  {new Date(video?.released_at).getFullYear()}
                                </p>
                              </>
                            ) : null
                          ) : null}
                        </div>
                      </div>

                      <p className="text-base h-28 text-white font-medium mt-5 w-[65%] scrollbar-hide overflow-y-scroll font-rubik cursor-default creator-descrip max-sm:w-full max-lg:w-[95%] max-xl:w-[95%]">
                        {video?.description
                          .replace(/&amp;/g, "&")
                          .replace(/&rsquo;/g, "’")
                          .replace(/&ldquo;/g, '"')
                          .replace(/&rdquo;/g, '"')
                          .replace(/&nbsp;/g, "")}
                      </p>
                    </div>
                    <div className="flex mt-4 cursor-pointer">
                      {video?.genres.length > 0 &&
                        video?.genres.map((genre: any, i: number) => (
                          <div key={i}>
                            <Link
                              to={`${RouteName.VideoDiscoverRoute}/${genre?.alt_name}`}
                            >
                              <p className="mr-3 text-black duration-200 bg-white header-badge drop-shadow-sm ">
                                {genre?.name}
                              </p>
                            </Link>
                          </div>
                        ))}
                    </div>
                    <div
                      className={`justify-start gap-3 flex sm:w-[50%] max-sm:w-full ${
                        video?.allowed_to_watch ? `` : ``
                      } items-center mt-10 cursor-pointer`}
                    >
                      {video && video?.allowed_to_watch ? (
                        <div
                          className="flex items-center"
                          onClick={setDataToPlayer}
                        >
                          <div className="w-[8%]">
                            <BsPlayCircleFill className="ml-2 drop-shadow-2xl text-[80px] hover:scale-110 text-white duration-300 max-sm:text-[50px] max-lg:text-[70px]" />
                          </div>
                        </div>
                      ) : (
                        <p className="p-4 text-white border-2 border-white border-solid">
                          {Notification.geoLocationNotAllowedText}
                        </p>
                      )}

                      <div
                        className={`flex items-center justify-start ${
                          video?.allowed_to_watch ? `` : `mt-2`
                        }`}
                      >
                        {loggedInUser && (
                          <div
                            className={`flex items-center ${
                              video?.allowed_to_watch
                                ? `ml-5`
                                : `ml-0 items-center`
                            } max-sm:ml-4 max-lg:ml-4`}
                          >
                            <AddToFaavVideo
                              seasons={video?.seasons}
                              id={video?.id}
                              title={video?.title}
                              movie={movie}
                              tvSeries={tvSeries}
                              Icon={true}
                            />
                          </div>
                        )}
                        <div
                          className={
                            "max-sm:ml-4 ml-5 max-lg:ml-4 flex items-center"
                          }
                        >
                          <VideoShareButton shareUrl={currentUrl} />
                          {/* <CopySharebleLink shareUrl={currentUrl} />; */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="w-[265px] h-[380px] overflow-hidden bg-no-repeat mr-[120px] max-sm:hidden rounded-lg"
                  style={{
                    backgroundImage: `url(${
                      video?.thumbnail_url || video?.thumbnail
                    })`,
                    backgroundSize: "cover", // Apply the "cover" property
                    backgroundPosition: "center center", // Center the image
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
        {isLoading && <HeroSection />}
      </div>
    </>
  );
}

export default VideoDetailHeader;
