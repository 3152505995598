import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { PlaylistCard } from "../shared/PlaylistCard";

function PlayListCardCoursel() {
  const playlist = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },

    {
      id: 8,
    },

    {
      id: 9,
    },
  ];

  return (
    <div>
      <div className="">
        <Swiper
          slidesPerView={7}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            350: {
              slidesPerView: 2,
            },
            550: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
            1100: {
              slidesPerView: 5,
            },
            1200: {
              slidesPerView: 6,
            },
            1400: {
              slidesPerView: 7,
            },
          }}
          modules={[Pagination]}
          className="mt-4 mb-5 mySwiper"
        >
          {playlist.map((list, i) => (
            <SwiperSlide className="" key={i}>
              <PlaylistCard {...list} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default PlayListCardCoursel;
