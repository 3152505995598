import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;

const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

const getArtistBySlug = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/star/by/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const ArtistService = {
  getArtistBySlug,
};

export default ArtistService;
