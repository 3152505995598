import FavoriteIcon from "components/FavoriteIcon";
import LazyLoadImg from "components/LazyLoadImg";
import { RouteName } from "constant/routeNames";
import { FaPlay } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "styles/card.css";

function MovieCarouselCard({
  id,
  time,
  runtime,
  user,
  title,
  info,
  year,
  img,
  slug,
  tv,
  seasons,
  thumbnail,
  movie,
  tvSeries,
  searchPage,
  first_episode,
}: any) {
  // used in every coursel
  const navigate = useNavigate();

  //console.log(first_episode);

  // const [isLiked, setIsLiked] = useState<boolean>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const setDataToPlayer = async () => {
    const state = { searchPage: searchPage ? searchPage : false };
    if (first_episode) {
      navigate(
        RouteName.WatchTVSeries.replace(":slug", slug).replace(
          ":stream",
          first_episode?.stream_key
        ),
        { state }
      );
    } else {
      navigate(RouteName.WatchMovies.replace(":slug", slug), { state });
    }
  };

  return (
    <div className="px-2 w-[190px] h-[100%] rounded-lg hover:bg-slate-800 place-items-center shadow-lg py-2 mx-auto max-sm:w-[180px] max-sm:h-[385px]">
      <div className="">
        {/* {title && (
          <p className="mb-2 text-xs title w-[80%] h-[25px] ">
            {title && (
              <>{title?.length > 35 ? `${title.slice(0, 35)}...` : title}</>
            )}
          </p>
        )} */}

        {/* background image */}
        <div className="relative w-[100%] rounded-lg bg-img max-sm:w-[98%]  max-sm:mx-auto object-cover ">
          <LazyLoadImg
            imgSrc={img || thumbnail}
            imgAlt={"Card pic"}
            imgHeight={"300px"}
            imgWidth={"100%"}
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
          />
          {/* <div className="absolute inset-0 bg-gradient-to-t from-[#1a1919] to-transparent opacity-80 hover:opacity-100 "></div> */}

          {/* play button */}
          <div className="absolute bottom-0 flex items-center justify-center w-full play-button h-80">
            <button className="p-3 rounded-full" onClick={setDataToPlayer}>
              <FaPlay />
            </button>
          </div>

          {/* booster icon */}
          {/* <div className="absolute flex booster-button rounded-full items-center bottom-8 bg-white w-[76px] ml-1 mb-1 cursor-pointer">
              <button className="p-1 rounded-full cursor-pointer">
                <HiLightningBolt className="text-xs " />
              </button>
              <p className="ml-1 text-xs cursor-pointer">Booster</p>
            </div> */}
          {/* time and two buttons */}
          <div className="absolute inset-x-0 bottom-0 flex items-center justify-between p-2 mt-10 bg-gradient-to-t from-[#1a1919] to-transparent opacity-80">
            {runtime || time ? (
              <>
                <p className="text-sm align-bottom text-slate-100">
                  <span>
                    {Math.floor(time / 60) > 0 && `${Math.floor(time / 60)}h`}
                  </span>
                  <span className="ml-1">
                    {Math.floor(time % 60) > 0 && `${Math.floor(time % 60)}min`}
                  </span>
                </p>
                <div></div>
              </>
            ) : (
              <></>
            )}
            {seasons ? (
              <p className="text-sm align-bottom text-slate-100">
                {seasons} seasons
              </p>
            ) : (
              <></>
            )}
            {loggedInUser &&
              (user ? (
                <>
                  {loggedInUser && user?.id === loggedInUser?.id && (
                    <FavoriteIcon
                      // handleFavorite={handleFavorite}
                      // isLiked={isLiked}
                      seasons={seasons}
                      id={id}
                      title={title}
                      movie={movie}
                      tvSeries={tvSeries}
                      VideoCard={true}
                    />
                  )}
                </>
              ) : (
                <>
                  <FavoriteIcon
                    seasons={seasons}
                    id={id}
                    title={title}
                    movie={movie}
                    tvSeries={tvSeries}
                    VideoCard={true}
                  />
                </>
              ))}
          </div>
        </div>
      </div>
      {/* bottom section */}
      <div className="mt-1 h-[60px]">
        {tv === 0 ? (
          <h2 className="mb-1 font-bold text-slate-200 text-md ">{info}</h2>
        ) : (
          <>
            <Link
              to={
                // slug === "ramod-with-cool-steps"
                // ? RouteName.VideoCreatorHomeRoute.replace(":slug", slug)
                // :
                RouteName.VideoDetailRoute.replace(":slug", slug)
              }
            >
              <h2
                className="mb-1 font-bold text-slate-200 text-[14px] text-md w-[90%]"
                data-tooltip-id={title?.length > 30 ? `add-song-to-favorite-${slug}`:""}
              >
                {title?.length > 30 ? `${title.slice(0, 30)}...` : title}
              </h2>
              <div className="z-50 text-sm ">
                <ReactTooltip
                  id={`add-song-to-favorite-${slug}`}
                  place="bottom"
                  content={title}
                  style={{ width: "180px" }}
                />
              </div>
            </Link>
          </>
          // <h2 dangerouslySetInnerHTML={{ __html: info }}></h2>
        )}
        {year !== "-1" ? (
          <p className="mb-1 text-xs gray-text">{year}</p>
        ) : (
          <></>
        )}
        {/* <p className="mb-1 text-xs gray-text">{type}</p> */}
      </div>
    </div>
  );
}

export default MovieCarouselCard;
