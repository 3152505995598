import { Notification } from 'constant/notifications';
import React, { useEffect, useState } from 'react'
import { FaCheck, FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { updateFavorites } from 'redux/userRedux';
import CreatorService from 'services/creators/CreatorService';
import { triggerErrorNotification, triggerSuccessNotification } from 'utils/notification';

function FollowCreators({ creatorId, user, creator }: any) {
  const dispatch = useDispatch<any>();
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  useEffect(() => {
    const favoriteCreators = favorite?.followed_creators?.map(
      (item: any) => item?.id
    );
    // console.log(creatorId);
    if (favoriteCreators?.includes(creator?.id)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creator]);

  const addFavToRedux = () => {
    const updatedArtistData = [...favorite?.followed_creators, creator];
    const updatedFavorite = {
      ...favorite,
      followed_creators: updatedArtistData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const removeFavFromRedux = () => {
    const updatedArtistData = favorite?.followed_creators.filter(
      (favCreator: any) => favCreator?.id !== creator?.id
    );
    const updatedFavorite = {
      ...favorite,
      followed_creators: updatedArtistData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  //follow unfollow creator
  const handleFavorite = () => {
    const favoriteCreator = {
      id: creatorId.toString(),
      object_type: "creator",
    };

    if (!isLiked) {
      CreatorService.followCreator(favoriteCreator).then((res) => {
        if (res.status) {
          setIsLiked(true);
          if (user && user?.id === loggedInUser.id) {
            addFavToRedux();
          }
          triggerSuccessNotification(Notification.Subscribed);
        } else {
          setIsLiked(false);
          triggerErrorNotification(Notification.FavError);
        }
      })
      .catch((e) => {
        setIsLiked(true);
        triggerErrorNotification(Notification.FavError);
      });
    } else {
      CreatorService.unfollowCreator(favoriteCreator).then((res) => {
        if (res.status) {
          if (user && user?.id === loggedInUser?.id) {
            removeFavFromRedux();
          }
          triggerSuccessNotification(Notification.Unsubscribed);
          setIsLiked(false);
        } else {
          setIsLiked(true);
          triggerErrorNotification(Notification.FavError);
        }
      })
      .catch((e) => {
        setIsLiked(true);
        triggerErrorNotification(Notification.FavError);
      });
    }
  };

  return (
    <>
        <>
          <p
            className="text-base font-bold text-white cursor-pointer hover:bg-[#48484895] py-3 px-3 border-[#f30543] border-2 rounded-full duration-200 ease-in-out max-sm:w-full text-center"
            onClick={handleFavorite}
          >
            {isLiked ? (
              <>
              <p className="flex items-center mx-6 text-base font-bold text-white cursor-pointer  max-sm:w-full  max-sm:mx-3 max-sm:text-[13px]">
               Subscribed <FaCheck className="ml-3 !text-[var(--green)]"/>
                </p>
              </>
            ) : (
              <>
                <p className="flex items-center mx-6 text-base font-bold text-white cursor-pointer  max-sm:w-full  max-sm:mx-3 max-sm:text-[13px]">
                  <FaPlus className="mr-3" /> Subscribe
                </p>
              </>
            )}
          </p>
        </>
      {/* )} */}
    </>
  );
}

export default FollowCreators