import React from "react";

const TvChannelSkeleton = () => {
  const arr = [1, 2, 3, 4, 5, 6];
  return (
    <div>
      {/* <div className="w-[120px] h-[20px] bg-[#1a1919] animate-pulse mb-8"></div> */}
      {arr.map((item: any) => (
        <div key={item} className="flex flex-col mt-10 items-center justify-center">
          <div className="w-[120px] h-[120px] rounded-full  bg-[#1a1919] animate-pulse mb-3"></div>
          <div className="w-[120px] h-[20px] bg-[#1a1919] animate-pulse"></div>
        </div>
      ))}
    </div>
  );
};

export default TvChannelSkeleton;
