import { RouteName } from "constant/routeNames";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ConditionalSeeAll = ({ length, route }: any) => {
  const [maxSlidesToShowSeeAll, setMaxSlidesToShowSeeAll] = useState(5);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width < 350) {
        setMaxSlidesToShowSeeAll(1);
      } else if (width < 550) {
        setMaxSlidesToShowSeeAll(2);
      } else if (width < 1000) {
        setMaxSlidesToShowSeeAll(4);
      } else if (width < 1100) {
        setMaxSlidesToShowSeeAll(4);
      } else if (width < 1200) {
        setMaxSlidesToShowSeeAll(5);
      } else if (width < 1400) {
        setMaxSlidesToShowSeeAll(6);
      } else {
        setMaxSlidesToShowSeeAll(7);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {length > maxSlidesToShowSeeAll ? (
        <Link to={route}>
          <p className="text-sm font-bold cursor-pointer red-text place-self-end">
            {""} See all
          </p>
        </Link>
      ) : (
        <></>
      )}
    </>
  );
};

export default ConditionalSeeAll;
