import Footer from "components/Footer";
import HomeNavbar from "components/HomeNavBar";

function LandingPages({ Content, anyPlayerActive }: any) {
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <HomeNavbar />
      <Content />
      <Footer />
    </div>
  );
}

export default LandingPages;
