import React from "react";
import Facebook from "./SocialMedia/Facebook";
import Twitter from "./SocialMedia/Twitter";
import Instagram from "./SocialMedia/Instagram";

function ShareBody({ content, shareUrl }: any) {
  return (
    <div className="p-3 ">
      {content.name === "Facebook" ? (
        <Facebook shareUrl={shareUrl} />
      ) : content.name === "Twitter" ? (
        <Twitter shareUrl={shareUrl} />
      ) : content.name === "Instagram" ? (
        <Instagram shareUrl={shareUrl} />
      ) : (
        <></>
      )}
    </div>
  );
}

export default ShareBody;
