import laptop from 'Assets/Images/laptop.png';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const VideoCarousel = () => {
  return (
    <div className="my-5 h-80 ">
      <div className="absolute z-10 w-full mx-auto mt-[-25px] gradientX">
        <img src={laptop} alt="" className="mx-auto " width={'660px'} />
      </div>

      <div className="relative flex items-center">
        <Swiper
          slidesPerView={8}
          loop={true}
          loopFillGroupWithBlank={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            650: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 6,
            },
            1600: {
              slidesPerView: 6,
            },
          }}
          modules={[Pagination]}
          className="relative mt-10 mb-5 mySwiper"
          // autoplay={{ delay: 3000 }}
          // speed={1000}
        >
          {channels.map((channel) => (
            <div key={channel.id}>
              <SwiperSlide className="">
                <img
                  src={channel.imageSrc}
                  alt=""
                  style={{
                    height: '280px',
                    width: '190px',
                    objectFit: 'cover',
                    borderRadius: '5px',
                  }}
                />
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default VideoCarousel;

const channels = [
  {
    id: 1,
    channelName: 'Channel Name',
    imageSrc: 'https://apollohou.com/wp-content/uploads/y0fmiq96uu871.jpg',
  },
  {
    id: 2,
    channelName: 'Channel Name',
    imageSrc:
      'https://www.scrolldroll.com/wp-content/uploads/2022/04/Doctor-strange-Hollywood-movies-releasing-in-may-2022.webp',
  },
  {
    id: 3,
    channelName: 'Channel Name',
    imageSrc:
      'https://qqcdnpictest.mxplay.com/pic/484f535d77542bca63e21efb896f4cd6/en/2x3/320x480/test_pic1638541013780.webp',
  },
  {
    id: 4,
    channelName: 'Channel Name',
    imageSrc:
      'https://m.media-amazon.com/images/M/MV5BODI0ZTljYTMtODQ1NC00NmI0LTk1YWUtN2FlNDM1MDExMDlhXkEyXkFqcGdeQXVyMTM0NTUzNDIy._V1_.jpg',
  },
  {
    id: 5,
    channelName: 'Channel Name',
    imageSrc:
      'https://assets.gadgets360cdn.com/pricee/assets/product/202205/jurassic_world_dominion_poster_1652087377.jpeg',
  },
  {
    id: 6,
    channelName: 'Channel Name',
    imageSrc:
      'https://img.republicworld.com/republic-prod/stories/images/15731132155dc3cd7fb3fd3.jpg',
  },
  {
    id: 7,
    channelName: 'Channel Name',
    imageSrc: 'https://m.media-amazon.com/images/I/A1CcbJfKqJL._AC_SL1500_.jpg',
  },
  {
    id: 8,
    channelName: 'Channel Name',
    imageSrc:
      'https://www.scrolldroll.com/wp-content/uploads/2022/04/Doctor-strange-Hollywood-movies-releasing-in-may-2022.webp',
  },
  {
    id: 9,
    channelName: 'Channel Name',
    imageSrc:
      'https://qqcdnpictest.mxplay.com/pic/484f535d77542bca63e21efb896f4cd6/en/2x3/320x480/test_pic1638541013780.webp',
  },
  {
    id: 10,
    channelName: 'Channel Name',
    imageSrc:
      'https://m.media-amazon.com/images/M/MV5BODI0ZTljYTMtODQ1NC00NmI0LTk1YWUtN2FlNDM1MDExMDlhXkEyXkFqcGdeQXVyMTM0NTUzNDIy._V1_.jpg',
  },
];
