import AyozatAudioPlayer from "components/AudioPlayer/AyozatAudioPlayer";
import React from "react";
import { useSelector } from "react-redux";

function RadioPlayer({ isActive }: any) {
  const { currentRadio } = useSelector((state: any) => state?.audio);
  return (
    <>
      <AyozatAudioPlayer
        currentAudio={currentRadio}
        isActive={isActive}
        radio={true}
      />
    </>
  );
}

export default RadioPlayer;
