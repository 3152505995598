export enum Notification {
  //  music
  NoSongs = "No Songs",
  SongAddedToQueue = "Song Added to Queue",
  SongsAddedToQueue = "Songs Added to Queue",
  SongAlreadyInTheQueue = "Song is already in the queue",
  AddToFav = "Added to favourites",
  RemovedFromFav = "Removed from favourites",
  FavError = "Request can not be completed",

  // podcast
  NoPodcasts = "No Podcasts",
  PodcastAddedToQueue = "Podcast Added to Queue",
  PodcastsAddedToQueue = "Podcasts Added to Queue",

  //
  CopyToClipboard = "Copied to clipboard",

  // create playlist
  PlaylistCreated = "Playlist Created",
  PlaylistUpdated = "Playlist Updated",
  PlaylistError = "Error Found",

  geoLocationNotAllowedText = "This content is not available in your region. ",

  // subscribe
  Subscribed = "Subscribed",
  Unsubscribed = "Unsubscribed",
}
