export enum AssetTypes {
  Movie = "Movie",
  Video = "Video",
  TVSeries = "TV Series",
  LiveTV = "LiveTV",
  Podcast = "Podcast",
  Music = "Music",
  Radio = "Radio",
  PayPerView = "PPV",
  Other = "Other",
}
