import MusicSkeleton from "components/skeleton/MusicSkeleton";
import { RouteName } from "constant/routeNames";
import { Link } from "react-router-dom";

const GenreCard = (props: any) => {
  const { id, name, alt_name, isLoading } = props.value;
  // console.log(genre);
  return (
    <>
      <Link to={RouteName.MusicCategoryPage.replace(":slug", alt_name)}>
        <div className="h-20 relative cursor-pointer w-[97%] bg-gradient-to-r from-[#272424] to-[#f30543] opacity-50 hover:opacity-100 rounded-lg">
          <div className="absolute inset-0 flex items-center justify-center mx-auto text-center">
            <p
              className="text-white  font-[800] text-[18px] "
              dangerouslySetInnerHTML={{ __html: name }}
            ></p>
          </div>
          {/* <img
          className="h-20 w-[97%] object-cover opacity-30 hover:opacity-70"
          src={
            // "https://townsquare.media/site/366/files/2022/02/attachment-slash-20202.jpg"
            "https://source.unsplash.com/random/800x800"
          }
          alt="/"
        /> */}
        </div>
      </Link>
    </>
  );
};

export default GenreCard;
