import PayPerViewPop from "components/PayPerViewPop";
import Calendar from "react-calendar";
import React, { useEffect, useState } from "react";
import { BsCalendarEvent } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import PaymentService from "services/PaymentService";
import { RouteName } from "constant/routeNames";
import {
  formatToLocalDateString,
  getFormattedLocalDate,
} from "utils/timeFunctions";
import { Helmet } from "react-helmet-async";

function PayperviewSuccesPage() {
  const navigate = useNavigate();
  const [event, setEvent] = useState<any>();
  const [fetching, setFetching] = useState(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ppv_e = queryParams.get("ppv_e");
  const payment_intent = queryParams.get("payment_intent");
  const redirect_status = queryParams.get("redirect_status");

  const getEvent = async () => {
    try {
      const response = await PaymentService.getEventByIntense(payment_intent);
      // console.log("response getEvent ", response.data);
      setEvent(response.data);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      console.error("Error during fetching event by payment_intent:", error);
    }
  };
  //get a link
  const baseUrl = window.location.origin;
  const currentUrl =
    baseUrl +
    `${RouteName.PayPerView.replace(
      "*",
      RouteName.PayperviewDetails.replace(":slug", event?.slug)
    )}`;

  const eventStartTime = getFormattedLocalDate(event?.event_formatted_date);
  const eventEndTime = getFormattedLocalDate(event?.end_date_time);

  const dateTimeString = event?.start_date_time;
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-based, so add 1
  const day = date.getDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // const oneHourBefore = new Date();
  // oneHourBefore.setUTCHours(hours - 4);
  // oneHourBefore.setUTCMinutes(minutes);

  const startDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;

  const startTime = `${eventStartTime?.hours < 10 ? "0" : ""}${
    eventStartTime?.hours
  }:${eventStartTime?.minutes < 10 ? "0" : ""}${eventStartTime?.minutes}`;

  const EndTime = `${eventEndTime?.hours < 10 ? "0" : ""}${
    eventEndTime?.hours
  }:${eventEndTime?.minutes < 10 ? "0" : ""}${eventEndTime?.minutes}`;

  const isNextDay =
    eventStartTime?.year !== eventEndTime?.year ||
    eventStartTime?.month !== eventEndTime?.month ||
    eventStartTime?.date !== eventEndTime?.date;

  const formattedEndTime = isNextDay ? "23:59" : EndTime;

  // console.log("date", startDate);
  // console.log("endformated", formattedEndTime);
  // console.log("start",startTime);

  useEffect(() => {
    if (payment_intent) {
      getEvent();
    }
  }, [payment_intent]);

  useEffect(() => {
    if (ppv_e) {
      try {
        // Decode the JWT token to get the original data
        const decodedData = jwt_decode(ppv_e) as any;
        const decodedPpvEvent = decodedData.ppvEvent;
        setEvent(decodedPpvEvent);
      } catch (error) {
        console.error("Error decoding ppv_e:", error);
      }
    }
  }, [ppv_e]);

  if (redirect_status === "failed") {
    navigate("/ppv/checkout", { state: { ppv_event: event } });
  }
  console.log("event checking ", event);
  // console.log("event time", event?.start_date_time);

  const handleGoHome = async () => {
    navigate(RouteName.PayPerView.replace("/*", ""));
  };

  return (
    <>
      <Helmet>
        <title>
          {event?.title
            ? `${event?.title} - Successfully purchased`
            : "AYOZAT Payperview"}
        </title>
        <meta
          name="description"
          content="Experience exclusive content and events on demand with our Pay-Per-View service."
        />
        {/* <link rel="canonical" href={`${FE_URL}/ppv`} /> */}
        <meta property="og:title" content="AYOZAT Payperview" />
        <meta
          property="og:description"
          content="Experience exclusive content and events on demand with our Pay-Per-View service."
        />
        {/* <meta property="og:image:secure_url" content="https://ayozat.co.uk/logo192.png" /> */}
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        {/* <meta property="og:url" content={`${FE_URL}/ppv`} /> */}
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AYOZAT Payperview" />
        <meta
          name="twitter:description"
          content="Experience exclusive content and events on demand with our Pay-Per-View service."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT Payperview" />
        {/* <meta name="twitter:url" content={`${FE_URL}/ppv`} /> */}
      </Helmet>

      {!fetching && redirect_status === "succeeded" && (
        <div className="flex items-center rounded-lg justify-center mx-auto bg-white max-sm:mb-20 w-[80%] max-lg:w-[95%] max-sm:block">
          <div
            className=" flex rounded-lg h-[600px] w-[50%] max-sm:w-[100%] max-sm:h-[200px]  overflow-hidden"
            // main back image
            style={{
              color: "#fff",
              backgroundImage: `url(${event?.bannerImage})`,
              // backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
          <div
            className=" bg-white flex w-[50%] max-sm:w-full p-0 rounded-lg "
            style={{}}
          >
            {/* <div> */}
            <div className="p-4 ml-5 max-sm:pt-[20px]">
              <h1 className="text-[35px] max-lg:text-[25px] leading-tight font-bold text-black max-sm:text-[25px]">
                Thank you for your purchase!
              </h1>
              <div className="mt-4">
                <p className="text-gray-500">
                  <span className="text-gray-900">Check Your Inbox</span> We've
                  just sent you an email with details about the event
                </p>
              </div>
              <div className="mt-[20px]">
                <h1 className="w-full my-5 font-rubik text-black text-[18px]">
                  {event?.title
                    .replace(/&amp;/g, "&")
                    .replace(/&rsquo;/g, "’")
                    .replace(/&nbsp;/g, "")}
                </h1>
              </div>
              <div className="">
                {event?.rentable ? (
                  <>
                    <>
                      <h2 className="my-4 text-center bg-red-600 text-white text-[15px] p-1 w-[50%]">
                        Available for Rent: {event?.rent_duration}{" "}
                        {event?.rent_duration > 1 ? <> Days</> : <> Day</>}
                      </h2>
                    </>
                  </>
                ) : (
                  <>
                    <h1 className="my-4 text-center bg-red-600 text-white text-[15px] p-1 w-[50%]">
                      {formatToLocalDateString(event?.event_formatted_date)}
                    </h1>
                  </>
                )}
              </div>
              {!event?.rentable && (
                <div>
                  <h1 className="w-full my-8 text-gray-500 text-[16px]">
                    You can set a calender reminder to make sure you will not
                    miss the event
                  </h1>
                </div>
              )}

              <div className="">
                <div className="flex items-center w-full mt-[10px]">
                  <h1 className="flex justify-center items-center text-[16px] text-white my-auto w-full ">
                    {!event?.rentable && (
                      <AddToCalendarButton
                        name={`${event?.title} - AYOZAT PPV`}
                        startDate={startDate}
                        startTime={startTime}
                        endTime={formattedEndTime}
                        // name="[Reminder] Test the Add to Calendar Button"
                        // startDate="2023-10-24"
                        // startTime="10:15"
                        // endTime="23:30"
                        timeZone={
                          Intl.DateTimeFormat().resolvedOptions().timeZone
                        }
                        location="World Wide Web"
                        description={`${event?.description}\nFor more Details: ${currentUrl}`}
                        options={[
                          "Apple",
                          "Outlook.com",
                          "Yahoo",
                          "Google",
                          "iCal",
                        ]}
                        recurrence=""
                        lightMode="bodyScheme"
                        buttonStyle="round"
                      />
                    )}
                    <button
                      className={`flex items-center max-sm:justify-center w-auto p-2 font-semibold text-center text-white rounded-full button-red max-sm:py-3 max-sm:text-[15px]  max-sm:mx-auto `}
                      onClick={handleGoHome}
                    >
                      Back to Home
                    </button>
                  </h1>
                  {/* </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {fetching && (
        <div className="flex items-center rounded-lg justify-center mx-auto bg-gray-600 max-sm:mb-20 w-[80%] max-lg:w-[95%] max-sm:block animate-pulse h-96"></div>
      )}
    </>
  );
}

export default PayperviewSuccesPage;
