import CreatorAllVideosSkeleton from "components/skeleton/CreatorAllVideosSkeleton";
import React from "react";
import VideohomeCreatorCard from "./VideohomeCreatorCard";

const VideoListCreator = ({ videos, isLoading }: any) => {
  return (
    <>
      {videos.map((video: any, index: any) => {
        return (
          <div key={index} className="mb-5 mr-4 ">
            {isLoading ? (
              <CreatorAllVideosSkeleton />
            ) : (
              <>
                <VideohomeCreatorCard
                  video={video}
                  slug={video?.creator?.slug}
                />
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default VideoListCreator;
