import SubText from "components/shared/SubText";
import React from "react";
import PlayListCardCoursel from "./home/PlayListCardCoursel";
import CreateNewPlaylistCard from "./collection/CreateNewPlaylistCard";
import MusicCardCourselHistory from "./history/MusicCardCourselHistory";
import { audios } from "constant/audios";

const MusicPageCollection = () => {
  return (
    <div className="mb-30">
      <div className="mt-[50px] max-sm:mt-4">
        <SubText text="Alex's Playlist" className="font-bold" />
        <PlayListCardCoursel />
      </div>
      <CreateNewPlaylistCard />
      <div
        className="
      mt-[50px] mb-28"
      >
        <SubText text="Recent Tracks " className="font-bold " />
        <div>
          <MusicCardCourselHistory audios={audios} />

          <MusicCardCourselHistory audios={audios} />
        </div>
      </div>
    </div>
  );
};

export default MusicPageCollection;
