import Podcastcard from "pages/podcastPage/shared/Podcastcard";
import NoSearch from "pages/searchPage/shared/NoSearch";
import React from "react";
import { useSelector } from "react-redux";

const PodcastPage = ({ creatorData }: any) => {
  const podcasts = creatorData?.podcasts || [];

  // console.log(podcasts);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  return (
    <div>
      {/* card section */}
      {podcasts.length === 0 && podcasts ? (
        <NoSearch text="Podcasts" />
      ) : (
        <div>
          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {podcasts.length > 0 &&
              podcasts.map((podcast: any, i: any) => (
                <div key={i}>
                  <Podcastcard podcast={podcast} user={loggedInUser} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PodcastPage;
