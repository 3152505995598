import React from "react";
import { useSelector } from "react-redux";

const ChatBody = ({ chats, showChatProgress }: any) => {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const isSingleEmoji = (message: any) => {
    const emojiRegex = new RegExp("^[\u{1F000}-\u{1F9FF}]$", "gu");
    return emojiRegex.test(message);
  };

  const isGifUrl = (url: string) => {
    const gifUrlPattern = /^https:\/\/giphy\.com\/embed\/[a-zA-Z0-9]+$/;
    return gifUrlPattern.test(url);
  };

  // console.log(chats);
  return (
    <>
      <div className="">
        {/* <div className="absolute w-[90%] h-[5%] bg-gradient-to-b from-[#131313]"></div> */}
        {chats?.map((chat: any, index: any) => {
          // Parse the timestamp
          const chatTime = new Date(chat?.created_at);
          // Extract hours and minutes
          const hours = chatTime.getHours();
          const minutes = chatTime.getMinutes();
          if (chat?.user !== null) {
            return (
              <div
                key={index}
                className={`flex  ${
                  chat?.user_id === loggedInUser.id
                    ? "justify-end pl-[15%]"
                    : "justify-start pr-[15%]"
                } mb-4`}
              >
                <div className="flex items-end justify-end">
                  {chat?.user_id !== loggedInUser.id && (
                    <img
                      src={chat?.user?.artwork_url}
                      alt="Avatar"
                      className="w-8 h-8 mr-2 rounded-full"
                    />
                  )}
                  <div className="flex flex-col">
                    <div
                      className={`px-3 py-2 text-[15px] font-light ${
                        chat?.user_id === loggedInUser.id
                          ? "bg-[#0b3144] text-gray-200 rounded-bl-xl rounded-tr-xl rounded-tl-xl "
                          : "bg-gray-800 text-gray-400  rounded-tl-xl rounded-br-xl rounded-tr-xl"
                      }`}
                    >
                      {chat?.user_id !== loggedInUser.id && (
                        <p className="text-white text-[10px] font-light pb-3 ">
                          {chat?.user?.username}
                        </p>
                      )}
                      <div
                        className={`flex justify-between ${
                          isSingleEmoji(chat?.message)
                            ? "text-[40px]"
                            : "text-[15px]"
                        }`}
                      >
                        <div className="flex flex-col justify-between"></div>
                        {isGifUrl(chat?.message) ? (
                          <div className="relative">
                            <iframe
                              src={chat?.message}
                              width="100%"
                              height="300"
                              frameBorder="0"
                              className="giphy-embed"
                              allowFullScreen
                              title="GIF"
                            ></iframe>
                          </div>
                        ) : (
                          chat?.message
                            .split("\n")
                            .map((line: any, index: any) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))
                        )}
                        {/* Display the extracted time */}
                        <p className="text-gray-500 text-[9px] flex justify-end items-end ml-2 font-light  mb-[2px]">
                          {hours}:{minutes < 10 ? "0" + minutes : minutes}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
        {showChatProgress && (
          <div className="typing">
            <div className="typing__dot"></div>
            <div className="typing__dot"></div>
            <div className="typing__dot"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatBody;
