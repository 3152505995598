import { createSlice } from "@reduxjs/toolkit";
import { VideoLastWatch } from "pages/videoPage/home/shared/VideoPlayer";

const VideoPlayerRedux = createSlice({
  name: "video",
  initialState: {
    currentVideo: null,
    currentLiveTv: null,
    previousVideo: null,
    isFetching: false,
    lastWatched: [],
    error: false,
    channelCategory: "all",
    channelId: 0,
    liveTVAdTriggered: false,
    playlistId: 0,
    volume: 0.4,
  },
  reducers: {
    setVideoStart: (state: any) => {
      state.isFetching = true;
    },
    setVideoLoaded: (state: any) => {
      state.isFetching = false;
    },
    setLiveTVSuccess: (state: any, action: any) => {
      state.isFetching = false;
      state.previousVideo = state.currentLiveTv;
      state.currentLiveTv = action.payload;
      state.error = false;
    },
    setVideoSuccess: (state: any, action: any) => {
      state.isFetching = false;
      state.currentVideo = action.payload;
      state.error = false;
    },
    removeVideoData: (state: any) => {
      state.currentVideo = null;
      state.previousVideo = null;
    },
    removeLiveTVData: (state: any) => {
      state.currentLiveTv = null;
    },
    addLastWatch: (state: any, action: any) => {
      // console.log(action.payload);
      if (action.payload.type === "Movie") {
        state.lastWatched =
          state.lastWatched &&
          state.lastWatched?.map((d: VideoLastWatch) => {
            if (d.id === action.payload.id && d.type === action.payload.type) {
              return action.payload; // Update existing element
            }
            return d; // Keep the original element
          });
      } else {
        state.lastWatched =
          state.lastWatched &&
          state.lastWatched?.map((d: VideoLastWatch) => {
            if (d?.tv_show_name === action.payload.tv_show_name) {
              return action.payload; // Update existing element
            }
            return d; // Keep the original element
          });
      }

      // If no match was found, add action.payload to the array
      if (
        state.lastWatched &&
        !state.lastWatched.some(
          (d: VideoLastWatch) => d.id === action.payload.id
        )
      ) {
        if (!state.lastWatched) {
          state.lastWatched = [action.payload];
        } else {
          state.lastWatched.push(action.payload);
        }
      }
    },
    removeLastWatch(state: any, action: any) {
      const idToRemove = action.payload;
      const existingItemIndex = state.lastWatched.findIndex(
        (item: any) => item.id === idToRemove
      );

      if (existingItemIndex !== -1) {
        state.lastWatched.splice(existingItemIndex, 1);
      }
    },
    setChannelCategory(state: any, action: any) {
      state.channelCategory = action.payload;
    },
    setCurrentChannelId(state: any, action: any) {
      state.channelId = action.payload;
    },
    setTriggerLiveTVAd(state: any, action: any) {
      state.liveTVAdTriggered = action.payload;
    },
    setPlaylistID(state: any, action: any) {
      state.playlistId = action.payload;
    },
    // set player volume
    adjustVolumeVideo: (state: any, action: any) => {
      state.volume = action.payload;
    },
  },
});

export const {
  setVideoStart,
  setLiveTVSuccess,
  setVideoSuccess,
  removeVideoData,
  removeLiveTVData,
  setVideoLoaded,
  addLastWatch,
  setChannelCategory,
  setCurrentChannelId,
  setTriggerLiveTVAd,
  removeLastWatch,
  setPlaylistID,
  adjustVolumeVideo,
} = VideoPlayerRedux.actions;
export default VideoPlayerRedux.reducer;
