import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

const getAllChannels = async () => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/tv-channels/all`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  return response.data;
};

const getFeaturedChannels = async () => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/tv-channels/featured`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  return response.data;
};

const getTVChannelBanner = async () => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/tv-channel/banner`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  return response.data;
};

const getEpgXML = async () => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/fetch-epg-xml`,
    // url: `http://127.0.0.1:5500/src/pages/liveTvPage/shared/program.xml`,
    headers: {
      'Content-Type': 'application/xml; charset=utf-8',
    },
  });

  return response;
};

const getCHannelDetailsBySlug = async (slug: any) => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/tv-channels/show/${slug}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  return response.data;
};

const getEpgDetailsBySlug = async (slug: any) => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/tv-channels/programs/${slug}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  return response.data;
};

const getJsonEpg = async () => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/tv-channels/epg/schedule`,
    headers: {
      'Content-Type': 'application/xml; charset=utf-8',
    },
  });

  return response;
};

const getLiveTvCategories = async () => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/tv-channel/categories`,
    headers: {
      'Content-Type': 'application/xml; charset=utf-8',
    },
  });

  return response.data;
};

const getLiveTvByCategory = async (category: any) => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/tv-channels/category/${category}`,
    headers: {
      'Content-Type': 'application/xml; charset=utf-8',
    },
  });
  return response.data;
};

const LiveTVService = {
  getAllChannels,
  getEpgXML,
  getCHannelDetailsBySlug,
  getEpgDetailsBySlug,
  getFeaturedChannels,
  getJsonEpg,
  getLiveTvCategories,
  getLiveTvByCategory,
  getTVChannelBanner,
};

export default LiveTVService;
