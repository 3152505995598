import MusicArt from "Assets/Images/MusicArt.jpg";
import { RouteName } from "constant/routeNames";
import { Link } from "react-router-dom";

export const PlaylistCard = ({ id }: any) => {
  return (
    // <Link to={RouteName.MusicPlaylistPage.replace(":id", id)}>
    <div className=" inline-block w-[198px]  rounded-md max-sm:w-[170px]">
      {/* image */}
      <div className=" w-full h-[198px] items-center justify-center max-sm:h-[170px]">
        <div className="absolute w-[198px] h-[61px] max-sm:w-[170px] object-cover overflow-hidden rounded-md mt-[137px] opacity-80 backdrop-blur-xl"></div>
        <img
          src={MusicArt}
          alt="Live Channel"
          className="w-[198px] h-full  object-cover overflow-hidden rounded-md"
        />
      </div>

      <div className="absolute mt-[-60px] ml-1">
        <div className="my-[4px]">
          <p className="text-[15px] font-[500] text-white">
            Red (Taylor’s Version)
          </p>

          <p className="text-[12px] font-[400] text-[#7B9DB4] mt-[5px]">
            Taylor Swift
          </p>
        </div>
      </div>
    </div>
    // </Link>
  );
};
