//import logo
import Logo from "Assets/Images/a_logo.png";
import LoadingSpin from "components/Loading spins/LoadingSpin";
import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthService from "services/auth/AuthService";
import CoverPoster from "Assets/Images/CoverPoster.jpg";

function ForgotPassword() {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
  });

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [errors, setErrors] = useState({
    email: [],
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [emailShow, setemailShow] = useState(false);

  const handleForgotPassword = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await AuthService.forgotPasswordRequest(formData)
      .then((response) => {
        if (response.status === 200) {
          resetForm();
          removeErrors();
          setemailShow(true);
          toast.success("We have sent the password reset link to your email.", {
            position: "top-right",
            autoClose: 9000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
        } else {
          toast.error(
            "An error occurred while attempting to send the password reset email."
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          const errorData = error.response.data.errors;
          setErrors(errorData);
        } else if (error.response.status === 404) {
          const emailError = [
            "We couldn't locate an account associated with your details",
          ];
          updateEmailError(emailError);
        } else {
          toast.error(
            "An error occurred while attempting to send the password reset email."
          );
        }
        setLoading(false);
      });
  };

  const updateEmailError = (error: any) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: error,
    }));
  };

  const resetForm = () => {
    setFormData({
      email: "",
    });
  };

  const removeErrors = () => {
    setErrors({
      email: [],
    });
  };

  return (
    <div
      className="overflow-hidden max-sm:w-[100%] "
      // main back image
      style={{
        color: "#fff",
        backgroundImage: `url(${CoverPoster})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      {/* red dot top left */}
      <div className="loginpage-back scrollbar-hide">
        <div
          className="absolute h-[800px] w-[800px] -left-[350px] -top-[350px] max-sm:hidden"
          style={{
            color: "#fff",
            backgroundImage: `url('https://i.stack.imgur.com/G7vn7.png')`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="overflow-hidden login-page-form-container scrollbar-hide">
          <div
            className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer mb-4`}
          >
            <HiArrowLeft
              className="text-black"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          {/* ayosat logo part */}
          <div className="relative left-0 bg-white rounded-full w-[100px] h-[100px] flex items-center mb-10">
            <img src={Logo} alt="logo" className="w-16 h-16 mx-auto mb-2" />
          </div>

          {/* main topic section */}

          <div className="relative">
            <form>
              <div className="text-left">
                <h1 className="mb-2 text-4xl font-bold white-text sm:text-4xl">
                  Forgot Password
                </h1>
                <p className="mb-4 text-sm tracking-wide gray-text">
                  Enter the email address you used when you joined and we'll
                  send you instructions to reset your password.
                </p>
              </div>

              {emailShow ? (
                <div className="mt-5 text-left">
                  <p className="mt-4 mb-4 text-lg text-red-400">
                    We have sent the password reset link to your email.
                  </p>
                </div>
              ) : (
                <></>
              )}
              {!emailShow && (
                <>
                  <div className="flex max-sm:block">
                    <div className="w-full">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={onChange}
                        autoComplete="email"
                        className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] mb-4 text-gray-600"
                      />
                      {errors.email?.length > 0 && (
                        <div className="mb-5 text-left text-red-400">
                          <span className="error">{errors.email[0]}</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <p
                      onClick={handleForgotPassword}
                      // type="submit"
                      className="flex items-center justify-center py-4 my-4 text-lg font-bold rounded-full white-text button-red"
                    >
                      Send Reset Instructions
                      <span className="ml-3">
                        {loading ? (
                          <LoadingSpin fontSize={20} color={"white"} />
                        ) : (
                          ``
                        )}
                      </span>
                    </p>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
