const HeroSkeleton = () => {
  return (
    <div>
      <div className="flex flex-col items-start justify-end">
        <div className="lg:w-[980px] max-md:w-[600px] max-sm:w-[300px] h-[500px] bg-[#1a1919] animate-pulse mt-2"></div>
      </div>
    </div>
  );
};

export default HeroSkeleton;
