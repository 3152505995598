import { RouteName } from "constant/routeNames";
import { FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";

function UserEditProfileButton() {
  return (
    <Link to={RouteName.EditProfile}>
      <button
        className={`flex items-center min-w-[128px] h-[40px] p-4 text-center border-solid border-2 border-white text-white rounded-full max-sm:p-2 `}
      >
        <div className="flex items-center justify-center w-full gap-2 mx-auto">
          <span>
            <FiSettings />
          </span>{" "}
          Settings
        </div>
      </button>
    </Link>
  );
}

export default UserEditProfileButton;
