import React from 'react'

const WelcomeHeroSlider = () => {
    return (
        <div>
            <div className='lg:w-full h-[400px] bg-[#1a1919] animate-pulse mb-5'></div>
        </div>
    )
}

export default WelcomeHeroSlider