import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { ArtistCard } from "../shared/ArtistCard";
import { useEffect, useState } from "react";
import ArtistService from "services/music/ArtistService";
import ArtistSkeleton from "../../../components/skeleton/ArtistSkeleton";

function ArtistCardCoursel() {
  const [artists, setArtists] = useState<any>([]);
  const [isLoading, setIsLoding] = useState<any>(false);
  const [url, setUrl] = useState("artists?page=1");
  useEffect(() => {
    setIsLoding(true);
    ArtistService.getAllArtists(url).then((res) => {
      setIsLoding(false);
      setArtists(res.data);
      // console.log(res.data);
    });
  }, []);
  return (
    <div>
      {!isLoading && (
        <div className="">
          <Swiper
            slidesPerView={6}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              350: {
                slidesPerView: 2,
              },
              500: {
                slidesPerView: 3,
              },
              1100: {
                slidesPerView: 4,
              },
              1300: {
                slidesPerView: 5,
              },
              1500: {
                slidesPerView: 6,
              },
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="mt-5 mb-5 mySwiper"
          >
            {artists &&
              artists.map((value: any, i: number) => (
                <SwiperSlide className="" key={i}>
                  <ArtistCard artist={value} isLoading={isLoading} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
      {isLoading && <ArtistSkeleton />}
    </div>
  );
}

export default ArtistCardCoursel;
