import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Podcastcard from "./Podcastcard";
import { useSelector } from "react-redux";

function SuggestedPodcastSection({ podcast }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  return (
    <>
      {podcast?.related && podcast.related.length > 0 && (
        <div>
          <div className="">
            <Swiper
              slidesPerView={7}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                370: {
                  slidesPerView: 2,
                },
                700: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                },
                1400: {
                  slidesPerView: 6,
                },
                1600: {
                  slidesPerView: 7,
                },
              }}
              modules={[Pagination, Navigation]}
              className="mt-4 mb-5 mySwiper"
              navigation={true}
            >
              {podcast?.related &&
                podcast?.related.map((podcast: any, i: number) => (
                  <SwiperSlide key={i}>
                    <Podcastcard
                      podcast={podcast}
                      user={loggedInUser}
                      // favorite={favorite}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
}

export default SuggestedPodcastSection;
