import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import Picker from "emoji-picker-react";
import { Theme } from "emoji-picker-react";
import { FaRegSmile } from "react-icons/fa";
import { IoSendSharp } from "react-icons/io5";
import ChatService from "../../../services/chatService/ChatService";
import { GoFileMedia } from "react-icons/go";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import MediaWindow from "./MediaWindow";

const ChatInput = ({
  inputValue,
  setInputValue,
  event,
  setSubmitScroll,
  setNewChats,
  newChats
}: any) => {
  // const CHAT_SOCKET_URL = process.env.REACT_APP_CHAT_SERVER;
  const CHAT_SOCKET_URL = 'https://test-socket-chat-server.onrender.com';

  const [showPicker, setShowPicker] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const pickerRef = useRef<HTMLDivElement>(null);
  const mediaRef = useRef<HTMLDivElement>(null);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [textareaHeight, setTextareaHeight] = useState("40px");
  const socket = io(CHAT_SOCKET_URL);
  // console.log(event)
  //Click outside for emoji picker
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        mediaRef.current &&
        !mediaRef.current.contains(event.target as Node)
      ) {
        setShowMedia(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!CHAT_SOCKET_URL) {
    throw new Error(
      "REACT_APP_CHAT_SERVER environment variable is not defined."
    );
  }

  const onEmojiClick = (event: any) => {
    setInputValue((prevInput: any) => prevInput + event.emoji);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setInputValue((prevMessage: string) => prevMessage + "\n");
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (inputValue.trim() !== "") {
        handleSubmit(e);
        setInputValue("");
      }
    }
  };

  useEffect(() => {
    const lineHeight = 30;
    const maxRows = 4;

    if (inputValue !== " ") {
      setTextareaHeight("40px");
      const rows = Math.ceil(inputValue.split("\n").length);
      if (rows > 1) {
        const clampedRows = Math.min(rows, maxRows);
        const height = `${lineHeight * clampedRows}px`;
        setTextareaHeight(height);
      }
    }
  }, [inputValue]);

  // const socket = useRef<WebSocket | null>(null);

  // const socket = new WebSocket(CHAT_SOCKET_URL);

  // useEffect(() => {
  //   if (socket) {
  //     console.log(
  //       "--------------------socket ready state",
  //       socket.readyState
  //     );
  //   }
  // });

  const sendWhenOpen = (socket: any, message: any) => {
    // if (socket.readyState === WebSocket.OPEN) {
    //   socket.send(message);
    // } else if (socket.readyState === WebSocket.CONNECTING) {
    //   const handleOpen = () => {
    //     socket.send(message);
    //     socket.removeEventListener("open", handleOpen);
    //   };
    //   socket.addEventListener("open", handleOpen);
    // } else {
    //   console.error("WebSocket is not in a state to send messages.");
    // }
    // socket.addEventListener("open", () => {
    //   if (event?.id) {
    //     console.log(`send message room-${event.id}`);
    //     const messageToSend = JSON.stringify(message);
    //     socket.send(messageToSend);
    //   }
    // });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // if (inputValue !== "") {
    //   sendWhenOpen(socket, {
    //     event: "send-message",
    //     data: {
    //       payperviewId: event.id,
    //       message: inputValue,
    //       token: loggedInUser?.access_token,
    //     },
    //   });
    //   sendWhenOpen(socket, {
    //     event: "chat-onChange",
    //     data: {
    //       payperviewId: event.id,
    //       onChange: false,
    //       userId: loggedInUser?.id,
    //     },
    //   });

    //   setTextareaHeight("40px");
    //   setInputValue("");
    //   setSubmitScroll(true);
    // }
    if (inputValue !== "") {
      socket.emit("send-message", {
        payperviewId: event.id,
        message: inputValue,
        token: loggedInUser?.access_token,
      });
      socket.emit("chat-onChange", {
        payperviewId: event.id,
        onChange: false,
        userId: loggedInUser?.id,
      });
      setTextareaHeight("40px");
      setInputValue("");
      setSubmitScroll(true);
    }
  };
  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setInputValue(e.target.value);

    // if (e.target.value !== "") {
    //   sendWhenOpen(socket, {
    //     event: "chat-onChange",
    //     data: {
    //       payperviewId: event.id,
    //       onChange: true,
    //       userId: loggedInUser?.id,
    //     },
    //   });
    // } else {
    //   sendWhenOpen(socket, {
    //     event: "chat-onChange",
    //     data: {
    //       payperviewId: event.id,
    //       onChange: false,
    //       userId: loggedInUser?.id,
    //     },
    //   });
    // }
    if (e.target.value !== "") {
      socket.emit("chat-onChange", {
        payperviewId: event.id,
        onChange: true,
        userId: loggedInUser?.id,
      });
    } else {
      socket.emit("chat-onChange", {
        payperviewId: event.id,
        onChange: false,
        userId: loggedInUser?.id,
      });
    }
  };

  const handleClear = () => {
    setInputValue("");
  };

  const isGifUrl = (url: string) => {
    const gifUrlPattern = /^https:\/\/giphy\.com\/embed\/[a-zA-Z0-9]+$/;
    return gifUrlPattern.test(url);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex items-end justify-between w-full px-3 py-2 border border-gray-400 rounded-xl"
      >
        {isGifUrl(inputValue) ? (
          <div className="relative">
            <iframe
              src={inputValue}
              width="100%"
              height="200"
              frameBorder="0"
              className="rounded-lg"
              allowFullScreen
              title="GIF"
            ></iframe>
            <button
              onClick={handleClear}
              className="absolute flex items-center justify-center top-0 right-0 px-1 w-[20px] h-[20px] text-white bg-red-500 rounded-full text-[12px]"
            >
              X
            </button>
          </div>
        ) : (
          <textarea
            placeholder="Type your message..."
            value={inputValue}
            onKeyDown={handleKeyPress}
            onChange={(e) => onChange(e)}
            className="w-full p-2 pl-5 text-white bg-[#131313] max-sm:text-[15px]
      "
            style={{ height: textareaHeight, resize: "none" }}
          />
        )}

        <FaRegSmile
          className="mx-2 text-white cursor-pointer"
          size={30}
          onClick={() => setShowPicker((val) => !val)}
        />

        {showPicker && (
          <div
            className="absolute bottom-[36%] right-[20%] overflow-visible max-sm:right-[10%] max-sm:bottom-[20%]"
            ref={pickerRef}
          >
            <Picker onEmojiClick={onEmojiClick} theme={Theme.DARK} />
          </div>
        )}

        <GoFileMedia
          className="mx-2 text-white cursor-pointer"
          size={30}
          onClick={() => setShowMedia((val) => !val)}
        />
        {showMedia && (
          <div
            className="absolute w-[70%]  bottom-[36%] right-[20%] overflow-visible max-sm:right-[10%] max-sm:bottom-[20%]"
            ref={mediaRef}
          >
            <MediaWindow
              setInputValue={setInputValue}
              setShowMedia={setShowMedia}
            />
          </div>
        )}
        <button
          type="submit"
          className="px-3 py-2 ml-2 text-white bg-[#f30543] rounded-xl"
        >
          <IoSendSharp className="text-white" size={20} />
        </button>
      </form>
    </>
  );
};

export default ChatInput;
