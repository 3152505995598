import React from 'react'

const PodecastSkeletonCard = () => {
  return (
    <div>
      <div className='w-[180px] h-[250px] bg-[#1a1919] animate-pulse'></div>
    </div>
  )
}

export default PodecastSkeletonCard