import { Route, Routes } from "react-router-dom";
import { RouteName } from "../constant/routeNames";
import FeaturePages from "../layout/FeaturePages";
import MusicAlbumPage from "pages/musicPage/MusicAlbumPage";
import MusicPageHome from "pages/musicPage/MusicPageHome";
import MusicPlaylistPage from "pages/musicPage/MusicPlaylistPage";
import MusicCategoryPage from "./musicPage/MusicCategoryPage";
import MusicDiscoverPage from "./musicPage/MusicDiscoverPage";
import MusicPageCollection from "./musicPage/MusicPageCollection";
import MusicPageHistory from "./musicPage/MusicPageHistory";
import MusicArtistPage from "./musicPage/MusicArtistPage";
import SeeAllArtist from "./musicPage/SeeAllArtist";
import SeeAllCategory from "./musicPage/SeeAllCategory";
import SeeAllNewRelease from "./musicPage/SeeAllNewRelease";
import SeeAllAlbums from "./musicPage/SeeAllAlbums";
import MusicTrackPage from "./musicPage/MusicTrackPage";
import DiscoverPlaylists from "./musicPage/DiscoverPlaylists";

function MusicPage({
  handleClick,
  isActive,
  setIsActive,
  // setPlayerIsActive,
  anyPlayerActive,
}: any) {
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        <Route
          path="/"
          element={
            <FeaturePages
              Content={MusicPageHome}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />
        <Route
          path={RouteName.MusicDiscover}
          element={
            <FeaturePages
              Content={MusicDiscoverPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />

        <Route
          path={RouteName.MusicAlbum}
          element={
            <FeaturePages
              Content={MusicAlbumPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />

        <Route
          path={RouteName.MusicPlaylist}
          element={
            <FeaturePages
              Content={MusicPlaylistPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllDiscoverPlaylists}
          element={
            <FeaturePages
              Content={DiscoverPlaylists}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />
        <Route
          path={RouteName.MusicCategory}
          element={
            <FeaturePages
              Content={MusicCategoryPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />
        <Route
          path={RouteName.MusicArtist}
          element={
            <FeaturePages
              Content={MusicArtistPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />
        <Route
          path={RouteName.MusicHistory}
          element={
            <FeaturePages
              Content={MusicPageHistory}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />
        <Route
          path={RouteName.MusicCollection}
          element={
            <FeaturePages
              Content={MusicPageCollection}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllArtist}
          element={
            <FeaturePages
              Content={SeeAllArtist}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllGenre}
          element={
            <FeaturePages
              Content={SeeAllCategory}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllNewRelease}
          element={
            <FeaturePages
              Content={SeeAllNewRelease}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllAlbums}
          element={
            <FeaturePages
              Content={SeeAllAlbums}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />

        <Route
          path={RouteName.MusicTrack}
          element={
            <FeaturePages
              Content={MusicTrackPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Music"}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default MusicPage;
