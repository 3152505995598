import LazyLoadImg from "components/LazyLoadImg";
import { IoMdRemoveCircle } from "react-icons/io";
import { useDispatch } from "react-redux";
import {
  removePodcastFromPlaylist,
  removePodcastFromPlaylistLoading,
  removeSongFromPlaylist,
  removeSongFromPlaylistLoading,
  setCurrentAudio,
  setCurrentPodcast,
} from "redux/AudioPlayerRedux";

function PlaylistCard({
  song,
  currentSong,
  index,
  music,
  radio,
  podcast,
}: // QueueCount,
any) {
  // console.log(song);
  const dispatch = useDispatch<any>();

  const chooseSong = () => {
    if (music) {
      dispatch(setCurrentAudio(song));
    } else if (podcast) {
      dispatch(setCurrentPodcast(song));
    }
  };

  const handleDelete = async () => {
    // handleDeleteSong(song.id);
    if (music) {
      const obj = {
        songId: song.id,
        index: index,
      };
      await dispatch(removeSongFromPlaylistLoading());
      await dispatch(removeSongFromPlaylist(obj));
    } else if (podcast) {
      const obj = {
        songId: song.id,
        index: index,
      };
      await dispatch(removePodcastFromPlaylistLoading());
      await dispatch(removePodcastFromPlaylist(obj));
    }
  };
  return (
    <>
      {song && (
        <div className="music-playlist-card-bg">
          {/* {QueueCount > 1 && ( */}
          <div
            className={`music-playlist-card-remove-btn`}
            onClick={handleDelete}
          >
            <IoMdRemoveCircle />
          </div>
          <div
            className="music-playlist-card-choose-song-btn"
            onClick={() => chooseSong()}
          >
            {(song.artwork_url || song?.podcast?.artwork_url) && (
              <LazyLoadImg
                imgSrc={
                  music
                    ? song?.artwork_url
                    : podcast
                    ? song?.podcast?.artwork_url
                    : ``
                }
                imgAlt={"first artist"}
                imgHeight={"60px"}
                imgWidth={"60px"}
                className="rounded-md album-page-image max-sm:h-[20%]"
                style={{
                  borderRadius: "6px",
                }}
              />
            )}

            <div className={`block`}>
              <p
                className={`${
                  currentSong?.id === song.id ? "text-red-600" : "text-white"
                }`}
              >
                {song.title.length > 10
                  ? `${song.title.slice(0, 10)}...`
                  : song.title}
              </p>
              <p className="music-playlist-card-artist-name ">
                {music && song?.artists && song?.artists[0]?.name}
                {podcast && song?.artist && song?.artist?.name}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PlaylistCard;
