import React from "react";

function NoSearch({ text }: any) {
  // const lowerTest = text.toLowerCase();
  return (
    <div>
      <div className="flex items-center justify-center h-full mt-20 text-gray-400 text-md">
        <p className="">
          {/* No {text} found */}
          Sorry ! There isn't any content related to the{" "}
          <span className="lowercase">{text}</span>.
        </p>
        {/* <p className="flex justify-center text-lg font-normal text-white">
              Share your profile with your friends to gain more followers!
            </p> */}
      </div>
    </div>
  );
}

export default NoSearch;
