import React from "react";
import { Link } from "react-router-dom";

function FooterResourcesLink({ text, route }: any) {
  return (
    <Link to={route}>
      <p className="mb-1 cursor-pointer">{text}</p>
    </Link>
  );
}

export default FooterResourcesLink;
