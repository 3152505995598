import LazyLoadImg from "components/LazyLoadImg";
import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { FaPlay } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { addStationToRadio } from "redux/AudioPlayerRedux";
import { Tooltip as ReactTooltip } from "react-tooltip";

function RadioStationCard({ station }: any) {
  const [underline, setUnderline] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const handleRadio = () => {
    dispatch(addStationToRadio(station));
  };
  return (
    <div className="flex justify-between mr-[20px] w-[265px] rounded-md cursor-pointer max-sm:mr-[10px]">
      {/* image */}
      {/* <img
        src={station.artwork_url}
        alt="radio station icon"
        className="w-[75px] rounded-lg h-[75px] bg-img white-text object-cover"
      /> */}
      <div
        onClick={handleRadio}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <LazyLoadImg
          imgSrc={station?.artwork_url}
          imgAlt={"podcast icon"}
          imgHeight={"75px"}
          imgWidth={"75px"}
          className="w-[75px] rounded-lg h-[75px] bg-img white-text object-cover"
          style={{
            borderRadius: "6px",
            width: "75px",
            height: "75px",
            objectFit: "cover",
            // marginRight: "8px",
          }}
        />
        {hover && (
          <div className="absolute w-[75px] h-[75px] ml-3 rounded-lg inset-0 flex items-center justify-center bg-[#00000074]">
            <FaPlay className="text-red-600" />
          </div>
        )}
      </div>

      <Link
        to={RouteName.RadioDescriptionRoute.replace(":slug", station?.slug)}
        className="flex items-center justify-center"
        onMouseEnter={() => {
          setUnderline(true);
        }}
        onMouseLeave={() => {
          setUnderline(false);
        }}
      >
        {/* <div className="flex items-center justify-center"> */}
        <div className="">
          <p
            className={`text-[15px] font-[600] text-white duration-200 ease-in-out ${
              underline && `underline`
            }`}
          >
            {station.title.length > 15 ? (
              <>
                <span data-tooltip-id={station?.slug}>{`${station.title.slice(
                  0,
                  15
                )}...`}</span>
              </>
            ) : (
              station.title
            )}
          </p>
          <div className="z-50 text-sm">
            <ReactTooltip
              id={station?.slug}
              content={station.title}
              place="top"
            />
          </div>
          <p className="text-[12px] w-[171px] font-[400] text-[#7B9DB4] mt-[5px]">
            {/* {"101.2 FM"} */}
          </p>
        </div>
        {/* </div> */}
      </Link>
    </div>
  );
}

export default RadioStationCard;
