import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import SubText from "components/shared/SubText";
import { MusicCard } from "pages/musicPage/shared/MusicCard";
import MusicService from "services/music/MusicService";
import { Link } from "react-router-dom";
import MusicSkeleton from "../../components/skeleton/MusicSkeleton";

function MusicCarousel({ user }: any) {
  const [randomSongs, setRandomSongs] = useState([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    MusicService.getRandomSongs()
      .then((res: any) => {
        setIsLoading(false);
        setRandomSongs(res.randomSongs);
      })
      .catch((e) => {
        setIsLoading(false);
        // console.log(e);
      });
  }, []);

  return (
    <>
      {!isLoading && (
        <div>
          {" "}
          <div className="flex justify-between items-center w-[95%]">
            <SubText text="Discover Music" className="font-bold" />
            <Link to="/music">
              <p className="text-sm font-bold cursor-pointer red-text place-self-end">
                {" "}
                See all
              </p>
            </Link>
          </div>
          <Swiper
            slidesPerView={6}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              370: {
                slidesPerView: 2,
              },
              700: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
              1400: {
                slidesPerView: 6,
              },
              1600: {
                slidesPerView: 7,
              },
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="mt-10 mb-10 mySwiper"
          >
            {randomSongs?.map((song, i) => (
              <SwiperSlide key={i}>
                <div className="mx-3 max-sm:mx-1">
                  <MusicCard song={song} isLoading={isLoading} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {isLoading && <MusicSkeleton />}
    </>
  );
}

export default MusicCarousel;
