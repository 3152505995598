import { convertTimeWithDashes } from "./timeFunctions";

export const convertToEPG = (data: any) => {
  let epg = {
    channelUuid: data.attributes.channel,
    title: data.children.find((item: any) => item.name === "title").value,
    description: data.children.find((item: any) => item.name === "desc").value,
    id: Math.floor(Math.random() * 1000000),
    // image: data.children.find((item: any) => item.name === "icon").attributes
    //   .src,
    since: convertTimeWithDashes(data.attributes.start),
    till: convertTimeWithDashes(data.attributes.stop),
  };

  return epg;
};

export const convertToChannel = (data: any) => {
  let value = {
    logo: data.children.find((item: any) => item.name === "logo").value,
    id: data.attributes.id,
    stream_url: data.children.find((item: any) => item.name === "url").value,
    name: data.children.find((item: any) => item.name === "display-name").value,
    slug: data.attributes.id,
  };
  return value;
};

export const convertFromGetAllChannels = (d: any) => {
  let value = {
    logo: d.thumbnail,
    poster: d.poster,
    // uuid: String(d.id),
    id: d.id,
    uuid: d.slug,
    stream_url: d.stream_url,
    name: d.name,
    slug: d.slug,
    channelNumber: d.channel_number,
  };
  return value;
};

// Function to split a 24-hour program into 2-hour segments and add them back to the array
function splitAndAddProgram(program: any) {
  let startDateTime = new Date(program.since);
  const endDateTime = new Date(program.till);
  const twoHours = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
  const gap = 1.5 * 60 * 60 * 1000; // 2 hours in milliseconds

  const segments = [];

  while (+endDateTime - +startDateTime > gap) {
    const segmentEndDateTime = new Date(startDateTime.getTime() + twoHours);

    const segment = { ...program };
    segment.id = program.id + "+" + Math.random();
    segment.till = segmentEndDateTime.toISOString().replace("T", " ");
    segment.since = startDateTime.toISOString().replace("T", " ");

    startDateTime = new Date(segmentEndDateTime); // Start the next segment one millisecond after the previous one ends
    segments.push(segment);
  }
  return segments;
}

export const break24HourLongProgrma = (data: any[]) => {
  const newData = [];
  for (const program of data) {
    if (
      +new Date(program.till) - +new Date(program.since) >
      18 * 60 * 60 * 1000
    ) {
      // Explicitly cast to number
      // If program is 24 hours or longer, split it into segments and add them back
      const programSegments = splitAndAddProgram(program);
      newData.push(...programSegments);
    } else {
      // Otherwise, keep the program as is
      newData.push(program);
    }
  }

  // Update the data array with the new data
  return newData;
};

//  if program data is not available
// then create whole day program and add back to programs
export const addMissingPrograms = (channels: any[], programs: any[]) => {
  const cheenlsasd = channels.map((channel) => channel.slug);
  // console.log("all channel ids :", cheenlsasd);
  channels.forEach((channel) => {
    const channelUuid = channel.slug;
    const programChannelUuids = programs.map((program) => program.channelUuid);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + 1);

    const formattedDate = convertDateToFormat(currentDate);
    const endformattedDate = convertDateToFormat(endDate);

    if (programChannelUuids.length > 0) {
      if (!programChannelUuids.includes(channelUuid)) {
        let program = {
          id: 3315,
          channelNumber: channel.channelNumber,
          title: channel.name,
          channelUuid: channel.slug,
          description: null,
          image: channel.poster,
          since: formattedDate,
          till: endformattedDate,
        };
        // console.log("program : ", program);
        programs.push(program);
      }
    } else {
      let program = {
        id: 3315,
        channelNumber: channel.channelNumber,
        title: channel.name,
        channelUuid: channel.slug,
        description: null,
        image: channel.logo,
        since: currentDate.toISOString().slice(0, 19),
        till: endDate.toISOString().slice(0, 19),
      };

      programs.push(program);
    }
  });

  return programs;
};

const convertDateToFormat = (date: Date): string => {
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)} ${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`;
};

export const convertToLocalDate = (data: any[]) => {
  // Function to determine if a given date is in BST period for the UK
  const isInBST = (date: Date): boolean => {
    const year = date.getFullYear();
    const lastMarchSunday = new Date(
      year,
      2,
      31 - ((new Date(year, 2, 31).getDay() + 1) % 7)
    );
    const lastOctoberSunday = new Date(
      year,
      9,
      31 - ((new Date(year, 9, 31).getDay() + 1) % 7)
    );

    return date >= lastMarchSunday && date < lastOctoberSunday;
  };

  // Calculate user's current time zone offset in milliseconds
  const userOffset = new Date().getTimezoneOffset() * 60000;
  // Update the 'since' and 'till' times in the data array
  const updatedArray = data.map((item) => {
    // Parse UK time assuming it's GMT (UTC) for simplicity here; adjust if using BST
    let ukStartTime = new Date(item.since.replace(" ", "T") + "Z");
    let ukEndTime = new Date(item.till.replace(" ", "T") + "Z");

    // Check if the dates fall in BST period and adjust by adding an hour if true
    if (isInBST(ukStartTime)) {
      ukStartTime = new Date(ukStartTime.getTime()); // Add one hour for BST
    }
    if (isInBST(ukEndTime)) {
      ukEndTime = new Date(ukEndTime.getTime()); // Add one hour for BST
    }

    return {
      ...item,
      since: convertDateToFormat(ukStartTime),
      till: convertDateToFormat(ukEndTime),
    };
  });

  return updatedArray;
};

const convertDateToFormat23 = (date: any) => {
  return (
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0") +
    "T" +
    String(date.getHours()).padStart(2, "0") +
    ":" +
    String(date.getMinutes()).padStart(2, "0") +
    ":" +
    String(date.getSeconds()).padStart(2, "0")
  );
};