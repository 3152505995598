import ClearQueueButton from "components/AudioPlayer/Components/OtherButtons/ClearQueueButton";
import SongQueue from "components/AudioPlayer/Components/SongQueue";
import React from "react";

function MobileQueue({
  mobileQueueHeight,
  playlist,
  currentAudio,
  music,
  radio,
  podcast,
}: any) {
  return (
    <>
      <div className="flex items-center justify-between my-2 mt-5">
        <p className="">Playing queue</p>
        <ClearQueueButton spinSize={20} text={"Clear Queue"} />
      </div>

      <div
        className={`overflow-y-scroll scrollbar-hide py-3`}
        style={{
          height: `${mobileQueueHeight}`,
        }}
      >
        <SongQueue
          musics={playlist}
          currentSong={currentAudio}
          music={music}
          radio={radio}
          podcast={podcast}
        />
      </div>
    </>
  );
}

export default MobileQueue;
