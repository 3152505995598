// import { Input } from "antd";
import { triggerSuccessNotification } from "utils/notification";
import { Notification } from "constant/notifications";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FiLink2 } from "react-icons/fi";

function CopySharebleLink({ shareUrl }: any) {
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(shareUrl);
    triggerSuccessNotification(Notification.CopyToClipboard);
  };
  return (
    <div className="flex items-center justify-start gap-2">
      {/* <Input
        type={"text"}
        defaultValue={shareUrl}
        // disabled
        className="text-white bg-[#40404092] border-none"
      /> */}
      <div className="text-white bg-[#40404092] border-none py-1 pl-3 pr-1 overflow-x-hidden rounded-md flex justify-between items-center gap-2">
        <FiLink2 />
        <p className="w-[250px] max-sm:w-[200px] overflow-x-hidden text-sm">
          {shareUrl}
        </p>

        <div
          className="px-2 py-1 text-sm duration-100 ease-in-out bg-gray-700 rounded-md cursor-pointer hover:bg-slate-800"
          onClick={copyToClipboard}
        >
          Copy
        </div>
        {/* <HiOutlineDocumentDuplicate
          className="flex w-6 h-6 text-white cursor-pointer hover:text-red-500"
          onClick={copyToClipboard}
          data-tooltip-id="copy-media-link"
        /> */}
      </div>

      <ReactTooltip
        id="copy-media-link"
        place="bottom"
        content="Copy to clipboard!"
      />
    </div>
  );
}

export default CopySharebleLink;
