import { Form, Switch } from "antd";
import { useForm } from "antd/es/form/Form";
import LoadingSpin from "components/Loading spins/LoadingSpin";
import { Notification } from "constant/notifications";
import AntDInputField from "pages/editProfile/AntDInputField";
import { useEffect, useRef, useState } from "react";
import { RiImageAddLine } from "react-icons/ri";
import { toast } from "react-toastify";
import PlaylistService from "services/music/PlaylistService";
import CreatePlaylist from "types/CreatePlaylist";

const onFinishFailed = (errorInfo: any) => {
  toast.error(errorInfo.errorFields[0].errors[0]);
};

const options: any[] | undefined = [];
for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}

const CreatePlayList = ({
  closeModal,
  setUserPlaylists,
  currentPlaylist,
  setPlaylist,
}: any) => {
  // console.log("playlist\n", currentPlaylist);
  const [form] = useForm<CreatePlaylist>();
  const [loadingSpin, setLoadingSpin] = useState<boolean>(false);
  const [makePublic, setMakePublic] = useState<boolean>(
    currentPlaylist?.visibility === 1 ? true : false
  );
  const [playlistImgeDetails, setPlaylistImageDetails] = useState("");
  const [profileImge, setProfileImage] = useState("");
  const upload = useRef<HTMLInputElement>(null);

  // load data to when edit details
  useEffect(() => {
    const initialValue: CreatePlaylist = {
      playlistName: currentPlaylist?.title,
      description: currentPlaylist?.description
        ? currentPlaylist?.description
        : "",
      artwork: currentPlaylist?.artwork_url ? currentPlaylist?.artwork_url : "",
      visibility: currentPlaylist?.visibility,
    };
    form.setFieldsValue(initialValue);
  }, [currentPlaylist, form]);

  // upload image
  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    } else {
      if (fileObj.type.startsWith("image/")) {
        setPlaylistImageDetails(fileObj);
        setProfileImage(URL.createObjectURL(fileObj)); // Set the uploaded image URL
      } else {
        toast.error("Please select a valid image file.");
      }
    }
    // reset file input
    event.target.value = null;
  };

  // change visibility
  const onChange = (checked: boolean) => {
    setMakePublic(checked);
  };

  // create new playlist
  const createNewPlaylist = async (formDataObj: any) => {
    await PlaylistService.createPlaylist(formDataObj)
      .then((response: any) => {
        if (response.status === 200) {
          // console.log(response);
          toast.success(Notification.PlaylistCreated);
          PlaylistService.getUserPlaylists().then((res) => {
            setUserPlaylists(res.data.data);
            setLoadingSpin(false);
            closeModal();
          });
        } else {
          toast.error(Notification.PlaylistError);
        }
      })
      .catch((e: any) => {
        setLoadingSpin(false);
        toast.error("An error occurred. Please try again.");
      });
  };

  // update playlist
  const UpdatePlaylist = async (formDataObj: any) => {
    await PlaylistService.updatePlaylist(formDataObj)
      .then((response: any) => {
        if (response.status === 200) {
          toast.success(Notification.PlaylistUpdated);
          PlaylistService.getPlaylistByPlaylistId(currentPlaylist?.id).then(
            (res) => {
              setPlaylist(res.data);
              setLoadingSpin(false);
              closeModal();
            }
          );
          PlaylistService.getUserPlaylists().then((res) => {
            setUserPlaylists(res.data.data);
          });
        } else {
          toast.error(Notification.PlaylistError);
        }
      })
      .catch((e: any) => {
        // console.log(e);
        setLoadingSpin(false);
        toast.error(Notification.PlaylistError);
      });
  };

  const onFinish = async (value: CreatePlaylist) => {
    setLoadingSpin(true);

    if (currentPlaylist) {
      const finalObj: any = {
        ...value,
        id: currentPlaylist?.id,
        artwork: playlistImgeDetails
          ? playlistImgeDetails
          : currentPlaylist?.artwork_url,
        visibility: makePublic ? 1 : 0,
      };
      const formDataObj = new FormData();

      for (const key in finalObj) {
        formDataObj.append(key, finalObj[key]);
      }
      UpdatePlaylist(formDataObj);
    } else {
      const finalObj: any = {
        ...value,
        artwork: playlistImgeDetails,
        visibility: makePublic ? 1 : 0,
      };
      const formDataObj = new FormData();

      for (const key in finalObj) {
        formDataObj.append(key, finalObj[key]);
      }
      createNewPlaylist(formDataObj);
    }
  };

  return (
    <div className="">
      <div className="text-xl font-bold ml-9 mb-6 mt-[-10px]">
        <h1>{currentPlaylist ? `Update Playlist` : `Create Playlist`} </h1>
      </div>
      <hr className="my-4 border-t border-[#c4c4c43e]" />

      {/*  */}
      <Form
        form={form}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        autoComplete="on"
      >
        <div className="flex items-center justify-between max-sm:block max-sm:w-full max-sm:mx-auto">
          {/* Left Section */}
          {/* <div className="flex w-[30%]  justify-center max-sm:block max-sm:w-full max-sm:mx-auto">
            
            <div className="relative flex max-sm:w-full max-sm:justify-center">
              <div className="relative">
                {profileImge ? (
                  <img
                    src={profileImge}
                    alt="profile"
                    className="w-[180px] h-[180px] bg-[#1e1e1e] rounded-lg border-dashed border-[0.3px] border-white max-sm:w-full max-sm:mx-auto"
                  />
                ) : currentPlaylist ? (
                  <>
                    <img
                      src={currentPlaylist?.artwork_url}
                      alt="profile"
                      className="w-[180px] h-[180px] bg-[#1e1e1e] rounded-lg border-dashed border-[0.3px] border-white"
                    />
                  </>
                ) : (
                  <div className="w-[180px] h-[180px] bg-[#1e1e1e] rounded-lg border-dashed border-[0.3px] border-white">
                    <RiImageAddLine className="relative top-[45%] left-[45%] text-center text-lg" />
                  </div>
                )}
              </div>
              <input
                ref={upload}
                type="file"
                onChange={handleFileChange}
                name="attachment"
                className="w-[180px] h-[180px] cursor-pointer absolute opacity-0 z-30 "
              />
            </div>
          </div> */}

          {/* Right Section */}
          <div className="w-[100%] max-sm:w-full max-sm:mt-3">
            <div className="max-sm:block">
              <span className="text-[15px] text-white my-4">Name</span>
              <AntDInputField
                name="playlistName"
                value={currentPlaylist && currentPlaylist?.title}
                rules={[
                  {
                    required: true,
                    message: "Please enter playlist name!",
                  },
                ]}
              />
            </div>

            <span className="text-[15px] text-white my-4">Description</span>
            <AntDInputField
              name="description"
              value={currentPlaylist && currentPlaylist?.description}
            />
          </div>
        </div>

        {/*  */}
        <div className="flex justify-between">
          {/* check box */}
          <div className="flex items-center justify-start my-3 ">
            {/* <BiLockAlt className="text-white" /> */}
            <p className="mx-3 text-white text-md">Make Public</p>
            <Switch
              defaultChecked={makePublic}
              onChange={onChange}
              style={{ backgroundColor: makePublic ? "red" : "#313131" }}
            />
          </div>

          <div className="flex w-[60%] justify-end">
            <button
              className="min-w-[40%] py-3 px-4 my-4 text-base font-bold rounded-full white-text button-red hover:bg-[#f30505]"
              type="submit"
            >
              {loadingSpin ? (
                <LoadingSpin fontSize={30} color={"white"} />
              ) : currentPlaylist ? (
                `Update Playlist`
              ) : (
                `Create Playlist`
              )}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CreatePlayList;
