import LiveTvChannelChangeIcon from "components/shared/LiveTvChannelChangeIcon";
import { RouteName } from "constant/routeNames";
import { useEffect, useState } from "react";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCurrentChannelId,
  setLiveTVSuccess,
  setVideoStart,
} from "redux/VideoPlayerRedux";

const YouAreWatching = ({ currentLiveTv, dataList }: any) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { channelId } = useSelector((state: any) => state?.video);



  useEffect(()=>{
    const foundIndex = dataList.findIndex((c:any) => currentLiveTv?.id === c?.id);
    
    if(foundIndex >= 0) {
      dispatch(setCurrentChannelId(foundIndex));
    }
  },[currentLiveTv, dataList])

  const clickNavigation = async (text: string) => {
    if (text === "Down") {
      if (channelId !== 0) {
        let newId: any = channelId - 1;
        await channelChange(newId);
      }
    } else {
      if (channelId <= dataList?.length) {
        let newId: any = channelId + 1;
        await channelChange(newId);
      }
    }
  };

  const channelChange = async (newId: any) => {
    await dispatch(setCurrentChannelId(newId));
    await dispatch(setVideoStart());
    await dispatch(setLiveTVSuccess(dataList[newId]));
    console.log(dataList[newId]?.slug);
    navigate(
      RouteName.LiveTVPlayerLinkWithSlug.replace(":slug", dataList[newId]?.slug)
    );
  };


  return (
    <div className="flex flex-col justify-center">
      <p className="mb-1 text-sm flex text-center justify-center Color7B9DB4 max-md-text-[20px]">
        You're now watching
      </p>
      <div className="flex items-center justify-center max-sm:justify-between">
        <LiveTvChannelChangeIcon
          Icon={BiSolidDownArrow}
          text="Down"
          changeChannel={clickNavigation}
          disabled={channelId === 0}
        />
        <div className="flex flex-col items-center justify-center  cursor-default max-sm:w-[45%]  max-sm:mx-auto ">
          <h1
            className={`flex items-center w-auto px-4 py-1 text-center text-white rounded-full border border-red-500 max-sm:p-2  max-sm:text-[15px] max-sm:w-[full] max-sm:px-6 max-sm:my-2  `}
          >
            {currentLiveTv && currentLiveTv.name}
          </h1>
        </div>
        <LiveTvChannelChangeIcon
          Icon={BiSolidUpArrow}
          text="Up"
          changeChannel={clickNavigation}
          disabled={channelId === dataList.length - 1}
        />
      </div>
    </div>
  );
};

export default YouAreWatching;
