import SubText from "components/shared/SubText";
import { Cards } from "constant/PayPerViewData";
import React, { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PayPerViewCard from "./PayPerViewCard";
import PayperviewPickedout from "components/skeleton/PayperviewPickedout";
import ConditionalSeeAll from "./ConditionalSeeAll";
import { RouteName } from "constant/routeNames";
import GridView from "./GridView";

function PastEventCoursel({ events, isLoading, user }: any) {
  const [pastedEvents, setPastedEvents] = useState<any>([]);

  useEffect(() => {
    if (events) {
      const pastedEvents = events.filter(
        (event: any) => event?.event_status === "ended"
      );
      setPastedEvents(pastedEvents);
      // console.log("pastedEvents", pastedEvents);
    }
  }, [events]);
  return (
    <>
      {!isLoading && (
        <>
          {pastedEvents?.length > 0 && (
            <>
              {/* {pastedEvents?.length >= 4 ? ( */}
              {/* <> */}
              <div>
                <div className="my-8 flex justify-between items-center w-[95%]">
                  <SubText text={"Past Events"} className="my-4 font-bold " />
                  <ConditionalSeeAll
                    length={pastedEvents.length}
                    route={RouteName.pastSeeAllRoute}
                  />
                </div>

                <div>
                  <Swiper
                    slidesPerView={7}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      350: {
                        slidesPerView: 2,
                      },
                      550: {
                        slidesPerView: 3,
                      },
                      1000: {
                        slidesPerView: 4,
                      },
                      1100: {
                        slidesPerView: 5,
                      },
                      1200: {
                        slidesPerView: 6,
                      },
                      1400: {
                        slidesPerView: 7,
                      },
                    }}
                    modules={[Pagination, Navigation]}
                    navigation={true}
                    className="mt-4 mb-5 mySwiper"
                  >
                    {pastedEvents &&
                      pastedEvents?.map((event: any, i: number) => (
                        <SwiperSlide className="" key={i}>
                          <PayPerViewCard event={event} user={user} />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
              {/* </> */}
              {/* ) : ( */}
              {/* <> */}
              {/* <GridView
                    events={pastedEvents}
                    text={"Past Events"}
                    route={RouteName.pastSeeAllRoute}
                  /> */}
              {/* </> */}
              {/* )} */}
            </>
          )}
        </>
      )}
      {isLoading && <PayperviewPickedout />}
    </>
  );
}
export default PastEventCoursel;
