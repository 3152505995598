import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { updateFavorites } from "redux/userRedux";
import LikeService from "services/music/LikeService";
import {
  triggerErrorNotification,
  triggerSuccessNotification,
} from "utils/notification";
import { Notification } from "constant/notifications";

function AddPlaylistToFav({ playlist, Icon, playlistCard, hover }: any) {
  // console.log(playlist);
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  // console.log(favorite?.loved?.data);
  const [addFav, setAddFav] = useState<boolean>(false);

  useEffect(() => {
    const favoritePlaylistIds = favorite?.favorite_public_playlists?.map(
      (item: any) => item.id
    );
    if (playlist && favoritePlaylistIds) {
      if (favoritePlaylistIds?.includes(playlist?.id)) {
        setAddFav(true);
      } else {
        setAddFav(false);
      }
    }
  }, [favorite, playlist]);

  const addFavToRedux = () => {
    const updatedPlaylistData = [
      ...favorite?.favorite_public_playlists,
      playlist,
    ];
    const updatedFavorite = {
      ...favorite,
      favorite_public_playlists: updatedPlaylistData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const removeFavFromRedux = () => {
    // const updatedPodcastData = [...favorite?.favorite_podcasts, podcast];
    const updatedPlaylistData = favorite?.favorite_public_playlists?.filter(
      (favPlaylist: any) => favPlaylist.id !== playlist.id
    );
    const updatedFavorite = {
      ...favorite,
      favorite_public_playlists: updatedPlaylistData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const handleFavorite = (e: any) => {
    e.preventDefault();
    const favoriteSong = {
      id: playlist?.id?.toString(),
      object_type: "playlist",
    };

    if (!addFav) {
      setAddFav(true);
      LikeService.likeByUserId(favoriteSong).then((res) => {
        if (res.status) {
          setAddFav(true);
          // updateFav();
          addFavToRedux();
          triggerSuccessNotification(Notification.AddToFav);
        } else {
          setAddFav(false);
          triggerErrorNotification(Notification.FavError);
        }
      })
      .catch((e) => {
        setAddFav(true);
        triggerErrorNotification(Notification.FavError);
      });
    } else {
      setAddFav(false);
      LikeService.unLikeByUserId(favoriteSong).then((res) => {
        if (res.status) {
          triggerSuccessNotification(Notification.RemovedFromFav);
          // updateFav();
          removeFavFromRedux();
          setAddFav(false);
        } else {
          setAddFav(true);
          triggerErrorNotification(Notification.FavError);
        }
      })
      .catch((e) => {
        setAddFav(true);
        triggerErrorNotification(Notification.FavError);
      });
    }
  };
  return (
    <div>
      {loggedInUser ? (
        <div
          onClick={handleFavorite}
          className="cursor-pointer"
          data-tooltip-id={`add-playlist-to-favorite-${playlist?.id}`}
        >
          {addFav ? (
            <div className="text-red-600">
              {Icon ? (
                <AiFillHeart className="w-10 h-10 cursor-pointer" />
              ) : (
                <AiFillHeart />
              )}
            </div>
          ) : (
            <div
              className={
                playlistCard
                  ? `${
                      hover
                        ? `!text-red-600 duration-500 ease-in-out`
                        : `text-white duration-500 ease-in-out`
                    }`
                  : ""
              }
            >
              {Icon ? (
                <AiOutlineHeart className="w-10 h-10 text-white cursor-pointer" />
              ) : (
                <AiOutlineHeart
                // className={`${playlistCard && `hover:text-red-600`}`}
                />
              )}
            </div>
          )}

          <div className="text-sm">
            <ReactTooltip
              id={`add-playlist-to-favorite-${playlist?.id}`}
              place="top"
              content={addFav ? "Remove from favourites" : "Add to favourites"}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AddPlaylistToFav;
