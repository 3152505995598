import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PayperviewService from "services/payperview/PayperviewServie";
import { TabNames } from "constant/tabNames";
import PPVEpisodes from "./shared/PPVEpisodes";
import PPVRecordingsTab from "./shared/PPVRecordingsTab";
import PPVInfoTab from "./shared/PPVInfoTab";
import ContentSideBar from "./ContentSideBar";

function PPVideoPlayerLeftSidePanel({
  isActive,
  videoDetails,
  openModal,
}: any) {
  const { slug } = useParams();
  const [event, setEvent] = useState<any>([]);
  // const [selectedTab, setSelectedTab] = useState("Info");

  // const scrollableRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (scrollableRef.current) {
  //     scrollableRef.current.scrollTop = 0; // Reset scroll position
  //   }
  // }, [selectedTab]);

  useEffect(() => {
    if (slug) {
      PayperviewService.getEventByEventSlug(slug)
        .then((res) => {
          // console.log("event", res);
          setEvent(res.data);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }, []);

  // // console.log(isActive);

  const [selectedTab, setSelectedTab] = useState("");
  useEffect(() => {
    setSelectedTab(
      event?.replay_videos !== undefined ? TabNames.Recordings : TabNames.Info
    );
  }, [event]);
  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 0; // Reset scroll position
    }
  }, [selectedTab]);

  return (
    <>
      {/* {/ side bar shows on isactive variable /} */}
      {videoDetails?.replay_videos?.length > 1 ? (
        <>
          <div
            className={`video-player-side-panel sidebar ${
              isActive
                ? "left-[-8px] md:w-[600px]"
                : "md:left-[-100px] left-[-60px] md:w-[100px]"
            }`}
          >
            {/* {/ icon bar /} */}
            <div className="items-center bg-gradient-to-r from-black/95 text-gray-100 mt-14 !w-[600px] pt-32 pl-[80px] px-5 text-left !h-screen justify-center ">
              {isActive && (
                <div className="fixed flex items-center mb-5 ">
                  {event?.replay_videos?.length > 1 ? (
                    <PPVRecordingsTab
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                    />
                  ) : (
                    <PPVInfoTab
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                    />
                  )}
                </div>
              )}
              <div
                className="h-[100%] pb-10 my-14 overflow-y-scroll scrollbar-hide"
                ref={scrollableRef}
              >
                {selectedTab === TabNames.Recordings && event && (
                  <PPVEpisodes
                    videoDetails={event?.replay_videos && event?.replay_videos}
                    event={event}
                    // loading={loading}
                  />
                )}
                {selectedTab === TabNames.Info && videoDetails && (
                  <ContentSideBar
                    event={videoDetails && videoDetails}
                    // loading={loading}
                  />
                )}
              </div>
            </div>
          </div>
        </>
       ) : (
         <>
           <div
             className={`!h-[150vh] max-sm:!h-auto overflow-y-hidden  max-sm:relative -mt-[88px] duration-500 fixed max-sm:mt-0 max-md:mt-0 max-sm:w-full   max-sm:px-0 ${
               isActive
                 ? "left-[-8px] md:w-[600px]  "
                 : "md:left-[-100px] left-[-60px] md:w-[100px] max-sm:left-0 max-md:left-0 max-lg:left-0"
             }`}
             style={{
               backdropFilter: isActive ? "blur(10px)" : "none",
             }}
           >
            {/* {/ icon bar /} */}
             <div className="items-center bg-gradient-to-r from-black/100 text-gray-100 w-[600px] pt-32 pl-[80px] px-5 text-left !h-[150vh] max-sm:!h-auto justify-center max-sm:pt-0 max-sm:bg-none max-sm:mt-[-50px] max-md:bg-none max-md:pt-0 max-md:mt-[-50px] max-sm:w-full max-md:w-full max-sm:pl-4">
               <div
                 className="w-full pb-10 scrollbar-hide max-md:my-3 max-md:pb-0"
                 ref={scrollableRef}
               >
                 <ContentSideBar event={event} />
               </div>
             </div>
           </div>
         </>
       )}
    </>
  );
}

export default PPVideoPlayerLeftSidePanel;