import laptop from "Assets/Images/laptop.png";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const MusicCarousel1 = () => {
  return (
    <div className="my-5 h-80 ">
      <div className="absolute z-10 w-full mx-auto mt-[-35px] gradientX">
        <img src={laptop} alt="" className="mx-auto " width={"550px"} />
      </div>

      <div className="relative flex items-center">
        <Swiper
          slidesPerView={7}
          loop={true}
          loopFillGroupWithBlank={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            650: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 6,
            },
            1600: {
              slidesPerView: 6,
            },
          }}
          modules={[Pagination]}
          className="relative mt-10 mb-5 mySwiper"
          // autoplay={{ delay: 3000 }}
          // speed={1000}
        >
          {channels.map((channel) => (
            <div key={channel.id}>
              <SwiperSlide className="">
                <img
                  src={channel.imageSrc}
                  alt=""
                  style={{
                    height: "200px",
                    width: "200px",
                    objectFit: "cover",
                    borderRadius: "5px",
                  }}
                />
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default MusicCarousel1;

const channels = [
  {
    id: 1,
    channelName: "Channel Name",
    imageSrc:
      "https://images-platform.99static.com/MfS9H7_pGGYYRYT1sxCVq33X1sg=/150x150:1350x1350/500x500/top/smart/99designs-contests-attachments/128/128456/attachment_128456291",
  },
  {
    id: 2,
    channelName: "Channel Name",
    imageSrc:
      "https://images.nightcafe.studio/jobs/nrOcbGDvjDgIZPO7rbA6/nrOcbGDvjDgIZPO7rbA6.jpg?tr=w-1600,c-at_max",
  },
  {
    id: 3,
    channelName: "Channel Name",
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMDcyYiRpfL3psyBcT_oy2HWkrB4X4kW3g1w&usqp=CAU",
  },
  {
    id: 4,
    channelName: "Channel Name",
    imageSrc:
      "https://www.thisisdig.com/wp-content/uploads/2021/07/dodos-grizzly-peak.jpg",
  },
  {
    id: 5,
    channelName: "Channel Name",
    imageSrc:
      "https://marketplace.canva.com/EAE7VxGl5xg/2/0/1600w/canva-blue-clouds-anime-lofi-aesthetic-album-cover-kPdjTE_89nc.jpg",
  },
  {
    id: 6,
    channelName: "Channel Name",
    imageSrc:
      "https://marketplace.canva.com/EAE_1WTl7DA/1/0/1600w/canva-teal-and-pink-abstract-minimal-aesthetic-album-cover-CnfwIxC4-N8.jpg",
  },
  {
    id: 7,
    channelName: "Channel Name",
    imageSrc:
      "https://marketplace.canva.com/EAE7Vh7i1RM/2/0/1600w/canva-galaxy-minimal-aesthetic-album-cover-TbjSSKWUCiU.jpg",
  },
  {
    id: 8,
    channelName: "Channel Name",
    imageSrc:
      "https://static01.nyt.com/images/2019/07/15/sports/15cricketWEB1/merlin_157953327_fcf2c9a5-91b0-45bd-a558-82c3ea87e047-superJumbo.jpg",
  },
  {
    id: 9,
    channelName: "Channel Name",
    imageSrc:
      "https://static01.nyt.com/images/2019/07/15/sports/15cricketWEB1/merlin_157953327_fcf2c9a5-91b0-45bd-a558-82c3ea87e047-superJumbo.jpg",
  },
  {
    id: 10,
    channelName: "Channel Name",
    imageSrc:
      "https://static01.nyt.com/images/2019/07/15/sports/15cricketWEB1/merlin_157953327_fcf2c9a5-91b0-45bd-a558-82c3ea87e047-superJumbo.jpg",
  },
];
