import React, { useState } from "react";
import { FaCircle } from "react-icons/fa";
import { formatToLocalDateString } from "utils/timeFunctions";

function TransectionRow({ id, transection }: any) {
  console.log("time", transection?.purchased_formatted_date);

  // console.log(transection?.payment_status.toLowerCase());
  const [hover, setHover] = useState<boolean>(false);
  return (
    <>
      <tr
        className={`relative cursor-pointer hover:bg-slate-800 hover:shadow-xl rounded-md text-white hover:text-slate-300 ease-in-out duration-150 max-sm:w-full ${
          hover ? "hovered" : ""
        }`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <td className="w-[5%] pl-3 py-5 max-sm:pl-0">
          <p className="text-[#626A66] text-[15px]">{id}</p>
        </td>
        <td className="w-[35%]">
          <p className="text-white text-[15px] max-sm:text-[10px]">
            {transection?.content_name}
          </p>
        </td>
        <td className="items-center w-[20%] ml-2 max-sm:text-[10px]">
          <p className="text-white text-[15px] max-sm:text-[10px]">
            {transection?.order_number}
          </p>
        </td>
        <td className="items-center w-[13%] ml-2 max-sm:text-[10px]">
          {transection?.payment_status.toLowerCase() === "expired" ? (
            <>
              <div className="flex items-center gap-3">
                <FaCircle className="text-xs text-[var(--red)]" />
                <p className="text-white text-[15px] max-sm:text-[10px]">
                  {transection?.payment_status}
                </p>
              </div>
            </>
          ) : transection?.payment_status.toLowerCase() === "paid" ? (
            <>
              <div className="flex items-center gap-3">
                <FaCircle className="text-xs text-[var(--green)]" />
                <p className="text-white text-[15px] max-sm:text-[10px]">
                  {transection?.payment_status}
                </p>
              </div>
            </>
          ) : transection?.payment_status.toLowerCase() === "refunded" ? (
            <>
              <div className="flex items-center gap-3">
                <FaCircle className="text-xs text-[var(--card-booster-button)]" />
                <p className="text-white text-[15px] max-sm:text-[10px]">
                  {transection?.payment_status}
                </p>
              </div>
            </>
          ) : (
            ""
          )}
        </td>
        <td className="items-center w-[12%] ml-2 max-sm:text-[10px]">
          <p className="text-white text-[15px] max-sm:text-[10px]">
          £{transection?.amount}
          </p>
        </td>
        <td className="items-center w-[15%] max-sm:mx-auto max-sm:pl-1 max-sm:w-[10%]">
          <p className="text-[#7B9DB4] text-[12px] max-sm:text-[10px] ">
            {/* formatDuration(data?.purchased_formatted_date)} */}
            {formatToLocalDateString(transection?.purchased_formatted_date)}
          </p>
        </td>
      </tr>
    </>
  );
}

export default TransectionRow;
