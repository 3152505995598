import React from "react";

function CreatorQueueListSkeleton() {
  return (
    <div className="w-full mt-5">
      <div className={""}>
        <div className="flex items-center justify-evenly">
          <div className="h-[80px] w-[150px] bg-[#1a1919] animate-pulse mt-2 mr-6"></div>
          <div className="h-[30px] w-[240px] bg-[#1a1919] animate-pulse mt-2"></div>
        </div>
      </div>
    </div>
  );
}

export default CreatorQueueListSkeleton;
