import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const PayperviewFeatured = () => {
  return (
    <div>
      <div className="flex justify-between max-sm:block max-md:block max-lg:block">
        <div className="w-[200px] h-[30px] mb-5 bg-[#1a1919] animate-pulse mt-2"></div>
      </div>
      <div className="flex flex-row lg:gap-[50px] sm:gap-[20px] ">
        <div className="flex flex-col items-start justify-center">
          <div className="w-[52vw] h-[320px] bg-[#1a1919] animate-pulse mt-2"></div>
        </div>
        <div className="flex flex-col items-start justify-center">
          <div className="w-[36vw] h-[320px] bg-[#1a1919] animate-pulse mt-2"></div>
        </div>
      </div>
      <div className="flex flex-row lg:gap-[50px] sm:gap-[20px] mt-10">
        <div className="flex flex-col items-start justify-center">
          <div className="w-[36vw] h-[320px] bg-[#1a1919] animate-pulse mt-2"></div>
        </div>

        <div className="flex flex-col items-start justify-center">
          <div className="w-[52vw] h-[320px] bg-[#1a1919] animate-pulse mt-2"></div>
        </div>
      </div>
    </div>
  );
};

export default PayperviewFeatured;
