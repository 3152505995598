import React from "react";
import SearchBar from "./SearchBar";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function FollowHeroSection({ text }: any) {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <div>
          <button
            className="w-10 h-10 mb-5 bg-white rounded-full ml"
            onClick={() => {
              navigate(-1);
            }}
          >
            <AiOutlineArrowLeft className="ml-3" />
          </button>
        </div>
      </div>
      <div>
        <h1 className="text-[24px] mb-4 font-bold text-white">{text}</h1>
        {/* <SearchBar /> */}
      </div>
    </div>
  );
}

export default FollowHeroSection;
