import React from "react";
import OnePodcast from "./OnePodcast";

function PodcastPlayList({ podcastList }: any) {
  return (
    <div>
      <div className="w-[640px] h-[570px] bg-[#1E1E1E]">
        <p className="pl-4 pt-5 text-[14px] font-[600] text-white">
          Podcast playlist
        </p>
        {podcastList.map((podcast: any, i: number) => (
          <div key={i}>
            <OnePodcast onePodcast={podcast} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PodcastPlayList;
