import { useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { RiPlayListAddLine } from "react-icons/ri";
import { Tooltip as ReactTooltip } from "react-tooltip";

function AddToQueue({ toolTiId, playlist }: any) {
  const popRef = useRef<HTMLDivElement | null>(null);
  return (
    <div ref={popRef} data-tooltip-id={`${toolTiId}-${playlist?.id}`}>
      <p className="flex items-center justify-center  text-[12px] mx-6 py-3 px-3 text-base font-bold text-white cursor-pointer max-sm:text-[15px] hover:text-red-600 duration-200 ease-in-out max-sm:mx-3 rounded-full max-sm:w-full">
        <RiPlayListAddLine className="text-3xl text-white hover:text-red-600" />
      </p>
      <div className="text-sm">
        <ReactTooltip
          // id={`song-card-${song?.id}`}
          id={`${toolTiId}-${playlist?.id}`}
          place="top"
          content="Add to queue"
        />
      </div>
    </div>
  );
}

export default AddToQueue;
