import facebook from "img/facebook.jpg";
import passport from "img/passport.png";
import twitter from "img/twitter-logo.png";
import { useState } from "react";
import { HiOutlineX } from "react-icons/hi";

function CreatorModel({ clickLogin }: any) {
  const [next1Click, setnext1Click] = useState(true);

  const clickNext = (e: any) => {
    e.preventDefault();
    setnext1Click(!next1Click);
  };

  const clickBack = (e: any) => {
    e.preventDefault();
    setnext1Click(true);
  };
  return (
    // login model in main navbar
    <div className="fixed top-0 flex items-center w-full ease-in-out bg-slate-600 login_modal">
      <div className="navbar w-[550px] mx-auto my-10 p-6 rounded text-center h-fit overflow-auto">
        {/* close button in right side */}
        <div className="flex justify-end">
          <HiOutlineX
            className="text-lg font-bold cursor-pointer white-text"
            onClick={clickLogin}
          />
        </div>

        {/* main topic */}
        <div className="relative">
          <h1 className="mb-2 text-4xl font-bold white-text sm:text-4xl">
            Claim Artist Profile
          </h1>
          {next1Click ? (
            <p className="mb-4 text-sm tracking-wide gray-text">
              Please verify your information below before claiming an artist.
              Your email and full legal name will be used for contact purposes.
            </p>
          ) : (
            <p className="mb-4 text-sm tracking-wide gray-text"></p>
          )}
          {/* main form */}
          <form>
            {next1Click ? (
              <>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-left white-text"
                  >
                    Email address
                  </label>
                  <div className="mt-2 mb-2">
                    <input
                      type="email"
                      className="block w-full rounded-md border-0 py-1.5 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-left white-text"
                  >
                    Full Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <button
                  className="w-full py-4 mt-5 mb-4 font-bold rounded-full text-md white-text button-red"
                  onClick={clickNext}
                >
                  Next
                </button>
              </>
            ) : (
              <>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-left white-text"
                  >
                    Enter your artist or band name:
                  </label>
                  <div className="mt-2 mb-2">
                    <input
                      type="email"
                      className="block w-full rounded-md border-0 py-1.5 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-4 gap-4 max-sm:grid-cols-2 ">
                  <div>
                    <div className="">
                      <label
                        htmlFor="email"
                        className="block w-full text-sm font-medium leading-6 text-left white-text"
                      >
                        Phone Number:
                      </label>
                      <div className="mt-2 mb-2">
                        <input
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-left white-text"
                      >
                        Ext:
                      </label>
                      <div className="mt-2 mb-2">
                        <input
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-left white-text"
                      >
                        Affiliation to Artist:
                      </label>
                      <div className="mt-2 mb-2">
                        <select className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                          <option>Select an option</option>
                          <option>Artist/Band member</option>
                          <option>Manager</option>
                          <option>Label</option>
                          <option>Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-left white-text"
                  >
                    Explain your relationship to the artist and provide a band
                    link:
                  </label>
                  <div className="mt-2 mb-2">
                    <textarea
                      id="about"
                      name="about"
                      rows={2}
                      className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                </div>

                <p className="mt-3 mb-4 text-sm tracking-wide text-left gray-text">
                  Speed up the process! Verify your artist's accounts below.
                </p>
                <div className="flex social-connect">
                  {/* <div
                    className="relative w-10 h-10 mt-1 mr-2 bg-center bg-cover rounded-lg shadow-lg bg-img"
                    style={{
                      color: "#fff",
                      background: `url(${twitter}) center no-repeat`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="w-3/4 pl-2">
                    <div className="w-[100%] text-left">
                      <h3 className="white-text text-md">Twitter</h3>
                      <p className="text-xs gray-text">
                        Click to quickly verify your artist's account and help
                        us verify you.
                      </p>
                    </div>
                  </div>
                  <div className="pt-4 pl-2">
                    <button className="px-3 py-2 ml-4 text-xs rounded-full white-text button-gray">
                      Connect
                    </button>
                  </div>
                </div>
                <div className="flex social-connect">
                  <div
                    className="relative w-10 h-10 mt-1 mr-2 bg-center bg-cover rounded-lg shadow-lg bg-img"
                    style={{
                      color: "#fff",
                      background: `url(${facebook}) center no-repeat`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="w-3/4 pl-2">
                    <div className="w-[100%] text-left">
                      <h3 className="white-text text-md">Facebook</h3>
                      <p className="text-xs gray-text">
                        Click to quickly verify your artist's account and help
                        us verify you.
                      </p>
                    </div>
                  </div>
                  <div className="pt-4 pl-2">
                    <button className="px-3 py-2 ml-4 text-xs rounded-full white-text button-gray">
                      Connect
                    </button>
                  </div> */}
                </div>
                <div className="flex social-connect">
                  <div
                    className="relative w-10 h-10 mt-1 mr-2 bg-center bg-cover rounded-lg shadow-lg bg-img"
                    style={{
                      color: "#fff",
                      background: `url(${passport}) center no-repeat`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="w-3/4 pl-2">
                    <div className="w-[100%] text-left">
                      <h3 className="white-text text-md">Passport/ NIC</h3>
                      <p className="text-xs gray-text">
                        Scan and upload passport to get verify even faster.
                      </p>
                    </div>
                  </div>
                  <div className="content-end pt-4 pl-2">
                    <button className="px-4 py-2 ml-4 text-xs rounded-full white-text button-gray">
                      Browse
                    </button>
                  </div>
                </div>
                <div className="relative flex justify-between social-connect gap-x-3">
                  <div className="flex items-center h-6">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <p className="text-left text-gray-500">
                      I have read and agree to the Artist Profile Terms and have
                      the legal right to claim this artist content.
                    </p>
                  </div>
                </div>
                <div className="flex justify-between mt-5">
                  <button
                    className="flex items-center justify-center w-32 py-2 mb-4 mr-1 text-sm font-bold rounded-full white-text button-gray"
                    onClick={clickBack}
                  >
                    Back
                  </button>
                  <button className="flex items-center justify-center w-32 py-2 mb-4 ml-1 text-sm font-bold rounded-full white-text button-red">
                    Send Request
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreatorModel;
