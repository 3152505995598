import React from "react";
import PayPerViewCategoryCard from "./PayPerViewCategoryCard";
import PayperviewCategoryskeleton from "components/skeleton/PayperviewCategoryskeleton";

function CategoryGridView({ categories, isLoading }: any) {
  return (
    <>
      {!isLoading && (
        <div className="grid w-full grid-cols-3 gap-3 pt-5 mt-4 max-sm:grid-cols-2">
          {categories &&
            categories.map((category: any, i: number) => (
              <div key={i}>
                <PayPerViewCategoryCard category={category} />
              </div>
            ))}
        </div>
      )}
      {isLoading && <PayperviewCategoryskeleton />}
    </>
  );
}

export default CategoryGridView;
