import LazyLoadImg from "components/LazyLoadImg";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
function AlbumCard({
  artwork_url,
  title,
  song_count,
  id,
  artists,
  isLoading,
  path,
}: any) {
  const navigate = useNavigate();

  const handleAlbumClick = () => {
    navigate(`/music/album/${id}`, { state: { prevPage: path } });
  };

  return (
    // <Link to={`/music/album/${id}`}>
    <div
      className=" inline-block w-[178px] h-[280px] p-3  hover:bg-slate-800 hover:shadow-xl rounded-md cursor-pointer text-white hover:text-slate-300 ease-in-out duration-150 max-sm:mx-auto"
      onClick={handleAlbumClick}
    >
      {/* image */}
      <div className=" w-full h-[181px] items-center justify-center relative">
        {/* <img
            src={artwork_url}
            alt="Artist Album"
            className="w-[181px] h-full  object-cover overflow-hidden rounded-md"
          /> */}
        <LazyLoadImg
          imgSrc={artwork_url}
          imgAlt={"Artist Album"}
          imgHeight={"181px"}
          imgWidth={"100%"}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            borderRadius: "5px",
          }}
        />
      </div>
      <div className="relative top-1">
        <div className="my-[4px]">
          <p className="text-[15px] leading-tight font-[600]" data-tooltip-id={`album-${id}`}>
            {title.length > 25
              ? `${title
                  .slice(0, 25)
                  ?.replace(/&amp;/g, "&")
                  .replace(/&rsquo;/g, "’")
                  .replace(/&nbsp;/g, "")}...`
              : title
                  ?.replace(/&amp;/g, "&")
                  .replace(/&rsquo;/g, "’")
                  .replace(/&nbsp;/g, "")}
          </p>
          <div className="z-50 text-sm ">
            <ReactTooltip
              id={`music-album-card-title-${title}`}
              place="top"
              content={title}
              style={{ width: "180px" }}
            />
          </div>

          {title.length>25&&<div className="text-sm">
              <ReactTooltip
                id={`album-${id}`}
                place="top"
                content={title}
              />
            </div>}

          <p className="text-[12px] font-[400] text-[#648194] mt-[5px]">
            {artists?.[0]?.name
              ?.replace(/&amp;/g, "&")
              .replace(/&rsquo;/g, "’")
              .replace(/&nbsp;/g, "")}
          </p>
        </div>
      </div>
    </div>
    // </Link>
  );
}

export default AlbumCard;
