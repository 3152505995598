import PopularVideosCoursel from "pages/BecomeACreator/shared/PopularVideosCoursel";
import VideosCoursel from "pages/BecomeACreator/shared/RecentVideosGrid";
import React from "react";
import Details from "./Details";
import SubText from "components/shared/SubText";
import SuggestedSection from "./SuggestedSection";
import EpisodeCoursel from "./EpisodeCoursel";

function InfoPage({ video, isLoading, similarContent }: any) {
  return (
    <>
      <div className="mt-10 ml-3">
        {/* VIdeo Details */}
        {video?.directors?.length === 0 &&
        video?.actors?.length === 0 &&
        video?.producers?.length === 0 ? (
          <></>
        ) : (
          <>
            <Details video={video} isLoading={isLoading} />
          </>
        )}

        {/* for tv series */}
        {video?.seasons ? (
          <>
            {video?.allowed_to_watch ? (
              <>
                <p className="mt-8 flex cursor-pointer justify-center items-center max-w-[120px] h-[40px] p-3 text-center !text-[#f30543] rounded-md  max-sm:p-2 border-b-[1px] max-sm:ml-0  my-1 border-[#f30543]">
                  {video?.seasons?.length === 1 ? "SEASON" : "SEASONS"}
                </p>
                {video?.seasons?.length > 0 &&
                  video?.seasons?.map((season: any, i: number) => (
                    <div key={i}>
                      <EpisodeCoursel
                        text={season?.name}
                        season={season}
                        seriesId={video?.id}
                        slug={video?.slug}
                      />
                    </div>
                  ))}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        {/* suggestion section */}
        <div className="my-5 mt-10">
          {similarContent && similarContent.length > 0 && (
            <>
              <SubText
                text={`You may also like these`}
                className="font-light"
              />
              <SuggestedSection video={similarContent} />
            </>
          )}
        </div>

        {/* <PopularVideosCoursel /> */}
      </div>
    </>
  );
}

export default InfoPage;
