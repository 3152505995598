import { RouteName } from "constant/routeNames";
import FeaturePages from "layout/FeaturePages";
import VideoPageFavorite from "pages/videoPage/VideoPageFavorite";
import VideoPageHistory from "pages/videoPage/VideoPageHistory";
import VideoPageHome from "pages/videoPage/VideoPageHome";
import VideoPageWatchlist from "pages/videoPage/VideoPageWatchlist";
import VideoPlayerPage from "pages/videoPage/VideoPlayerPage";
import { Route, Routes } from "react-router-dom";
import SeeAllMovies from "./videoPage/SeeAllMovies";
import SeeAllSeries from "./videoPage/SeeAllSeries";
import SeeAllTopcategory from "./videoPage/SeeAllTopcategory";
import VideoPageDiscover from "./videoPage/VideoPageDiscover";
import VideoPageDiscoverByGenreId from "./videoPage/discover/VideoPageDiscoverByGenreId";
import VideoDetails from "./videoPage/VideoDetails";

function VideoPage({
  handleClick,
  isActive,
  setIsActive,
  anyPlayerActive,
}: any) {
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        <Route
          path=""
          element={
            <FeaturePages
              Content={VideoPageHome}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Video"}
            />
          }
        />
        <Route
          path={RouteName.VideoDiscover}
          element={
            <FeaturePages
              Content={VideoPageDiscover}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Video"}
            />
          }
        />
        <Route
          path={RouteName.VideoHistory}
          element={
            <FeaturePages
              Content={VideoPageHistory}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Video"}
            />
          }
        />
        <Route
          path={RouteName.VideoWatchlist}
          element={
            <FeaturePages
              Content={VideoPageWatchlist}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Video"}
            />
          }
        />
        <Route
          path={RouteName.VideoFavourite}
          element={
            <FeaturePages
              Content={VideoPageFavorite}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Video"}
            />
          }
        />
        <Route
          path={RouteName.VideoPlayerPage}
          element={
            <>
              <VideoPlayerPage />
            </>
          }
        />

        <Route
          path={RouteName.VideoDiscoverByGenreId}
          element={
            <FeaturePages
              Content={VideoPageDiscoverByGenreId}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Video"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllMovies}
          element={
            <FeaturePages
              Content={SeeAllMovies}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Video"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllSeries}
          element={
            <FeaturePages
              Content={SeeAllSeries}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Video"}
            />
          }
        />
        <Route
          path={RouteName.TopCategories}
          element={
            <FeaturePages
              Content={SeeAllTopcategory}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Video"}
            />
          }
        />
        <Route
          path={RouteName.VideoDetail}
          element={
            <FeaturePages
              Content={VideoDetails}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Video"}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default VideoPage;
