import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation, Pagination } from "swiper";
import { Autoplay } from "swiper";
import HeroCard from "pages/videoPage/home/shared/HeroCard";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useEffect, useState } from "react";
import VideoService from "services/video/VideoService";
import VideoHeroSkeleton from "components/skeleton/VideoHeroSkeleton";
function HeroCarousel() {
  const [sliderData, setSliderData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    VideoService.getSliderDetails().then((res) => {
      setIsLoading(false);
      const video: any[] = res.movies;
      const tv: any[] = res.tv_series;
      const all: any[] = video.concat(tv);
      setSliderData(all);
    });
  }, []);
  return (
    <>
      {!isLoading && (
        <Swiper
          navigation={false}
          modules={[Navigation, Autoplay, Pagination]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
        >
          {sliderData.map((d: any, i: number) => (
            <SwiperSlide className="mb-15" key={i}>
              <HeroCard data={d} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {isLoading && <VideoHeroSkeleton />}
    </>
  );
}

export default HeroCarousel;
