import Podcastcard from "pages/podcastPage/shared/Podcastcard";
import React, { useEffect, useState } from "react";
import NoSearch from "./NoSearch";

function SearchPodcastSection({ data, user }: any) {
  const podcasts = data?.podcasts || [];
  // const [podcasts, setPodcasts] = useState<any>([]);

  // useEffect(() => {
  //   if (data) {
  //     setPodcasts(data.podcasts);
  //   }
  // }, [data]);

  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
        <div>
          <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl max-sm:ml-3">
            {/* search podcast */}
            Podcasts
          </h1>
        </div>
      </div>
      {/* card section */}
      {podcasts.length === 0 && podcasts ? (
        <NoSearch text="Podcasts" />
      ) : (
        <div>
          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:mx-auto max-sm:w-full max-sm:gap-4 max-sm:ml-3">
            {podcasts.length > 0 &&
              podcasts.map((podcast: any, i: any) => (
                <div key={i}>
                  <Podcastcard podcast={podcast} user={user} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchPodcastSection;
