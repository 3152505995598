import React from 'react'

const VideoHeroSkeleton = () => {
    return (
        <div>
            <div className='flex lg:w-full h-[400px] w-[800px] bg-[#1a1919] animate-pulse'></div>
        </div>
    )
}

export default VideoHeroSkeleton
