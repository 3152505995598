import React from "react";
import CreatorRadio from "./shared/CreatorRadio";

const RadioPage = ({ creatorData }: any) => {
  return (
    <div>
      <CreatorRadio creatorData={creatorData} />
    </div>
  );
};

export default RadioPage;
