// OldUserRedirect.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import { useSelector } from "react-redux";

const OldUserRedirect = () => {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedInUser && loggedInUser.old_user === 1) {
      navigate(RouteName.UpdateOldUserPassword);
    }
  }, [loggedInUser, navigate]);

  return null; // This component doesn't render anything
};

export default OldUserRedirect;
