import { useNavigate } from "react-router-dom";
import LazyLoadImg from "components/LazyLoadImg";
import { HiArrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";

function RadioDetails({ data, radioCategory }: any) {
  const navigate = useNavigate();

  return (
    <>
      <div className="relative h-auto ">
        <div
          className="relative h-auto mb-30"
          style={{
            backgroundImage: `url(${data?.artwork_url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="p-3 pl-4 full-backdrop-bottom">
            <div>
              <button
                className="w-10 h-10 mt-5 flex justify-center items-center bg-white rounded-full max-sm:ml-[7px]"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <HiArrowLeft className="text-2xl text-black" />
              </button>
            </div>
            <div className="flex items-end max-sm:h-[250px] ">
              <div className="mt-5 max-sm:hidden">
                <LazyLoadImg
                  imgSrc={data?.artwork_url}
                  imgAlt={"Card pic"}
                  imgHeight={"280px"}
                  imgWidth={"280px"}
                  className="rounded-md album-page-image max-sm:h-[20%]"
                />
              </div>

              <div className="ml-10 max-sm:ml-0 ">
                <h1
                  className="my-5 text-6xl font-bold cursor-default font-secular fafafa max-sm:text-4xl"
                  dangerouslySetInnerHTML={{ __html: data?.title }}
                ></h1>

                {/* <p>{data?.description}</p> */}

                {/* display artsits and hi image */}

                <div className="flex items-center gap-3 mt-5 text-sm text-white max-sm:mt-8">
                  <div className="flex items-center justify-start">
                    <Link
                      to={RouteName.Radio.replace(
                        "*",
                        RouteName.RadioChannelsByCategory.replace(
                          ":slug",
                          radioCategory?.alt_name
                        )
                      )}
                    >
                      {radioCategory && (
                        <p className="px-2 py-1 bg-[#57575781] rounded-2xl">
                          {radioCategory?.name.replace("amp;", "")}
                        </p>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RadioDetails;
