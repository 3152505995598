import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineHeart,
  AiOutlineStar,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { updateFavorites } from "redux/userRedux";
import RadioService from "services/radio/RadioService";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  triggerErrorNotification,
  triggerSuccessNotification,
} from "utils/notification";
import { Notification } from "constant/notifications";

function AddRadioToFavPlayer({ station, Icon, RadioCard, hover }: any) {
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  //   console.log(favorite);
  const [addFav, setAddFav] = useState<boolean>(false);
  // const [hover, setHover] = useState(false);

  useEffect(() => {
    const favoriteRadioIds = favorite?.favorite_radio_stations.map(
      (item: any) => item.id
    );
    if (station && favoriteRadioIds) {
      if (favoriteRadioIds?.includes(station?.id)) {
        setAddFav(true);
      } else {
        setAddFav(false);
      }
    }
  }, [favorite, station]);

  const addFavToRedux = () => {
    const updatedStationData = [...favorite?.favorite_radio_stations, station];
    const updatedFavorite = {
      ...favorite,
      favorite_radio_stations: updatedStationData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const removeFavFromRedux = () => {
    // const updatedPodcastData = [...favorite?.favorite_podcasts, podcast];
    const updatedStationData = favorite?.favorite_radio_stations.filter(
      (favRadio: any) => favRadio.id !== station.id
    );
    const updatedFavorite = {
      ...favorite,
      favorite_radio_stations: updatedStationData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const handleFavorite = async () => {
    const favoriteRadioStation = {
      id: station.id.toString(),
      object_type: "radio",
    };

    if (!addFav) {
      setAddFav(true);
      RadioService.likeRadio(favoriteRadioStation).then((res) => {
        if (res.status) {
          setAddFav(true);
          // updateFav();
          addFavToRedux();
          triggerSuccessNotification(Notification.AddToFav);
        } else {
          setAddFav(false);
          triggerErrorNotification(Notification.FavError);
        }
      });
    } else {
      setAddFav(false);
      RadioService.unlikeRadio(favoriteRadioStation).then((res) => {
        if (res.status) {
          triggerSuccessNotification(Notification.RemovedFromFav);
          // updateFav();
          removeFavFromRedux();
          setAddFav(false);
        } else {
          setAddFav(true);
          triggerErrorNotification(Notification.FavError);
        }
      });
    }
  };

  return (
    <div>
      {loggedInUser ? (
        <div
          onClick={handleFavorite}
          className={`cursor-pointer`}
          data-tooltip-id={`add-podcast-to-favorite-${station.id}`}
        >
          {addFav ? (
            <div className="text-red-600">
              {Icon ? (
                <AiFillHeart className="w-6 h-6 cursor-pointer" />
              ) : (
                <AiFillStar />
              )}
            </div>
          ) : (
            <div
              className={
                RadioCard
                  ? `${
                      hover
                        ? `text-red-600 duration-500 ease-in-out`
                        : `text-white duration-500 ease-in-out`
                    }`
                  : "text-white"
              }
            >
              {Icon ? (
                <AiOutlineHeart className="w-6 h-6 cursor-pointer hover:text-red-600" />
              ) : (
                <AiOutlineStar className="hover:text-red-600" />
              )}
            </div>
          )}

          <div className="text-sm">
            <ReactTooltip
              id={`add-podcast-to-favorite-${station.id}`}
              place="top"
              content={addFav ? "Remove from favourites" : "Add to favourites"}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AddRadioToFavPlayer;
