export const podcast = [
    {
        id: 1,
        title: "The Daily Show With Trevor Noah: Ears Edition",
        description: "Comedy Central and iHear...",
        artwork_url: "https://m.media-amazon.com/images/M/MV5BN2E4M2M5OWQtZjgyMy00ZGQ5LTg2YTAtMGE3Y2FiZjA3Y2Y3XkEyXkFqcGdeQXVyMTM2Mzg4MA@@._V1_FMjpg_UX1000_.jpg",
      },
      {
        id: 2,
        title: "Conan O’Brien Needs A Friend",
        description: "Team Coco & Earwolf",
        artwork_url: "https://upload.wikimedia.org/wikipedia/en/thumb/e/ee/Conan_O%27Brien_Needs_a_Friend_podcast.jpg/220px-Conan_O%27Brien_Needs_a_Friend_podcast.jpg",
      },
      {
        id: 3,
        title: "Wait Wait... Don't Tell Me!",
        description: "NPR",
        artwork_url: "https://media.npr.org/assets/img/2019/11/20/npr_91201389-1-_wide-fd512eb66d8e78f4ad727b3d87d17ca3b2a801f6-s1400-c100.jpg",
      },
      {
        id: 4,
        title: "Office Ladies",
        description: "Earwolf & Jenna Fischer an...",
        artwork_url: "https://i.scdn.co/image/ab6765630000ba8a0c044067a9c5961661627571",
      },
      {
        id: 5,
        title: "2 Bears, 1 Cave with Tom Segura & Bert Kreischer",
        description: "YMH Studios",
        artwork_url: "https://i.scdn.co/image/ab6765630000ba8a284324ba6c5a0b4256c9f6ec",
      },
      {
        id: 6,
        title: "Distractible",
        description: "Wood Elf Media",
        artwork_url: "https://i.scdn.co/image/ab6765630000ba8a70cc9cbaa74ada843d4b80cc",
      },
      {
        id: 7,
        title: "The Daily Show With Trevor Noah: Ears Edition",
        description: "Comedy Central and iHear...",
        artwork_url: "https://m.media-amazon.com/images/M/MV5BN2E4M2M5OWQtZjgyMy00ZGQ5LTg2YTAtMGE3Y2FiZjA3Y2Y3XkEyXkFqcGdeQXVyMTM2Mzg4MA@@._V1_FMjpg_UX1000_.jpg",
      },
      {
        id: 8,
        title: "Conan O’Brien Needs A Friend",
        description: "Team Coco & Earwolf",
        artwork_url: "https://upload.wikimedia.org/wikipedia/en/thumb/e/ee/Conan_O%27Brien_Needs_a_Friend_podcast.jpg/220px-Conan_O%27Brien_Needs_a_Friend_podcast.jpg",
      },
      {
        id: 9,
        title: "Wait Wait... Don't Tell Me!",
        description: "NPR",
        artwork_url: "https://media.npr.org/assets/img/2019/11/20/npr_91201389-1-_wide-fd512eb66d8e78f4ad727b3d87d17ca3b2a801f6-s1400-c100.jpg",
      },
      {
        id: 10,
        title: "Office Ladies",
        description: "Earwolf & Jenna Fischer an...",
        artwork_url: "https://i.scdn.co/image/ab6765630000ba8a0c044067a9c5961661627571",
      },
    ]