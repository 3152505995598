import React from "react";
import NoSearch from "./shared/NoSearch";
import AlbumCard from "pages/musicPage/shared/AlbumCard";
import SearchTrackList from "./SearchTrackList";
import { ArtistCard } from "pages/musicPage/shared/ArtistCard";
import PlaylistCard from "pages/musicPage/playlist/PlaylistCard";
import SearchArtist from "./SearchArtist";

function SearchMusic({ data, user }: any) {
  const albums = data?.albums || [];
  const songs = data?.songs || [];
  const artists = data?.artists || [];
  const playLists = data?.public_playlists || [];
  // const [albums, setAlbums] = useState([]);
  // const [songs, setSongs] = useState([]);

  // useEffect(() => {
  //   const searchAll = async () => {
  //     await SearchServie.searchAll(text).then((res) => {
  //       // console.log("first", res);
  //       setAlbums(res.albums);
  //       setSongs(res.songs);
  //     });
  //   };

  //   searchAll();
  // }, [text]);
  return (
    <div>
      <div>
        {/* top section */}
        <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
          <div>
            <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl">
              {/* search albums */}
              Albums
            </h1>
          </div>
        </div>
        {/* card section */}
        {albums.length === 0 && albums ? (
          <NoSearch text="Albums" />
        ) : (
          <div>
            <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 gap-y-1 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
              {albums?.length > 0 &&
                albums?.map((album: any, i: any) => (
                  <div key={i}>
                    <AlbumCard {...album} />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
      {/* Songs list */}
      <div>
        {/* top section */}
        <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
          <div>
            <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl">
              {/* search Songs */}
              Songs
            </h1>
          </div>
        </div>
        {/* card section */}
        <SearchTrackList songs={songs} user={user} />
      </div>

      {/* Artist list */}
      <div>
        {/* top section */}
        <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
          <div>
            <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl">
              {/* search Songs */}
              Artists
            </h1>
          </div>
        </div>
        {/* card section */}
        <SearchArtist artists={artists} user={user} />
      </div>
      {/* playlist */}
      <div>
        <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
          <div>
            <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl">
              {/* search playlist */}
              Playlists
            </h1>
          </div>
        </div>
        {/* card section */}
        {playLists.length === 0 && playLists ? (
          <NoSearch text="Playlists" />
        ) : (
          <div>
            <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
              {playLists.length > 0 &&
                playLists.map((playlist: any, i: any) => (
                  <div key={i}>
                    <PlaylistCard playlist={playlist} searchPage={true} />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchMusic;
