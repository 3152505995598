import LoadingSpin from 'components/Loading spins/LoadingSpin';

import { useState } from 'react';
import { Form, Input } from 'antd';
import UserProfileService from 'services/UserProfileService';
import { toast } from 'react-toastify';
import AntDInputField from 'pages/editProfile/AntDInputField';
import { FaArrowCircleRight } from 'react-icons/fa';

function ChangePasswordOldUser({ handleLogout, openModal }: any) {
  const [loadingSpin, setLoadingSpin] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({
    current_password: '',
    new_password: '',
  });
  const [arrowHover, setArrowHover] = useState<boolean>(false);

  // const handleLogout = () => {
  //   AuthService.logout()
  //     .then((response: any) => {
  //       if (response.status === 200) {
  //         // console.log("200");
  //         dispatch(logout());
  //         // console.log("logout()");
  //         navigate("/login");
  //         // console.log("navigate");
  //         dispatch(clearDataWhenLogout());
  //         // console.log("clearDataWhenLogout");
  //       }
  //     })
  //     .catch((e: Error) => {
  //       console.log("log out user error ==> ", e);
  //     });
  // };

  const onSubmit = async () => {
    setLoadingSpin(true);
    const formData = form.getFieldsValue();
    const user: any = localStorage.getItem('persist:root');
    const data = JSON.parse(JSON.parse(user).user);
    setErrors({ current_password: '', new_password: '' });
    await UserProfileService.changePassword(
      data.currentUser.access_token,
      formData
    )
      .then((res) => {
        setLoadingSpin(false);
        if (res.status === 200) {
          toast.success('Password successfully changed!');
          // console.log(res?.data?.forceLogout);
          form.setFieldsValue({
            current_password: '',
            new_password: '',
            new_password_confirmation: '',
          });
          // setIsUpdatedPassword(true);
          if (res?.data?.forceLogout === 1) {
            handleLogout();
          }
        } else {
          toast.error('There is an issue when changing password!');
        }
        //console.log(res);
      })
      .catch((e: any) => {
        // console.log(e.message);
        setLoadingSpin(false);
        if (e.response && e.response.status === 422) {
          let errorData = '';
          if (e?.response?.data?.errors?.current_password) {
            errorData = e?.response?.data?.errors?.current_password[0];
            setErrors({ ...errors, current_password: errorData });
          } else {
            errorData = e?.response?.data?.errors?.new_password[0];
            setErrors({ ...errors, new_password: errorData });
          }
          toast.warning(errorData);
          // console.log("first", errors);
        } else {
          // Handle other types of errors (e.g., server errors)
          toast.error('An error occurred. Please try again.');
        }
      });
  };

  return (
    <div className="w-full max-sm:mx-[10px]">
      <div className="w-[500px] max-sm:mx-[10px] max-sm:w-full">
        <div>
          <p className="text-white text-[25px] font-bold  my-3 max-sm:w-full ">
            Change Password
          </p>
          <span className="text-[14px] text-gray-500 ">
            We have upgraded our security measures, and to align with these
            improvements, please update your password.
          </span>
        </div>
      </div>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
        style={{ maxWidth: 600 }}
      >
        <div className="w-full max-sm:w-full">
          <div className="flex-row w-full my-3">
            <span className="text-[15px] text-white">Current Password</span>

            <AntDInputField
              name="current_password"
              type="password"
              placeholder="Current Password"
              rules={[
                {
                  required: true,
                  message: 'Please enter your current password!',
                },
              ]}
              password={true}
              className="w-full mb-4 text-gray-600 rounded outline-none"
            />
            {errors?.current_password ? (
              <p className="red-text">{errors?.current_password}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={` w-full mt-5 max-sm:block  max-sm:w-full`}>
          <div className="flex-row w-full">
            <span className="text-[15px] text-white my-3">New Password</span>

            <AntDInputField
              name="new_password"
              type="password"
              placeholder="New Password"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
              ]}
              password={true}
            />
          </div>

          <div className="flex-row w-full">
            <span className="text-[15px] text-white my-3">
              Confirm New Password
            </span>
            <AntDInputField
              name="new_password_confirmation"
              type="password"
              placeholder="New Password"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }: any) => ({
                  validator(_: any, value: any) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Incorrect password. Please try again.')
                    );
                  },
                }),
              ]}
              password={true}
            />
          </div>
        </div>

        <div className="flex items-center justify-start gap-10 mt-8 max-sm:block">
          <button
            className="px-4 py-2 text-white min-w-[130px] rounded-full button-red max-sm:w-full"
            type="submit"
            onClick={onSubmit}
          >
            {loadingSpin ? (
              <LoadingSpin fontSize={20} color={'white'} />
            ) : (
              `Change Password`
            )}
          </button>

          <p
            className="flex items-center justify-start gap-2 text-base text-white cursor-pointer max-sm:mt-3"
            onMouseEnter={() => {
              setArrowHover(true);
            }}
            onMouseLeave={() => {
              setArrowHover(false);
            }}
            onClick={openModal}
          >
            Skip{' '}
            <span className={`${arrowHover ? 'ml-1' : 'ml-0'} duration-200`}>
              <FaArrowCircleRight />
            </span>
          </p>
        </div>
      </Form>
    </div>
  );
}

export default ChangePasswordOldUser;
