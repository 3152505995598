import React, { useState } from "react";
import ProfileDetails from "../ProfileDetails";
import ChangeUserName from "../ChangeUserName";
import EditButton from "./EditButton";
import ChangePassword from "../ChangePassword";

function EditProfileButtonSection() {
  const [isSelected, setIsSelected] = useState<any>(1);

  const [components, setComponents] = useState<any>(pageName[0]);
  return (
    <div className="">
      {/* <HeroBoosterSection follow="257 Followers" /> */}
      <div className="flex my-3 ml-6 mt-9 max-sm:grid max-sm:grid-cols-2 max-sm:mx-2 max-sm:mt-[80px] ">
        {pageName.map((pages: any, i: number) => (
          <div key={i} className="mr-4">
            <EditButton
              pages={pages}
              setIsSelected={setIsSelected}
              isSelected={isSelected}
              selected={pages.id === isSelected ? true : false}
              setComponents={setComponents}
            />
          </div>
        ))}
      </div>

      <div id="section1" className=" text-white mb-[150px]max-sm:ml-0">
        {<components.component />}
      </div>
    </div>
  );
}
const pageName = [
  {
    id: 1,
    component: ProfileDetails,
    name: "Edit Profile",
  },
  {
    id: 2,
    component: ChangeUserName,
    name: "Change Username",
  },
  {
    id: 3,
    component: ChangePassword,
    name: "Change Password",
  },
];

export default EditProfileButtonSection;
