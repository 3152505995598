import RadioCard from "pages/radioPage/shared/RadioCard";
import NoFavorite from "./shared/NoFavorite";
import OverviewSkeleton from "components/skeleton/OverviewSkeleton";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

function UserRadioPage({
  user,
  favoriteData,
  favoriteRadioIds,
  isLoading,
}: any) {
  const [radioStations, setRadioStations] = useState<any>([]);
  const radios = favoriteData?.favorite_radio_stations || [];

  useEffect(() => {
    setRadioStations(radios);
  }, []);

  return (
    <>
      {!isLoading && (
        <div>
          {/* top section */}
          <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h2 className="text-lg font-medium text-white lg:text-xl md:text-2xl">
                {/* Videos by booster */}
                Favourite Radios
              </h2>
            </div>
          </div>
          {/* card section */}
          {radios?.length === 0 && radios ? (
            <div>
              <NoFavorite text="Stations" user={user} />
            </div>
          ) : (
            <div>
              <div className="grid w-[95%] grid-cols-2 pl-6 mt-4 mb-5 max-sm:pl-0 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-0 max-sm:w-full max-sm:gap-3  ">
                {radioStations?.length > 0 &&
                  radioStations?.map((radios: any, i: number) => (
                    <div key={i}>
                      <RadioCard
                        station={radios}
                        user={user}
                        favorite={favoriteRadioIds}
                      />
                    </div>
                  ))}
              </div>
            </div>
            // <>
            //   {/* coursel */}
            //   <div className="">
            //     <Swiper
            //       slidesPerView={6}
            //       breakpoints={{
            //         0: {
            //           slidesPerView: 1,
            //         },
            //         370: {
            //           slidesPerView: 2,
            //         },
            //         700: {
            //           slidesPerView: 3,
            //         },
            //         1000: {
            //           slidesPerView: 4,
            //         },
            //         1200: {
            //           slidesPerView: 5,
            //         },
            //         1400: {
            //           slidesPerView: 6,
            //         },
            //         1600: {
            //           slidesPerView: 7,
            //         },
            //       }}
            //       modules={[Pagination]}
            //       className="mt-4 mb-10 mySwiper"
            //     >
            //       {radios.length > 0 &&
            //         radios.map((radios: any, i: number) => (
            //           <SwiperSlide className="" key={i}>
            //             <RadioCard
            //               station={radios}
            //               user={user}
            //               favorite={favoriteRadioIds}
            //             />
            //           </SwiperSlide>
            //         ))}
            //     </Swiper>
            //   </div>
            // </>
          )}
        </div>
      )}
      {isLoading && <OverviewSkeleton />}
    </>
  );
}

export default UserRadioPage;
