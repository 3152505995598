import { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import RadioCard from "../shared/RadioCard";
import RadioService from "services/radio/RadioService";
import SubText from "components/shared/SubText";
import LikeService from "services/music/LikeService";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import RadioSkeleton from "components/skeleton/RadioSkeleton";
import { useSelector } from "react-redux";

function MightLikeCoursel({ text, user, randomStations, isLoading }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  //const [stations, setStations] = useState([]);
  //const [isLoading, setIsLoading] = useState<any>(false);

  // useEffect(() => {
  //   setIsLoading(true);
  //   RadioService.getRandomRadioStations().then((res) => {
  //     // console.log(res.slides);
  //     setIsLoading(false);
  //     setStations(res.randomRadioStations);
  //   });
  // }, []);

  //Like function
  const [favorite, setFavorite] = useState<any>();

  useEffect(() => {
    if (user && loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, user?.id)
        .then((res) => {
          const favoritesIds = res.profile.favorite_radio_stations.map(
            (item: any) => item.id
          );
          setFavorite(favoritesIds);
        })
        .catch(() => {});
    }
  }, [user]);

  return (
    <div className="mt-10 mb-20">
      {/* top section */}
      {!isLoading && (
        <div className="flex justify-between w-full mt-5 mb-8 ">
          <div>
            <SubText text={text} className="font-bold" />
          </div>
          <Link to={RouteName.SeeAllMightLikeRoute + "?page=1"}>
            <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-4">
              See all
            </p>
          </Link>
        </div>
      )}
      {/* coursel */}
      {!isLoading && (
        <div className="">
          <Swiper
            slidesPerView={6}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              370: {
                slidesPerView: 2,
              },
              700: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
              1400: {
                slidesPerView: 6,
              },
              1600: {
                slidesPerView: 7,
              },
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="mt-4 mb-10 mySwiper"
          >
            {randomStations?.map((randomStations: any, i: number) => (
              // <div key={i}>
              <SwiperSlide className="" key={i}>
                <RadioCard
                  station={randomStations}
                  user={user}
                  favorite={favorite}
                />
              </SwiperSlide>
              // </div>
            ))}
          </Swiper>
        </div>
      )}
      {isLoading && <RadioSkeleton />}
    </div>
  );
}

export default MightLikeCoursel;
