import { useEffect, useRef, useState } from "react";
import { BsClock } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import { RiPlayListAddLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  addPodcastToPodcastPlayer,
  addPodcastToQueue,
} from "redux/AudioPlayerRedux";
import { triggerSuccessNotification } from "utils/notification";
import { formatDurationHrAndMins } from "utils/timeFunctions";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Notification } from "constant/notifications";
import { triggerWarningNotification } from "utils/notification";

function PodcastOneEpisode({ episode }: any) {
  const { currentPodcast } = useSelector((state: any) => state?.audio);
  const dispatch = useDispatch<any>();

  // const [addFav, setAddFav] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(false);

  const popRef = useRef<HTMLDivElement | null>(null);

  // const handleAddToFav = () => {
  //   setAddFav(!addFav);
  // };

  const dropDown = () => {
    // console.log("dropdown -> ", isActive);
    setIsActive(!isActive);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popRef.current && !popRef.current?.contains(event.target as Node)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handlePodcast = () => {
    dispatch(addPodcastToPodcastPlayer(episode));
  };
  const handleAddToQueue = () => {
    if (episode) {
      dispatch(addPodcastToQueue(episode));
      triggerSuccessNotification(Notification.PodcastAddedToQueue);
    } else {
      triggerWarningNotification(Notification.NoPodcasts);
    }
  };

  // readmore
  const [readMore, setReadMore] = useState<boolean>(false);
  const [slicedDes, setSlicedDes] = useState<boolean>(false);

  useEffect(() => {
    if (episode?.description) {
      if (readMore) {
        setSlicedDes(episode?.description.replace(/&amp;/g, '&'));
      } else {
        if (episode?.description?.length > 150) {
          const text = episode?.description.slice(0, 150).replace(/&amp;/g, '&');
          setSlicedDes(text.concat("..."));
        } else {
          setSlicedDes(episode?.description.replace(/&amp;/g, '&'));
        }
      }
    }
  }, [readMore]);

  const handleDescription = () => {
    setReadMore(!readMore);
  };

  return (
    <div className="w-full p-3 my-1 ml-4 cursor-pointer mt-9 hover:bg-gradient-to-t from-black to-black/50 max-sm:ml-0">
      <div className="flex items-center justify-between w-[95%]">
        {/* left side */}
        <div>
          <div onClick={handlePodcast}>
            <div className="flex items-center justify-start gap-1">
              {episode?.duration === 0 || 1 ? (
                <></>
              ) : (
                <>
                  <div className="w-[20px] h-[19px] text-[#7B9DB4]">
                    <BsClock />
                  </div>
                  <p className="text-[12px] text-[#7B9DB4] font-[700]">
                    {formatDurationHrAndMins(episode?.duration)}
                  </p>
                </>
              )}

              <div className="flex ">
                <div className="text-white font-[700] text-[20px] w-[90%] max-sm:text-[18px]">
                  {episode?.title}
                </div>
                {currentPodcast && currentPodcast?.id === episode?.id && (
                  <>
                    {/* <div className="ml-4 flex items-center justify-center w-[28px] h-[28px] bg-red-600 rounded-full shadow-lg cursor-pointer max-sm:w-[40px] max-sm:h-[35px]">
                      <FaPlay className="w-[10px] h-[15px] text-white" />
                    </div> */}
                    <div className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-red-600 mx-3">
                      <FaPlay className="w-[10px] h-[15px] text-white" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="text-white font-[700] text-[20px]">
            <div className="text-white w-[635px] font-[300] text-[12px] max-sm:w-full max-md:w-full my-2">
              {slicedDes}{" "}
              {episode?.description && (
                <span className="text-red-600" onClick={handleDescription}>
                  {readMore ? `Read less` : `Read more`}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* buttons */}
        <div className="flex items-start top-0 text-[#7B9DB4] justify-end gap-5 pl-10 text-xl">
          {/* <div onClick={handleAddToFav}>
            {addFav ? (
              <div className="text-red-600 ">
                <AiFillHeart />
              </div>
            ) : (
              <AiOutlineHeart />
            )}
          </div> */}

          <div>
            <div onClick={handleAddToQueue} data-tooltip-id="my-tooltip-1">
              <RiPlayListAddLine />
            </div>
            <div className="text-sm">
              <ReactTooltip
                id="my-tooltip-1"
                place="bottom"
                content="Add to queue"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex my-auto max-sm:flex max-md:flex max-lg:block">
        <div className="w-[95%]">
          <div className="flex justify-between max-sm:w-full max-lg:w-full max-md:w-full">
            <div className="flex justify-between h-[30px] items-center">
              <div className="flex items-center">
                <div className="w-[20px] h-[19px] text-[#7B9DB4] mr-3">
                  <BsClock />
                </div>
                <p className="text-[12px] text-[#7B9DB4] font-[700]">
                  {formatDurationHrAndMins(episode.duration)}
                </p>
              </div>
              <div>
                <div>
                  {currentPodcast && currentPodcast.id === episode.id && (
                    <>
                      <div className="ml-4 flex items-center justify-center w-[28px] h-[28px] bg-red-600 rounded-full shadow-lg cursor-pointer">
                        <FaPlay className="w-[10px] h-[15px] text-white" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center text-[#7B9DB4] justify-end gap-5 pl-10 text-xl">
              <div onClick={handleAddToFav}>
                {addFav ? (
                  <div className="text-red-600 ">
                    <AiFillHeart />
                  </div>
                ) : (
                  <AiOutlineHeart />
                )}
              </div>

              <div onClick={dropDown} ref={popRef}>
                <RiPlayListAddLine />

                {isActive && (
                  <WatchlistPopUp className="right-[160px]" list="playlist" />
                )}
              </div>
              <FiMoreHorizontal />
            </div>
          </div>
          <div
            className="text-white font-[700] text-[20px]"
            onClick={handlePlay}
          >
            {episode.title}
            <div className="text-white w-[635px] font-[300] text-[12px] max-sm:w-full max-md:w-full my-2">
              {slicedDes}{" "}
              {episode.description && (
                <span className="text-red-600" onClick={handleDescription}>
                  {readMore ? `Read less` : `Read more`}
                </span>
              )}
            </div>
          </div>
          <div className="text-[#7B9DB4] font-[400] text-[12px] flex">
            {ago}
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default PodcastOneEpisode;
