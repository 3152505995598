import { useEffect, useState } from "react";
import DetailHeader from "./shared/VideoDetailHeader";
import VideoDetailButtonSection from "./shared/VideoDetailButtonSection";
import { useParams } from "react-router";
import VideoService from "services/video/VideoService";
import VideoDetailsSkeleton from "components/skeleton/videoDetailsSkeleton/VideoDetailsSkeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteName } from "constant/routeNames";

function VideoDetails({
  handleClick,
  isActive,
  anyPlayerActive,
  setIsActive,
}: any) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);

  const [video, setVideo] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // console.log(slug);
  const [contentType, setContentType] = useState<"movie" | "tv-series" | null>(
    null
  );
  const [similarContent, setSimilarContent] = useState<any>([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    let cleanedSlug = slug && slug.endsWith(".html") ? slug.slice(0, -5) : slug;
    VideoService.checkContentType(cleanedSlug)
      .then((res) => {
        // console.log(res?.type);
        setContentType(res?.type);
      })
      .catch((err) => {
        navigate(RouteName.pageNotFound);
      });
  }, [navigate, slug]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      if (contentType === "movie") {
        setIsLoading(true);
        VideoService.getMovieDetailsBySlug(slug)
          .then((res) => {
            setIsLoading(false);
            // console.log(res.data);
            setVideo(res.data);
          })
          .catch((e: any) => {
            console.log(e);
          });
        VideoService.getSimilarVideoContent(slug)
          .then((res) => {
            // console.log(res.data);
            setSimilarContent(res?.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setIsLoading(true);
        VideoService.getTVSeriesDetailsBySlug(slug)
          .then((res) => {
            setIsLoading(false);
            // console.log(res.data);
            setVideo(res.data);
          })
          .catch((e: any) => {
            console.log(e);
          });
        VideoService.getSimilarTvSeriesContent(slug)
          .then((res) => {
            // console.log(res.data);
            setSimilarContent(res?.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 1000);
  }, [contentType, slug]);

  return (
    <div>
      <div>
        {!isLoading ? (
          <>
            {video && (
              <>
                <DetailHeader
                  video={video}
                  isLoading={isLoading}
                  slug={slug}
                  playerPage={
                    location?.state?.playerPage === true ? true : false
                  }
                />
                <div className="mt-8 max-sm:mt-0 max-sm:ml-3 max-lg:mt-0">
                  <VideoDetailButtonSection
                    video={video}
                    isLoading={isLoading}
                    similarContent={similarContent}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <VideoDetailsSkeleton />
        )}
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default VideoDetails;
// function setError(arg0: string) {
//   throw new Error("Function not implemented.");
// }
