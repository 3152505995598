import React, { useRef } from "react";
import { TbTrashX } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  removeSongFromPlaylist,
  removeSongFromPlaylistLoading,
} from "redux/AudioPlayerRedux";
import PlaylistService from "services/music/PlaylistService";

function DeleteSongFromPlaylist({
  mediaId,
  setRemovedSongId,
  userPlaylists,
  removeFromPlaylist,
  selectedPlaylistId,
}: any) {
  const { id } = useParams();
  const popRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  const { musicPlaylistId, playlist } = useSelector(
    (state: any) => state?.audio
  );

  // console.log(index);

  const handleRemoveSongFromPlaylist = async (playlistId: any) => {
    const data = {
      song_id: mediaId,
      playlist_id: playlistId,
    };

    await PlaylistService.removeSongFromPlaylist(data)
      .then((response: any) => {
        // console.log(response);
        if (response.data.success === true) {
          toast.success(`Removed from the playlist`);
          setRemovedSongId(mediaId);

          // remove song from queue
          const index = playlist.indexOf(
            playlist?.find((song: any) => song.id == mediaId)
          );

          if (musicPlaylistId == selectedPlaylistId && index > -1) {
            const obj = {
              songId: mediaId,
              index: index,
            };
            dispatch(removeSongFromPlaylistLoading());
            dispatch(removeSongFromPlaylist(obj));
          }
        } else {
          toast.error(`There is an issue when removing from playlist`);
        }
      })
      .catch((e: any) => {
        toast.error("An error occurred. Please try again.");
      });
  };

  return (
    <>
      {removeFromPlaylist && (
        <>
          {userPlaylists?.length > 0 && (
            <div
              ref={popRef}
              data-tooltip-id={`${`delete-song-from-playlist`}-${mediaId}`}
              onClick={() => handleRemoveSongFromPlaylist(id)}
            >
              <TbTrashX className="text-white text-md hover:text-red-600" />
              <div className="text-sm">
                <ReactTooltip
                  id={`delete-song-from-playlist-${mediaId}`}
                  place="top"
                  content={"Delete song from Playlist"}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default DeleteSongFromPlaylist;
