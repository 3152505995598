import { useEffect, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addMusicToPlayer } from "redux/AudioPlayerRedux";
import MusicService from "services/music/MusicService";
import TrackDetails from "./trackPage/TrackDetails";
import LikeService from "services/music/LikeService";
import { HiDotsHorizontal } from "react-icons/hi";
import PlaylistService from "services/music/PlaylistService";
import CreatePlaylistIcon from "./audioPlayer/AudioPlayer3/CreatePlaylistIcon/CreatePlaylistIcon";
import AddSongToFav from "./shared/AddSongToFav";
import { Helmet } from "react-helmet-async";
import TrackPageArtistRow from "./shared/TrackPageArtistRow";
import ArtistSuggestions from "./shared/ArtistSuggestions";
import MusicTractPageSkeleton from "components/skeleton/MusicTractPageSkeleton";
import AddSongToQueue from "./shared/AddSongToQueue";

function MusicTrackPage() {
  const dispatch = useDispatch<any>();
  const { id } = useParams();
  // console.log(id)
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const [trackData, setTrackData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [favorite, setFavorite] = useState<any>([]);
  const [userPlaylists, setUserPlaylists] = useState([]);
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    // setIsLoading(true);
    if (loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, loggedInUser?.id)
        .then((res) => {
          const favoritesIds = res?.profile?.loved?.data?.map(
            (item: any) => item?.id
          );
          // setIsLoading(false);
          setFavorite(favoritesIds);
        })
        .catch(() => {});
    }
  }, [loggedInUser, loggedInUser?.id]);

  useEffect(() => {
    setIsLoading(true);
    MusicService.getTrackDataByID(id).then((res) => {
      setIsLoading(false);
      setTrackData(res);
    });
  }, [id]);

  const handleMusicPlayer = async () => {
    dispatch(addMusicToPlayer(trackData));
  };

  useEffect(() => {
    if (loggedInUser) {
      PlaylistService.getUserPlaylists().then((res) => {
        setUserPlaylists(res.data.data);
      });
    }
  }, [loggedInUser]);

  return (
    <>
      <Helmet>
        <title>
          {trackData?.title ? `${trackData?.title} - AYOZAT Music` : "AYOZAT"}
        </title>
        <meta name="title" content={`${trackData?.title} - AYOZAT Music`} />
        <meta name="description" content={trackData?.title} />
        <link
          rel="canonical"
          href={`${FE_URL}/music/track/${trackData?.id || ""}`}
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${trackData?.title} - AYOZAT Music`}
        />
        <meta property="og:description" content={trackData?.artists[0]?.name} />
        <meta property="og:image" content={trackData?.artwork_url} />
        <meta
          property="og:url"
          content={`${FE_URL}/music/track/${trackData?.id || ""}`}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${trackData?.title} - AYOZAT Music`}
        />
        <meta
          name="twitter:description"
          content={trackData?.artists[0]?.name}
        />
        <meta name="twitter:image" content={trackData?.artwork_url} />
        <meta
          name="twitter:url"
          content={`${FE_URL}/music/track/${trackData?.id || ""}`}
        />
      </Helmet>

      {!isLoading && trackData ? (
        <>
          {/* hero details */}
          <TrackDetails data={trackData && trackData} />

          <div className="mt-5">
            {/* play icons */}
            <div className="flex items-center gap-3">
              <button
                className={`flex items-center w-28 p-3 text-center mr-3 justify-center text-white rounded-full button-red max-sm:p-2 max-sm:ml-2 max-sm:text-[15px] max-sm:w-[full]`}
                onClick={handleMusicPlayer}
              >
                <FaPlay className="mr-1 " /> Play
              </button>

              <AddSongToQueue song={trackData} toolTiId={`new-released-card`} Icon={true}/>

              <AddSongToFav song={trackData} favorite={favorite} Icon={true} />

              <CreatePlaylistIcon
                userPlaylists={userPlaylists}
                setUserPlaylists={setUserPlaylists}
                mediaId={id}
                Icon={HiDotsHorizontal}
              />
            </div>

            <hr className="my-5" />

            {/* artist details */}
            {trackData?.artists?.map((artist: any, i: number) => (
              <TrackPageArtistRow artist={artist} key={i} />
            ))}

            <div className="my-5">
              {trackData?.artists?.map((artist: any, i: number) => (
                <ArtistSuggestions
                  artist={artist}
                  key={i}
                  trackId={trackData?.id}
                />
              ))}
            </div>

            {/* <div className="relative w-full px-3 mt-5">

              <table className="w-full border-collapse cursor-context-menu">
                <thead className="text-[#7B9DB4]">
                  <tr>
                    <th className="w-[5%] text-left">#</th>
                    <th className="w-[45%] text-left">Title</th>
                    <th className="w-[25%]">Plays</th>
                    <th className="w-[25%] text-right pr-10">Duration</th>
                  </tr>
                </thead>

                <tr>
                  <td colSpan={4}>
                    <hr className="border-[#7B9DB4] h-[1px] my-5" />
                  </td>
                </tr>

                <tbody className="text-center text-white">
                  <tr>
                    <td className="w-[5%] text-left">1</td>
                    <td className="w-[45%] text-left">
                      <p>{trackData?.title}</p>
                      <Link
                        to={RouteName.MusicArtistPage.replace(
                          ":slug",
                          trackData?.artists[0]?.slug
                        )}
                      >
                        <p className="text-[#7B9DB4] text-[12px]">
                          {trackData?.artists[0].name}
                        </p>
                      </Link>
                    </td>
                    <td className="w-[25%]">{trackData?.plays}</td>
                    <td className="w-[25%] text-right pr-10">
                      {trackData?.duration
                        ? formatDuration(trackData.duration)
                        : "00:00"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </div>
        </>
      ) : (
        <></>
      )}
      {isLoading && <MusicTractPageSkeleton />}
    </>
  );
}

export default MusicTrackPage;
