import { AiFillAndroid, AiFillApple } from "react-icons/ai";
import { FaAmazon } from "react-icons/fa";

export const AppsData = [
    {   title: "play_store",
        icon: AiFillAndroid
    },
    {   title: "app_store",
        icon: AiFillApple
    },
    {   title: "android_tv",
        icon: AiFillAndroid
    },
    {   title: "fire_tv",
        icon: FaAmazon
    },
]