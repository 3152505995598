import { useParams } from "react-router-dom";

import AddToQueue from "components/shared/AddToQueue";
import FollowArtist from "components/shared/FollowArtist";
import PlayButton from "components/shared/PlayButton";
import AlbumCardCoursel from "./artist/AlbumCardCoursel";
import ArtistDetails from "./artist/ArtistDetails";
import { useEffect, useState } from "react";
import ArtistService from "services/music/ArtistService";
import GenreService from "services/GenreService";
import { useDispatch, useSelector } from "react-redux";
import { addSongsToQueue } from "redux/AudioPlayerRedux";
import LikeService from "services/music/LikeService";
import {
  triggerSuccessNotification,
  triggerWarningNotification,
} from "utils/notification";
import { Notification } from "constant/notifications";
import PlaylistService from "services/music/PlaylistService";
import MusicTable from "./shared/MusicTable";
import MusicPlaylistSkeleton from "components/skeleton/MusicPlaylistSkeleton";

const MusicArtistPage = () => {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const { slug } = useParams();
  // console.log(slug);
  const dispatch = useDispatch<any>();
  const [artistData, setArtistData] = useState<any | null>({});
  // const [favorite, setFavorite] = useState<any>();
  const [favoriteIds, setFavoriteIds] = useState<any>();
  // playlist
  const [userPlaylists, setUserPlaylists] = useState([]);

  const [genre, setGenre] = useState([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    GenreService.getAllGenres().then((res) => {
      setIsLoading(false);
      setGenre(res.data);
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // console.log(id);

  useEffect(() => {
    // setTimeout(() => {
    if (slug) {
      ArtistService.getArtistBySlug(slug).then((res) => {
        setArtistData(res.artist);
        // console.log(res);
      });
    }
    // }, 1000);
  }, [slug]);

  const addAlbumToExistingQueue = () => {
    if (artistData?.songs?.data.length > 0) {
      dispatch(addSongsToQueue(artistData?.songs?.data));
      triggerSuccessNotification(Notification.SongsAddedToQueue);
    } else {
      triggerWarningNotification(Notification.NoSongs);
    }
  };
  // console.log("first", artistData.id);

  useEffect(() => {
    if (loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, loggedInUser?.id)
        .then((res) => {
          const favoritesIds = res.profile.loved.data.map(
            (item: any) => item.id
          );
          setFavoriteIds(favoritesIds);
          // setFavorite(res.profile);
          // console.log("first", res.profile);
        })
        .catch(() => {});

      PlaylistService.getUserPlaylists().then((res) => {
        setUserPlaylists(res.data.data);
      });
    }
  }, [loggedInUser]);

  return (
    <>
      {!isLoading && artistData && genre && (
        <div className="mb-16">
          <ArtistDetails value={artistData && artistData} />

          <div className="flex items-center mt-8 max-sm:grid max-sm:grid-cols-2 ">
            {artistData?.songs?.data?.length > 0 && (
              <div className="flex">
                <PlayButton
                  songs={artistData.songs.data && artistData.songs.data}
                />
              </div>
            )}
            {artistData?.songs?.data && artistData?.songs?.data?.length > 0 && (
              <div className="flex" onClick={addAlbumToExistingQueue}>
                <AddToQueue />
              </div>
            )}
            <div className="">
              {loggedInUser && (
                <div className="flex max-sm:mt-2">
                  <FollowArtist
                    user={loggedInUser}
                    artistId={artistData?.id}
                    artist={artistData}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="mt-8">
            <div className="grid grid-cols-3 text-white max-sm:block">
              <div className="col-span-4 ">
                <p className="text-[20px] font-[500]">Picked for you</p>

                <div className="my-5">
                  <MusicTable
                    songs={artistData && artistData?.songs?.data}
                    userPlaylists={userPlaylists}
                    setUserPlaylists={setUserPlaylists}
                    favorite={favoriteIds}
                  />
                </div>
              </div>
            </div>
          </div>
          {artistData?.album_count > 0 ? (
            <div className="mt-10">
              <p className="text-[20px] font-[500] text-white">
                {artistData.name} albums
              </p>
              <p className="text-[12px] font-[400] text-[#7B9DB4]">
                Based on what you played
              </p>
              <div>
                <AlbumCardCoursel artistData={artistData} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {isLoading && <MusicPlaylistSkeleton />}
    </>
  );
};

export default MusicArtistPage;
