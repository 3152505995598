import FilterSection from 'components/shared/FilterSection';
import WatchlistSection from 'pages/videoPage/watchlist/WatchlistSection';
import { useEffect } from 'react';

function VideoPageWatchlist() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* main topic section */}
      <div className="mb-8 flex items-center justify-between w-[98%] max-sm:block">
        {/* left part */}
        <div>
          <h1 className="text-2xl font-medium text-white lg:text-3xl md:text-3xl max-sm:text-3xl max-sm:ml-6">
            User's Watchlist
          </h1>
        </div>
        {/* right part */}
        <FilterSection />
      </div>
      {/* card section */}

      <WatchlistSection />
    </div>
  );
}

export default VideoPageWatchlist;
