import React, { useState } from "react";
import RadioCard from "./RadioCard";

function RadioProgrammesCardSection({ programmes }: any) {
  return (
    <div>
      <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6">
        {programmes.map((programme: any, i: number) => (
          <div key={i}>
            <RadioCard radio={programme} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default RadioProgrammesCardSection;
