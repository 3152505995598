import React, { useEffect, useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import PayperviewService from "services/payperview/PayperviewServie";
import LoadingSpin from "./Loading spins/LoadingSpin";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  ppv_event: any;
}

const TicketVerificationModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  ppv_event,
}) => {
  const navigate = useNavigate();
  const slug = ppv_event?.slug;
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    ticket: "",
  });
  const [errors, setErrors] = useState({
    ticket: [],
  });

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleVerifyButton = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await PayperviewService.verifyEventTicket(formData)
      .then((response) => {
        console.log("response handleVerifyButton ", response);
        if (response.status === 200) {
          resetForm();
          removeErrors();
          navigate("/ppv/watch/event/" + slug);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error handleVerifyButton ", error);
        if (error.response && error.response.status === 422) {
          const errorData = error.response.data.errors;
          setErrors(errorData);
        } else if (error.response.status === 404) {
          updateTicketError(["Invalid Ticket Entry"]);
        } else if (error.response.status === 403) {
          updateTicketError(["Maximum users limit reached for this ticket"]);
        } else {
          updateTicketError(["Something went wrong, Try again"]);
        }
        setLoading(false);
      });
  };

  const resetForm = () => {
    setFormData({
      ticket: "",
    });
  };

  const removeErrors = () => {
    setErrors({
      ticket: [],
    });
  };

  const updateTicketError = (error: any) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      ticket: error,
    }));
  };

  return isOpen ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="fixed inset-0 flex items-center justify-center backdrop-blur">
        <div className="absolute w-[35%] overflow-hidden rounded-lg shadow-xl bg-gray-700 p-8 max-sm:w-[80%] max-lg:w-[70%]">
          <div className="mt-0 rounded-lg">
            <button
              className="flex items-center justify-center w-10 h-10 my-2 text-2xl text-gray-500 bg-white rounded-full cursor-pointer z-100 left-6 hover:text-gray-700"
              onClick={onClose}
            >
              <HiArrowLeft className="text-black" />
            </button>
            <form>
              <div className="text-left">
                <h1 className="mb-2 text-xl font-bold white-text">
                  Ticket verification
                </h1>
                <p className="mb-4 text-sm tracking-wide gray-text">
                  Enter the ticket you received to the your email when you
                  purchased the event.
                </p>
              </div>
              <div className="flex max-sm:block">
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="Enter your ticket"
                    name="ticket"
                    value={formData.ticket}
                    onChange={onChange}
                    autoComplete="ticket"
                    className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] mb-4 text-gray-600"
                  />
                  {errors.ticket?.length > 0 && (
                    <div className="mb-5 text-left text-red-400">
                      <span className="error">{errors.ticket[0]}</span>
                    </div>
                  )}

                  {loading ? (
                    <button className="flex mx-auto mt-4 px-12 py-3 bg-[#f30543] rounded-full w-5/12 justify-center items-center">
                      <LoadingSpin fontSize={20} color={"white"} />
                    </button>
                  ) : (
                    <button
                      className="flex mx-auto mt-4 px-12 py-3 bg-[#f30543] rounded-full"
                      onClick={handleVerifyButton}
                    >
                      Verify Now
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default TicketVerificationModal;
