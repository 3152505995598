const BlogPageHeader = ({ Logo, link }: any) => {
  return (
    <div className="blogs-heroimage h-[300px]  mb-10 rounded-xl max-sm:h-[300px]">
      <div className=" mt-[120px] mx-auto">
        <div className="flex flex-col justify-center w-[100%] mx-auto mt-5 mb-5 text-center">
          <img
            src={Logo}
            className="w-[15%] justify-center items-center mx-auto mb-3"
            alt="ppv"
          />
          <h1 className="text-[30px] font-bold text-white cursor-default uppercase max-sm:text-[25px]">
            BLOGS
          </h1>
        </div>
      </div>
    </div>
  );
};

export default BlogPageHeader;
