import React, { useEffect, useState } from "react";
import AlbumCard from "../shared/AlbumCard";

function CategoryAlbumSection({ albums, seeAll }: any) {
  const fliteredAlbums = seeAll ? albums : albums?.slice(0, 3);
  // const [albums, setAlbums] = useState([]);
  // const [fliterdAlbums, setFliterdAlbums] = useState([]);

  // useEffect(() => {
  //   if (seeAll) {
  //     setFliterdAlbums(albums);
  //   } else {
  //     if (albums.length > 8) {
  //       const slicedData = albums.slice(0, 2);
  //       setFliterdAlbums(slicedData);
  //     } else {
  //       setFliterdAlbums(albums);
  //     }
  //   }
  // }, [seeAll, albums]);
  return (
    <div className="grid grid-cols-2 gap-2 text-white max-sm:w-full sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 max-sm:mx-auto">
      {fliteredAlbums?.map((album: any, i: number) => (
        <AlbumCard {...album} key={i} />
      ))}
    </div>
  );
}

export default CategoryAlbumSection;
