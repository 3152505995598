import FeaturePages from "layout/FeaturePages";
import { Route, Routes } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import RadioPageFavorite from "./radioPage/RadioPageFavorite";
import RadioPageHome from "./radioPage/RadioPageHome";
import RadioPageDiscover from "./radioPage/RadioPageDiscover";
import RadioPageHistory from "./radioPage/RadioPageHistory";
import RadioPageChannelsByCategoryPage from "./radioPage/RadioPageChannelsByCategoryPage";
import SeeAllRadioCategories from "./radioPage/SeeAllRadioCategories";
import SeeAllMightLike from "./radioPage/SeeAllMightLike";
import RadioDescriptionPage from "./radioPage/RadioDescriptionPage";

function RadioPage({
  handleClick,
  isActive,
  setIsActive,
  // setPlayerIsActive,
  anyPlayerActive,
}: any) {
  // useEffect(() => {
  //   setPlayerIsActive(isActive);
  // }, []);
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        <Route
          path=""
          element={
            <FeaturePages
              Content={RadioPageHome}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Radio"}
            />
          }
        />
        <Route
          path={RouteName.RadioDiscover}
          element={
            <FeaturePages
              Content={RadioPageDiscover}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Radio"}
            />
          }
        />
        <Route
          path={RouteName.RadioHistory}
          element={
            <FeaturePages
              Content={RadioPageHistory}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Radio"}
            />
          }
        />
        <Route
          path={RouteName.RadioFavourite}
          element={
            <FeaturePages
              Content={RadioPageFavorite}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Radio"}
            />
          }
        />

        <Route
          path={RouteName.RadioChannelsByCategory}
          element={
            <FeaturePages
              Content={RadioPageChannelsByCategoryPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Radio"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllRadioCategory}
          element={
            <FeaturePages
              Content={SeeAllRadioCategories}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Radio"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllMightLike}
          element={
            <FeaturePages
              Content={SeeAllMightLike}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Radio"}
            />
          }
        />
        <Route
          path={RouteName.RadioDescription}
          element={
            <FeaturePages
              Content={RadioDescriptionPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Radio"}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default RadioPage;
