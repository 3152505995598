import Podcastcard from "pages/podcastPage/shared/Podcastcard";
import NoFavorite from "./shared/NoFavorite";
import OverviewSkeleton from "components/skeleton/OverviewSkeleton";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

function UserPodcastPage({
  user,
  favoriteData,
  favoritePodcastsIds,
  isLoading,
}: any) {
  const [allPodcasts, setAllPodcasts] = useState<any>([]);
  const podcasts = favoriteData?.favorite_podcasts || [];

  useEffect(() => {
    setAllPodcasts(podcasts);
  }, []);

  return (
    <>
      {!isLoading && (
        <div>
          {/* top section */}
          <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h2 className="text-lg font-medium text-white lg:text-xl md:text-2xl">
                {/* Videos by booster */}
                Favourite Podcasts
              </h2>
            </div>
          </div>
          {/* card section */}
          {podcasts?.length === 0 && podcasts ? (
            <div>
              <NoFavorite text="Podcasts" user={user} />
            </div>
          ) : (
            <div>
              <div className="grid w-[95%] grid-cols-2 pl-6 mt-4 mb-5 max-sm:pl-0 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-0 max-sm:w-full max-sm:gap-3 ">
                {allPodcasts?.length > 0 &&
                  allPodcasts?.map((podcasts: any, i: number) => (
                    <div key={i}>
                      <Podcastcard
                        podcast={podcasts}
                        user={user}
                        favorite={favoritePodcastsIds}
                      />
                    </div>
                  ))}
              </div>
            </div>
            // <>
            //   {/* coursel */}
            //   <div className="">
            //     <Swiper
            //       slidesPerView={5}
            //       breakpoints={{
            //         0: {
            //           slidesPerView: 2,
            //         },
            //         370: {
            //           slidesPerView: 2,
            //         },
            //         700: {
            //           slidesPerView: 3,
            //         },
            //         1100: {
            //           slidesPerView: 4,
            //         },
            //         1200: {
            //           slidesPerView: 5,
            //         },
            //         1500: {
            //           slidesPerView: 6,
            //         },
            //         1600: {
            //           slidesPerView: 7,
            //         },
            //       }}
            //       modules={[Pagination]}
            //       className="mt-4 mb-5 mySwiper"
            //     >
            //       {podcasts.length > 0 &&
            //         podcasts.map((podcasts: any, i: number) => (
            //           <div key={i}>
            //             <SwiperSlide className="">
            //               <Podcastcard
            //                 podcast={podcasts}
            //                 user={user}
            //                 favorite={favoritePodcastsIds}
            //               />
            //             </SwiperSlide>
            //           </div>
            //         ))}
            //     </Swiper>
            //   </div>
            // </>
          )}
        </div>
      )}
      {isLoading && <OverviewSkeleton />}
    </>
  );
}

export default UserPodcastPage;
