import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { MusicCard } from "../shared/MusicCard";

function MusicCardCourselHistory({ audios }: any) {
  return (
    <div>
      <div className="">
        <Swiper
          slidesPerView={8}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            370: {
              slidesPerView: 2,
            },
            650: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
            1100: {
              slidesPerView: 5,
            },
            1200: {
              slidesPerView: 6,
            },
            1400: {
              slidesPerView: 7,
            },
          }}
          modules={[Pagination]}
          className="mt-4 mb-5 mySwiper"
        >
          {audios.map((song: any, i: number) => (
            <div key={i}>
              <SwiperSlide className="">
                <MusicCard song={song} />
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default MusicCardCourselHistory;
