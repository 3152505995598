import axios from "axios";
import authHeader from "utils/auth-header";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;

const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

const welcomePageSlider = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/home/slider`,
    // headers: authHeader(),
  });

  return response.data;
};

const Sliders = {
  welcomePageSlider,
};

export default Sliders;
