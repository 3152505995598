import { RouteName } from "constant/routeNames";
import React from "react";
import { Link } from "react-router-dom";

function PodcastCategories({ genre }: any) {
  // console.log(genre.alt_name);
  return (
    <Link
      // to={RouteName.PodcastDiscoverByGenreId.replace(":slug", genre.alt_name)}
      to={RouteName.Podcast.replace(
        "*",
        RouteName.PodcastDiscoverByGenreId.replace(":slug", genre.alt_name)
      )}
    >
      <div className="h-16 w-[70%] relative cursor-pointer bg-gradient-to-r from-[#272424] to-[#f30543] opacity-50 hover:opacity-100 rounded-lg max-sm:w-[95%]">
        <div className="absolute inset-0 flex items-center justify-center mx-auto text-center">
          <p className="text-white font-[800] text-md">
            {genre.name.replace(/&amp;/g, "&")}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default PodcastCategories;
