import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer, { initialState } from "./userRedux";
import videoReducer from "./VideoPlayerRedux";
import audioReducer from "./AudioPlayerRedux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: initialState.version,
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  video: videoReducer,
  audio: audioReducer,
});

const configurePersistedStore = () => {
  const storedVersion = localStorage.getItem("rootVersion");
  if (storedVersion !== String(initialState.version)) {
    localStorage.removeItem("persist:root");
    localStorage.setItem("rootVersion", String(initialState.version));
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });

  return {
    store,
    persistor: persistStore(store),
  };
};

const { store, persistor } = configurePersistedStore();

export { store, persistor };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// });

// export let persistor = persistStore(store);
