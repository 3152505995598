import { useEffect, useState } from "react";
import LiveTv from "Assets/Images/livetv.jpg";
import Music from "Assets/Images/Music.jpg";
import PPV from "Assets/Images/ppv.png";
import Podcast from "Assets/Images/podcast.jpg";
import Radio from "Assets/Images/Radio.jpg";
import Video from "Assets/Images/video.png";
import GridCard from "pages/welcome/shared/GridCard";
import { RouteName } from "constant/routeNames";
import { AssetTypes } from "constant/AssetTypes";

function GridCardsSection() {
  const cards = [
    {
      id: 1,
      theme: "dark",
      title: AssetTypes.LiveTV,
      info: "Stay up-to-date with live broadcasts and events from around the world with LiveTV.",
      img: LiveTv,
      link: RouteName.LiveTVPlayerLinkWithSlug.replace(":slug", "ayozat-tv"),
    },
    {
      id: 2,
      theme: "light",
      title: AssetTypes.Video,
      info: "Dive into a world of visual entertainment with our extensive video library.",
      img: Video,
      link: RouteName.Video.replace("/*", ""),
    },
    {
      id: 3,
      theme: "dark",
      title: AssetTypes.Music,
      info: "Explore a symphony of sounds and melodies in our music collection.",
      img: Music,
      link: RouteName.Music.replace("/*", ""),
    },
    {
      id: 4,
      theme: "light",
      title: AssetTypes.Podcast,
      info: "Immerse yourself in captivating conversations and storytelling through our podcast library.",
      img: Podcast,
      link: RouteName.Podcast.replace("/*", ""),
    },
    {
      id: 5,
      theme: "dark",
      title: AssetTypes.Radio,
      info: "Tune in to your favorite stations and discover new music on our radio platform.",
      img: Radio,
      link: RouteName.Radio.replace("/*", ""),
    },
    {
      id: 6,
      theme: "light",
      title: AssetTypes.PayPerView,
      info: "Experience exclusive content and events on demand with our Pay-per-View service.",
      img: PPV,
      link: RouteName.PayPerView.replace("/*", ""),
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [cardDetails] = useState(cards);
  return (
    <div className="grid mt-10 mb-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {cardDetails.map((card, i) => (
        // <div>
        <GridCard {...card} key={i} />
        // </div>
      ))}
    </div>
  );
}

export default GridCardsSection;
