import { Tooltip as ReactTooltip } from "react-tooltip";

function LiveTvChannelChangeIcon({ Icon, text, changeChannel, disabled }: any) {
  return (
    <>
      <div
        className={`flex flex-col items-center uppercase justify-center cursor-pointer w-[80px]   ${
          !disabled
            ? " text-white hover:text-[#f30543]"
            : "cursor-not-allowed text-gray-400"
        }`}
        onClick={() => {
          if (!disabled) {
            changeChannel(text);
          }
        }}
      >
        <Icon className={`w-7 h-7  `} data-tooltip-id={text} />
        <div className={` text-[10px]`}>{`CH ${text}`}</div>
      </div>
    </>
  );
}

export default LiveTvChannelChangeIcon;
