export default function authHeader() {
  const user = localStorage.getItem("persist:root");

  if (user != null) {
    const data = JSON.parse(JSON.parse(user).user);
    if (data.currentUser?.access_token) {
      return { Authorization: "Bearer " + data.currentUser.access_token };
    } else {
      return { Authorization: "" };
    }
  }
}
