import { createSlice } from "@reduxjs/toolkit";
import { Notification } from "constant/notifications";
import {
  triggerSuccessNotification,
  triggerWarningNotification,
} from "utils/notification";

// interface playerState {
//   playerVisible: false;
//   currentAudio: any;
//   playlist: any[];
//   playlistLoading: boolean;
//   //
//   currentPodcast: any;
//   podcastVisible: boolean;
//   podcastPlaylist: any[];
//   podcastLoading: boolean;
//   //
//   radioVisible: boolean;
//   currentRadio: any;
// }

// const initialState: playerState = {
//   playerVisible: false,
//   currentAudio: null,
//   playlist: [],
//   playlistLoading: false,
//   //
//   podcastVisible: false,
//   currentPodcast: null,
//   podcastPlaylist: [],
//   podcastLoading: false,
//   //
//   currentRadio: null,
//   radioVisible: false,
// };

const AudioPlayerRedux = createSlice({
  name: "audio",
  initialState: {
    playerVisible: false,
    currentAudio: null,
    playlist: [],
    playlistLoading: false,
    musicPlaylistId: 0,
    //
    podcastVisible: false,
    currentPodcast: null,
    podcastPlaylist: [],
    podcastLoading: false,
    //
    currentRadio: null,
    radioVisible: false,
    //
    volume: 0.4,
    fullScreen: false,
    visibleQueue: false,
  },
  reducers: {
    // add one music to player
    addMusicToPlayer: (state: any, action: any) => {
      state.playerVisible = true;
      state.radioVisible = false;
      state.podcastVisible = false;
      // state.currentAudio = action.payload;
      state.currentPodcast = null;
      state.currentRadio = null;
      // state.playlist = [action.payload];

      const existingAudioIndex = state.playlist.findIndex(
        (audio: any) => audio?.id === action.payload.id // Assuming that there's an "id" property to identify the audio
      );
      // If it doesn't exist, add the new payload to the playlist array
      if (existingAudioIndex === -1) {
        state.currentAudio = action.payload;
        state.playlist = [action.payload];
      } else {
        state.currentAudio = action.payload;
      }
    },

    setCurrentAudio: (state: any, action: any) => {
      state.currentAudio = action.payload;
    },

    // remove music from the playlist
    removeSongFromPlaylistLoading: (state: any) => {
      state.playlistLoading = true;
    },
    removeSongFromPlaylist: (state: any, action: { payload: any }) => {
      const songId = action.payload.songId;
      const index = action.payload.index;

      if (songId === state.currentAudio.id) {
        if (index === 0) {
          if (state.playlist.length > 1) {
            state.currentAudio = state.playlist[index + 1];
          } else {
            state.currentAudio = null;
            state.playerVisible = false;
          }
        } else {
          state.currentAudio = state.playlist[index - 1];
        }
      } else {
      }

      state.playlist = state.playlist.filter(
        (audio: any) => audio.id !== songId
      );

      state.playlistLoading = false;
    },

    addToQueue: (state: any, action: any) => {
      state.playerVisible = true;
      state.radioVisible = false;
      state.podcastVisible = false;
      state.currentPodcast = null;
      state.currentRadio = null;

      // Check if the new payload already exists in playlist array
      const existingAudioIndex = state.playlist.findIndex(
        (audio: any) => audio?.id === action.payload.id // Assuming that there's an "id" property to identify the audio
      );
      // If it doesn't exist, add the new payload to the playlist array
      if (existingAudioIndex === -1) {
        if (state.currentAudio === null) {
          state.currentAudio = action.payload;
          // state.playlist = [action.payload];
        }
        state.playlist = [...state.playlist, action.payload];
        triggerSuccessNotification(Notification.SongAddedToQueue);
      } else {
        // removeSongFromPlaylist(action);
        triggerWarningNotification(Notification.SongAlreadyInTheQueue);
      }
    },

    addSongsToQueue: (state: any, action: any) => {
      state.playerVisible = true;
      state.radioVisible = false;
      state.podcastVisible = false;
      state.currentPodcast = null;
      state.currentRadio = null;

      if (state.currentAudio === null) {
        state.currentAudio = action.payload[0];
        state.playlist = action.payload;
      }

      const newAudios = action.payload.filter((audio: any) => {
        // Assuming there's an "id" property in each audio object
        return (
          state.playlist.findIndex(
            (existingAudio: any) => existingAudio.id === audio.id
          ) === -1
        );
      });

      state.playlist = [...state.playlist, ...newAudios];
    },

    playFromTopAudios: (state: any, action: any) => {
      state.playerVisible = true;
      state.radioVisible = false;
      state.podcastVisible = false;
      state.currentPodcast = null;
      state.currentRadio = null;
      state.playlist = action.payload;
      // console.log(action.payload)
      if (state.playlist.length > 0) {
        state.currentAudio = state.playlist[0];
      }
    },

    changeSongOrderOfQueue: (state: any, action: any) => {
      state.playerVisible = true;
      state.radioVisible = false;
      state.podcastVisible = false;
      state.currentPodcast = null;
      state.currentRadio = null;

      state.playlist = action.payload;
    },

    setMusicPlaylistId: (state: any, action: any) => {
      state.musicPlaylistId = action.payload;
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // redux related for podcast player
    addPodcastToPodcastPlayer: (state: any, action: any) => {
      state.playerVisible = false;
      state.radioVisible = false;
      state.podcastVisible = true;
      // state.currentPodcast = action.payload;
      state.currentAudio = null;
      state.currentRadio = null;
      // state.podcastPlaylist = [action.payload];

      const existingAudioIndex = state.podcastPlaylist.findIndex(
        (audio: any) => audio?.id === action.payload.id // Assuming that there's an "id" property to identify the audio
      );
      // If it doesn't exist, add the new payload to the playlist array
      if (existingAudioIndex === -1) {
        state.currentPodcast = action.payload;
        state.podcastPlaylist = [action.payload];
      } else {
        state.currentPodcast = action.payload;
      }
    },

    setCurrentPodcast: (state: any, action: any) => {
      state.currentPodcast = action.payload;
    },

    removePodcastFromPlaylistLoading: (state: any) => {
      state.podcastLoading = true;
    },

    removePodcastFromPlaylist: (state: any, action: { payload: any }) => {
      const songId = action.payload.songId;
      const index = action.payload.index;

      if (songId === state.currentPodcast.id) {
        if (index === 0) {
          if (state.podcastPlaylist.length > 1) {
            state.currentPodcast = state.podcastPlaylist[index + 1];
          } else {
            state.currentPodcast = null;
            state.podcastVisible = false;
          }
        } else {
          state.currentPodcast = state.podcastPlaylist[index - 1];
        }
      }

      state.podcastPlaylist = state.podcastPlaylist.filter(
        (audio: any) => audio.id !== songId
      );

      state.podcastLoading = false;
    },

    addPodcastToQueue: (state: any, action: any) => {
      state.playerVisible = false;
      state.radioVisible = false;
      state.podcastVisible = true;
      //
      state.currentAudio = null;
      state.currentRadio = null;

      if (state.currentPodcast === null) {
        state.currentPodcast = action.payload;
        state.podcastPlaylist = [action.payload];
      }

      // Check if the new payload already exists in playlist array
      const existingAudioIndex = state.podcastPlaylist.findIndex(
        (audio: any) => audio.id === action.payload.id
      );
      // If it doesn't exist, add the new payload to the playlist array
      if (existingAudioIndex === -1) {
        state.podcastPlaylist = [...state.podcastPlaylist, action.payload];
      }
    },

    playLatestPodcasts: (state: any, action: any) => {
      state.playerVisible = false;
      state.radioVisible = false;
      state.podcastVisible = true;
      state.currentAudio = null;
      state.currentRadio = null;
      state.podcastPlaylist = action.payload;
      if (state.podcastPlaylist.length > 0) {
        state.currentPodcast = state.podcastPlaylist[0];
      }
    },
    changePodcastOrderOfQueue: (state: any, action: any) => {
      state.playerVisible = false;
      state.radioVisible = false;
      state.podcastVisible = true;
      state.currentAudio = null;
      state.currentRadio = null;

      state.podcastPlaylist = action.payload;
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // redux related to radio
    addStationToRadio: (state: any, action: any) => {
      state.playerVisible = false;
      state.radioVisible = true;
      state.podcastVisible = false;
      state.currentRadio = action.payload;
      state.currentAudio = null;
      state.currentPodcast = null;
    },

    // hide all players
    removeAllPlayer: (state: any) => {
      state.playerVisible = false;
      state.radioVisible = false;
      state.podcastVisible = false;
    },

    // Removing players when the user leaves
    clearDataWhenLogout: (state: any) => {
      state.playerVisible = false;
      state.radioVisible = false;
      state.podcastVisible = false;
      state.currentRadio = null;
      state.currentAudio = null;
      state.currentPodcast = null;
      state.playlist = [];
      state.playlistLoading = false;
      state.podcastPlaylist = [];
      state.podcastLoading = false;
      state.fullScreen = false;
      state.visibleQueue = false;
    },

    // set player volume
    adjustVolume: (state: any, action: any) => {
      state.volume = action.payload;
    },

    toggleFullScreen: (state: any, action: any) => {
      state.fullScreen = action.payload;
    },

    handleVisibleQueue: (state: any, action: any) => {
      state.visibleQueue = action.payload;
    },
  },
});

export const {
  // music
  addMusicToPlayer,
  removeSongFromPlaylist,
  removeSongFromPlaylistLoading,
  addToQueue,
  setCurrentAudio,
  playFromTopAudios,
  addSongsToQueue,
  changeSongOrderOfQueue,
  setMusicPlaylistId,
  // radio
  addStationToRadio,
  // podcast
  addPodcastToPodcastPlayer,
  setCurrentPodcast,
  addPodcastToQueue,
  removePodcastFromPlaylistLoading,
  removePodcastFromPlaylist,
  playLatestPodcasts,
  changePodcastOrderOfQueue,
  // hide all players
  removeAllPlayer,
  // logout
  clearDataWhenLogout,
  // adjust volume
  adjustVolume,
  // fullscreen
  toggleFullScreen,
  // visible queue
  handleVisibleQueue,
} = AudioPlayerRedux.actions;
export default AudioPlayerRedux.reducer;
