import MovieAndSeriesCardSkeleton from "components/skeleton/MovieAndSeriesCardSkeleton";
import NoSearch from "./NoSearch";
import MovieCarouselCard from "pages/videoPage/shared/MovieCarouselCard";
import CreatorVideoSkeleton from "components/skeleton/CreatorVideoSkeleton";
import CreatorVideoCard from "pages/BecomeACreator/shared/CreatorVideoCard";

function SearchCreatorVideosSection({ data, user, isLoading }: any) {
  const creatorVideos = data?.creatorVideos || [];

  return (
    <>
      {!isLoading && (
        <div>
          {/* top section */}
          <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl max-sm:ml-3">
                {/* search creator */}
                Creator Videos
              </h1>
            </div>
          </div>
          {/* card section */}
          {creatorVideos.length === 0 && creatorVideos ? (
            <NoSearch text="Creator Videos" />
          ) : (
            <div>
              <div className="mt-4 mb-5 w-[95%] grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
                {creatorVideos &&
                  creatorVideos?.map((video: any, index: any) => (
                    <div key={index} className="mb-5 mr-4">
                      <CreatorVideoCard
                        video={video}
                        slug={video?.slug}
                        // season={episode?.season}
                        // id={episode?.id}
                        // isLoading={isLoading}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
      {isLoading && <CreatorVideoSkeleton />}
    </>
  );
}

export default SearchCreatorVideosSection;
