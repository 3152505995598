import { RouteName } from "constant/routeNames";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setVideoStart, setVideoSuccess } from "redux/VideoPlayerRedux";

function SingleEpisode({ data, id, season }: any) {
  const dispatch = useDispatch<any>();
  const { currentVideo } = useSelector((state: any) => state?.video);
  const navigate = useNavigate();
  const { slug }: any = useParams();

  const episodeOnClick = () => {
    const value = {
      ...data,
      tv_series_id: id,
      season: season,
      poster_image: data.thumbnail,
    };
    navigate(
      RouteName.WatchTVSeries.replace(":slug", slug).replace(
        ":stream",
        value?.stream_key
      )
    );

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    console.log("episode clisk", value);
    // dispatch(setVideoStart());
    // dispatch(setVideoSuccess(value));

    // window.location.reload();
  };

  return (
    <div
      className={`flex items-center w-[411px]  backdrop:blur-md rounded-md my-4 min-h-[60px] cursor-pointer  max-sm:w-full bg-black bg-opacity-50 overflow-hidden hover:border hover:border-red-500 ${
        currentVideo.id === data.id ? "border border-red-700" : " "
      }`}
      onClick={episodeOnClick}
    >
      {/* image */}
      <div
        className={`w-[120px]  bg-cover ${
          currentVideo.id === data.id ? "h-[71px]" : "h-[45px]"
        }`}
      >
        <img
          src={data.thumbnail}
          alt="thumbnail"
          className="w-full h-full rounded-md"
        />
      </div>
      {/* details */}
      <div className="w-full ml-2">
        {/* top */}
        <div className="flex justify-between w-full">
          <p className="text-[8px] max-sm:text-[] text-gray-400">
            {data.season_name}
          </p>
          {/* <p className="text-[8px] text-gray-400">34m left</p> */}
        </div>
        {/* title */}
        <p className="text-gray-300 max-sm:text-[12px] max-sm:w-full">
          {data.title}
        </p>
        {currentVideo.id === data.id && (
          <p className="text-gray-500 text-[10px]  max-sm:w-full">
            {data.description}
          </p>
        )}
        {/* progress bar */}
      </div>
    </div>
  );
}

export default SingleEpisode;
