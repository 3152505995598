import axios from "axios";
import authHeader from "utils/auth-header";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;
// const VIUZAT_URL = process.env.REACT_APP_VIUZAT_SERVER;
const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

const getAllRadioCategories = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/radio/categories`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getRadioStations = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/radio`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getMostTunedRadioStations = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/most-tuned/radio-stations`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getRandomRadioStations = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/random/radio-stations`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getRadioStationsByGenre = async (genre: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/radio/category/${genre}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};
const getAllRadios = async (pageNo:any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/radio-stations?page=${pageNo}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const likeRadio = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/radio-station/like`,
    headers: authHeader(),
  });

  return response.data;
};

const unlikeRadio = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/radio-station/unlike`,
    headers: authHeader(),
  });

  return response.data;
};

const getRadioBySlug = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/radio-stations/show/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const RadioService = {
  getAllRadioCategories,
  getRadioStations,
  getMostTunedRadioStations,
  getRandomRadioStations,
  getRadioStationsByGenre,
  getAllRadios,
  likeRadio,
  unlikeRadio,
  getRadioBySlug,
};

export default RadioService;
