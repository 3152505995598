import { formatDateTo12HourTime } from "utils/timeFunctions";

function TVProgramPopup({
  setProgramViewPopup,
  selectProgram,
  setSelectProgram,
}: any) {
  const closeOnClick = (data: any) => {
    setSelectProgram(null);
    setProgramViewPopup((val: boolean) => !val);
  };

  const sinceTime = formatDateTo12HourTime(selectProgram.since);
  const tillTime = formatDateTo12HourTime(selectProgram.till);
  return (
    <div onClick={closeOnClick} className="mt-5 ">
      <p className="mb-5 text-xl font-semibold text-gray-200">
        {selectProgram.title}
      </p>
      <img
        src={selectProgram.image}
        alt="thumbnail"
        className="object-cover w-full h-48 mx-auto rounded-md"
      />

      <p className="mt-4 text-base text-gray-200">
        {sinceTime} - {tillTime}
      </p>
      {selectProgram.description && (
        <>
          <p className="mt-4 text-base text-gray-200">Description</p>
          <p className="mt-1 text-sm text-gray-400">
            {selectProgram.description}
          </p>
        </>
      )}
    </div>
  );
}

export default TVProgramPopup;
