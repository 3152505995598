import React from "react";
import { HiArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import OpenEpisodeLeftSection from "./OpenEpisodeLeftSection";

function LeftSection({
  artwork_url,
  title,
  description,
  noOfEpisodes,
  episodes,
  podcastId,
  podcast,
}: any) {
  const navigate = useNavigate();

  // ];
  return (
    <div>
      {/* back arrow icon */}
      <div
        className={`!z-20 flex items-center justify-center mb-10 w-10 h-10 text-2xl bg-white rounded-full cursor-pointer max-sm:ml-[16px]`}
        onClick={() => {
          navigate(-1);
        }}
      >
        <HiArrowLeft />
      </div>
      {/* {oneEpisode.map((episode: any, i: number) => ( */}
      <div>
        <OpenEpisodeLeftSection
          artwork_url={artwork_url}
          title={title}
          description={description}
          noOfEpisodes={noOfEpisodes}
          episodes={episodes}
          podcastId={podcastId}
          podcast={podcast}
        />
      </div>
      {/* ))} */}
    </div>
  );
}

export default LeftSection;
