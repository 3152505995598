import { RouteName } from "constant/routeNames";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RedirectComponent = () => {
  const navigate = useNavigate();
  const enabled = localStorage.getItem("ppv_enabled");

  useEffect(() => {
    if (window.location.pathname.endsWith(".html")) {
      const pathWithoutHtml = window.location.pathname.slice(0, -5);
      navigate(pathWithoutHtml);
    }
  }, [navigate]);

  useEffect(()=>{
    if(enabled === "0" && window.location.pathname ==="/ppv"){
      const pathWithoutHtml = window.location.pathname.slice(0, -3);
      navigate(pathWithoutHtml);
    }
  },[navigate,enabled])

  // You can render a loading message or a component if needed
  return <div></div>;
};

export default RedirectComponent;

// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// const RedirectComponent = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const pathname = window.location.pathname;
//     // Check if pathname contains "@" character
//     if (!pathname.includes("@")) {
//       // Check if pathname ends with ".html"
//       if (pathname.endsWith(".html")) {
//         const pathWithoutHtml = pathname.slice(0, -5);
//         navigate(pathWithoutHtml);
//       } else {
//         const pathWithoutHtml = pathname.slice(0, -5);
//         navigate(pathWithoutHtml);
//       }
//     }
//   }, [navigate]);

//   // You can render a loading message or a component if needed
//   return <div></div>;
// };

// export default RedirectComponent;

