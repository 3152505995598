import React from "react";

function TextSkeleton() {
  return (
    <div>
      <div className="w-[120px] h-[20px] bg-[#1a1919] animate-pulse"></div>
      <div className=" mt-10 w-[70%] h-[20px] bg-[#1a1919] animate-pulse max-sm:w-full max-sm:pr-4 max-lg:pr-4 max-lg:w-full max-xl:pr-4 max-xl:w-full"></div>
      <div className=" mt-4 w-[70%] h-[20px] bg-[#1a1919] animate-pulse max-sm:w-full max-sm:pr-4 max-lg:pr-4 max-lg:w-full max-xl:pr-4 max-xl:w-full"></div>
      <div className=" mt-4 w-[50%] h-[20px] bg-[#1a1919] animate-pulse max-sm:w-full max-sm:pr-4 max-lg:pr-4 max-lg:w-full max-xl:pr-4 max-xl:w-full"></div>
    </div>
  );
}

export default TextSkeleton;
