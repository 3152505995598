const MostPlayedSongSkeleton = () => {
  return (
    <div>
      <div className="flex flex-col items-start justify-center">
        <div className="lg:w-[400px] w-[1000px] h-[500px] bg-[#1a1919] animate-pulse mt-2"></div>
      </div>
    </div>
  );
};

export default MostPlayedSongSkeleton;
