import PayPerViewPop from "components/PayPerViewPop";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PayperviewService from "services/payperview/PayperviewServie";
import PaymentModal from "./videoPlayer/PaymentModal";
import { useDispatch, useSelector } from "react-redux";
import {
  countdownToTargetDate,
  formatToLocalDateString,
} from "utils/timeFunctions";
import { BsPlayCircleFill } from "react-icons/bs";
import NotificationModal from "components/NotificationModal";
import { AiOutlineArrowLeft } from "react-icons/ai";
import PayperviewCountdown from "components/skeleton/PayperviewCountdown";
import PayperviewDetail from "components/skeleton/PayperviewDetail";
import TicketVerificationModal from "components/TicketVerificationModal";
import LoginModel from "./shared/LoginModal";
import LazyLoadImg from "components/LazyLoadImg";
import WatchTrailerbutton from "components/shared/WatchTrailerbutton";
import PpvResurvebtnskeleton from "components/skeleton/PpvResurvebtnskeleton";
import { RouteName } from "constant/routeNames";
import { CiShop } from "react-icons/ci";
import { Helmet } from "react-helmet-async";
import { Notification } from "constant/notifications";
import { FaPlay } from "react-icons/fa";
import PpvVideoModal from "components/PpvVideoModal";
import { removeAllPlayer } from "redux/AudioPlayerRedux";
import Recordings from "./shared/Recordings";

interface NotificationMessage {
  heading: string;
  text: string;
  btnText: string;
}

function PayperviewDetailsPage() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [event, setEvent] = useState<any>();
  const [countdown, setCountdown] = useState<any>();
  const [purchasedEvent, setPurchasedEvent] = useState<any>([]);
  const [purchased, setPurchased] = useState<boolean>(false);
  const [hasPurchased, setHasPurchased] = useState<boolean>(false);
  const [hasPurchasedMovie, setHasPurchasedMovie] = useState<boolean>(false);
  const [hasTicketVerified, setHasTicketVerified] = useState<boolean>(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showTicketVerificationModal, setShowTicketVerificationModal] =
    useState(false);
  const [ticketToken, setTicketToken] = useState<string>("");
  const [remainingDays, setRemainingDays] = useState();
  const [notificationModals, setNotificationModals] = useState<
    NotificationMessage[]
  >([]);

  const [isLoadingCountdown, setIsLoadingCountdown] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<any>(false);
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    if (slug) {
      getEventData();
    }
  }, [slug]);

  useEffect(() => {
    if (event) {
      hasEventPurchased(event?.id);
    }
  }, [event]);

  useEffect(() => {
    if (event) {
      hasEventPurchasedMovie(event?.id);
    }
  }, [event]);


  // useEffect(() => {
  //   if (purchasedEvent.length > 0) {
  //     const isPurchased = purchasedEvent.some(
  //       (purchasedEvent: any) => purchasedEvent.id === event?.id
  //     );
  //     // console.log("Event Purchased", isPurchased);
  //     setPurchased(isPurchased);
  //   }
  // }, [purchasedEvent, event?.id, loggedInUser]);

  useEffect(() => {
    if (countdown === "ended") {
      getEventData();
    }
  }, [countdown]);

  const getEventData = async () => {
    setIsLoading(true);
    try {
      const res = await PayperviewService.getEventByEventSlug(slug);
      // console.log("recordings",res)
      setEvent(res.data);

      // console.log("event details", event);
      setIsLoadingCountdown(true);
      const targetDateStr = res.data?.event_formatted_date;
      const interval = setInterval(() => {
        const newCountdown = countdownToTargetDate(targetDateStr);
        setIsLoading(false);
        setIsLoadingCountdown(false);
        setCountdown(newCountdown);
        if (typeof newCountdown !== "object") {
          clearInterval(interval);
        }
      }, 1000);
    } catch (error) {
      console.log(error);
      // Handle error if event fetch fails
    }
  };

  useEffect(() => {
    if (loggedInUser !== null) {
      PayperviewService.getPurchasedEvents(loggedInUser?.id)
        .then((res) => {
          // console.log("event", res.data);
          const eventdata = res.data;
          setPurchasedEvent(res.data);

          if (eventdata.length > 0) {
                const isPurchased = eventdata.some(
                  (purchasedEvent: any) => purchasedEvent.id === event?.id
                );
                console.log("Event Purchased", isPurchased);
                setPurchased(isPurchased);
              }
        })
        .catch((e: any) => {
          console.log("getPurchasedEvents ", e);
        });
    }
  }, [loggedInUser, event?.id]);
  
  const handleReservedButton = () => {
    if (!loggedInUser) {
      // showNotificationModal(
      //   "Sign In to Buy",
      //   "To proceed with your purchase, log in to your account securely",
      //   "Okay"
      // );
      setShowLoginModal(true);
      return;
    }
    setShowCheckoutModal(true);
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  const closeModal = () => {
    setShowCheckoutModal(false);
  };

  const closeTicketModal = () => {
    setShowTicketVerificationModal(false);
  };

  const showNotificationModal = (
    heading: string,
    text: string,
    btnText: string
  ) => {
    setNotificationModals([
      ...notificationModals,
      {
        heading: heading,
        text: text,
        btnText: btnText,
      },
    ]);
  };

  const closeNotificationModal = (index: number) => {
    const updatedModals = [...notificationModals];
    updatedModals.splice(index, 1);
    setNotificationModals(updatedModals);
  };

  const hasEventPurchased = async (event_id: any) => {
    const response = await PayperviewService.hasPurchasedEvent(event_id);
    setHasPurchased(response?.hasPurchased);
    setTicketToken(response?.ticket_token);
    setHasTicketVerified(response?.has_ticket_verified);
  };

  const hasEventPurchasedMovie = async (event_id: any) => {
    const response = await PayperviewService.hasPurchasedMovie(event_id);
    setHasPurchasedMovie(response?.isRentalValid);
    setRemainingDays(response?.remaining_days);
  };
  // console.log(remainingDays);

  const handlePlayButton = () => {
    if (!hasTicketVerified) {
      setShowTicketVerificationModal(true);
    } else {
      navigate("/ppv/watch/event/" + slug, {
        state: { prevPage: event?.slug },
      });
    }
  };

  const handleFreePlayButton = () => {
    navigate("/ppv/watch/event/" + slug, { state: { prevPage: event?.slug } });
  };
  const handleMoviePlayButton = () => {
    navigate("/ppv/watch/event/" + slug, { state: { prevPage: event?.slug } });
  };
  const handelCategoryButton = () => {
    navigate(
      RouteName.PayPerView.replace(
        "*",
        RouteName.PayperviewDiscoverByGenreId.replace(
          ":slug",
          event?.category_slug
        )
      ),
      { state: { prevPage: event?.slug } }
    );
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  const [showTrailerModel, setShowTrailerModel] = useState(false);
  const handleWatchTrailer = async () => {
    dispatch(removeAllPlayer());
    setShowTrailerModel(true);
  };

  const closeTrailerModel = () => {
    setShowTrailerModel(false);
  };

  // console.log("Event Details Final", event);
  // console.log("has purchased", hasPurchased);
  return (
    <>
      <Helmet>
        <title>
        {event ? (event?.seo_title ? event?.seo_title : (event?.title ? `${event?.title} - AYOZAT` : "AYOZAT Pay-Per-View")) : "AYOZAT Pay-Per-View"}
        </title>
        <meta name="description" content={event?.meta_description} />
        <link rel="canonical" href={`${FE_URL}/ppv/details/${event?.slug}`} />
        <meta name="keywords" content={event?.focus_keyword} />
        <meta property="og:title" content={event?.seo_title} />
        <meta property="og:description" content={event?.meta_description} />
        {/* <meta property="og:image:secure_url" content="https://ayozat.co.uk/logo192.png" /> */}
        <meta property="og:image" content={event?.bannerImage} />
        <meta
          property="og:url"
          content={`${FE_URL}/ppv/details/${event?.slug}`}
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={event?.seo_title} />
        <meta name="twitter:description" content={event?.meta_description} />
        <meta name="twitter:image" content={event?.bannerImage} />
        <meta name="twitter:site" content="AYOZAT Payperview" />
        <meta
          name="twitter:url"
          content={`${FE_URL}/ppv/details/${event?.slug}`}
        />
      </Helmet>
      <div className="relative flex w-full h-auto min-h-[900px] rounded-lg white-text max-sm:w-full object-cover overflow-hidden max-sm:h-auto ">
        <div
          className="absolute inset-0 bg-center bg-no-repeat bg-cover"
          style={{
            backgroundImage: `url(${event?.bannerImage})`,
            zIndex: -1,
          }}
        ></div>

        <div className=" w-[35%] payperviewhero-back   scrollbar-hide max-sm:w-full rounded-lg max-lg:w-[60%]  pb-10 ">
          <div>
            <button
              className="w-10 h-10 mt-5 ml-5 bg-black rounded-full max-sm:ml-[15px] hover:bg-white hover:text-black "
              onClick={() => {
                navigate(RouteName.PayPerView.replace("/*", ""));
              }}
            >
              <AiOutlineArrowLeft className="ml-3 " />
            </button>
          </div>

          <div className="mt-10 ml-5 text-left cursor-default font-rubik max-sm:pr-3 max-sm:ml-3">
            {" "}
            {!isLoading && (
              <>
                <h1 className="text-[32px] text-white leading-none font-bold ">
                  {event?.title
                    .replace(/&amp;/g, "&")
                    .replace(/&rsquo;/g, "’")
                    .replace(/&nbsp;/g, "")}
                </h1>
                <div>
                  <p className="text-[15px] text-[#7B9DB4] w-[630px] flex items-center gap-2 my-2 max-sm:w-[80%] max-sm:my-2 max-sm:ml-1">
                    {event?.thumbnailImage && (
                      <LazyLoadImg
                        imgSrc={event?.host_logo}
                        imgAlt={"host"}
                        imgHeight={"40px"}
                        imgWidth={"40px"}
                        className="album-page-image"
                        style={{
                          borderRadius: "100%",
                          backgroundSize: "cover",
                        }}
                      />
                    )}
                    by{" "}
                    {event?.host_name
                      .replace(/&amp;/g, "&")
                      .replace(/&rsquo;/g, "’")
                      .replace(/&nbsp;/g, "")}
                  </p>
                </div>

                <div className="flex items-center gap-2">
                  <div onClick={handelCategoryButton}>
                    <h2 className="text-[10px] mt-5 text-center w-full font-normal cursor-pointer text-white px-2 py-1 bg-slate-600">
                      {event?.category}
                    </h2>
                  </div>
                  {parseFloat(event?.price) === 0 && (
                    <h2 className="text-[10px] mt-5 text-center text-white px-2 py-1 bg-sky-400 font-bold">
                      Free content
                    </h2>
                  )}
                </div>
                {event?.duration && (
                  <div>
                    <h2 className="text-[13px] my-2  w-[40%] font-semibold text-white ">
                      Duration: {event?.duration}
                    </h2>
                  </div>
                )}
                <div>
                  <h2 className="text-[13px] my-4  w-[90%] font-normal text-white ">
                    {event?.description
                      .replace(/&amp;/g, "&")
                      .replace(/&rsquo;/g, "’")
                      .replace(/&nbsp;/g, "")}
                  </h2>
                </div>
                <div>
                  {event?.rentable ? (
                    <>
                      <h2 className="text-[13px] my-4  w-[90%] text-center px-2 py-1 font-bold text-white bg-gray-700 rounded max-sm:w-[98%] ">
                        Available for Rent: {event?.rent_duration}
                        {event?.rent_duration > 1 ? <> Days</> : <> Day</>}
                      </h2>
                    </>
                  ) : (
                    <>
                      <h2 className="text-[13px] my-4  w-[90%] font-bold text-white ">
                        {formatToLocalDateString(event?.event_formatted_date)}
                        {event?.streaming_status === "streaming" &&
                          countdown === "ended" && (
                            <span className="px-2 py-1 ml-2 text-xs text-white rounded bg-sky-400">
                              Streaming
                            </span>
                          )}
                        {event?.streaming_status === "ended" && (
                          <span className="px-2 py-1 ml-2 text-xs text-white bg-red-400 rounded">
                            Ended
                          </span>
                        )}
                      </h2>
                    </>
                  )}
                </div>
                {event?.trailer_url && (
                  <div className="flex mb-4">
                    {/* <WatchTrailerbutton
                      video={event?.trailer_url}
                      video_title={event?.title}
                    /> */}
                    <button
                      className={`flex items-center text-xs max-sm:justify-center w-auto py-3 px-5 mb-3 text-center text-white rounded-full button-red max-sm:py-3  max-sm:text-[15px] max-sm:w-full max-sm:mx-auto `}
                      onClick={handleWatchTrailer}
                    >
                      <FaPlay className="mr-2" /> Watch trailer
                    </button>
                  </div>
                )}
              </>
            )}
            {isLoading && <PayperviewDetail />}
            {event?.rentable ? (
              <>
                {loggedInUser && purchased && (
                  <>
                    {hasPurchasedMovie ? (
                      <div className="w-[90%] px-5 py-6 mb-8 text-gray-400 bg-gray-700 rounded max-sm:w-[98%]">
                        <h3 className="mb-3 text-xl font-bold text-gray-200">
                          You Can Watch Now!
                        </h3>
                        <span className="text-base">
                          The event is now live! Click the play button below to
                          enjoy the live broadcast.
                        </span>
                        <div>
                          {/* {ticketToken && ( */}
                          <div className="mt-4">
                            {/* <p className="text-sm">Movie Rental Expires in </p> */}
                            <div className="flex items-center gap-2">
                              {remainingDays !== undefined && (
                                <>
                                  {remainingDays !== 0 ? (
                                    <>
                                      <h4 className="mb-0 text-xl font-bold text-gray-100">
                                        Movie Rental Expires in {remainingDays}
                                        {remainingDays > 1 ? (
                                          <> Days</>
                                        ) : (
                                          <> Day</>
                                        )}
                                      </h4>
                                    </>
                                  ) : (
                                    <>
                                      <h4 className="mb-0 text-xl font-bold text-gray-100">
                                        Movie Rental Expires Today
                                      </h4>
                                    </>
                                  )}
                                </>
                              )}

                              {/* {hasTicketVerified ? (
                              <span className="px-2 py-1 text-xs text-white rounded bg-sky-400">
                                Verified
                              </span>
                            ) : (
                              ""
                            )} */}
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                    ) : (
                      <div className="w-[90%] px-5 py-6 mb-8 text-gray-400 bg-gray-700 rounded max-sm:w-[98%]">
                        <h3 className="mb-3 text-xl font-bold text-gray-200">
                          Movie Expired!
                        </h3>
                        {/* <span className="text-base">
                  The event is now live! Click the play button below to
                  enjoy the live broadcast.
                </span> */}
                        <div>
                          {/* {ticketToken && ( */}
                          <div className="mt-4">
                            {/* <p className="text-sm">Movie Rental Expires in </p> */}
                            <div className="flex items-center gap-2">
                              {/* <h4 className="mb-0 text-xl font-bold text-gray-100">
                        Movie Rental Expires in {remainingDays} Days
                      </h4> */}
                              {/* {hasTicketVerified ? (
                          <span className="px-2 py-1 text-xs text-white rounded bg-sky-400">
                            Verified
                          </span>
                        ) : (
                          ""
                        )} */}
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {loggedInUser && hasPurchased && (
                  <div>
                    {event?.streaming_status === "streaming" &&
                      countdown === "ended" && (
                        <div className="w-[90%] px-5 py-6 mb-8 text-gray-400 bg-gray-700 rounded max-sm:w-[98%]">
                          <h3 className="mb-3 text-xl font-bold text-gray-200">
                            Streaming Now!
                          </h3>
                          <span className="text-base">
                            The event is live now! Click the play button below
                            to enjoy the live broadcast.
                          </span>
                          <div>
                            {ticketToken && (
                              <div className="mt-4">
                                <p className="text-sm">Your Ticket </p>
                                <div className="flex items-center gap-2">
                                  <h4 className="mb-0 text-xl font-bold text-gray-100">
                                    {ticketToken}
                                  </h4>
                                  {hasTicketVerified ? (
                                    <span className="px-2 py-1 text-xs text-white rounded bg-sky-400">
                                      Verified
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    {event?.streaming_status === "not started" &&
                      countdown !== "ended" && (
                        <div className="w-[90%] px-5 py-6 mb-8 text-gray-400 bg-gray-700 rounded max-sm:w-[98%]">
                          <h3 className="mb-3 text-xl font-bold text-gray-200">
                            Get Ready for the Event!
                          </h3>
                          <span className="text-base">
                            The event has not started streaming yet. Please Stay
                            tuned for exclusive coverage.
                          </span>
                        </div>
                      )}
                  </div>
                )}
                <div>
                  {event?.streaming_status === "streaming" &&
                    countdown === "ended" &&
                    parseFloat(event?.price) === 0 && (
                      <div className="w-[90%] px-5 py-6 mb-8 text-gray-400 bg-gray-700 rounded max-sm:w-[98%]">
                        <h3 className="mb-3 text-xl font-bold text-gray-200">
                          Streaming Now!
                        </h3>
                        {/* <span className="text-base">
                          The event is live now! Click the play button below to
                          enjoy the live broadcast.
                        </span> */}
                        {loggedInUser ? (
                          <>
                            <span className="text-base">
                              The event is live now! Click the play button below
                              to enjoy the live broadcast.
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="text-base">
                              The event is live now! Kindly login to the system
                              and Click the play button below to enjoy the live
                              broadcast.
                            </span>
                          </>
                        )}
                      </div>
                    )}
                  {event?.streaming_status === "not started" &&
                    countdown !== "ended" &&
                    parseFloat(event?.price) === 0 && (
                      <div className="w-[90%] px-5 py-6 mb-8 text-gray-400 bg-gray-700 rounded max-sm:w-[98%]">
                        <h3 className="mb-3 text-xl font-bold text-gray-200">
                          Get Ready for the Event!
                        </h3>
                        <span className="text-base">
                          The event has not started streaming yet. Please Stay
                          tuned for exclusive coverage.
                        </span>
                      </div>
                    )}
                </div>
                {event?.streaming_status === "ended" && (
                  <div>
                    <div className="w-[90%] px-5 py-6 mb-8 text-gray-400 bg-gray-700 rounded max-sm:w-[98%]">
                      <h3 className="mb-3 text-xl font-bold text-gray-200">
                        Event Streaming Ended
                      </h3>
                      <span className="text-base max-sm:w-full">
                        The live streaming for this event has concluded. Stay
                        tuned for future events and updates. If you missed the
                        live broadcast, check back later for on-demand content.
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
            {!event?.rentable && (
              <>
                {!isLoadingCountdown && (
                  <div className="flex flex-col justify-between w-[90%] max-sm:w-full max-lg:w-[90%]  overflow-hidden ">
                    {!isLoadingCountdown &&
                      countdown &&
                      countdown !== "ended" &&
                      event?.streaming_status !== "ended" && (
                        <>
                          <div
                            className="bg-black/30 border-[1px] border-white/50 rounded-lg
                          px-5 py-3 mb-5"
                          >
                            <p className="mb-3 text-base font-semibold text-center">
                              Event Starts in{" "}
                            </p>
                            <hr className="w-[120%] mx-auto my-3 ml-[-20px] opacity-[50%]" />
                            <div className="flex justify-between ">
                              <div className="flex-col text-center">
                                <h2 className="text-[12px] text-gray-300 ">
                                  Days
                                </h2>{" "}
                                <h2 className="text-[40px] font-extrabold text-red-600">
                                  {countdown.days?.toString().padStart(2, "0")}
                                </h2>
                              </div>
                              <div className="flex-col text-center">
                                <h2 className="text-[12px] text-gray-300 ">
                                  Hours
                                </h2>{" "}
                                <h2 className="text-[40px] font-extrabold text-red-600">
                                  {countdown.hours?.toString().padStart(2, "0")}
                                </h2>
                              </div>
                              <div className="flex-col text-center">
                                <h2 className="text-[12px] text-gray-300 ">
                                  Minutes
                                </h2>{" "}
                                <h2 className="text-[40px] font-extrabold text-red-600">
                                  {countdown.minutes
                                    ?.toString()
                                    .padStart(2, "0")}
                                </h2>
                              </div>
                              <div className="flex-col text-center">
                                <h2 className="text-[12px] text-gray-300 ">
                                  Seconds
                                </h2>{" "}
                                <h2 className="text-[40px] font-extrabold text-red-600">
                                  {countdown.seconds
                                    ?.toString()
                                    .padStart(2, "0")}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                )}
                {countdown && isLoadingCountdown && <PayperviewCountdown />}
              </>
            )}
            <div>
              {event?.rentable ? (
                <>
                  {loggedInUser &&
                    hasPurchasedMovie &&
                    event?.allowed_to_watch && (
                      <>
                        {/* {loggedInUser && ( */}
                        {/* <button
                          className="cursor-pointer"
                          onClick={handleMoviePlayButton}
                        >
                          <div className="flex items-center justify-center ">
                            <BsPlayCircleFill className="ml-2 drop-shadow-2xl text-[80px] hover:scale-110 hover:text-red-600 text-white duration-300 max-sm:text-[50px]" />
                            <p className="mx-10 uppercase">Watch Movie</p>
                          </div>
                        </button> */}
                        <button
                          className="flex justify-start px-2 py-2 bg-red-600 rounded-full max-sm:w-[60%] items-center"
                          onClick={handleMoviePlayButton}
                        >
                          <BsPlayCircleFill size={50} className="" />
                          <h2 className="text-[18px] text-white my-auto mx-4">
                            Watch Now
                          </h2>
                        </button>

                        {/* )} */}
                      </>
                    )}
                </>
              ) : (
                <>
                  <div>
                    {loggedInUser &&
                      event?.streaming_status === "streaming" &&
                      hasPurchased &&
                      event?.allowed_to_watch && (
                        // <button
                        //   className="cursor-pointer"
                        //   onClick={handlePlayButton}
                        // >
                        //   <div className="flex items-center justify-center ">
                        //     <BsPlayCircleFill className="ml-2 drop-shadow-2xl text-[80px] hover:scale-110 hover:text-red-600 text-white duration-300 max-sm:text-[50px]" />
                        //     <p className="mx-10 uppercase">Watch Live</p>
                        //   </div>
                        // </button>
                        <button
                          className="flex justify-start px-2 py-2 bg-red-600 rounded-full max-sm:w-[60%] items-center"
                          onClick={handlePlayButton}
                        >
                          <BsPlayCircleFill size={50} className="" />
                          <h2 className="text-[18px] text-white my-auto mx-4">
                            Watch Now
                          </h2>
                        </button>
                      )}
                  </div>
                  <div>
                    {loggedInUser &&
                      event?.allowed_to_watch &&
                      event?.streaming_status === "streaming" &&
                      parseFloat(event?.price) === 0 && (
                        // <button
                        //   className="cursor-pointer"
                        //   onClick={handleFreePlayButton}
                        // >
                        //   <div className="w-[8%]">
                        //     <BsPlayCircleFill className="ml-2 drop-shadow-2xl text-[80px] hover:scale-110 hover:text-red-600 text-white duration-300 max-sm:text-[50px]" />
                        //   </div>
                        // </button>
                        <button
                          className="flex justify-start px-2 py-2 bg-red-600 rounded-full max-sm:w-[60%] items-center"
                          onClick={handleFreePlayButton}
                        >
                          <BsPlayCircleFill size={50} className="" />
                          <h2 className="text-[18px] text-white my-auto mx-4">
                            Watch Now
                          </h2>
                        </button>
                      )}
                  </div>
                </>
              )}
              {event?.rentable ? (
                <>
                  {event?.allowed_to_watch ? (
                    <div className="mt-5">
                      {!purchased &&
                        !isLoading &&
                        // hasPurchasedMovie === false || null &&
                        parseFloat(event?.price) !== 0 && (
                          <button
                            className="flex justify-center px-5 py-2 bg-red-600 rounded-full max-sm:w-full max-lg:w-[90%] items-center"
                            onClick={handleReservedButton}
                          >
                            <CiShop size={25} />
                            <h2 className="text-[15px] text-white my-auto mx-3">
                              Rent Movie{" "}
                            </h2>{" "}
                            <h2 className="text-[30px] text-white font-extrabold">
                              {"£"}
                              {event?.price}
                            </h2>
                          </button>
                        )}

                      {isLoading && <PpvResurvebtnskeleton />}
                    </div>
                  ) : (
                    <>
                      {!isLoading && (
                        <p className="p-4 text-white border-2 border-white border-solid w-[90%] max-sm:w-[98%]">
                          {Notification.geoLocationNotAllowedText}
                        </p>
                      )}
                      {isLoading && <PpvResurvebtnskeleton />}
                    </>
                  )}
                </>
              ) : (
                <>
                  {event?.allowed_to_watch ? (
                    <div className="mt-5">
                      {!isLoading &&
                        !purchased &&
                        event?.streaming_status !== "ended" &&
                        parseFloat(event?.price) !== 0 && (
                          <button
                            className="flex justify-center px-5 py-2 bg-red-600 rounded-full max-sm:w-full max-lg:w-[90%] items-center"
                            onClick={handleReservedButton}
                          >
                            <CiShop size={25} />
                            <h2 className="text-[15px] text-white my-auto mx-3">
                              Reserve Now{" "}
                            </h2>{" "}
                            <h2 className="text-[30px] text-white font-extrabold">
                              {"£"}
                              {event?.price}
                            </h2>
                          </button>
                        )}
                      {isLoading && <PpvResurvebtnskeleton />}
                    </div>
                  ) : (
                    <>
                      {!isLoading && (
                        <p className="p-4 text-white border-2 border-white border-solid w-[90%] max-sm:w-[98%]">
                          {Notification.geoLocationNotAllowedText}
                        </p>
                      )}
                      {isLoading && <PpvResurvebtnskeleton />}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {event?.isReplay === 1 && event?.replay_videos?.length >= 1 && (
            <Recordings
              loggedInUser={loggedInUser}
              hasPurchased={hasPurchased}
              event={event}
              isReplay={event?.isReplay}
            />
          )}
        </div>

        {/* Render notification modals */}
        {notificationModals.map((modalMessage, index) => (
          <NotificationModal
            key={index}
            isOpen={true}
            onClose={() => closeNotificationModal(index)}
            message={modalMessage}
          />
        ))}

        <TicketVerificationModal
          isOpen={showTicketVerificationModal}
          onClose={closeTicketModal}
          ppv_event={event}
        />

        <PayPerViewPop isOpen={showCheckoutModal} onClose={closeModal}>
          <>
            <PaymentModal ppv_event={event} />
          </>
        </PayPerViewPop>

        {showLoginModal ? (
          <LoginModel
            clickLogin={closeLoginModal}
            setShowLoginModal={setShowLoginModal}
          />
        ) : (
          <></>
        )}
        {showTrailerModel ? (
          <PpvVideoModal
            isOpen={true}
            onClose={closeTrailerModel}
            video_URL={event?.trailer_url}
            title={event?.title}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default PayperviewDetailsPage;
