import React, { useEffect, useState } from "react";
import NoSearch from "./shared/NoSearch";
import CreatorCard from "pages/videoPage/shared/CreatorCard";

function SearchCreators({ data, user }: any) {
  const creators = data?.creators || [];

  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
        <div>
          <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl">
            {/* search podcast */}
            Creators
          </h1>
        </div>
      </div>
      {/* card section */}
      {creators?.length === 0 && creators ? (
        <NoSearch text="Creators" />
      ) : (
        <div>
          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {creators?.length > 0 &&
              creators?.map((creator: any, i: any) => (
                <div key={i}>
                  <CreatorCard creator={creator} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchCreators;
