import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { BsPlayCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addMusicToPlayer } from "redux/AudioPlayerRedux";
import { shrinkText } from "utils/helperFuncs";
import AddSongToQueue from "./AddSongToQueue";
import LazyLoadImg from "components/LazyLoadImg";
import AddSongToFav from "./AddSongToFav";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const MusicCard = ({ song }: any) => {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const dispatch = useDispatch<any>();
  // console.log(song);
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  // const [favorite, setFavorite] = useState<any>();
  // const [isLoading, setIsLoding] = useState<any>(false);

  const popupTrigger = () => {
    setActive((val: boolean) => !val);
  };

  const handleMusicPlayer = async () => {
    dispatch(addMusicToPlayer(song));
  };

  return (
    // <Link to={"/music/album/sdsdf"}>
    <div
      className="mx-auto inline-block w-[176px] h-[270px]  px-2 py-2 rounded-md shadow-lg cursor-pointer duration-300 ease-in-out hover:text-slate-300 text-white hover:bg-slate-800 hover:shadow-xl place-items-center shadow-lg"
      // onClick={handleMusicPlayer}
    >
      {/* image */}
      {/* <div className=" w-full h-[171px] items-center justify-center relative">
        <img
          src={song.artwork_url}
          alt="Song Art Work"
          className="w-[171px] h-full  object-cover overflow-hidden rounded-md"
        />
      </div> */}

      <div className="relative w-[100%] h-[176px] rounded-lg  bg-img white-text max-sm:w-[98%] max-sm:h-[180px]">
        <LazyLoadImg
          imgSrc={song?.artwork_url}
          imgAlt={song?.title}
          imgHeight={"176px"}
          imgWidth={"158px"}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            borderRadius: "5px",
          }}
        />
        {/* centerlize play icon */}
        <div className="flex items-center justify-center h-[165px]">
          <button
            className="absolute top-[50px] items-center p-1 rounded-full add-button hover:shadow-2xl"
            onClick={handleMusicPlayer}
          >
            <BsPlayCircle className="w-12 h-12 my-auto duration-300 ease-in-out text-md action-icon-size hover:text-red-600" />
          </button>
        </div>

        {/* card inside content */}
        <div className="absolute inset-x-0 bottom-0 flex justify-between p-2">
          <div className="items-center p-1 rounded-full add-button">
            <AddSongToQueue song={song} toolTiId={`new-released-card`} />
            {/* <button
              className="items-center p-1 rounded-full add-button"
              onClick={addToQueueSong}
              // data-tooltip-id={`song-${song.id}`}
            >
              <AddSongToQueue song={song} />
            </button> */}
          </div>
          {loggedInUser && (
            <div
              className="items-center p-1 rounded-full add-button"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <AddSongToFav song={song} musicCard={true} hover={hover} />
            </div>
          )}
          {/* <div>
            <button
              className="items-center p-1 rounded-full add-button"
              onClick={handleMusicPlayer}
            >
              <BsPlayCircle className="text-md action-icon-size " />
            </button>
          </div> */}
        </div>
      </div>
      <div className="relative top-1">
        <div className="my-[4px]">
          <Link to={RouteName.MusicTrackRoute.replace(":id", song.id)}>
            <p
              className="text-[14px] leading-tight font-[600]"
              data-tooltip-id={`song-${song.id}`}
            >
              {/* {shrinkText(song?.title.replace(/_/g, " "), 28)}
               */}
              {song?.title.length > 28
                ? shrinkText(song?.title.replace(/_/g, " "), 28)
                : song?.title}
            </p>

            {song.title.length > 28 && (
              <div className="text-sm">
                <ReactTooltip
                  id={`song-${song.id}`}
                  place="top"
                  content={song.title}
                />
              </div>
            )}
          </Link>
          <Link
            to={RouteName.MusicArtistPage.replace(
              ":slug",
              song?.artists[0]?.slug
            )}
          >
            <p className="text-[11px] font-[400]  hover:text-zinc-100 mt-[5px] hover:underline">
              {song?.artists[0]?.name}
            </p>{" "}
          </Link>
          {/* 
            <p className="absolute text-4xl text-white duration-200 ease-in-out bottom-3 right-2 hover:text-red-600">
              <IoAddCircleOutline className="w-6 h-6" />
            </p> */}
        </div>
      </div>

      {active ? (
        <>
          <div
            className="absolute w-[400px] h-[400px] bg-red-800 top-0"
            onClick={popupTrigger}
          ></div>
        </>
      ) : (
        <></>
      )}
    </div>
    // </Link>
  );
};
