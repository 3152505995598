import SubText from "components/shared/SubText";
import React, { useEffect, useState } from "react";
import AlbumCardCoursel from "../artist/AlbumCardCoursel";
import ArtistService from "services/music/ArtistService";
import MusicCardCoursel from "../home/MusicCardCoursel";
import { useSelector } from "react-redux";
import AlbumCarouselSkeleton from "components/skeleton/AlbumCarouselSkeleton";
import MusicCardCourselLong from "./MusicCardCarousalLong";

function ArtistSuggestions({ artist, trackId }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [artistData, setArtistData] = useState<any | null>({});
  const [artistSongs, setArtistSongs] = useState<any | null>({});
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    if (artist) {
      ArtistService.getArtistBySlug(artist?.slug).then((res) => {
        setArtistData(res.artist);
        setIsLoading(false);
        const filteredSongs = res?.artist?.songs?.data.filter(
          (song: any) => song.id !== trackId
        );
        setArtistSongs(filteredSongs);
      });
    }
  }, [artist, trackId]);
  return (
    <div>
      {artistData?.album_count > 0 && (
        <div className="my-5 mt-10">
          <SubText text={`Albums by ${artist?.name}`} className="font-light" />
          <AlbumCardCoursel artistData={artistData} />
        </div>
      )}
      {isLoading && <AlbumCarouselSkeleton />}

      {artistSongs?.length > 0 && (
        <div className="my-5 mt-10">
          <SubText
            text={`More Tracks by ${artist?.name}`}
            className="mb-10 font-light"
          />
          <MusicCardCourselLong audios={artistSongs} user={loggedInUser} />
        </div>
      )}
      {isLoading && <AlbumCarouselSkeleton />}
    </div>
  );
}

export default ArtistSuggestions;
