import { Notification } from "constant/notifications";
import { FaPlay } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { playFromTopAudios, setMusicPlaylistId } from "redux/AudioPlayerRedux";
import {
  triggerSuccessNotification,
  triggerWarningNotification,
} from "utils/notification";

function PlayButton({ songs, playListId }: any) {
  // console.log(playListId);
  const dispatch = useDispatch<any>();

  const handlePlayFromTop = async () => {
    if (songs.length > 0) {
      dispatch(playFromTopAudios(songs));
      dispatch(setMusicPlaylistId(playListId));
      triggerSuccessNotification(Notification.SongsAddedToQueue);
    } else {
      triggerWarningNotification(Notification.NoSongs);
    }
  };
  return (
    <button
      className={`flex items-center max-sm:justify-center w-auto p-3  text-center text-white rounded-full button-red max-sm:py-3  max-sm:text-[15px] max-sm:w-full max-sm:mx-auto `}
      onClick={handlePlayFromTop}
    >
      <FaPlay className="mr-3" /> Play from top
    </button>
  );
}

export default PlayButton;
