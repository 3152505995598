import React from "react";
import LiveProgrammeCoursel from "./shared/LiveProgrammeCoursel";
import { programmes } from "constant/programmes";
import Categories from "./shared/Categories";
import { GenreData } from "constant/genre";
import MightLikeCoursel from "./shared/MightLikeCoursel";
import { mostLike } from "constant/like";
import { stations } from "constant/stations";
import StationMightLikeCoursel from "./discover/StationMightLikeCoursel";

function RadioPageDiscover() {
  return (
    <div>
      {/* main topic section */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white lg:text-2xl md:text-2xl">
          Discover new radio channels and programs
        </h1>
      </div>
      <StationMightLikeCoursel stations={stations} />
      <LiveProgrammeCoursel liveProgrammes={programmes} />
      <Categories />
      <MightLikeCoursel mostLike={mostLike} />
    </div>
  );
}

export default RadioPageDiscover;
