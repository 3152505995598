import { FaApple, FaFacebookF } from "react-icons/fa";
import { HiOutlineX } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

//import logo
import Logo from "Assets/Images/a_logo.png";
import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { loginStart, loginSuccess, loginFailure } from "redux/userRedux";
import { toast } from "react-toastify";
import AuthService from "services/auth/AuthService";

function LoginModel({ clickLogin }: any) {
  let navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const submit = async (e: any) => {
    e.preventDefault();

    dispatch(loginStart());

    await AuthService.login(formData)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(loginSuccess(response.data.data.user));
          navigate(RouteName.Welcome);
          toast.success("Login Completed");
        } else {
          toast.error("There is an issue when attempting to log in");
          dispatch(loginFailure);
        }
      })
      .catch((e: any) => {
        toast.error("An error occurred. Please try again.");
        dispatch(loginFailure);
      });
  };
  return (
    // login model in main navbar
    <div className="fixed top-0 flex items-center w-full ease-in-out bg-slate-600 login_modal">
      <div className="navbar w-[500px] mx-auto my-10 p-6 rounded text-center  h-[730px] overflow-hidden">
        {/* close button in right side */}
        <div className="flex justify-end">
          <HiOutlineX
            className="text-lg font-bold cursor-pointer white-text"
            onClick={clickLogin}
          />
        </div>

        {/* image section with multiple circles */}
        <div className="relative -top-[150px] red-circle-1 rounded-full w-[400px] h-[400px] mx-auto mb-10 flex items-center">
          <div className="red-circle-2 rounded-full w-[300px] h-[300px] mx-auto flex items-center shadow-lg">
            <div className="red-circle-3 rounded-full w-[200px] h-[200px] mx-auto flex items-center shadow-lg">
              <div className="bg-white rounded-full w-[100px] h-[100px] mx-auto flex items-center">
                <img src={Logo} alt="logo" className="w-16 h-16 mx-auto mb-2" />
              </div>
            </div>
          </div>
        </div>
        {/* main topic */}
        <div className="relative -top-[300px]">
          <h1 className="mb-2 text-4xl font-bold white-text sm:text-4xl">
            Welcome back!
          </h1>
          <p className="mb-4 tracking-wide gray-text text-md">
            Log in and catch up with your favorite things
          </p>

          {/* main form */}
          <form onSubmit={submit}>
            <input
              type="email"
              name="email"
              autoComplete="email"
              placeholder="Email"
              onChange={onChange}
              className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] mb-4 text-gray-600"
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={onChange}
              className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] mb-4 text-gray-600"
            />

            <button className="w-full py-4 mb-4 font-bold rounded-full text-md white-text button-red">
              Log in
            </button>
          </form>

          <div className="grid grid-cols-3 w-[80%] mx-auto mb-3">
            <hr className="mt-3" />
            <p className="gray-text text-md">Or sign in with</p>
            <hr className="mt-3" />
          </div>

          {/* rest of the buttos */}
          <div className="flex ">
            <button className="flex items-center justify-center w-full py-2 mb-4 mr-1 font-bold rounded-full text-md white-text button-red facebook-button">
              <FaFacebookF className="mr-1 text-xl " /> Facebook
            </button>
            <button className="flex items-center justify-center w-full py-2 mb-4 ml-1 font-bold rounded-full text-md white-text button-red apple-button">
              <FaApple className="mr-1 text-3xl" />
              Apple
            </button>
          </div>
          <button className="flex items-center justify-center w-full py-2 mb-4 font-bold border-2 border-white rounded-full text-md white-text ">
            <img
              src="https://img.icons8.com/fluency/48/000000/google-logo.png"
              alt="google"
              className="w-8 h-8 mr-2"
            />
            Google
          </button>
          <p className="text-sm gray-text">
            Not a member yet?{" "}
            <Link to={RouteName.Register}>
              <span className="cursor-pointer red-text">Create Account</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginModel;
