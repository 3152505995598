import React from "react";
import { FiShare2 } from "react-icons/fi";

function UserProfileShareBtn({ openModal }: any) {
  return (
    <>
      <button
        className={`flex items-center min-w-[128px] h-[40px] p-4 text-center border-solid border-2 border-white text-white rounded-full max-sm:p-2 `}
        onClick={openModal}
      >
        <div className="flex items-center justify-center w-full gap-2 mx-auto">
          <span>
            <FiShare2 />
          </span>{" "}
          Share
        </div>
      </button>
      ;
    </>
  );
}

export default UserProfileShareBtn;
