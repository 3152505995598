import React from "react";

const PpvCheckoutImage = () => {
  return (
    <>
      <div className="flex flex-col gap-2 mt-[60px] mb-[10px]">
        <div className="w-[30%] h-[15px] bg-[#1a1919] animate-pulse"></div>
        <div className="w-[100%] h-[40px] bg-[#1a1919] animate-pulse"></div>

        <div className="w-[30%] mt-3 h-[15px] bg-[#1a1919] animate-pulse"></div>
        <div className="flex justify-between">
          <div className="w-[45%] h-[40px] bg-[#1a1919] animate-pulse"></div>
          <div className="w-[45%] h-[40px] bg-[#1a1919] animate-pulse"></div>
        </div>

        <div className="w-[30%] mt-3 h-[15px] bg-[#1a1919] animate-pulse"></div>
        <div className="w-[100%] h-[40px] bg-[#1a1919] animate-pulse"></div>
        <div className="w-[30%] h-[35px] mt-3 bg-[#1a1919] animate-pulse"></div>
      </div>
    </>
  );
};

export default PpvCheckoutImage;
