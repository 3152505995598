import PodcastHeroCard from "./PodcastHeroCard";
import PodcastGridSection from "./PodcastGridSection";
import { useEffect, useState } from "react";
import PodcastService from "services/podcast/PodcastService";
import { useSelector } from "react-redux";
import PodcastCategoriesSkeleton from "components/skeleton/PodcastCategoriesSkeleton";
import TrendingPodcastSkeleton from "components/skeleton/TrendingPodcastSkeleton";

function PodcastHeroSection({ latest }: any) {
  const [categories, setCategories] = useState<any>([]);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [isLoading, setIsLoading] = useState<any>(false);

  // useEffect(() => {
  //   PodcastService.getAllCategories().then((res) => {
  //     setPodcasts(res);
  //     console.log(res);
  //   });
  // }, []);

  
  useEffect(() => {
    setIsLoading(true);
    PodcastService.getAllCategories().then((res) => {
      setIsLoading(false);
      setCategories(res.data);
      // console.log(res);
    });
  }, []);

  // const podcast = [
  //   {
  //     title: "Highest rated this week",
  //     subTitle: "All Ears English Podcast",
  //     image: "https://ichef.bbci.co.uk/images/ic/1200x675/p0c6pgfc.jpg",
  //     time: "1:30 pm - 3:30 pm",
  //     name: "AEE 1786: What's Your State of Mind?",
  //     description:
  //       "Are you looking for a new and fun way to learn American Eng...",
  //   },
  // ];
  return (
    <>
      {!isLoading && (
        <div className="flex justify-between mr-5 max-sm:mr-0 max-sm:block max-sm:w-full max-md:block max-md:w-full max-lg:block max-lg:w-full">
          <div className="w-[600px] max-sm:w-full max-md:w-full max-lg:w-full sm:mr-[50px]">
            {latest && (
              <PodcastHeroCard latestPodcast={latest} isLoading={isLoading} />
            )}
          </div>
          <div className="w-[65%] flex justify-between max-sm:w-full max-md:w-full max-lg:w-full ">
            {categories && (
              <PodcastGridSection categories={categories} user={loggedInUser} />
            )}
          </div>
        </div>
      )}
      {isLoading && <TrendingPodcastSkeleton />}
    </>
  );
}

export default PodcastHeroSection;
