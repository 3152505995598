import React from "react";

function DiscoverRadioSkeleton() {
  //   console.log("down", channels);
  const arr = [1, 2, 3, 4, 5, 6];
  return (
    <div>
      <div className="w-[400px] h-[20px] bg-[#1a1919] mt-12"></div>
      <div className={"flex flex-row justify-between w-[85vw] mt-8 mr-12"}>
        {arr.map((item: any) => (
          <div key={item} className="flex flex-col items-start justify-center">
            <div className="w-[160px] h-[200px] bg-[#1a1919] animate-pulse mt-2"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DiscoverRadioSkeleton;
