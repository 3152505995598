import { useEffect, useState } from "react";
import { useParams } from "react-router";
import RadioService from "services/radio/RadioService";
import RadioDetails from "./RadioDescriptionPage/RadioDetails";
import { FaPlay } from "react-icons/fa";
import { addStationToRadio } from "redux/AudioPlayerRedux";
import { useDispatch, useSelector } from "react-redux";
import AddRadioToFav from "./shared/AddRadioToFav";
import LikeService from "services/music/LikeService";
import RadioCard from "./shared/RadioCard";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import DescriptionSkeletion from "./skeleton/DescriptionSkeletion";
import { Helmet } from "react-helmet-async";

function RadioDescriptionPage() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const { slug } = useParams();
  const dispatch = useDispatch<any>();
  const [radio, setRadio] = useState<any>();
  const [radioCategory, setRadioCategory] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [randomStations, setRandomStations] = useState([]);
  const [stationCount, setStationCount] = useState<number>(0);
  const [favorite, setFavorite] = useState<any>();
  const [gridCount, setGridCount] = useState<any>();

  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    setGridCount(Math.floor(window.innerWidth / 180));
    console.log(Math.floor(window.innerWidth / 180));
  }, []);

  // get radio
  useEffect(() => {
    RadioService.getRadioBySlug(slug)
      .then((res) => {
        // console.log(res.data);
        setRadio(res.data);
        setRadioCategory(res?.data?.category[0]);
      })
      .catch((error) => {});
  }, [slug]);

  // get similar stations
  useEffect(() => {
    setIsLoading(true);
    if (radioCategory?.alt_name) {
      RadioService.getRadioStationsByGenre(radioCategory?.alt_name).then(
        (res) => {
          //   console.log(res.stations.data);
          const filteredData = res.stations.data?.filter(
            (item: any) => item.slug !== slug
          );
          // console.log(filteredData);
          if (filteredData.length > 0) {
            setRandomStations(filteredData.slice(0, 3));
            setStationCount(filteredData?.length ? filteredData.length : 0);
            setIsLoading(false);
          } else {
            RadioService.getRandomRadioStations().then((res) => {
              // console.log("random", res.randomRadioStations);
              setIsLoading(false);
              const filteredData = res.randomRadioStations?.filter(
                (item: any) => item.slug !== slug
              );
              setRandomStations(filteredData.slice(0, 3));
              setStationCount(filteredData?.length ? filteredData.length : 0);
              setIsLoading(false);
            });
          }
        }
      );
    }
  }, [radioCategory]);

  // play radio
  const handleRadio = () => {
    dispatch(addStationToRadio(radio));
  };

  // favorites
  useEffect(() => {
    if (loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, loggedInUser?.id)
        .then((res) => {
          const favoritesIds = res.profile.favorite_radio_stations.map(
            (item: any) => item.id
          );
          setFavorite(favoritesIds);
        })
        .catch(() => {});
    }
  }, [loggedInUser]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {radio?.title ? `${radio?.title} - AYOZAT` : "AYOZAT"}
        </title>
        <meta name="title" content={radio?.title} />
        <meta name="description" content={radio?.description} />
        <link rel="canonical" href={`${FE_URL}/radio/${radio?.slug}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={radio?.title} />
        <meta name="twitter:description" content={radio?.description} />
        <meta name="twitter:image" content={radio?.artwork_url} />
        <meta name="twitter:url" content={`${FE_URL}/radio/${radio?.slug}`} />

        <meta property="og:title" content={radio?.title} />
        <meta property="og:description" content={radio?.description} />
        <meta property="og:image" content={radio?.artwork_url} />
        <meta property="og:url" content={`${FE_URL}/radio/${radio?.slug}`} />
        <meta property="og:type" content="website" />
      </Helmet>
      {!isLoading ? (
        <>
          {radio && (
            <div className="text-white">
              <RadioDetails
                data={radio && radio}
                radioCategory={radioCategory}
              />

              {/* play button */}
              <div className="flex items-center gap-2 pl-4 my-3 max-sm:pl-4">
                <button
                  className={`flex items-center w-28 p-3 text-center mr-3 justify-center text-white rounded-full button-red max-sm:p-2 max-sm:text-[15px] max-sm:w-[full]`}
                  onClick={handleRadio}
                >
                  <FaPlay className="mr-1 " /> Play
                </button>

                <AddRadioToFav station={radio} Icon={true} />
              </div>

              {/* horizontal line */}
              <hr className="my-5" />

              {/* similar channels */}
              <div className="flex justify-between w-full max-sm:block max-sm:pl-4">
                <div className="items-start w-1/2 sm:px-4 max-sm:w-full max-sm:mb-5">
                  {/* <p className="mb-5 text-2xl text-white">Description</p> */}
                  <p className="text-[--small-text]">{radio?.description}</p>
                </div>

                <div className="w-1/2 max-sm:w-full max-sm:mt-5">
                  {randomStations && randomStations.length > 0 && (
                    <>
                      <div className="flex items-center justify-between pr-4">
                        <p className="mb-5 text-xl text-white max-sm:text-xl">
                          You Might Like
                        </p>
                        {stationCount && stationCount > 3 && (
                          <Link
                            to={RouteName.Radio.replace(
                              "*",
                              RouteName.RadioChannelsByCategory.replace(
                                ":slug",
                                radioCategory?.alt_name
                              )
                            )}
                          >
                            <p className="mb-5 text-base text-red-600">
                              See All
                            </p>
                          </Link>
                        )}
                      </div>
                      <div
                        className={`grid grid-cols-3 gap-2 max-sm:grid-cols-${gridCount}`}
                      >
                        {randomStations?.map((station: any) => (
                          <RadioCard
                            key={station?.id}
                            station={station}
                            user={loggedInUser}
                            favorite={favorite}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <DescriptionSkeletion />
        </>
      )}
    </>
  );
}

export default RadioDescriptionPage;
