import React from "react";
import { MdArrowBack } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setLiveTVSuccess, setVideoStart } from "redux/VideoPlayerRedux";
import LiveTVService from "services/liveTv/LiveTVService";

function SidebarIcon({
  data,
  setSelectedIcon,
  setIconClicked,
  iconClicked,
}: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentLiveTv } = useSelector((state: any) => state?.video);
  const dispatch = useDispatch<any>();
  const handleClick = () => {
    if (!iconClicked) {
      // setIconClicked(true);
      if (data.title === "LiveTV") {
        let link = data.link.replace(
          "*",
          currentLiveTv ? currentLiveTv?.slug : "ayozat-tv"
        );
        loadChannelData(link);
        navigate(link);
      } else {
        navigate(data.link);
      }
    }
    setSelectedIcon(data);
  };
  const loadChannelData = async (link: any) => {
    await LiveTVService.getCHannelDetailsBySlug(link).then((res) => {
      dispatch(setVideoStart());
      dispatch(setLiveTVSuccess(res.data));
    });
  };
  const handleBack = () => {
    setIconClicked((val: boolean) => !val);
    setSelectedIcon();
  };

  return (
    <div className={`relative mb-8 !overflow-visible !z-50 `}>
      {/* <Link to={data.link}> */}
      <div>
        <button
          className={`items-center p-1 mb-1 rounded-full md:p-2 max-sm:p-4 max-md:p-4 ${
            location.pathname.split("/")[1] === data.link.split("/")[1]
              ? "button-white red-text "
              : "button-red text-white"
          }`}
          onClick={handleClick}
        >
          <data.icon className="text-lg md:text-2xl" />
        </button>

        <p className="text-xs gray-text">{data.title}</p>
      </div>
      {/* </Link> */}

      {iconClicked === true && (
        <div
          className={`flex flex-col justify-center ${
            iconClicked ? "open" : "close"
          }`}
        >
          {data.subroutes.map((sub: any, i: number) => (
            <div
              className="my-6 text-center text-gray-100 cursor-pointer feature-side-nav"
              key={i}
            >
              <Link to={sub.subRouteLink}>
                <div className="flex flex-col items-center">
                  <button className="mb-1">
                    <sub.icon className="mr-2 text-lg md:text-2xl" />
                  </button>

                  <p className="text-sm gray-text">{sub.subRouteName}</p>
                </div>
              </Link>
            </div>
          ))}

          {/* <div className="p-[1px] mx-auto text-4xl text-black duration-200 ease-in-out bg-white rounded-full cursor-pointer hover:scale-[1.05]">
            <MdArrowBack onClick={handleBack} />
          </div> */}
        </div>
      )}
    </div>
  );
}

export default SidebarIcon;
