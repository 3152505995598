import { FaPlay } from "react-icons/fa";

function MusicDiscoverFeature({ headText, title, artist, image }: any) {
  return (
    <>
      <div className="w-full my-10">
        <p className="mb-4 text-base text-white">{headText}</p>
        <p className="text-2xl font-bold text-white">{title}</p>

        <p className="text-[12px] font-[400] text-[#7B9DB4] mt-[5px]">
          {artist}
        </p>

        <div
          className="flex justify-end shadow-lg h-[250px] w-[90%] rounded-lg p-2 my-3 items-end max-sm:w-full"
          style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="flex items-center justify-center w-12 h-12 bg-red-600 rounded-full shadow-lg cursor-pointer">
            <FaPlay className="w-5 h-5 text-white" />
          </div>
        </div>
      </div>
    </>
  );
}

export default MusicDiscoverFeature;
