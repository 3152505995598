import { useEffect, useRef, useState } from "react";
import VideoService from "services/video/VideoService";

function GenreSelector({ selectedGenre, setSelectedGenre, genres }: any) {
  const dropdownRef = useRef(null);

  const onChange = (e: any) => {
    setSelectedGenre(e.target.value);
  };

  const handledropDown = () => {};

  return (
    <div className="flex justify-between pr-4 max-sm:mb-2 max-sm:ml-2 max-sm:w-full">
      <label htmlFor="watched" className="text-white ">
        Genres{" "}
      </label>
      <select
        name="cars"
        id="cars"
        className="p-1 ml-2 text-white rounded-md outline-none searchbar max-h-[60px] overflow-y-auto"
        onChange={onChange}
        value={selectedGenre}
      >
        <>
          <option value={""}>All</option>
          {genres &&
            genres?.map((c: any, i: number) => (
              <option value={c.alt_name} key={i}>
                {c.name
                  .replace(/&amp;/g, "&")
                  .replace(/&rsquo;/g, "’")
                  .replace(/&nbsp;/g, "")}
              </option>
            ))}
        </>
      </select>
    </div>
  );
}

export default GenreSelector;
