import React from "react";
import VideoCard2 from "./VideoCard2";
import redElipce from "Assets/Images/red-eclipse.png";

const RightTextSection = ({ mainText, subText, image }: any) => {
  return (
    <div>
      <img
        src={redElipce}
        alt="red_eclipse"
        className="absolute -mt-[250px] -ml-100"
      />
      <VideoCard2 mainText={mainText} subText={subText} image={image} />
    </div>
  );
};

export default RightTextSection;
