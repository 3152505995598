import React, { useState } from "react";
import ShareBody from "./ShareBody";

function SocialMediaPopUp({ shareUrl }: any) {
  const [activeContentId, setActiveContentId] = useState(1);
  const [activeContent, setActiveContent] = useState<any>(data[0]);

  const handleContent = (id: number) => {
    setActiveContentId(id);
    setActiveContent(data[id - 1]);
  };
  return (
    <div className="bg-[#000000]">
      {/* tabs */}
      <div className="flex items-center justify-start">
        {data.map((tab: any, i: number) => (
          <p
            key={i}
            className={`p-3 text-center cursor-pointer ${
              tab.id === activeContentId ? `text-red-600` : `text-white`
            }`}
            onClick={() => handleContent(tab.id)}
          >
            {tab.name}
          </p>
        ))}
      </div>

      <hr />

      {/* content */}
      <ShareBody content={activeContent} shareUrl={shareUrl} />
    </div>
  );
}

export default SocialMediaPopUp;

const data = [
  {
    id: 1,
    name: "Facebook",
  },
  // {
  //   id: 2,
  //   name: "Twitter",
  // },
  {
    id: 2,
    name: "Twitter",
  },
];
