import ayozatProps from "Assets/Images/ayozat_prop.svg";
import RedEclipse from "Assets/Images/red-eclipse.png";
import MusicLandingCardSection from "pages/musicPage/landingPage/MusicLandingCardSection";
import MusicCard1 from "pages/musicPage/landingPage/shared/MusicCard1";
import MusicCard2 from "pages/musicPage/landingPage/shared/MusicCard2";
import MusicCarousel from "pages/welcome/MusicCarousel";
import HeroSection from "./shared/HeroSection";
import MusicCarousel1 from "pages/musicPage/landingPage/shared/MusicCarousel1";
import Logo from 'img/headers/music.png';
function MusicSEOLandingPage() {
  return (
    <div className="overflow-hidden">
      {/* main topic section */}
      <HeroSection Logo={Logo} link={"/music"} />

      {/* landing page section 2 */}
      <div className="relative overflow-hidden">
        <img
          src={RedEclipse}
          alt="red_eclipse"
          className="ml-[1200px] mt-[-170px] absolute"
        />
        <MusicCard1 />
      </div>
      <div>
        <img
          src={RedEclipse}
          alt="red_eclipse"
          className="absolute -mt-52 -ml-72"
        />
        <MusicCard2 />
      </div>

      <div>
        <h1 className="font-Plus Jakarta Sans w-[744px] text-[32px] white-text font-[700] text-center mx-auto mt-10">
          Watch movies and shows anytime anywhere personalized for you
        </h1>
        <p className="font-Plus Jakarta Sans w-[543px] text-[20px] font-[400] text-center mx-auto text-[#7B9DB4] mt-6">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </p>
      </div>

      {/* coursel */}
      <div>
        <MusicCarousel1 />
      </div>
      
      <div className="mt-10 -mb-28">
        <div className="w-[450px] mx-auto">
          <p className="font-Plus Jakarta Sans white-text text-[32px] font-[700] text-center ">
            Join with us today and gain access to all
          </p>
          <button className="white-text button-red px-8 py-1.5 ml-36 mt-[25px] font-medium rounded-full xl:text-lg lg:text-lg md:text-md sm:text-sm">
            Register now
          </button>
        </div>
      </div>

      {/* landing page section 3 */}
      <div>
        <img src={ayozatProps} alt="ayozat figure" className="mx-auto" />
        <div className="w-[300px] mx-auto">
          <p className="-mt-36 font-Plus Jakarta Sans white-text text-center font-[700] text-[44px]">
            $1.99 /<span className="font-normal text-[24px]"> month</span>
          </p>
        </div>
      </div>

      {/* landing page final section */}
      <div>
        <h1 className="font-Plus Jakarta Sans w-[744px] white-text text-[32px] font-[700] text-center mx-auto mt-8">
          Explore some of what more you can recive with the membership
        </h1>
        <MusicLandingCardSection />
        {/* <MusicLandiingCard /> */}
      </div>
    </div>
  );
}

export default MusicSEOLandingPage;
