import PopUpModel from "components/PopUpModel";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BiSolidEditAlt } from "react-icons/bi";
import CreatePlayList from "../shared/CreatePlayList";
import { useState } from "react";

function EditPlaylist({ playlist, setPlaylist, setUserPlaylists }: any) {
  // edit playlist
  const [showModalEditPlaylist, setShowModalEditPlaylist] = useState(false);

  const openModalEditPlaylist = () => {
    setShowModalEditPlaylist(true);
  };

  const closeModalEditPlaylist = () => {
    setShowModalEditPlaylist(false);
  };

  return (
    <div className="px-5 text-white">
      <BiSolidEditAlt
        className="text-3xl text-white duration-200 ease-in-out cursor-pointer hover:text-red-600"
        onClick={openModalEditPlaylist}
        data-tooltip-id={`update-playlist-${playlist?.id}`}
      />
      {showModalEditPlaylist && (
        <div className="z-50">
          <PopUpModel
            isOpen={showModalEditPlaylist}
            onClose={closeModalEditPlaylist}
            width={"800px"}
          >
            <CreatePlayList
              closeModal={closeModalEditPlaylist}
              setUserPlaylists={setUserPlaylists}
              currentPlaylist={playlist}
              setPlaylist={setPlaylist}
            />
          </PopUpModel>
        </div>
      )}
      <div className="text-sm">
        <ReactTooltip
          id={`update-playlist-${playlist?.id}`}
          place="top"
          content={`Edit Playlist`}
        />
      </div>
    </div>
  );
}

export default EditPlaylist;
