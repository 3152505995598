import VideoSeeAllSkeleton from "components/skeleton/VideoSeeAllSkeleton";
import { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";
import MovieCarouselCard from "./shared/MovieCarouselCard";
import GenreSelector from "./shared/GenreSelector";
import YearSelector from "./shared/YearSelector";
import VideoService from "services/video/VideoService";
import Pagination from "pages/podcastPage/shared/Pagination";
import NoSearch from "pages/searchPage/shared/NoSearch";
import { Helmet } from "react-helmet-async";
import { Adsense } from "@ctrl/react-adsense";
import GeneralServices from "services/General/GeneralServices";
import { RouteName } from "constant/routeNames";
function SeeAllSeries() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page") || "1";
  const pageNoInt = parseInt(page, 10);
  const [cardDetails, setCardDetails] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const navigate = useNavigate();

  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [url, setUrl] = useState("tv-series/all");
  const [currentPage, setCurrentPage] = useState<any>(pageNoInt);
  const [lastPage, setLastPage] = useState();
  const [genres, setGenres] = useState<any>();
  const [gAdSlot, setgAdSlot] = useState<any>();
  const currentUrl = window.location.pathname;
  const path = currentUrl + `?page=${currentPage}`;
  
  useEffect(() => {
    const handlePopstate = () => {
      const newPage =
        new URLSearchParams(window.location.search).get("page") || "1";
      setCurrentPage(parseInt(newPage, 10));
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_video_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    VideoService.getAllGenres().then((res) => {
      // console.log(res);
      setGenres(res.genres);
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (selectedGenre === "" && selectedYear === "") {
      // setUrl(`movies/all?genre=${selectedGenre}`);
      setUrl(`tv-series/all?page=${currentPage}`);
    } else if (selectedGenre === "" && selectedYear !== "") {
      setCurrentPage(1);
      setUrl(`tv-series/all?released_at=${selectedYear}&page=${currentPage}`);
    } else if (selectedGenre !== "" && selectedYear === "") {
      setCurrentPage(1);
      setUrl(`tv-series/all?genre=${selectedGenre}&page=${currentPage}`);
    } else {
      setCurrentPage(1);
      setUrl(
        `tv-series/all?genre=${selectedGenre}&released_at=${selectedYear}&page=${currentPage}`
      );
    }
  }, [selectedGenre, selectedYear, currentPage]);
  useEffect(() => {
    navigate(RouteName.SeeAllSeriesRoute + `?page=${currentPage}`);
  }, [currentPage, navigate]);
  useEffect(() => {
    // console.log(selectedGenre);
    // console.log(selectedYear);
    setIsLoading(true);
    VideoService.getAllFilteredTVSeries(url)
      .then((res: any) => {
        setIsLoading(false);
        // console.log(res);
        setCardDetails(res?.data);
        setLastPage(res?.meta.last_page);
        //console.log(res?.data);
      })
      .catch((e: any) => {
        setIsLoading(false);
        console.log(e);
      });
  }, [url]);

  console.log(cardDetails);

  return (
    <>
      <Helmet>
        <title> All Series - AYOZAT </title>
        <meta name="title" content="All Series - AYOZAT" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="All Series - AYOZAT" />

        <meta property="og:title" content="All Series - AYOZAT" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div>
        {/* main topic section */}
        <div>
          <button
            className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
            onClick={() => {
              navigate(-1);
            }}
          >
            <AiOutlineArrowLeft className="ml-3" />
          </button>
        </div>
        <div className="flex items-center justify-between mb-8 mr-5 max-sm:block max-sm:mr-0">
          <h1 className="text-xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
            Discover Series
          </h1>

          <div className="flex my-3 max-sm:justify-between max-sm:block max-sm:w-full">
            <GenreSelector
              selectedGenre={selectedGenre}
              setSelectedGenre={setSelectedGenre}
              genres={genres}
            />
            <YearSelector
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          </div>
        </div>
        {gAdSlot && (
          <Adsense
            client="ca-pub-5773929890067376"
            slot={gAdSlot}
            style={{
              display: "flex",
              marginBottom: "20px",
              marginTop: "20px",
              height: "90px",
              justifyContent: "center",
            }}
            format="horizontal"
            data-full-width-responsive="true"
            // format="fluid"
          />
        )}
      </div>
      <div>
        {!isLoading && (
          <>
            {cardDetails?.length === 0 && cardDetails ? (
              <NoSearch text="Series" />
            ) : (
              <div className="grid w-[98%] grid-cols-2 gap-4 mt-4 mb-5  sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full max-sm:gap-4">
                {cardDetails?.map((card: any, i: number) => (
                  <div
                    key={i}
                    className="mt-4 mb-5 h-[400px] overflow-visible max-sm:mt-0 max-sm:mb-0"
                  >
                    <MovieCarouselCard
                      id={card?.id}
                      //   time={card.runtime}
                      title={card?.title}
                      //   info={card.title}
                      //   year={card.release}
                      //   type={card.genre}
                      img={card.thumbnail}
                      seasons={card?.no_of_seasons}
                      slug={card?.slug}
                      tvSeries={card}
                      tv={true}
                      first_episode={card?.first_episode}
                    />
                  </div>
                ))}
              </div>
            )}

            <div className="flex justify-center my-5">
              {cardDetails?.length === 0 && cardDetails ? (
                <></>
              ) : (
                <>
                  <Pagination
                    totalPages={lastPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </>
              )}
            </div>
          </>
        )}
        {isLoading && <VideoSeeAllSkeleton />}
      </div>
    </>
  );
}

export default SeeAllSeries;
