import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { Link } from "react-router-dom";
import BlogCardCategory from "./BlogCardCategory";
import { Tooltip as ReactTooltip } from "react-tooltip";

function BlogCard({ isLoading, blog }: any) {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <>
      <Link
        to={RouteName.Blog.replace(
          "*",
          RouteName.BlogDetails.replace(":slug", `${blog.slug}`)
        )}
      >
        <div
          className="w-full max-sm:my-3 max-sm:block"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div
            className="relative w-[98%] rounded-lg h-[300px] bg-img white-text flex max-sm:w-[100%]"
            style={{
              background: `url(${blog.thumbnail_url}) no-repeat center`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div className="absolute inset-x-0 bottom-0 flex justify-between h-auto p-2 rounded-lg bg-black/80 backdrop-blur-2xl">
              <div className="flex h-full overflow-hidden">
                <div>
                  <div className="flex items-center justify-between p-1">
                    <p
                      className="text-[18px] leading-tight font-[600] h-[40px] overflow-hidden w-[100%] max-sm:h-[45px]"
                      data-tooltip-id={`blog-title-${blog?.id}`} // Changed to use blog.id
                    >
                      {blog.title?.length > 80
                        ? `${blog?.title
                            ?.slice(0, 80)
                            .replace(/&amp;/g, "&")
                            .replace(/&rsquo;/g, "’")
                            .replace(/&nbsp;/g, "")}...`
                        : blog?.title
                            ?.replace(/&amp;/g, "&")
                            .replace(/&rsquo;/g, "’")
                            .replace(/&nbsp;/g, "")}{" "}
                    </p>
                  </div>

                  <div className="flex justify-between my-2">
                    <BlogCardCategory categories={blog.categories} />
                  </div>

                  <div className="flex justify-between mt-5 ml-2">
                    <p className="font-light text-gray-600 text-[10px]">
                      {blog.created_at}
                    </p>
                    <p className="text-[12px] text-[#7b7a7a] ml-2 font-[500]">
                      {blog.created_by}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-sm z-100">
          <ReactTooltip
            id={`blog-title-${blog?.id}`} // Changed to use blog.id
            place="top"
            content={blog?.title}
            style={{ width: "300px" }}
            delayShow={200}
          />
        </div>
      </Link>
    </>
  );
}

export default BlogCard;
