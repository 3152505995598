import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import { useEffect, useState } from "react";
import AlbumCard from "../shared/AlbumCard";
import { useParams } from "react-router-dom";
import AlbumService from "services/music/AlbumService";

function SuggestedAlbumCardCoursel({ genre }: any) {
  const { id } = useParams();

  console.log(id);

  const [albums, setAlbums] = useState([]);
  useEffect(() => {
    AlbumService.getSuggestedAlbumsById(id).then((res) => {
      setAlbums(res.data);
    });
  }, [id]);

  // console.log(albums);

  return (
    <div>
      <div className="">
        <Swiper
          slidesPerView={7}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            370: {
              slidesPerView: 2,
            },
            700: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 5,
            },
            1400: {
              slidesPerView: 6,
            },
            1600: {
              slidesPerView: 7,
            },
          }}
          modules={[Pagination, Navigation]}
          className="mt-4 mb-5 mySwiper"
          navigation={true}
        >
          {albums &&
            albums.map((album: any, i: number) => (
              <SwiperSlide key={i}>
                <AlbumCard {...album} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}

export default SuggestedAlbumCardCoursel;
