import React from "react";
import ReactGiphySearchbox from "react-giphy-searchbox";

const GIFPicker = ({ setInputValue, setShowMedia }: any) => {
  const handleSelect = (item: any) => {
    setInputValue(item.embed_url);
    setShowMedia(false);
  };
  return (
    <div className="flex justify-center">
      <ReactGiphySearchbox
        apiKey={`${process.env.REACT_APP_GIF_API_KEY}`}
        onSelect={handleSelect}
        masonryConfig={[
          { columns: 2, imageWidth: 125, gutter: 5 },
          { mq: "700px", columns: 3, imageWidth: 100, gutter: 5 },
        ]}
      />
    </div>
  );
};

export default GIFPicker;
