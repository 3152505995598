import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;
// const VIUZAT_URL = process.env.REACT_APP_VIUZAT_SERVER;

const getAllGenres = async () => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/genres`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  // alert("Favourite created --- "+ response);
  return response.data;
};

const GenreService = { getAllGenres };

export default GenreService;
