import { RouteName } from "constant/routeNames";
import PlaylistCard from "pages/musicPage/playlist/PlaylistCard";
import PlaylistCoursel from "pages/musicPage/playlist/PlaylistCoursel";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function UserPlaylistPage({
  user,
  favoriteData,
  isLoading,
  // haveData,
  userPlaylist,
  isLoadingPlaylist,
}: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  return (
    <>
      {!isLoading && (
        <div>
          {/* top section */}
          <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h2 className="text-lg font-medium text-white lg:text-xl md:text-2xl">
                {/* Videos by booster */}
                Favourite Playlists
              </h2>
            </div>
          </div>

          {favoriteData?.favorite_public_playlists?.length === 0 &&
          favoriteData?.favorite_public_playlists ? (
            <div>
              <div className="items-center h-full mt-20">
                {user?.id === loggedInUser?.id ? (
                  <>
                    <p className="flex items-center justify-center text-base text-gray-500">
                      You don't have any playlists
                    </p>
                  </>
                ) : (
                  <>
                    {/* {favoriteData?.favorite_public_playlists?.length === 0 && ( */}
                    <p className="flex items-center justify-center text-base text-gray-500">
                      No any playlists
                    </p>
                    {/* )} */}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="grid w-full grid-cols-2 gap-5 pl-6 mt-4 mb-5 max-sm:pl-0 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:w-full max-sm:gap-4">
                {favoriteData?.favorite_public_playlists?.length > 0 &&
                  favoriteData?.favorite_public_playlists?.map(
                    (playlist: any, i: number) => (
                      <div key={i}>
                        <Link
                          to={RouteName.MusicPlaylistPage.replace(
                            ":id",
                            playlist?.id
                          )}
                        >
                          <PlaylistCard
                            playlist={playlist}
                            isLoading={isLoading}
                            userPage={true}
                          />
                        </Link>
                      </div>
                    )
                  )}
              </div>
            </div>
          )}
          {user?.id === loggedInUser?.id && (
            <>
              {userPlaylist?.length > 0 && (
                <div className="mt-10 ml-6 max-sm:w-full max-sm:mx-0">
                  {/* <BackButton /> */}
                  <h2 className="font-medium text-white ttext-lg lg:text-xl md:text-2xl">
                    My Playlists
                  </h2>

                  {/* {haveData ? ( */}
                  {/* <> */}
                  <PlaylistCoursel
                    playlists={userPlaylist}
                    isLoadingPlaylist={isLoadingPlaylist}
                    libraryPage={true}
                  />
                  {/* </> */}
                  {/* ) : ( */}
                  {/* <> */}
                  {/* <div className="flex flex-col items-center justify-center h-96 max-sm:w-[95%] max-sm:mx-auto">
                        <p className="text-2xl text-white max-sm:text-lg">
                          You have no items in your playlist.
                        </p>
                        <div className="flex items-center gap-2 mt-8">
                          <Link to={RouteName.Music.replace("/*", "")}>
                            <button
                              className={`max-sm:justify-center  w-[200px] py-3 ml-10 text-center text-white rounded-full button-red max-sm:py-3  max-sm:text-[13px] max-sm:w-[160px] max-sm:mx-auto `}
                            >
                              Explore Music
                            </button>
                          </Link>
                        </div>
                      </div> */}
                  {/* </> */}
                  {/* )} */}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default UserPlaylistPage;
