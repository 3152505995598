import SearchLiveTvSection from "./shared/SearchLiveTvSection";
import SearchVideoSection from "./shared/SearchVideoSection";
import SearchTvSeries from "./shared/SearchTvSeries";
import SearchAlbumSection from "./shared/SearchAlbumSection";
import SearchSongsSection from "./shared/SearchSongsSection";
import SearchPodcastSection from "./shared/SearchPodcastSection";
import SearchRadioSection from "./shared/SearchRadioSection";
import SearchUsersSection from "./shared/SearchUsersSection";
import SearchPlaylistSection from "./shared/SearchPlaylistSection";
import SearchArtistSection from "./shared/SearchArtistSection";
import NoSearch from "./shared/NoSearch";
import SearchPPV from "./SearchPPV";
import { useEffect } from "react";
import SearchCreatorsSection from "./shared/SearchCreatorsSection";
import SearchCreatorVideosSection from "./shared/SearchCreatorVideosSection";

function SearchAll({ data, user, isLoading, enabled }: any) {
  // const [tvChannels, setTvChannels] = useState([]);
  // const [movies, setMovies] = useState([]);
  // const [tvSeries, setTvSeries] = useState([]);
  // const [albums, setAlbums] = useState([]);
  // const [songs, setSongs] = useState([]);
  // const [podcasts, setPodcasts] = useState([]);
  // const [radios, setRadios] = useState([]);
  // const [allData, setAllData] = useState<any>();

  // useEffect(() => {
  //   const searchAll = async () => {
  //     await SearchServie.searchAll(text).then((res) => {
  //       console.log("first", res.tv_channels);
  //       setAllData(res);
  //     //   // setTvChannels(res.tv_channels);
  //     //   // setMovies(res.movies);
  //     //   // setTvSeries(res.tv_shows);
  //     //   // setAlbums(res.albums);
  //     //   // setSongs(res.songs);
  //     //   // setPodcasts(res.podcasts);
  //     //   // setRadios(res.radio_stations);
  //     // });
  //   };

  //   searchAll();
  // }, [text]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  console.log(data);

  return (
    <div>
      <div>
        {data?.tv_channels.length === 0 &&
        data?.movies.length === 0 &&
        data?.tv_shows.length === 0 &&
        data?.albums.length === 0 &&
        data?.songs.length === 0 &&
        data?.artists.length === 0 &&
        data?.tv_channels.length === 0 &&
        data?.podcasts.length === 0 &&
        data?.radio_stations.length === 0 &&
        data?.users.length === 0 &&
        data?.public_playlists.length === 0 &&
        data?.ppv_events.length === 0 &&
        data?.creators?.length === 0 &&
        data?.creatorVideos?.length === 0 ? (
          <>
            <div className="flex items-center justify-center h-full mt-20 text-gray-400 text-md">
              <p className="">Sorry ! There isn't any content found.</p>
            </div>
          </>
        ) : (
          <>
            {data?.tv_channels.length !== 0 && (
              <SearchLiveTvSection data={data} isLoading={isLoading} />
            )}
            {data?.movies.length !== 0 && (
              <SearchVideoSection
                data={data}
                user={user}
                isLoading={isLoading}
              />
            )}
            {data?.tv_shows.length !== 0 && (
              <SearchTvSeries data={data} user={user} isLoading={isLoading} />
            )}
            {data?.creators?.length !== 0 && (
              <SearchCreatorsSection
                data={data}
                user={user}
                isLoading={isLoading}
              />
            )}
            {data?.creatorVideos?.length !== 0 && (
              <SearchCreatorVideosSection
                data={data}
                user={user}
                isLoading={isLoading}
              />
            )}
            {data?.albums.length !== 0 && (
              <SearchAlbumSection
                data={data}
                user={user}
                isLoading={isLoading}
              />
            )}
            {data?.songs.length !== 0 && (
              <SearchSongsSection
                data={data}
                user={user}
                isLoading={isLoading}
              />
            )}
            {data?.artists.length !== 0 && (
              <SearchArtistSection data={data} isLoading={isLoading} />
            )}
            {data?.podcasts.length !== 0 && (
              <SearchPodcastSection
                data={data}
                user={user}
                isLoading={isLoading}
              />
            )}
            {data?.radio_stations.length !== 0 && (
              <SearchRadioSection
                data={data}
                user={user}
                isLoading={isLoading}
              />
            )}
            {data?.users.length !== 0 && (
              <SearchUsersSection
                data={data}
                user={user}
                isLoading={isLoading}
              />
            )}
            {data?.public_playlists.length !== 0 && (
              <SearchPlaylistSection
                user={user}
                data={data}
                isLoading={isLoading}
              />
            )}
            {enabled === "1" && (
              <>
                {data?.ppv_events.length !== 0 && (
                  <SearchPPV user={user} data={data} isLoading={isLoading} />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SearchAll;
