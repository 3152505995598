import { Link } from "react-router-dom";

function HeroSection({ Logo, link, image }: any) {
  return (
    <div className="ppv-heroimage h-[500px] max-sm:h-[400px]">
      <div className=" mt-[150px] mx-auto">
        <div className="flex flex-col justify-center w-[100%] mx-auto mt-5 mb-5 text-center">
          <img
            src={Logo}
            className="w-[20%] justify-center items-center mx-auto mb-3 max-sm:w-[70%]"
            alt="ppv"
          />
          <h1 className="text-[35px] font-bold text-white uppercase max-sm:text-[25px]">
            Pay Per View
          </h1>
        </div>

        <div className="flex justify-center w-full mx-auto my-5">
          <Link to={link}>
            <button className="white-text  button-red px-8 py-1.5 mt-[25px] font-medium rounded-full xl:text-lg lg:text-lg md:text-md sm:text-sm">
              Explore content
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
