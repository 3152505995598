import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Notification } from "constant/notifications";
import { triggerSuccessNotification } from "utils/notification";
import { FiLink2, FiShare2 } from "react-icons/fi";

function VideoShareButton({ shareUrl }: any) {
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(shareUrl);
    triggerSuccessNotification(Notification.CopyToClipboard);
  };
  return (
    <div className="flex items-center justify-start">
      <div
        className="text-white bg-transperant py-1 pl-3 pr-3 rounded-full overflow-x-hidden flex justify-between items-center  gap-2"
        onClick={copyToClipboard}
        data-tooltip-id="copy-media-link"
      >
        <FiShare2 className={"w-6 h-6"} />
        {/* <span> Share</span> */}
      </div>

      <ReactTooltip
        id="copy-media-link"
        place="bottom"
        content="Copy Link"
      />
    </div>
  );
}
export default VideoShareButton;
