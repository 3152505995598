import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

type RequireAuthProps = {
  component: JSX.Element;
};

const RequireAuth = ({ component: Component }: RequireAuthProps) => {
  const currentUser = useSelector((state: any) => state?.user?.currentUser);
  // console.log("RequireAuth currentUser ==> ", currentUser);
  if (!currentUser?.access_token) return <Navigate to="/login" />;

  return <div>{Component}</div>;
};

export default RequireAuth;
