import LandingLiveTv from 'Assets/Images/Live TVLanding page.png';
import Music from 'Assets/Images/Music Landing page.png';
import LandingRadio from 'Assets/Images/Radio Landing page.png';
import PPV from 'Assets/Images/payperview.png';
import Podcast from 'Assets/Images/podcast.jpg';
import { useState } from 'react';
import VideoLandingCard from './shared/VideoLandingCard';

const VideoLandingCardSection = () => {
  const VideoCard = [
    {
      id: 1,
      theme: 'dark',
      title: 'Music',
      info: 'Watch movies and shows anytime anywhere personalized for you',
      discription:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
      img: Music,
    },
    {
      id: 2,
      theme: 'light',
      title: 'Radio',
      info: 'Watch movies and shows anytime anywhere personalized for you ',
      img: LandingRadio,
    },
    {
      id: 3,
      theme: 'dark',
      title: 'LiveTV',
      info: 'Watch movies and shows anytime anywhere personalized for you ',
      img: LandingLiveTv,
    },
    {
      id: 4,
      theme: 'dark',
      title: 'PayPerView',
      info: 'Watch movies and shows anytime anywhere personalized for you ',
      img: PPV,
    },
    {
      id: 5,
      theme: 'light',
      title: 'Podcast',
      info: 'Watch movies and shows anytime anywhere personalized for you ',
      img: Podcast,
    },
  ];

  const [videoCardDetails] = useState(VideoCard);
  return (
    <div className="grid sm:grid-cols-1  md:grid-cols-2 lg:grid-cols-3  mb-10 mt-10 w-[60%] mx-auto">
      {videoCardDetails.map((card) => (
        <div
          key={card.id}
          className={`${card.id === 1 ? 'row-span-2 h-full' : ''}`}
        >
          <VideoLandingCard {...card} />
        </div>
      ))}
    </div>
  );
};

export default VideoLandingCardSection;
