import React from 'react'

const PpvResurvebtnskeleton = () => {
  return (
    <div>
      <div className='w-[250px] h-[50px] bg-[#1a1919] animate-pulse'></div>
    </div>
  )
}

export default PpvResurvebtnskeleton