import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;
// const VIUZAT_URL = process.env.REACT_APP_VIUZAT_SERVER;
const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

const getAllCustomPages = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/custom-pages`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getCustomPageDetailsBySlug = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/custom-pages/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const CutomPagesServices = {
  getAllCustomPages,
  getCustomPageDetailsBySlug,
};

export default CutomPagesServices;
