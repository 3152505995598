import AyozatAudioPlayer from "components/AudioPlayer/AyozatAudioPlayer";
import React from "react";
import { useSelector } from "react-redux";
import { setCurrentAudio } from "redux/AudioPlayerRedux";
import MusicService from "services/music/MusicService";

function AudioPlayer({ isActive }: any) {
  const { playlist, currentAudio, playlistLoading } = useSelector(
    (state: any) => state?.audio
  );

  const handlePlayCount = async (songId: number) => {
    // console.log("song ID ", songId);
    try {
      const response = await MusicService.trackSongPlay(songId);
      // console.log("handlePlayCount response", response);
    } catch (error: any) {
      // console.log("handlePlayCount ==> ", error);
    }
  };
  return (
    <>
      <AyozatAudioPlayer
        playlist={playlist}
        currentAudio={currentAudio}
        playlistLoading={playlistLoading}
        setCurrentAudio={setCurrentAudio}
        handlePlayCount={handlePlayCount}
        isActive={isActive}
        music={true}
      />
    </>
  );
}

export default AudioPlayer;
