import { ArtistCard } from "pages/musicPage/shared/ArtistCard";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import NoFavorite from "./NoFavorite";
import "swiper/css";
import "swiper/css/pagination";

function ArtistByUser({ favoriteData, user, favorite }: any) {
  const favoriteArtist = favoriteData?.favorite_artists || [];
  // console.log("first", favoriteArtist);
  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
        <div>
          <h2 className="text-xl font-medium text-white lg:text-xl md:text-xl">
            {/* Videos by booster */}
            Favourite Artists
          </h2>
        </div>
      </div>
      {/* card section */}
      {/* <div>
        <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-0 max-sm:w-full max-sm:gap-3 ">
          {favoriteArtist.length > 0 &&
            favoriteArtist.map((artist: any, i: number) => (
              <div key={i}>
                <ArtistCard artist={artist} />
              </div>
            ))}
        </div>
      </div> */}

      {/* coursel */}
      {favoriteArtist.length === 0 ? (
        <>
          <NoFavorite text="Artist" />
        </>
      ) : (
        <>
          <div className="">
            <Swiper
              slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                370: {
                  slidesPerView: 2,
                },
                700: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                },
                1400: {
                  slidesPerView: 6,
                },
                1600: {
                  slidesPerView: 7,
                },
              }}
              modules={[Pagination, Navigation]}
              navigation={true}
              className="mt-4 mb-10 mySwiper"
            >
              {favoriteArtist.length > 0 &&
                favoriteArtist?.map((artist: any, i: number) => (
                  <SwiperSlide className="" key={i}>
                    <ArtistCard artist={artist} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </>
      )}
    </div>
  );
}

export default ArtistByUser;
