import SubText from "components/shared/SubText";
import PayperviewPickedout from "components/skeleton/PayperviewPickedout";
import { RouteName } from "constant/routeNames";
import PayPerViewCard from "pages/PayPerView/shared/PayPerViewCard";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PayperviewService from "services/payperview/PayperviewServie";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

function WelcomePagePayperview() {
  const [upComingEvents, setUpComingEvents] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    PayperviewService.getAllPayperviewEvents()
      .then((res) => {
        // console.log("event", res.data);
        setIsLoading(false);
        const upComingEvent = res?.data.filter(
          (event: any) => event?.event_status === "not started"
        );
        setUpComingEvents(upComingEvent);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);
  return (
    <>
      {!isLoading && (
        <div className="mb-10">
          {upComingEvents?.length > 0 && (
            <>
              {/* top section */}
              <div className="flex justify-between mt-10 mb-5 w-[95%]">
                <div>
                  <SubText
                    text="Discover Payperview Events"
                    className="font-bold"
                  />
                </div>
                <Link to={RouteName.upcomingSeeAllRoute}>
                  <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer place-self-end">
                    See all
                  </p>
                </Link>
              </div>

              <div>
                <Swiper
                  slidesPerView={7}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    350: {
                      slidesPerView: 2,
                    },
                    550: {
                      slidesPerView: 3,
                    },
                    1000: {
                      slidesPerView: 4,
                    },
                    1100: {
                      slidesPerView: 5,
                    },
                    1200: {
                      slidesPerView: 6,
                    },
                    1400: {
                      slidesPerView: 7,
                    },
                  }}
                  modules={[Pagination, Navigation]}
                  navigation={true}
                  className="mt-4 mb-5 mySwiper"
                >
                  {upComingEvents &&
                    upComingEvents?.map((event: any, i: number) => (
                      <SwiperSlide className="max-sm:mx-1" key={i}>
                        <PayPerViewCard
                          event={event}
                          //   isPurchased={
                          //     events.some(
                          //       (purchasedEvent: any) =>
                          //         purchasedEvent?.id === event?.id
                          //     )
                          //       ? true
                          //       : false
                          //   }
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </>
          )}
        </div>
      )}
      {isLoading && <PayperviewPickedout />}
    </>
  );
}
export default WelcomePagePayperview;
