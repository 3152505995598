import { useEffect, useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import VideoService from 'services/video/VideoService';
import CategoryCard from './home/shared/CategoryCard';
import GeneralServices from 'services/General/GeneralServices';
import { Adsense } from "@ctrl/react-adsense";

function SeeAllTopcategory() {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [gAdSlot, setgAdSlot] = useState<any>();
  
  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_video_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === '1') {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    setIsLoading(true);
    VideoService.getAllGenres()
      .then((res) => {
        setIsLoading(false);
        setCategories(res.genres);
      })
      .catch((e) => {
        setIsLoading(false);
        // console.log(e);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      {/* main topic section */}
      <div>
        <button
          className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
          onClick={() => {
            navigate(-1);
          }}
        >
          <AiOutlineArrowLeft className="ml-3" />
        </button>
      </div>
      <div className="mb-8">
        <h1 className="text-xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
          Top Categories
        </h1>
      </div>
      {gAdSlot && (
          <Adsense
          client="ca-pub-5773929890067376"
          slot={gAdSlot}
          style={{ display: 'flex', marginBottom:'20px', marginTop:'10px', height:'90px', justifyContent: 'center' }}
          format="horizontal"
          data-full-width-responsive="true"
          // format="fluid"
          />
        )}
      {categories && (
        <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 max-sm:w-full">
          {categories &&
            categories.map((card: any, index: number) => (
              <div key={index} className="mb-4">
                <CategoryCard data={card} />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default SeeAllTopcategory;
