import MovieCarouselCard from "pages/videoPage/shared/MovieCarouselCard";
import NoFavorite from "./shared/NoFavorite";
import { useEffect, useState } from "react";
import OverviewSkeleton from "components/skeleton/OverviewSkeleton";
import CreatorVideoCard from "pages/BecomeACreator/shared/CreatorVideoCard";
import CreatorCard from "pages/videoPage/shared/CreatorCard";

function UserVideoPage({
  user,
  favoriteData,
  favoriteMoviesIds,
  favoriteTvSeriesIds,
  isLoading,
}: any) {
  const [videos, setVideos] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);
  const [episodes, setEpisodes] = useState<any>([]);
  const [creators, setCreators] = useState<any>([]);
  const favVideos = favoriteData?.favorite_movies || [];
  const favTvseries = favoriteData?.favorite_tv_series || [];
  const favEpisodes = favoriteData?.favorite_publication_videos || [];
  const favCreators = favoriteData?.followed_creators || [];

  useEffect(() => {
    setVideos(favVideos);
    setSeries(favTvseries);
    setEpisodes(favEpisodes);
    setCreators(favCreators);
  }, []);

  return (
    <>
      {!isLoading && (
        <div>
          {/* top section */}
          <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h2 className="text-lg font-medium text-white lg:text-xl md:text-2xl">
                {/* Videos by booster */}
                Favourite Videos
              </h2>
            </div>
          </div>
          {/* card section */}
          {favVideos?.length === 0 && favVideos ? (
            <div>
              <NoFavorite text="Movies" user={user} />
            </div>
          ) : (
            <div>
              <div className="grid w-full grid-cols-2 gap-5 mt-4 mb-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:w-full max-sm:gap-4">
                {videos?.length > 0 &&
                  videos?.map((card: any, i: number) => (
                    <div key={i}>
                      <MovieCarouselCard
                        id={card?.id}
                        time={card?.runtime}
                        title={card?.title}
                        info={card?.title}
                        year={card?.release}
                        type={card?.genre}
                        img={card?.thumbnail_url}
                        thumbnail_url={card?.thumbnail}
                        slug={card?.slug}
                        user={user}
                        movie={card}
                        favoriteMovies={favoriteMoviesIds}
                      />
                    </div>
                  ))}
              </div>
            </div>
            // <div className="">
            //   <Swiper
            //     slidesPerView={7}
            //     breakpoints={{
            //       0: {
            //         slidesPerView: 1,
            //       },
            //       380: {
            //         slidesPerView: 2,
            //       },
            //       700: {
            //         slidesPerView: 3,
            //       },
            //       1000: {
            //         slidesPerView: 4,
            //       },
            //       1200: {
            //         slidesPerView: 5,
            //       },
            //       1400: {
            //         slidesPerView: 6,
            //       },
            //       1600: {
            //         slidesPerView: 7,
            //       },
            //     }}
            //     modules={[Pagination]}
            //     className="mt-8 mb-10 mySwiper "
            //   >
            //     {favVideos.length > 0 &&
            //       favVideos.map((card: any, i: number) => (
            //         <SwiperSlide key={i}>
            //           <div className="mx-3 max-sm:mx-1">
            //             <MovieCarouselCard
            //               id={card.id}
            //               time={card.runtime}
            //               title={card.title}
            //               info={card.title}
            //               year={card.release}
            //               type={card.genre}
            //               img={card.thumbnail_url}
            //               thumbnail_url={card.thumbnail}
            //               slug={card.slug}
            //               user={user}
            //               movie={card}
            //               favoriteMovies={favoriteMoviesIds}
            //             />
            //           </div>
            //         </SwiperSlide>
            //       ))}
            //   </Swiper>
            // </div>
          )}

          <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h2 className="text-lg font-medium text-white lg:text-xl md:text-2xl">
                {/* tv series by booster */}
                Favourite TV Series
              </h2>
            </div>
          </div>
          {/* card section */}
          {favTvseries?.length === 0 && favTvseries ? (
            <div>
              <NoFavorite text="TV Series" user={user} />
            </div>
          ) : (
            <div>
              <div className="grid w-full grid-cols-2 gap-5 mt-4 mb-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:w-full max-sm:gap-4">
                {series?.length > 0 &&
                  series?.map((card: any, i: number) => (
                    <div key={i}>
                      <MovieCarouselCard
                        id={card?.id}
                        // time={card.runtime}
                        title={card?.title}
                        // info={card.description}
                        // year={card.release}
                        // type={card.video_type}
                        img={card?.thumbnail_url}
                        thumbnail={card?.thumbnail}
                        seasons={card?.no_of_seasons}
                        slug={card?.slug}
                        tv={true}
                        user={user}
                        tvSeries={card}
                        favoriteTvSeries={favoriteTvSeriesIds}
                        first_episode={card?.first_episode}
                      />
                    </div>
                  ))}
              </div>
            </div>
            // <div className="">
            //   <Swiper
            //     slidesPerView={7}
            //     breakpoints={{
            //       0: {
            //         slidesPerView: 1,
            //       },
            //       380: {
            //         slidesPerView: 2,
            //       },
            //       700: {
            //         slidesPerView: 3,
            //       },
            //       1000: {
            //         slidesPerView: 4,
            //       },
            //       1200: {
            //         slidesPerView: 5,
            //       },
            //       1400: {
            //         slidesPerView: 6,
            //       },
            //       1600: {
            //         slidesPerView: 7,
            //       },
            //     }}
            //     modules={[Pagination]}
            //     className="mt-8 mb-10 mySwiper "
            //   >
            //     {favTvseries.length > 0 &&
            //       favTvseries.map((card: any, i: number) => (
            //         <SwiperSlide key={i}>
            //           <div className="mx-3 max-sm:mx-1">
            //             <MovieCarouselCard
            //               id={card.id}
            //               // time={card.runtime}
            //               title={card.title}
            //               // info={card.description}
            //               // year={card.release}
            //               // type={card.video_type}
            //               img={card.thumbnail_url}
            //               thumbnail_url={card.thumbnail}
            //               seasons={card.no_of_seasons}
            //               slug={card.slug}
            //               tv={true}
            //               user={user}
            //               tvSeries={card}
            //               favoriteTvSeries={favoriteTvSeriesIds}
            //             />
            //           </div>
            //         </SwiperSlide>
            //       ))}
            //   </Swiper>
            // </div>
          )}

          <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h2 className="text-lg font-medium text-white lg:text-xl md:text-2xl">
                {/* creator videos by booster */}
                Favourite Creator Videos
              </h2>
            </div>
          </div>
          {/* card section */}
          {favEpisodes?.length === 0 && favEpisodes ? (
            <div>
              <NoFavorite text="Creator videos" user={user} />
            </div>
          ) : (
            <div>
              <div className="mt-4 mb-5 w-[95%] grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
                {episodes &&
                  episodes?.map((video: any, index: any) => (
                    <div key={index} className="mb-5 mr-4">
                      <CreatorVideoCard
                        video={video}
                        slug={video?.creator?.slug}
                        // season={episode?.season}
                        // id={episode?.id}
                        // isLoading={isLoading}
                      />
                    </div>
                  ))}
              </div>
            </div>
            // <div className="">
            //   <Swiper
            //     slidesPerView={7}
            //     breakpoints={{
            //       0: {
            //         slidesPerView: 1,
            //       },
            //       380: {
            //         slidesPerView: 2,
            //       },
            //       700: {
            //         slidesPerView: 3,
            //       },
            //       1000: {
            //         slidesPerView: 4,
            //       },
            //       1200: {
            //         slidesPerView: 5,
            //       },
            //       1400: {
            //         slidesPerView: 6,
            //       },
            //       1600: {
            //         slidesPerView: 7,
            //       },
            //     }}
            //     modules={[Pagination]}
            //     className="mt-8 mb-10 mySwiper "
            //   >
            //     {favTvseries.length > 0 &&
            //       favTvseries.map((card: any, i: number) => (
            //         <SwiperSlide key={i}>
            //           <div className="mx-3 max-sm:mx-1">
            //             <MovieCarouselCard
            //               id={card.id}
            //               // time={card.runtime}
            //               title={card.title}
            //               // info={card.description}
            //               // year={card.release}
            //               // type={card.video_type}
            //               img={card.thumbnail_url}
            //               thumbnail_url={card.thumbnail}
            //               seasons={card.no_of_seasons}
            //               slug={card.slug}
            //               tv={true}
            //               user={user}
            //               tvSeries={card}
            //               favoriteTvSeries={favoriteTvSeriesIds}
            //             />
            //           </div>
            //         </SwiperSlide>
            //       ))}
            //   </Swiper>
            // </div>
          )}
        </div>
      )}
      {isLoading && <OverviewSkeleton />}
    </>
  );
}

export default UserVideoPage;
