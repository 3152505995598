import { RouteName } from "constant/routeNames";
import React from "react";
import { Link } from "react-router-dom";

function Followers({ followFriends }: any) {
  return (
    <Link to={RouteName.UserProfile.replace("*", followFriends.username)}>
      <div className="w-[180px] h-[193px] flex items-center justify-center mt-5 bg-[#1E1E1E]">
        <div>
          <div className="flex justify-center h-[140px] ">
            {/* image */}
            <img
              src={followFriends.artwork_url}
              alt="profile pic"
              className="rounded-full h-[102px] bg-img white-text object-cover border-red-900 border-solid border-[1px]"
            />
          </div>
          <p className="w-[50%] mx-auto flex justify-center text-sm font-semibold text-center text-white">
            {followFriends.name}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default Followers;
