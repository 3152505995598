import { RouteName } from "constant/routeNames";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const PayPerViewCategoryCard = ({ category }: any) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(RouteName.payperviewDiscoveRoute.replace(":slug", category?.slug),{state:{categoryPage:true}})
  }
  return (
      <div className="h-16 w-[80%] relative cursor-pointer bg-gradient-to-r from-[#272424] to-[#f30543] opacity-50 hover:opacity-100 rounded-lg max-sm:w-[95%]" onClick={handleClick}>
        <div className="absolute inset-0 flex items-center justify-center mx-auto text-center">
          <p className="text-white font-[800] text-md">{category?.name}</p>
        </div>
      </div>
  );
};

export default PayPerViewCategoryCard;
