import { BsPlayCircle } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { addStationToRadio } from "redux/AudioPlayerRedux";

function RadioHomeCard({ data }: any) {
  const dispatch = useDispatch<any>();
  const handleRadio = () => {
    dispatch(addStationToRadio(data));
  };
  return (
    <>
      <div className="w-full my-10 radio-card max-sm:my-0" onClick={handleRadio}>
        {/* <p className="mb-4 text-base text-white">{headText}</p>  */}
        <p className="text-2xl font-[400] my-2 text-white max-xl:my-1">
          Most tuned in this week
        </p>

        {/* <p className="text-[12px] font-[400] text-[#7B9DB4] mt-[5px] max-sm:mt-1s">
          {data.description}
        </p> */}

        <div
          className="relative w-[95%] rounded-lg h-[350px] bg-img white-text max-sm:h-[250px] max-sm:w-full"
          style={{
            background: `url(${data?.artwork_url}) no-repeat center`,
            backgroundSize: "100%",
            backgroundPosition: "center",
          }}
        >
          {/* card inside content  */}
          {/* <div className="absolute mt-52 flex left-[4%] gap-1">
            <div className="bg-[#F50537] w-[52px] h-[23.68px] rounded-sm text-center">
              <p className="uppercase text-white text-[15px]">{"live"}</p>
            </div>
            <div className="bg-white/20   flex backdrop-blur-lg w-auto h-[23.68px] rounded-sm items-center text-white">
              <div className="mx-1 my-1 text-[10px]">
                <AiOutlineEye />
              </div>
              <div className="my-1 mr-1 text-[10px] ">{"liveCount"}</div>
            </div>
          </div> */}
          <div className="absolute inset-x-0 bottom-0 flex justify-between p-2 bg-gradient-to-t from-black to-black/50 h-[100px] radio-card-gradient">
            <div className="flex items-center h-full">
              <div>
                {/* <p className="text-[12px] text-white ml-2 font-[500]">
                  {"time"}
                </p> */}
                <p className="text-[15px] text-white ml-2 my-1 font-[700]">
                  {data?.title}
                </p>
                <p className="text-[10px] text-white ml-2 font-[500]">
                  {data?.description?.length > 100
                    ? `${data?.description.slice(0, 100)}...`
                    : data?.description}
                </p>
              </div>
            </div>
            <div>
              <button className="flex items-center h-full p-1 rounded-full hover:text-[var(--red)]">
                <BsPlayCircle className="text-5xl" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RadioHomeCard;
