import React from "react";
import Podcastcard from "./Podcastcard";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";

function PodcastCardSection({ gridCard }: any) {
  return (
    <div>
      <div className="flex">
        <div>
          <p className="text-xl font-[400] text-white lg:text-2xl md:text-2xl">
            Trending podcasts in
          </p>
        </div>
        {/* dropdown */}
        <div className="px-3 py-2 ml-2 text-white rounded-full select text-md searchbar">
          <select
            name="podcast"
            id="podcast"
            className="outline-none searchbar"
          >
            <option value="today">Business</option>
            <option value="yesterday">Education</option>
            <option value="lastweek">Funny</option>
          </select>
        </div>
      </div>
      <div>
        <p className="text-[10px] font-[400] text-[#7B9DB4] mt-[5px]">
          All Ears English Podcast
        </p>
      </div>
      {/* <Link to={RouteName.PodcastOpenEpisode}> */}
      <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 max-sm:gap-5">
        {gridCard.map((card: any, i: number) => (
          <div key={i}>
            <Podcastcard podcast={card} />
          </div>
        ))}
      </div>
      {/* </Link> */}
    </div>
  );
}

export default PodcastCardSection;
