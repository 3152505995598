import React from "react";
import img from "../../Assets/Images/wallpaper_symbol.png";
import PlaylistCoursel from "./shared/PlaylistCoursel";

function PlayListsPage({ creatorData, isLoading }: any) {
  // console.log(allPlaylists);
  return (
    <div>
      <PlaylistCoursel creatorData={creatorData} isLoading={isLoading} />
    </div>
  );
}

export default PlayListsPage;
