import React from "react";

const PayperviewDetail = () => {
  return (
    <>
      <div className="flex flex-col gap-2 mt-[20px] mb-[10px]">
        <div className="w-[350px] h-[40px] bg-[#1a1919] animate-pulse"></div>
        <div className="w-[150px] h-[20px] bg-[#1a1919] animate-pulse"></div>
        <div className="w-[100px] h-[20px] bg-[#1a1919] animate-pulse"></div>
        <div className="w-[80px] h-[20px] bg-[#1a1919] animate-pulse"></div>
        <div className="w-[350px] h-[80px] bg-[#1a1919] animate-pulse"></div>
        <div className="w-[350px] h-[30px] bg-[#1a1919] animate-pulse"></div>
      </div>
    </>
  );
};

export default PayperviewDetail;
