import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import PayPerViewCategoryCard from "./shared/PayPerViewCategoryCard";
import PayperviewService from "services/payperview/PayperviewServie";
import { RouteName } from "constant/routeNames";

function SeeAllPayPerCategories() {
  const navigate = useNavigate();

  const [categories, setCategories] = useState<any>(null);

  useEffect(() => {
    PayperviewService.getAllPayperviewCategories().then((res) => {
      //console.log(res.data);
      setCategories(res.ppv_categories);
    });
  }, []);

  return (
    <div>
      {/* main topic section */}
      <div>
        <button
          className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
          onClick={() => {
            navigate(RouteName.PayPerView.replace("/*",""));
          }}
        >
          <AiOutlineArrowLeft className="ml-3" />
        </button>
      </div>
      <div className="mb-8">
        <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-3xl max-sm:w-[60%] max-sm:ml-2">
          Pay Per View Categories
        </h1>
      </div>
      {categories && (
        <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 max-sm:w-full">
          {categories &&
            categories.map((category: any, index: number) => (
              <div key={index} className="mb-5 mr-4">
                <PayPerViewCategoryCard category={category} />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default SeeAllPayPerCategories;
