import axios from "axios";
import authHeader from "utils/auth-header";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;

const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

const checkContentType = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/video-type/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const getAllGenres = async () => {
  const response = await axios({
    method: "get",
    url: `/video/genres`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

//get details of movie by slig
const getMovieDetailsBySlug = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/movies/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};
//get details of tv series by slig
const getTVSeriesDetailsBySlug = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/tv-series/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

//get all movies
const getAllMovies = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/movies/all`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const getAllFilteredMovies = async (url: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/${url}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

//get random movies
const getRandomMovies = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/movies/random`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

// get random tvSeries
const getRandomTVSeries = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/tv-series/random`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

// get all tvSeries
const getAllTVSeries = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/tv-series/all`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

//get all filtered tvSeries
const getAllFilteredTVSeries = async (url: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/${url}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const getSliderDetails = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/video/slider`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const getMoviesAndTVSeriesByGenre = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/videos/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const likeMovie = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/movie/like`,
    headers: authHeader(),
  });

  return response.data;
};

const unlikeMovie = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/movie/unlike`,
    headers: authHeader(),
  });

  return response.data;
};

const likeTVSeries = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/tv-series/like`,
    headers: authHeader(),
  });

  return response.data;
};

const unlikeTVSeries = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/tv-series/unlike`,
    headers: authHeader(),
  });

  return response.data;
};

const updatePlayCount = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/video/record-play-count`,
    headers: authHeader(),
  });

  return response.data;
};

const updateMovieLastWatch = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/movie/record/watch-progress`,
    headers: authHeader(),
  });

  return response.data;
};
const updateTVSeriesLastWatch = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/tv-series/record/watch-progress`,
    headers: authHeader(),
  });

  return response.data;
};

const removeLastWatch = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/watch-progress/remove`,
    headers: authHeader(),
  });

  return response.data;
};

const getTVSeriesByStreamKey = async (stream: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/tv-series/episodes/${stream}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const getPlaylistStreamKey = async (stream: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/tv-series/related/episodes/${stream}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const getUserLastWatch = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/users/watching/contents`,
    headers: authHeader(),
  });

  return response.data;
};

const getSimilarVideoContent = async (slug:any)=> {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/movies/related/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
}

const getSimilarTvSeriesContent = async (slug:any)=> {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/tv-series/related/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
}

const VideoService = {
  getAllGenres,
  getMovieDetailsBySlug,
  getAllMovies,
  getSliderDetails,
  getAllTVSeries,
  getTVSeriesDetailsBySlug,
  getMoviesAndTVSeriesByGenre,
  likeMovie,
  unlikeMovie,
  likeTVSeries,
  unlikeTVSeries,
  getRandomTVSeries,
  getRandomMovies,
  checkContentType,
  getAllFilteredMovies,
  getAllFilteredTVSeries,
  updatePlayCount,
  updateMovieLastWatch,
  updateTVSeriesLastWatch,
  removeLastWatch,
  getTVSeriesByStreamKey,
  getUserLastWatch,
  getPlaylistStreamKey,
  getSimilarVideoContent,
  getSimilarTvSeriesContent,
};

export default VideoService;
