import React from "react";

function MostTunedSkeleton() {
  const arr = [1, 2, 3];
  return (
    <div>
      <div
        className={"flex justify-between  w-[85vw] mt-12 mr-12 max-sm:block"}
      >
        {arr.map((item: any) => (
          <div
            key={item}
            className="flex w-[95%] flex-col items-start justify-center"
          >
            <div className="w-[300px] h-[20px] bg-[#1a1919]"></div>
            <div className="w-[95%] h-[300px] mr-3 bg-[#1a1919] animate-pulse mt-2"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MostTunedSkeleton;
