import { GenreData } from 'constant/genre';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import GenreCard from './GenreCard';
import { useState, useEffect } from 'react';
import GenreService from 'services/GenreService';

function MusicGenreSlider() {
  const [genres, setGenres] = useState<any>([]);
  useEffect(() => {
    GenreService.getAllGenres().then((res: any) => {
      // console.log(res.data);
      setGenres(res.data);
    });
  }, []);
  return (
    <>
      <div>
        <div className="">
          <Swiper
            slidesPerView={5}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              370: {
                slidesPerView: 2,
              },
              700: {
                slidesPerView: 3,
              },
              1100: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
              1500: {
                slidesPerView: 6,
              },
              1600: {
                slidesPerView: 7,
              },
            }}
            modules={[Pagination]}
            className="mt-4 mb-5 mySwiper"
          >
            {genres &&
              genres.map((value: any, i: number) => (
                <SwiperSlide className="" key={i}>
                  <GenreCard key={i} value={value} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default MusicGenreSlider;
