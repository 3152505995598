import MostPlayedSongList from "./home/MostPlayedSongList";
import GenreSection from "./home/GenreSection";
import MusicCardCoursel from "./home/MusicCardCoursel";
import ArtistCardCoursel from "./home/ArtistCardCoursel";
import HeroSlider from "./home/HeroSlider";
import SubText from "components/shared/SubText";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import MusicService from "services/music/MusicService";
import AlbumCoursel from "./home/AlbumCoursel";
import { useSelector } from "react-redux";
import PlaylistService from "services/music/PlaylistService";
import PlaylistCoursel from "./playlist/PlaylistCoursel";
import HeroSkeleton from "../../components/skeleton/HeroSekeleton";
import MostPlayedSongSkeleton from "../../components/skeleton/MostPlayedSongSkeleton";
import MusicSkeleton from "../../components/skeleton/MusicSkeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";
import DiscoverAlbumSkeleton from "components/skeleton/DiscoverAlbumSkeleton";

// import MusicCardSkeleton from "./skeleton/MusicCardSkeleton";

function MusicPageHome() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [latestSongs, setLatestSongs] = useState([]);
  const [mostPlayed, setMostPlayed] = useState([]);
  const [userPlaylists, setUserPlaylists] = useState([]);
  const [isLoading, setIsLoding] = useState<any>(false);
  const [isLoadingMusic, setIsLodingMusic] = useState<any>(false);
  const [isLoadingPlaylist, setIsLodingPlaylist] = useState<any>(false);
  const [publicPlaylists, setPublicPlaylists] = useState<any>([]);

  useEffect(() => {
    setIsLodingPlaylist(true);
    if (loggedInUser) {
      PlaylistService.getUserPlaylists().then((res) => {
        setIsLodingPlaylist(false);
        setUserPlaylists(res.data.data);
        // console.log(res.data.data);
      });
    }
  }, [loggedInUser]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setIsLoding(true);
    setIsLodingMusic(true);
    MusicService.getLatestMusic()
      .then((res: any) => {
        setIsLodingMusic(false);
        setLatestSongs(res.latestSongs);
      })
      .catch((e) => {
        setIsLodingMusic(false);

        // console.log(e);
      });
    MusicService.getMostPlayed()
      .then((res: any) => {
        setIsLoding(false);
        setMostPlayed(res.mostPlayedSongs);
      })
      .catch((e) => {
        setIsLoding(false);
        // console.log(e);
      });
    PlaylistService.getPublicPlaylists()
      .then((res: any) => {
        setIsLoding(false);
        // console.log(res.data.data);
        if (loggedInUser) {
          const filteredData = res.data.data.filter(
            (item: any) => item?.user?.id !== loggedInUser?.id
          );
          setPublicPlaylists(filteredData);
        } else {
          setPublicPlaylists(res.data.data);
        }
      })
      .catch((e) => {
        setIsLoding(false);
        // console.log(e);
      });
  }, []);

  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  return (
    <>
      <Helmet>
        <title>AYOZAT Music</title>
        <meta
          name="description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <link rel="canonical" href={`${FE_URL}/music`} />
        <meta property="og:title" content="AYOZAT Music" />
        <meta
          property="og:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:url" content={`${FE_URL}/music`} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AYOZAT Music" />
        <meta
          name="twitter:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT Music" />
        <meta name="twitter:url" content={`${FE_URL}/music`} />
      </Helmet>

      <div className={`w-full gap-5 `}>
        {/* hero section */}
        <div className="flex max-sm:w-full max-sm:block max-lg:block max-xl:block max-xl:w-full">
          {!isLoading && (
            <div className="w-[70%] max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full ">
              <HeroSlider value={mostPlayed[0]} />
            </div>
          )}
          {isLoading && <HeroSkeleton />}
          <div className="px-4 w-[30%] max-sm:w-full max-sm:px-0  max-md:block max-lg:block max-lg:w-full max-lg:px-0 max-lg:mt-3 max-xl:w-full max-xl:block max-xl:px-0 max-xl:mt-3 ">
            {!isLoading && (
              <MostPlayedSongList
                audios={mostPlayed.slice(1, 5)}
                user={loggedInUser}
                userPlaylists={userPlaylists}
                setUserPlaylists={setUserPlaylists}
              />
            )}
            {isLoading && <MostPlayedSongSkeleton />}
          </div>
        </div>

        {/* New Release and genre */}
        <div className="flex justify-between mb-10 max-sm:w-full max-sm:block max-md:block">
          <div className="w-[68%] max-sm:w-full max-md:w-full">
            <div className="items-center justify-between mt-[40px] flex max-sm:mx-0 max-md:mt-[9%]">
              <SubText text="New Releases" className="font-light" />
              <Link to={RouteName.SeeAllNewReleaseRoute}>
                <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer">
                  See all
                </p>
              </Link>
            </div>
            {!isLoadingMusic && (
              <MusicCardCoursel
                audios={latestSongs}
                user={loggedInUser}
                // favorite={favorite}
              />
            )}
            {isLoadingMusic && <MusicSkeleton />}
            <div className="flex flex-row items-center justify-between mt-[30px] max-sm:mx-1 ">
              <SubText text="Discover Artists" className="font-light" />
              <Link to={RouteName.SeeAllArtistRoute + "?page=1"}>
                <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer">
                  See all
                </p>
              </Link>
            </div>
            <ArtistCardCoursel />
          </div>
          <div className=" mt-5 pl-5 pr-3 w-[30%] max-sm:w-full max-md:block max-lg:block max-lg:w-full max-md:pl-0 max-lg:pr-0">
            <GenreSection />
          </div>
        </div>

        {/* trending playlist today */}
        {/* <div className="mb-28">
          <div className="flex flex-row items-center justify-between mt-5">
            <SubText text="Trending playlist today " className="font-light" />
            <p className="font-[500] pr-4 text-[15px] text-[#F50537] cursor-pointer">
              See all
            </p>
          </div>
          <PlayListCardCoursel />
        </div> */}
        {/* trending playlist today */}
        <div className="mb-10">
          <div className="flex flex-row items-center justify-between mt-5">
            <SubText text="Discover Albums" className="font-light" />
            <Link to={RouteName.SeeAllAlbumsRoute + "?page=1"}>
              <p className="font-[500] pr-4 text-[15px] text-[#F50537] cursor-pointer">
                See all
              </p>
            </Link>
          </div>
          <AlbumCoursel />
        </div>

        {/* public playlists */}
        {publicPlaylists && publicPlaylists?.length > 0 && (
          <div className="mb-10">
            <div className="flex flex-row items-center justify-between mt-5">
              <SubText text="Discover Playlists" className="font-light" />
              <Link to={RouteName.SeeAllDiscoverPlaylistsRoute}>
                <p className="font-[500] pr-4 text-[15px] text-[#F50537] cursor-pointer">
                  See all
                </p>
              </Link>
            </div>
            <PlaylistCoursel
              playlists={publicPlaylists}
              isLoadingPlaylist={isLoadingPlaylist}
              isLoading={isLoading}
            />
          </div>
        )}

        {loggedInUser && userPlaylists && userPlaylists?.length > 0 && (
          <div className="mb-28">
            <div className="flex flex-row items-center justify-between mt-5">
              <SubText text="Your Playlists" className="font-light" />
              {/* <Link to={RouteName.SeeAllAlbumsRoute}>
                <p className="font-[500] pr-4 text-[15px] text-[#F50537] cursor-pointer">
                  See all
                </p>
              </Link> */}
            </div>
            <PlaylistCoursel
              playlists={userPlaylists}
              isLoadingPlaylist={isLoadingPlaylist}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default MusicPageHome;
