import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();
  return (
    <button
      className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
      onClick={() => {
        navigate(-1);
      }}
    >
      <AiOutlineArrowLeft className="ml-3" />
    </button>
  );
}

export default BackButton;
