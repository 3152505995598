import RadioCard from "pages/radioPage/shared/RadioCard";
import NoSearch from "pages/searchPage/shared/NoSearch";
import React from "react";
import { useSelector } from "react-redux";

const CreatorRadio = ({ creatorData }: any) => {
  const stations = creatorData?.radios || [];
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  return (
    <div>
      {/* card section */}
      {stations?.length === 0 && stations ? (
        <NoSearch text="Radios" />
      ) : (
        <div>
          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {stations?.length > 0 &&
              stations?.map((station: any, i: any) => (
                <div key={i}>
                  <RadioCard station={station} user={loggedInUser} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatorRadio;
