import { Link } from "react-router-dom";
import { contentNavData } from "constant/ContentNavBarData";

function NavbarPageNav() {
    const enabled = localStorage.getItem("ppv_enabled");

  return (
    <div
      className={`md:w-[300px] text-center navbar max-sm:w-[320px] max-sm:top-[20px] max-sm:h-[240px] submenu-main`}
    >
      <div className="items-center text-gray-100">
        <div className="grid grid-cols-3 mb-2 max-md:grid-cols-2 max-sm:grid-cols-3">
          {enabled === "1" ? (
            <>
              {contentNavData.map((data: any, i: number) => (
                <div className="mb-2 " key={i}>
                  <Link to={data.link}>
                    <button className="p-1 mb-2 rounded-full button-red md:p-2 max-sm:p-5">
                      <data.icon className="text-lg md:text-2xl max-sm:text-3xl" />
                    </button>
                    <p className="mb-2 text-xs gray-text">{data.title}</p>
                  </Link>
                </div>
              ))}
            </>
          ) : (
            <>
              {contentNavData.slice(0, -1).map((data: any, i: number) => (
                <div className="mb-2 " key={i}>
                  <Link to={data.link}>
                    <button className="p-1 mb-2 rounded-full button-red md:p-2 max-sm:p-5">
                      <data.icon className="text-lg md:text-2xl max-sm:text-3xl" />
                    </button>
                    <p className="mb-2 text-xs gray-text">{data.title}</p>
                  </Link>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default NavbarPageNav;
