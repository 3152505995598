import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import PayPerViewCard from "./shared/PayPerViewCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PayperviewService from "services/payperview/PayperviewServie";
import PodcastGenreSelector from "pages/podcastPage/shared/PodcastGenreSelector";
import PayperviewGenreSelector from "./videoPlayer/shared/PayperviewGenreSelector";
import PayperviewDiscoverSkeleton from "components/skeleton/PayperviewDiscoverSkeleton";
import PayperviewPickedout from "components/skeleton/PayperviewPickedout";
import { RouteName } from "constant/routeNames";
import { Adsense } from "@ctrl/react-adsense";
import GeneralServices from "services/General/GeneralServices";

function DiscoverByPayperviewCategory() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [events, setEvents] = useState<any>([]);
  const [genre, setGenre] = useState<any>(slug);
  const [category, setCategory] = useState<any>("");
  const [categories, setCategories] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gAdSlot, setgAdSlot] = useState<any>();
  const [categoryBack, setCategoryBack] = useState<boolean>(false);
  const [detailsBack, setDetailsBack] = useState<any>();
  const location = useLocation();

  useEffect(() => {
    const { categoryPage } = location.state || { categoryPage: null };
    setCategoryBack(categoryPage);
  }, []);

  useEffect(() => {
    const { prevPage } = location.state || { prevPage: null };
    setDetailsBack(prevPage);
  }, []);

  useEffect(() => {
    setGenre(slug);
    if (slug) {
      setIsLoading(true);
      PayperviewService.getCategoryBySlug(slug)
        .then((res) => {
          setIsLoading(false);
          setEvents(res?.data);
          setCategory(res?.category);
        })
        .catch((e: any) => {
          // console.log(e);
        });
    }
  }, [slug]);

  useEffect(() => {
    PayperviewService.getAllPayperviewCategories()
      .then((res) => {
        setCategories(res?.ppv_categories);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_ppv_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    const handleBrowserBack = () => {
      navigate(RouteName.PayPerView.replace("/*", ""));
    };

    window.addEventListener("popstate", handleBrowserBack);

    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, [navigate]);

  const handleGoBack = () => {
    if (detailsBack) {
      navigate(RouteName.PayperviewDetailsRoute.replace(":slug", detailsBack));
    } else if (categoryBack) {
      navigate(
        RouteName.PayPerView.replace("*", RouteName.SeeAllPayperviewCategories)
      );
    } else {
      navigate(RouteName.PayPerView.replace("/*", ""));
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 1);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <div>
        <button
          className="w-10 h-10 mb-5 bg-white rounded-full ml"
          onClick={handleGoBack}
        >
          <AiOutlineArrowLeft className="ml-3" />
        </button>
      </div>
      {!isLoading && (
        <div>
          {/* main topic section */}

          <div className="flex items-center justify-between mb-8 max-sm:block w-[98%]">
            <h1 className="text-xl font-medium text-white lg:text-2xl md:text-3xl max-sm:ml-2 max-sm:mb-5">
              Discover pay-per view in{" "}
              <span className="red-text">{category?.name}</span>
            </h1>
            <PayperviewGenreSelector slug={genre} categories={categories} />
          </div>
          {gAdSlot && (
            <Adsense
              client="ca-pub-5773929890067376"
              slot={gAdSlot}
              style={{
                display: "flex",
                marginBottom: "20px",
                marginTop: "20px",
                height: "90px",
                justifyContent: "center",
              }}
              format="horizontal"
              data-full-width-responsive="true"
              // format="fluid"
            />
          )}
          {/* podcast grid */}
          <div
            className={`${
              events && events?.length > 0 ? `grid` : `mt-40`
            } w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full`}
          >
            {events && events?.length > 0 ? (
              <>
                {events?.map((paypervewEvent: any, index: number) => (
                  <div key={index} className="mb-5 mr-4">
                    <PayPerViewCard event={paypervewEvent} />
                  </div>
                ))}
              </>
            ) : (
              <>
                <p className="w-full text-center text-white">
                  There are no {category?.name} in this category at this moment
                </p>
              </>
            )}
          </div>
        </div>
      )}
      {isLoading && <PayperviewPickedout />}
    </>
  );
}

export default DiscoverByPayperviewCategory;
