import MovieCarouselCard from "pages/videoPage/shared/MovieCarouselCard";
import NoSearch from "./shared/NoSearch";
import CreatorVideoCard from "pages/BecomeACreator/shared/CreatorVideoCard";

function SearchVideo({ data, user }: any) {
  const videos = data?.movies || [];
  const tvSeries = data?.tv_shows || [];
  const creatorsVideos = data?.creatorVideos || [];

  return (
    <div>
      <div>
        {/* top section */}
        <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
          <div>
            <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl">
              {/* search videos */}
              Videos
            </h1>
          </div>
        </div>
        {/* card section */}
        {videos.length === 0 && videos ? (
          <NoSearch text="Videos" />
        ) : (
          <div>
            <div className="mt-4 mb-5 w-[98%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
              {videos.length > 0 &&
                videos.map((card: any, i: any) => (
                  <div key={i}>
                    <div className="mx-3 my-2 max-sm:mx-1">
                      <MovieCarouselCard
                        id={card.id}
                        time={card.runtime}
                        title={card.title}
                        info={card.title}
                        year={card.release}
                        type={card.genre}
                        img={card.thumbnail_url}
                        slug={card.slug}
                        user={user}
                        movie={card}
                        searchPage={true}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
      {/* tv Series */}
      <div>
        {/* top section */}
        <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
          <div>
            <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl">
              {/* search tvSeries */}
              TV Series
            </h1>
          </div>
        </div>
        {/* card section */}
        {tvSeries.length === 0 && tvSeries ? (
          <NoSearch text="TV Series" />
        ) : (
          <div>
            <div className="mt-4 mb-5 w-[98%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
              {tvSeries.length > 0 &&
                tvSeries.map((card: any, i: any) => (
                  <div key={i}>
                    <div className="mx-3 my-2 max-sm:mx-1">
                      <MovieCarouselCard
                        id={card?.id}
                        // time={card?.runtime}
                        title={card?.title}
                        // info={card?.title}
                        // year={card?.release}
                        // type={card?.genre}
                        img={card?.thumbnail_url}
                        seasons={card?.no_of_seasons}
                        first_episode={card?.first_episode}
                        slug={card?.slug}
                        tv={true}
                        tvSeries={card}
                        user={user}
                        searchPage={true}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
      {/* creator videos */}
      <div>
        {/* top section */}
        <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
          <div>
            <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl">
              {/* search creator videos */}
              Creators Videos
            </h1>
          </div>
        </div>
        {/* card section */}
        {creatorsVideos.length === 0 && creatorsVideos ? (
          <NoSearch text="Creator Videos" />
        ) : (
          <div>
            <div className="mt-4 mb-5 w-[95%] grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
              {creatorsVideos &&
                creatorsVideos?.map((video: any, index: any) => (
                  <div key={index} className="mb-5 mr-4">
                    <CreatorVideoCard
                      video={video}
                      slug={video?.creator.slug}
                      // season={episode?.season}
                      // id={episode?.id}
                      // isLoading={isLoading}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchVideo;
