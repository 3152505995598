import React, { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import RadioStationCard from "../shared/RadioStationCard";
import RadioService from "services/radio/RadioService";
import SubText from "components/shared/SubText";
import StationSkeleton from "../skeleton/StationSkeleton";

function StationCoursel({ stations, isLoadingStation }: any) {
  // const [stations, setStations] = useState([]);
  // const [isLoadingStation, setIsLodingStation] = useState<any>(false);

  // useEffect(() => {
  //   setIsLodingStation(true);
  //   RadioService.getMostTunedRadioStations().then((res) => {
  //     // console.log(res.slides);
  //     setIsLodingStation(false);
  //     setStations(res.mostTunedRadioStations);

  //     const radioIds = res.mostTunedRadioStations.map(
  //       (item: any) => item.stream_url
  //     );
  //     console.log(radioIds);
  //   });
  // }, []);
  return (
    <>
      {!isLoadingStation && (
        <div>
          {/* top section */}
          <div className="mt-10 mb-8 w-[98%]">
            <div>
              <SubText
                text="Most tuned into stations
"
                className="font-bold"
              />
            </div>
          </div>
          {/* coursel */}
          <div className="">
            <Swiper
              slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                370: {
                  slidesPerView: 2,
                },
                900: {
                  slidesPerView: 3,
                },
                1100: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
                1500: {
                  slidesPerView: 5,
                },
                1600: {
                  slidesPerView: 6,
                },
              }}
              modules={[Pagination, Navigation]}
              navigation={true}
              className="mt-5 mb-5 mySwiper"
            >
              {stations.map((station: any, i: number) => (
                <div key={i}>
                  <SwiperSlide className="">
                    <div className="mx-3">
                      <RadioStationCard station={station} />
                    </div>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      )}
      {isLoadingStation && <StationSkeleton />}
    </>
  );
}

export default StationCoursel;
