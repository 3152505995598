import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ChatBody from "./components/ChatBody";
import ChatHeader from "./components/ChatHeader";
import ChatInput from "./components/ChatInput";
import ScrollBottomBtn from "./components/ScrollBottomBtn";
import { io } from "socket.io-client";

const ChatWidget = ({ event, setIsNewChat }: any) => {
  // const CHAT_SOCKET_URL = process.env.REACT_APP_CHAT_SERVER;
  const CHAT_SOCKET_URL = "https://test-socket-chat-server.onrender.com";
  const [showChatProgress, setChatProgress] = useState<boolean>(false);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [firstScroll, setFirstScroll] = useState(false);
  const [submitScroll, setSubmitScroll] = useState(false);
  const [newChats, setNewChats] = useState<any[]>([]);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollButtonRef = useRef<HTMLButtonElement | null>(null);

  // Function to handle scrolling
  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const isNearBottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 100; // 100px threshold from the bottom
      setShowScrollButton(!isNearBottom);
    }
  };

  const scroll = () => {
    const container = containerRef.current;
    setTimeout(() => {
      if (container) {
        container.scrollTop = container.scrollHeight - container.clientHeight;
        setFirstScroll(false);
      }
    }, 100);
  };
  // Initial scroll to bottom, only once
  useEffect(() => {
    scroll();
  }, [firstScroll]);

  useEffect(() => {
    if (submitScroll) {
      scroll();
    }
  }, [submitScroll]);

  // useEffect(() => {
  //   const container = containerRef.current;
  //   setTimeout(() => {
  //     if (container) {
  //       container.scrollTop = container.scrollHeight - container.clientHeight;
  //       setSubmitScroll(false);
  //     }
  //   }, 100);
  // }, [submitScroll]);

  // Attach scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // Function to scroll to the bottom of the chat
  const scrollToBottom = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
      setShowScrollButton(false);
    }
  };

  // console.log(event);
  useEffect(() => {
    if (!CHAT_SOCKET_URL) {
      throw new Error(
        "REACT_APP_CHAT_SERVER environment variable is not defined."
      );
    }
    const socket = io(CHAT_SOCKET_URL); // Use the environment variable for the chat server URL

    socket.on("connect", () => {
      if (event?.id) {
        console.log(`Connected to the server in room-${event.id}`);
        socket.emit("joinRoom", {
          payperviewId: event.id,
          token: loggedInUser?.access_token,
        });
      }
    });

    socket.on("updateMessages", (data: any) => {
      console.log("Received updated messages: ");
      console.log(data);
      if (data.firstRender) {
        if (data?.data?.length >= 0) {
          setNewChats(data.data);
        } else {
          setNewChats([data.data]);
        }
        setFirstScroll(true);
      } else {
        setNewChats((prevChats) => {
          if (prevChats.length >= 0) {
            return [...prevChats, data.data];
          } else {
            return data.data;
          }
        });
      }
      setIsNewChat(true);
      setSubmitScroll(true);
      // console.log(newMessages);
    });

    socket.on("chat-onChangeReceive", ({ onChange, userId }: any) => {
      console.log("onChangeReceive messages: :userId", onChange, userId);
      // console.log(newMessages);
      if (userId === loggedInUser.id) {
        setChatProgress(false);
      } else {
        setChatProgress(onChange);
      }
      // console.log(newMessages);
    });
    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    // Cleanup function to disconnect socket when component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  // useEffect(() => {
  //   if (!CHAT_SOCKET_URL) {
  //     throw new Error(
  //       "REACT_APP_CHAT_SERVER environment variable is not defined."
  //     );
  //   }

  //   const socket = new WebSocket(CHAT_SOCKET_URL);

  //   socket.addEventListener('open', () => {
  //     if (event?.id) {
  //       console.log(`Connected to the server in room-${event.id}`);
  //       const joinMessage = JSON.stringify({
  //         event: 'joinRoom',
  //         data: {
  //           payperviewId: event.id,
  //           token: loggedInUser?.access_token,
  //         },
  //       });
  //       socket.send(joinMessage);
  //     }
  //   });

  //   socket.addEventListener('message', (message) => {
  //     const parsedMessage = JSON.parse(message.data);
  //     const { event, data } = parsedMessage;

  //     if (event === 'updateMessages') {
  //       console.log("Received updated messages: ", data);
  //       if (data.firstRender) {
  //         setNewChats(data.data.length >= 0 ? data.data : [data.data]);
  //         setFirstScroll(true);
  //       } else {
  //         setNewChats((prevChats) => prevChats.length >= 0 ? [...prevChats, data.data] : data.data);
  //       }
  //       setIsNewChat(true);
  //     }

  //     if (event === 'chat-onChangeReceive') {
  //       console.log("onChangeReceive messages: :userId", data.onChange, data.userId);
  //       if (data.userId === loggedInUser.id) {
  //         setChatProgress(false);
  //       } else {
  //         setChatProgress(data.onChange);
  //       }
  //     }
  //   });

  //   socket.addEventListener('close', () => {
  //     console.log("Disconnected from server");
  //   });

  //   // Cleanup function to disconnect socket when component unmounts
  //   return () => {
  //     socket.close();
  //   };
  // }, [event, loggedInUser]);

  return (
    <div className="flex flex-col h-[85vh] rounded-2xl border-gray-700 border-[2px] p-[15px]">
      <ChatHeader event={event} liveUsers={10} />
      <div
        ref={containerRef}
        className="flex-1 px-4 pb-4 overflow-y-auto scrollbar-hide"
        style={{ maxHeight: "calc(100% - 40px)" }}
      >
        <ChatBody
          chats={newChats}
          event={event}
          showChatProgress={showChatProgress}
        />
        <ScrollBottomBtn
          showScrollButton={showScrollButton}
          scrollButtonRef={scrollButtonRef}
          scrollToBottom={scrollToBottom}
        />
      </div>
      <ChatInput
        inputValue={inputValue}
        setInputValue={setInputValue}
        event={event}
        setSubmitScroll={setSubmitScroll}
        setNewChats={setNewChats}
        newChats={newChats}
      />
    </div>
  );
};

export default ChatWidget;
