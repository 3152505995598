import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Podcastcard from "./Podcastcard";
import SubText from "components/shared/SubText";
import { useSelector } from "react-redux";
import NewPodcastSkeleton from "components/skeleton/NewPodcastSkeleton";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";

function NewestPodcastCoursel({ podcasts, isLoading }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  // const [favorite, setFavorite] = useState<any>();
  // const [isLoading, setIsLoading] = useState<any>(false);

  // useEffect(() => {
  //   setIsLoading(true);
  //   if (loggedInUser) {
  //     LikeService.getFavoriteById(loggedInUser?.id).then((res) => {
  //       setIsLoading(false);
  //       const favoritesIds = res.profile.favorite_podcasts.map(
  //         (item: any) => item.id
  //       );
  //       setFavorite(favoritesIds);
  //     });
  //   }
  // }, [loggedInUser]);

  // useEffect(() => {
  //   if (favorite?.includes(podcast.id)) {
  //     setIsLiked(true);
  //   } else {
  //     setIsLiked(false);
  //   }
  // }, [favorite]);

  // const handleFavorite = () => {
  //   const favoritePodcast = {
  //     id: podcast.id.toString(),
  //     object_type: "podcast",
  //   };

  //   if (!isLiked) {
  //     PodcastService.likePodcast(favoritePodcast).then((res) => {});
  //     setIsLiked(true);
  //   } else {
  //     PodcastService.unlikePodcast(favoritePodcast).then((res) => {});
  //     setIsLiked(false);
  //   }
  // };

  // useEffect(() => {
  //   PodcastService.getLatestPodcasts().then((res) => {
  //     setPodcasts(res.data);
  //     // console.log(res.data);
  //   });
  // }, []);

  return (
    <>
      {!isLoading && (
        <div className="mb-40">
          {podcasts && (
            <>
              {/* top section */}
              <div className="flex justify-between w-full mt-10 mb-8">
                <div>
                  <SubText text="New podcasts" className="font-bold" />
                </div>
                <Link to={RouteName.SeeAllNewPodcastRoute + "?page=1"}>
                  <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-4">
                    See all
                  </p>
                </Link>
              </div>
              {/* coursel */}
              <div className="">
                <Swiper
                  slidesPerView={5}
                  breakpoints={{
                    0: {
                      slidesPerView: 2,
                    },
                    370: {
                      slidesPerView: 2,
                    },
                    700: {
                      slidesPerView: 3,
                    },
                    1100: {
                      slidesPerView: 4,
                    },
                    1200: {
                      slidesPerView: 5,
                    },
                    1500: {
                      slidesPerView: 6,
                    },
                    1600: {
                      slidesPerView: 7,
                    },
                  }}
                  modules={[Pagination, Navigation]}
                  navigation={true}
                  className="mt-4 mb-5 mySwiper"
                >
                  {podcasts.map((podcast: any, i: number) => (
                    <div key={i}>
                      <SwiperSlide className="">
                        <Podcastcard
                          podcast={podcast}
                          user={loggedInUser}
                          // favorite={favorite}
                        />
                      </SwiperSlide>
                    </div>
                  ))}
                </Swiper>
              </div>
            </>
          )}
        </div>
      )}
      {isLoading && <NewPodcastSkeleton />}
    </>
  );
}

export default NewestPodcastCoursel;
