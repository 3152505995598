import React, { useEffect, useState } from "react";

function PPVAdvertiesmentCard({ data }: any) {
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    const width = window.innerWidth;
    if (width < 500) {
      setIsActive(true);
    }
  }, []);

  return (
    <div
      className="flex h-[65vh] w-full -left-[400px] -top-[350px]  rounded-lg overflow-hidden"
      style={{
        backgroundImage: `url(${
          isActive === true ? data?.thumbnail_banner_url : data.thumbnail_url
        })`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className=" text-white pl-[32px] bottom-0 py-8 absolute w-full bg-gradient-to-t from-black to-transparent max-sm:w-full overflow-hidden max-sm:pl-[20px]">
        <p className="bg-[#F50537] inline-block px-3 text-[11px] items-center p-1 text-center">
          ADVERTISEMENT
        </p>
      </div>
    </div>
  );
}

export default PPVAdvertiesmentCard;
