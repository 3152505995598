import React from 'react'

const MusicCategorySkeleton = () => {

    const arr = [1, 2, 3, 4, 5, 6, 7, 8];

    return (
        <div className='flex flex-col'>
            {/* <div className='w-[350px]  h-[50px] bg-[#1a1919] animate-pulse'></div>
            <div className='w-[100px] h-[20px] bg-[#1a1919] animate-pulse mt-2'></div> */}

            <div className='flex flex-row justify-between mt-[40px]'>
                <div className='lg:flex lg:flex-row max-md:flex-col max-sm:flex-col items-baseline gap-5'>
                    <div className='flex flex-col'>
                        <div className='flex flex-row items-end justify-between gap-[500px]'>
                            <div className='w-[100px] h-[30px] bg-[#1a1919] animate-pulse'></div>
                            <div className='w-[100px] h-[30px] bg-[#1a1919] animate-pulse'></div>
                        </div>
                        <div className='grid lg:grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2 gap-5 mt-[20px]'>
                            {arr.map((item: any) => (
                                <div key={item}>
                                    <div className='w-[150px] max-sm:w-[250px] h-[150px] bg-[#1a1919] animate-pulse'></div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <div className='flex flex-row items-end justify-between gap-[500px]'>
                            <div className='w-[90px] h-[30px] bg-[#1a1919] animate-pulse'></div>
                            <div className='w-[90px] h-[30px] bg-[#1a1919] animate-pulse'></div>
                        </div>
                        <div className='flex flex-col gap-5 mt-[20px]'>
                            {arr.map((item: any) => (
                                <div key={item}>
                                    <div className='w-full h-[70px] bg-[#1a1919] animate-pulse'></div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default MusicCategorySkeleton