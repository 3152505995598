import { useNavigate } from "react-router-dom";

function PurchasedSection({ ppv_event }: any) {
  const navigate = useNavigate();

  const handlePurchaseButton = () => {
    navigate(`/ppv/details/${ppv_event?.slug}`);
  };

  return (
    <div className="flex items-center justify-center h-screen max-sm:block max-sm:w-full max-sm:p-5 max-sm:h-screen max-sm:items-center">
      <div className="flex items-center justify-between w-3/4 gap-6 p-10 mx-auto text-gray-300 bg-gray-700 rounded max-sm:block max-sm:w-[98%] ">
        <div className="flex flex-col items-center justify-center w-1/2 max-sm:w-full ">
          <h1 className="text-[21px] font-bold text-gray-400">
            {ppv_event?.title}
          </h1>
          <div>
            <h1 className="text-[27px] font-bold text-gray-300">
              ${ppv_event?.price}
            </h1>
          </div>
          <div className="mt-4">
            <img
              src={ppv_event?.bannerImage}
              alt={ppv_event?.title}
              className="object-cover w-3/4 mx-auto max-sm:w-[95%]"
            />
          </div>
        </div>
        <div className="w-1/2 px-4 text-center max-sm:w-full max-sm:px-0 max-sm:mt-10">
          <h1 className="mb-3 text-xl font-bold text-gray-200">
            Access Restricted, Purchase Required!!
          </h1>
          <p className="text-base text-gray-400">
            To view this pay-per-view event, you need to make a purchase. Click
            'Purchase' to buy your ticket and enjoy the live broadcast. Thank
            you!
          </p>
          <button
            className="flex mx-auto mt-8 px-10 py-3 bg-[#f30543] rounded-full"
            onClick={handlePurchaseButton}
          >
            Purchase Event
          </button>
        </div>
      </div>
    </div>
  );
}

export default PurchasedSection;
