import { useEffect, useState } from 'react';
import GenreService from 'services/GenreService';
import TrendingNearYouSideBar from 'components/shared/TrendingNearYouSideBar';

function VideoPageDiscover() {
  // const { id } = useParams();
  // console.log(id);
  const [genres, setGenres] = useState([]);
  const [, setLoading] = useState(false);

  //load data according to the id
  useEffect(() => {
    setLoading(true);
    GenreService.getAllGenres()
      .then((res) => {
        // console.log(res.data);
        setGenres(res.data);
        setLoading(false);
      })
      .catch((e) => {
        // console.log(e);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* main topic section */}
      <div className="mb-8">
        <h1 className="text-xl font-medium text-white lg:text-2xl md:text-3xl max-sm:w-[60%] max-sm:ml-2">
          Discover Movies and TV series
        </h1>
      </div>
      {/* coursel */}
      {genres &&
        genres.map((genre: any, i: number) => (
          <TrendingNearYouSideBar genre={genre} key={i} />
        ))}
      {/* 
      <TrendingNearYou cardDetails={cardDetails && cardDetails} />

      <TrendingNearYou cardDetails={cardDetails && cardDetails} />

      <TrendingNearYou cardDetails={cardDetails && cardDetails} /> */}
      <div className="-mt-20">{/* <TrendingNearYou /> */}</div>

      <div className="-mt-20">{/* <TrendingNearYou /> */}</div>
    </div>
  );
}

export default VideoPageDiscover;
