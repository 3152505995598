import { useEffect } from "react";
import EditProfileLeftSection from "./editProfile/EditProfileLeftSection";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RouteName } from "constant/routeNames";

function EditProfile({ handleClick, isActive, anyPlayerActive }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedInUser) {
      navigate(RouteName.pageNotFound);
    }
  }, []);
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      {/* <ContentNavbar handleClick={handleClick} />
      <WelcomePageSidebar isActive={isActive} navData={contentNavData} /> */}
      <div
        className={
          isActive
            ? "md:pl-[100px] pl-[80px] duration-500 p-2 mt-2 ml-4 "
            : "pl-0 duration-500 p-2 mt-2 ml-3"
        }
      >
        <div
          className="overflow-hidden max-sm:w-[100%] "
          // main back image
          style={{
            color: "#fff",
            backgroundImage: `url('https://cdn.wallpapersafari.com/31/38/qjUg2J.jpg')`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="loginpage-back">
            <div className="flex justify-between mx-auto max-sm:block max-sm:w-full max-md:block max-md:w-full max-lg:full max-lg:block max-sm:mx-0 max-md:mx-0">
              <div className=" max-sm:w-full max-md:w-full max-lg:w-full">
                <EditProfileLeftSection />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
