import React from "react";

function MessagePopUp({ message, onClose }: any) {
  return (
    <div className="flex justify-center items-center rounded-lg w-[70%] min-h-[500px] mx-auto bg-[#313131]">
      <div className="flex flex-col items-center">
        <h1 className="font-bold text-center text-white text-2xl mb-3">
          {message?.heading}
        </h1>
        <p className="text-base text-center text-white w-2/3">
          {message?.text}
        </p>
        <button
          className="flex mx-auto mt-8 px-10 py-2 bg-[#f30543] rounded-full"
          onClick={onClose}
        >
          <h1 className="mx-3 my-auto text-lg text-white">
            {message?.btnText}
          </h1>
        </button>
      </div>
    </div>
  );
}

export default MessagePopUp;
