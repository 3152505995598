import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ChannelCard } from "./shared/ChannelCard";
import { useNavigate, useParams } from "react-router-dom";
import LiveTVService from "services/liveTv/LiveTVService";
import TvChannelSkeleton from "components/skeleton/TvChannelSkeleton";
import TvChannelSeeAllSkeleton from "components/skeleton/TvChannelSeeAllSkeleton";
import LiveTVGenreSelector from "./shared/LiveTVGenreSelector";
import NoSearch from "pages/searchPage/shared/NoSearch";
import { Adsense } from "@ctrl/react-adsense";
import GeneralServices from "services/General/GeneralServices";

function SeeAllChannels() {
  const { slug } = useParams();
  // const [genre, setGenre] = useState<any>(slug);
  const [channels, setChannels] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [category, setCategory] = useState<any>(slug);
  const [gAdSlot, setgAdSlot] = useState<any>();
  
  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_livetv_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === '1') {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  
  useEffect(() => {
    setCategory(slug);
  }, [slug]);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    LiveTVService.getAllChannels().then((res) => {
      setIsLoading(false);
      setChannels(res.data);
      //   console.log(res.data);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (category === "all") {
      LiveTVService.getAllChannels().then((res) => {
        setIsLoading(false);
        setChannels(res.data);
        //   console.log(res.data);
      });
    } else {
      LiveTVService.getLiveTvByCategory(category).then((res) => {
        setIsLoading(false);
        setChannels(res.data);
        //   console.log(res.data);
      });
    }
  }, [category]);

  // category
  useEffect(() => {
    setCategory("all");
  }, []);

  return (
    <>
      {!isLoading && (
        <div>
          {/* main topic section */}
          <div>
            <button
              className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
              onClick={() => {
                navigate(-1);
              }}
            >
              <AiOutlineArrowLeft className="ml-3" />
            </button>
          </div>
          <div className="flex items-center justify-between mb-8 max-sm:block w-[98%]">
            <h1 className="text-xl font-medium text-white lg:text-2xl md:text-3xl max-sm:w-[60%] max-sm:ml-2">
              All Channels
            </h1>
            <LiveTVGenreSelector
              category={category}
              setCategory={setCategory}
              page={true}
            />
          </div>
          {gAdSlot && (
            <Adsense
              client="ca-pub-5773929890067376"
              slot={gAdSlot}
              style={{ display: 'flex', marginBottom:'20px', marginTop:'20px', height:'90px', justifyContent: 'center' }}
              format="horizontal"
              data-full-width-responsive="true"
              // format="fluid"
            />
          )}
          
          {channels && (
            <div className="">
              {channels && (
                <>
                  {channels?.length > 0 ? (
                    <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 max-sm:w-full">
                      {channels.map((channel: any, index: number) => (
                        <div key={index} className="mb-10">
                          <ChannelCard data={channel} img={channel.thumbnail} />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-full">
                      <NoSearch text={category.replace("-", " ")} />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
      {isLoading && <TvChannelSeeAllSkeleton />}
    </>
  );
}

export default SeeAllChannels;
