import PlayerLoadingSpin from "components/shared/PlayerLoadingSpin";
import React from "react";
import {
  AiFillPauseCircle,
  AiFillPlayCircle,
  AiFillStepBackward,
  AiFillStepForward,
} from "react-icons/ai";
import { FiShuffle } from "react-icons/fi";
import { TbRepeat, TbRepeatOff, TbRepeatOnce } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { formatDurationHr_Min_Sec } from "utils/timeFunctions";

function ControlButtons({
  fullScreen,
  isPlaying,
  loadingStreamingUrl,
  togglePlayPause,
  skipForward,
  skipBack,
  toggleShuffle,
  isRandom,
  currentTime,
  repeat,
  setRepeat,
  duration,
  playlist,
  music,
  radio,
  podcast,
}: any) {
  const handleRepeat = () => {
    if (!isRandom) {
      if (repeat === 0) {
        setRepeat(1);
      } else if (repeat === 1) {
        setRepeat(2);
      } else {
        setRepeat(0);
      }
    }
  };
  return (
    <>
      {/* play time */}
      {!radio && !fullScreen && (
        <p className={`max-md:hidden min-w-[50px]  text-center`}>
          {currentTime ? formatDurationHr_Min_Sec(currentTime) : "00:00"}
        </p>
      )}

      {/* shuffle btn */}
      {toggleShuffle && (
        <div
          className={`loop-button text-2xl max-sm:hidden cursor-pointer`}
          onClick={toggleShuffle}
        >
          <FiShuffle
            className={` ${
              isRandom
                ? "text-red-600 max-sm:hidden"
                : "text-white max-sm:hidden"
            }`}
            data-tooltip-id={`audio-player-shuffle`}
          />
          <div className="text-sm">
            <ReactTooltip
              id={`audio-player-shuffle`}
              place="top"
              content={"Shuffle"}
            />
          </div>
        </div>
      )}

      {/* backward btn */}
      {skipBack && (
        <div
          className="text-3xl cursor-pointer skip-back-button"
          onClick={skipBack}
        >
          <AiFillStepBackward
            data-tooltip-id={`audio-player-previous`}
            className={
              playlist?.length > 1
                ? `text-white`
                : `text-[var(--apple)] cursor-not-allowed`
            }
          />
          <div className="text-sm">
            <ReactTooltip
              id={`audio-player-previous`}
              place="top"
              content={music ? `Previous Song` : `Previous Podcast`}
            />
          </div>
        </div>
      )}

      {/* play button */}
      <div
        className={`text-4xl cursor-pointer play-pause-button`}
        onClick={togglePlayPause}
      >
        {loadingStreamingUrl ? (
          <PlayerLoadingSpin />
        ) : isPlaying ? (
          <AiFillPauseCircle
            data-tooltip-id={`audio-player-play-pause`}
            className={`${radio && `scale-[1.4]`}`}
          />
        ) : (
          <AiFillPlayCircle
            data-tooltip-id={`audio-player-play-pause`}
            className={`${radio && `scale-[1.4]`}`}
          />
        )}
        <div className="text-sm">
          <ReactTooltip
            id={`audio-player-play-pause`}
            place="top"
            content={isPlaying ? "Pause" : "Play"}
          />
        </div>
      </div>

      {/* forward btn */}
      {skipForward && (
        <div
          className="text-3xl cursor-pointer skip-forward-button"
          onClick={skipForward}
        >
          <AiFillStepForward
            data-tooltip-id={`audio-player-next`}
            className={
              playlist?.length > 1
                ? `text-white`
                : `text-[var(--apple)] cursor-not-allowed`
            }
          />
          <div className="text-sm">
            <ReactTooltip
              id={`audio-player-next`}
              place="top"
              content={music ? `Next Song` : `Next Podcast`}
            />
          </div>
        </div>
      )}

      {/* repeat btn */}
      {handleRepeat && !radio && (
        <div className="cursor-pointer repeat-button max-sm:hidden">
          <button
            className={`text-2xl ${
              isRandom ? "text-[#747474]" : "text-white"
            } ease-in-out duration-200`}
            disabled={isRandom ? true : false}
            onClick={handleRepeat}
            data-tooltip-id={`audio-player-repeat`}
          >
            {repeat === 0 ? (
              <TbRepeat />
            ) : repeat === 1 ? (
              <TbRepeatOnce />
            ) : (
              <TbRepeatOff />
            )}
            <div className="text-sm">
              {!isRandom && (
                <ReactTooltip
                  id={`audio-player-repeat`}
                  place="top"
                  content={
                    repeat === 0
                      ? "Repeat list"
                      : repeat === 1
                      ? "Repeat current song"
                      : "No repeat"
                  }
                />
              )}
            </div>
          </button>
        </div>
      )}

      {/* duration */}
      {!radio && !fullScreen && (
        <p className={`max-md:hidden min-w-[50px]  text-center`}>
          {duration ? formatDurationHr_Min_Sec(duration) : "00:00"}
        </p>
      )}
    </>
  );
}

export default ControlButtons;
