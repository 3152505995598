import React from "react";
import PayPerViewCard from "./PayPerViewCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import SubText from "components/shared/SubText";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import ConditionalSeeAll from "./ConditionalSeeAll";

function PurchesedCoursel({ events, user }: any) {
  return (
    <div className="my-8">
      {/* top section */}
      <div className="flex justify-between w-[98%] mt-10 mb-8">
        <SubText text={"Purchased Events"} className="font-bold" />
        <ConditionalSeeAll
          length={events?.length}
          route={RouteName.purchasedSeeAllRoute}
        />
      </div>
      <div>
        <Swiper
          slidesPerView={7}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            350: {
              slidesPerView: 2,
            },
            550: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
            1100: {
              slidesPerView: 5,
            },
            1200: {
              slidesPerView: 6,
            },
            1400: {
              slidesPerView: 7,
            },
          }}
          modules={[Pagination]}
          className="mt-4 mb-5 mySwiper"
        >
          {events &&
            events?.map((event: any, i: number) => (
              <SwiperSlide className="" key={i}>
                <PayPerViewCard
                  event={event}
                  user={user}
                  isPurchased={
                    events.some(
                      (purchasedEvent: any) => purchasedEvent?.id === event?.id
                    )
                      ? true
                      : false
                  }
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}

export default PurchesedCoursel;
