import { useEffect, useState } from "react";
import { useParams } from "react-router";
import TrendingNearYou from "pages/videoPage/discover/TrendingNearYou";
import VideoService from "services/video/VideoService";
import GenreSelector from "components/shared/GenreSelector";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import NoSearch from "pages/searchPage/shared/NoSearch";
import MovieCardSkeleton from "components/skeleton/MovieCardSkeleton";
import GeneralServices from "services/General/GeneralServices";
import { Adsense } from "@ctrl/react-adsense";
import ContentType from "components/shared/ContentType";
import MoviesGrid from "./MoviesGrid";

function VideoPageDiscoverByGenreId() {
  const { id } = useParams();
  // console.log(id);
  const [movies, setMovies] = useState([]);
  const [tvShows, setTvShows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState<any>("");
  const navigate = useNavigate();
  const [genreName, setGenreName] = useState<any>(id);
  const [contentType, setContentType] = useState<any>("Movies");

  const [gAdSlot, setgAdSlot] = useState<any>();

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_video_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  //load data according to the id
  useEffect(() => {
    setLoading(true);
    VideoService.getMoviesAndTVSeriesByGenre(id)
      .then((res) => {
        setMovies(res.movies);
        setTvShows(res["tv-series"]);
        setLoading(false);
        setTitle(res.genre);
      })
      .catch((e) => {
        // console.log(e);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    setGenreName(id);
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div>
        <button
          className="w-10 h-10 mb-5 bg-white rounded-full ml"
          onClick={() => {
            navigate(-3);
          }}
        >
          <AiOutlineArrowLeft className="ml-3" />
        </button>
      </div>
      {/* main topic section */}
      {!loading ? (
        <>
          <div>
            <div className="flex items-center justify-between mb-8 max-sm:block w-[98%]">
              <h1 className="text-xl font-medium text-white lg:text-2xl md:text-3xl max-sm:ml-2 max-sm:mb-5">
                Discover Movies and TV series in{" "}
                <span className="red-text">{title.name}</span>
              </h1>
              <div className="flex gap-4">
                <ContentType
                  setContentType={setContentType}
                  contentType={contentType}
                />
                <GenreSelector slug={genreName} />
              </div>
            </div>
            {gAdSlot && (
              <Adsense
                client="ca-pub-5773929890067376"
                slot={gAdSlot}
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  marginTop: "20px",
                  height: "90px",
                  justifyContent: "center",
                }}
                format="horizontal"
                data-full-width-responsive="true"
                // format="fluid"
              />
            )}
          </div>

          {/* {movies.length === 0 && tvShows.length === 0 ? (
            <NoSearch text={title.name} />
          ) : (
            <> */}
          {contentType === "Movies" ? (
            <>
              {movies?.length === 0 ? (
                <>
                  <NoSearch text={title.name} />
                </>
              ) : (
                <>
                  {/* movie coursel */}
                  {movies?.length > 0 && (
                    <MoviesGrid cardDetails={movies && movies} isMovie={true} />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {tvShows?.length === 0 ? (
                <>
                  <NoSearch text={title.name} />
                </>
              ) : (
                <>
                  {/* tv shows coursel */}
                  {tvShows?.length > 0 && (
                    <MoviesGrid
                      cardDetails={tvShows && tvShows}
                      isMovie={false}
                    />
                  )}
                </>
              )}
            </>
          )}
          {/* </>
          )} */}
        </>
      ) : (
        <>
          {loading && (
            <>
              {" "}
              <div className="h-[40px] w-[50%] max-sm:w-[75%] bg-[#1a1919] animate-pulse mt-2"></div>
              <MovieCardSkeleton />
              <MovieCardSkeleton />
            </>
          )}
        </>
      )}
    </>
  );
}

export default VideoPageDiscoverByGenreId;
