import RippleLoader from "img/Ripple.svg";
function Ripple() {
  return (
    <div className="flex items-center">
      <img
        src={RippleLoader}
        alt="loader"
        style={{ margin: "0 auto", display: "block" }}
      />
    </div>
  );
}

export default Ripple;
