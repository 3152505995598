import React from "react";
import { gridCard } from "constant/gridCard";
import PodcastHistoryCardSection from "./shared/PodcastHistoryCardSection";

function PodcastPageHistory() {
  return (
    <div className="max-sm:mx-3">
      {/* main topic part */}
      <div>
        <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl">
          User's History
        </h1>
      </div>

      {/* card section */}
      <PodcastHistoryCardSection gridCard={gridCard} />
    </div>
  );
}

export default PodcastPageHistory;
