import { useEffect, useRef, useState } from "react";
import TVSeriesTabs from "./TVSeriesTabs";
import { TabNames } from "constant/tabNames";
import SourceInfo from "./SourceInfo";
import Episodes from "./Episodes";
import MovieTabs from "./MovieTabs";

function VideoPlayerLeftSidePanel({ isActive, videoDetails, loading }: any) {
  const [selectedTab, setSelectedTab] = useState("");
  useEffect(() => {
    setSelectedTab(
      videoDetails.no_of_seasons !== undefined
        ? TabNames.Episodes
        : TabNames.Info
    );
  }, [videoDetails]);
  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 0; // Reset scroll position
    }
  }, [selectedTab]);

  return (
    <>
      {/* side bar shows on isactive variable */}

      <div
        className={`video-player-side-panel sidebar ${
          isActive
            ? "left-[-8px] md:w-[600px]"
            : "md:left-[-100px] left-[-60px] md:w-[100px]"
        }`}
      >
        {/* icon bar */}
        <div className="items-center bg-gradient-to-r from-black/95 text-gray-100 mt-14 !w-[600px] pt-32 pl-[80px] px-5 text-left !h-screen justify-center ">
          {isActive && (
            <div className="fixed flex items-center mb-5 ">
              {videoDetails.no_of_seasons !== undefined ? (
                <TVSeriesTabs
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              ) : (
                <MovieTabs
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              )}
            </div>
          )}
          {/* <div className="h-[100%]  pb-10 my-10 overflow-y-scroll mb-20 scrollbar-hide">
            {selectedTab === TabNames.Episodes && (
              <Episodes videoDetails={videoDetails} />
            )}
            {selectedTab === TabNames.Info && (
              <SourceInfo videoDetails={videoDetails} />
            )}
          </div> */}
          <div
            className="h-[100%] pb-10 my-14 overflow-y-scroll scrollbar-hide"
            ref={scrollableRef}
          >
            {selectedTab === TabNames.Episodes && videoDetails && (
              <Episodes
                videoDetails={videoDetails && videoDetails}
                loading={loading}
              />
            )}
            {selectedTab === TabNames.Info && videoDetails && (
              <SourceInfo
                videoDetails={videoDetails && videoDetails}
                loading={loading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoPlayerLeftSidePanel;
