import axios from 'axios';
import authHeader from 'utils/auth-header';

const baseURL = process.env.REACT_APP_BACKEND_SERVER;

const getArtistById = async (id: any) => {
  const response = await axios({
    method: 'get',
    url: `${baseURL}/artist/${id}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  // alert("Favourite created --- "+ response);
  return response.data;
};

const getAlbumsByArtistId = async (id: any) => {
  const response = await axios({
    method: 'get',
    url: `${baseURL}/artist/${id}/albums`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  // alert("Favourite created --- "+ response);
  return response.data;
};

const getAllArtists = async (url: any) => {
  const response = await axios({
    method: 'get',
    url: `${baseURL}/${url}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  // alert("Favourite created --- "+ response);
  return response.data;
};

const getSimilarArtists = async (id: any) => {
  const response = await axios({
    method: 'get',
    url: `${baseURL}/artist/${id}/similar-artists`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  // alert("Favourite created --- "+ response);
  return response.data;
};

const getArtistsByGenre = async (slug: any) => {
  const response = await axios({
    method: 'get',
    url: `${baseURL}/genre/${slug}/artists`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  return response.data;
};

const likeArtist = async (data: any) => {
  const response = await axios({
    method: 'post',
    data: data,
    url: `${baseURL}/artist/like`,
    headers: authHeader(),
  });
  return response.data;
};

const UnLikeArtist = async (data: any) => {
  const response = await axios({
    method: 'post',
    data: data,
    url: `${baseURL}/artist/unlike`,
    headers: authHeader(),
  });
  return response.data;
};

const getArtistBySlug = async (slug: any) => {
  const response = await axios({
    method: 'get',
    url: `${baseURL}/artist/by/${slug}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  // alert("Favourite created --- "+ response);
  return response.data;
};

const ArtistService = {
  getArtistById,
  getAlbumsByArtistId,
  getAllArtists,
  getSimilarArtists,
  getArtistsByGenre,
  likeArtist,
  UnLikeArtist,
  getArtistBySlug,
};

export default ArtistService;
