import React, { useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import peopleHero from "Assets/Images/peopleHero.png";
import CreatorModel from "components/CreatorModal";

const BecomeACreatorHome = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const [creatorModel, setCreatorModel] = useState(false);

  const clickLogin = () => {
    // console.log("login");
    setCreatorModel((current) => !current);
  };
  return (
    <div
      className="overflow-hidden max-sm:w-[100%] mt-[-20px] ml-[-10px]"
      // main back image
      style={{
        color: "#fff",
        backgroundImage: `url('')`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <div className="loginpage-back scrollbar-hide">
        <div
          className="absolute h-[1200px] w-[1200px] -right-[-10px] -top-[300px] overflow-hidden"
          style={{
            color: "#fff",
            backgroundImage: `url('https://i.stack.imgur.com/G7vn7.png')`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="absolute h-[500px] w-[500px] right-[300px] top-[180px] overflow-hidden scrollbar-hide max-sm:blur-md max-sm:opacity-30 max-sm:right-0 max-md:blur-md max-md:opacity-30 max-md:right-0 max-lg:blur-md max-lg:opacity-30 max-lg:right-[100px] max-xl:right-[50px] max-2xl:right-[100px]">
          <img src={peopleHero} alt="" />
        </div>
        <div className="login-page-form-container w-[60%] max-sm:w-full max-md:w-full max-xl:w-full scrollbar-hide">
          <div
            className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer mb-4`}
          >
            <HiArrowLeft
              className="text-black"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="max-sm:w-[80%] max-md:w-[80%] max-lg:w-[80%]">
            <h1 className="text-[60px] font-[700] w-full text-start mt-[100px] max-sm:text-[40px] max-sm:font-[500]  max-sm:mt-[70px] max-md:text-[50px]">
              Connect With Your <br /> Fans on AYOZAT
            </h1>
            <p className="mt-10 text-sm text-start">
              Become a verified artist and get connect with your fan, sell your
              songs,
              <br /> earn streaming money, and many more.
            </p>
          </div>
          <button
            className="flex px-4 py-3 text-lg font-bold rounded-full mt-[80px] white-text button-red"
            // onClick={clickLogin}
            disabled
          >
            {/* Claim Artist Profile */}
            Coming Soon
          </button>
        </div>
        {creatorModel && <CreatorModel clickLogin={clickLogin} />}
      </div>
    </div>
  );
};

export default BecomeACreatorHome;
