import { RouteName } from "constant/routeNames";
import React from "react";
import { HiArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const BlogPageHeader = ({ blogsDetails }: { blogsDetails: any }) => {
  // console.log(blogsDetails);

  const navigate = useNavigate();

  if (!blogsDetails) {
    return <div>Loading...</div>; // Or handle the case when blogsDetails is undefined
  }

  return (
    <div className="flex-col items-center justify-center w-full mx-auto mb-10 overflow-hidden text-justify text-gray-400 rounded-lg ">
      <div
        className="relative w-[100%] h-[300px] rounded-xl border-[1px] border-gray-700  "
        style={{
          background: `url(${blogsDetails?.thumbnail_url}) no-repeat center`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "300px",
          width: "100%",
        }}
      >
        <div
          className={`!z-20 absolute left-5 top-5 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer max-sm:ml-[7px] mb-3`}
          onClick={() => {
            navigate(RouteName.Blog.replace("/*", ""));
          }}
        >
          <HiArrowLeft className="text-black" />
        </div>
        <div className="absolute flex items-center h-[100%] mx-auto w-[100%] bg-gradient-to-t from-black to-transparent">
          <div className="flex flex-col justify-center items-center w-[100%] mx-auto my-auto h-[100%] mb-5 text-center mt-20">
            <h1 className="text-[30px] font-bold text-white  max-sm:text-[20px] w-[70%] max-sm:w-[90%]">
              {blogsDetails?.title}
            </h1>
            <p className="font-light text-center text-gray-400 max-sm:text-[15px] mt-10">
              {blogsDetails?.created_by}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPageHeader;
