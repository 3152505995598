import MovieCarouselCard from "pages/videoPage/shared/MovieCarouselCard";
import React, { useEffect, useState } from "react";
import NoFavorite from "./NoFavorite";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import CreatorVideoCard from "pages/BecomeACreator/shared/CreatorVideoCard";
import "swiper/css";
import "swiper/css/pagination";

function EpisodesByUser({ user, favorite, favoriteData }: any) {
  const [episodes, setEpisodes] = useState<any>([]);
  const allEpisodes = favoriteData?.favorite_publication_videos || [];

  useEffect(() => {
    setEpisodes(allEpisodes);
  }, []);
  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
        <div>
          <h2 className="text-xl font-medium text-white lg:text-xl md:text-xl">
            {/* Videos by User */}
            Favourite Creator Videos
          </h2>
        </div>
      </div>
      {/* card section */}
      {allEpisodes?.length === 0 && allEpisodes ? (
        <NoFavorite text="Creator Videos" />
      ) : (
        <div className="">
          <Swiper
            slidesPerView={5}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              380: {
                slidesPerView: 2,
              },
              700: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
              // 1400: {
              //   slidesPerView: 6,
              // },
              // 1600: {
              //   slidesPerView: 7,
              // },
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="mt-8 mb-10 mySwiper "
          >
            {allEpisodes?.length > 0 &&
              allEpisodes?.map((card: any, i: any) => (
                <SwiperSlide key={i}>
                  <div className="mx-3 max-sm:mx-1">
                    <CreatorVideoCard
                      video={card}
                      slug={card?.creator?.slug}
                      // season={episode?.season}
                      // id={episode?.id}
                      //   isLoading={isLoading}
                    />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
    </div>
  );
}

export default EpisodesByUser;
