import { formatDuration } from "utils/timeFunctions";
import AddSongToFav from "pages/musicPage/shared/AddSongToFav";
import AudioDetails from "../NewComponents/AudioDetails";

function FullScreenNextSong({ nextSong, music, radio, podcast }: any) {
  return (
    <div className="flex items-center justify-between">
      <AudioDetails
        music={music}
        radio={radio}
        podcast={podcast}
        currentSong={nextSong}
      />
      <div className="flex items-center justify-between gap-4">
        <p>{formatDuration(nextSong?.duration)}</p>
        <AddSongToFav song={nextSong} />
      </div>
    </div>
  );
}

export default FullScreenNextSong;
