import React from "react";

function ButtonSection() {
  return (
    <div>
      <div className="w-[110px] h-[40px] rounded-[40px] bg-[#1a1919] animate-pulse"></div>
    </div>
  );
}

export default ButtonSection;
