import { notification } from "antd";
import { toast } from "react-toastify";

export function triggerSuccessNotification(msg: string) {
  toast.success(`${msg}`);

  // notification.success(
  //     {
  //     message: msg,
  //     placement: "topRight",
  //     className: "dark-notification",
  //     duration: 20,
  //     style: {
  //       width: 300,
  //       backgroundColor: "#00000",
  //     },
  //   }
  // );
}

export function triggerWarningNotification(msg: string) {
  // notification.warning({
  //   message: msg,
  //   // description: `${song?.title} has been added to the queue.`,
  //   placement: "topRight", // Customize the placement as needed
  //   className: "dark-notification",
  //   duration: 1, // never close automatically
  // });

  toast.success(`${msg}`);
}

export function triggerErrorNotification(msg: string) {
  // notification.error({
  //   message: msg,
  //   // description: `${song?.title} has been added to the queue.`,
  //   placement: "topRight", // Customize the placement as needed
  //   className: "dark-notification",
  //   duration: 1, // never close automatically
  // });

  toast.error(`${msg}`);
}
