import { useNavigate } from "react-router-dom";

function EventWaitingSection({ ppv_event }: any) {
  const navigate = useNavigate();

  const handleEventButton = () => {
    navigate(`/ppv`);
  };

  return (
    <div className="flex items-center h-screen justify-center">
      <div className="p-10 rounded bg-gray-700 text-gray-300 flex items-center justify-between gap-6 w-3/4 mx-auto">
        <div className="w-1/2 flex flex-col justify-center items-center">
          <h1 className="text-[21px] font-bold text-gray-400">
            {ppv_event?.title}
          </h1>
          <div>
            <h1 className="text-[27px] font-bold text-gray-300">
              ${ppv_event?.price}
            </h1>
          </div>
          <div className="mt-4">
            <img
              src={ppv_event?.bannerImage}
              alt={ppv_event?.title}
              className="object-cover mx-auto w-3/4"
            />
          </div>
        </div>
        <div className="w-1/2 text-center px-4">
          <h1 className="font-bold text-xl mb-3 text-gray-200">
            Get Ready for the Event!
          </h1>
          <p className="text-base text-gray-400">
            The event has not started streaming yet. Please Stay tuned for
            exclusive coverage.
          </p>
          <button
            className="flex mx-auto mt-8 px-10 py-3 bg-[#f30543] rounded-full"
            onClick={handleEventButton}
          >
            View events
          </button>
        </div>
      </div>
    </div>
  );
}

export default EventWaitingSection;
