import FeaturePages from "layout/FeaturePages";
import { Routes, Route } from "react-router";
import SearchResultPage from "./searchPage/SearchResultPage";

function SearchPage({
  handleClick,
  isActive,
  setIsActive,
  anyPlayerActive,
}: any) {
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        <Route
          path=""
          element={
            <FeaturePages
              Content={SearchResultPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default SearchPage;
