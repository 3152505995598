import React, { useEffect, useState } from "react";
import RadioGenreCard from "./RadioGenreCard";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import RadioService from "services/radio/RadioService";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import SubText from "components/shared/SubText";
import CategorySkeleton from "../skeleton/CategorySkeleton";

function Categories() {
  const [categories, setCategories] = useState([]);
  const [isLoadingCategory, setIsLoadingCategory] = useState<any>(false);

  useEffect(() => {
    setIsLoadingCategory(true);
    RadioService.getAllRadioCategories().then((res) => {
      setIsLoadingCategory(false);
      setCategories(res);
      // console.log(res);
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      {!isLoadingCategory && (
        <div>
          <div className="flex justify-between w-full mt-10">
            <SubText text="Categories" className="font-bold" />
            <Link to={RouteName.SeeAllRadioCategoryRoute}>
              <button className="text-[#F50537] text-[15px] font-[500] mr-4">
                See all
              </button>
            </Link>
          </div>
          {/* coursel */}
          <div className="">
            <Swiper
              slidesPerView={5}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                370: {
                  slidesPerView: 2,
                },
                740: {
                  slidesPerView: 3,
                },
                1100: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 5,
                },
                1500: {
                  slidesPerView: 6,
                },
                1600: {
                  slidesPerView: 7,
                },
              }}
              modules={[Pagination, Navigation]}
              navigation={true}
              className="mt-5 mb-5 mySwiper"
            >
              {categories.map((genre: any, i: number) => (
                <SwiperSlide className="" key={i}>
                  <RadioGenreCard genre={genre} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
      {isLoadingCategory && <CategorySkeleton />}
    </>
  );
}

export default Categories;
