import { HiArrowRight } from 'react-icons/hi';

const VideoLandingCard = ({
  theme,
  title,
  info,
  id,
  discription,
  img,
}: any) => {
  return (
    <div
      className={`${
        theme === 'light'
          ? 'button-white pt-4 gridCard-hover -mt-6'
          : 'background-gray pt-4 gridCard-hover -mt-6'
      } h-full overflow-hidden ${id === 1 ? 'w-full text-[20px]' : ''}`}
    >
      <div className="w-[333px] h-[320px] mx-auto">
        <div className="w-[80%]">
          <h3
            className={
              theme === 'light' ? 'black-text mb-4' : 'white-text mb-4'
            }
          >
            <span className="mr-2 font-extrabold red-text lg:text-xl md:text-lg sm:text-md ">
              AYOZAT
            </span>{' '}
            {title}
          </h3>
          <p
            className={
              theme === 'light'
                ? 'black-text font-Plus Jakarta Sans font-[700] mb-4'
                : 'white-text font-Plus Jakarta Sans font-[700] mb-4'
            }
          >
            {info}
          </p>
          <p
            className={`${
              theme === 'light'
                ? 'black-text font-medium flex items-center cursor-pointer'
                : 'white-text font-medium flex items-center cursor-pointer'
            } ${
              id === 1 ? 'text-[12px] text-[#7B9DB4] !important w-[280px]' : ''
            }`}
          >
            {discription}
          </p>
          <p
            className={`${
              theme === 'light'
                ? 'black-text font-medium flex items-center cursor-pointer'
                : 'white-text font-medium flex items-center cursor-pointer'
            } ${id === 1 ? 'text-[16px] mt-4' : ''}`}
          >
            Explore <HiArrowRight className="ml-2" />
          </p>
        </div>
        {/* bottom image */}
        <div className="w-full">
          <img
            // src="https://i.pcmag.com/imagery/reviews/05cItXL96l4LE9n02WfDR0h-6..v1575422587.png"
            src={img}
            alt="card figure"
            className={`${
              id === 1
                ? 'mt-28 overflow-hidden w-[320px] h-[350px] shadow-2xl img-shadow'
                : 'mt-8 overflow-hidden w-[320px] h-[200px] shadow-2xl mt-22 img-shadow'
            }`}
            style={{
              backgroundSize: '100%',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoLandingCard;
