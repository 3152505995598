import React from "react";
import { TbLivePhoto } from "react-icons/tb";

function LiveButton() {
  return (
    <div className="absolute top-0 w-full flex justify-end left-0 rounded-md text-black h-6 text-[12px] bg-gradient-to-t from-transparent to-black">
      <div className="flex items-center mt-1 mr-2 w-[30%] p-1 liveButton rounded-sm">
        <TbLivePhoto className="mr-1 text-black animate-ping" />
        <p>LIVE</p>
      </div>
    </div>
  );
}

export default LiveButton;
